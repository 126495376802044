import PermanentDebtStatus from '@/modules/PermanentDebts/statics/permanentDebtStatus';
import TimePeriod from '@/modules/PermanentDebts/statics/timePeriod';

export default {
  title: 'Ihre regelmäßigen Einzüge',
  subtitle: 'Hier finden Sie einen Überblick über Ihre regelmäßigen Einzüge',
  permanentDebtsCounted: '1 regelmäßiger Einzug | {count} regelmäßige Einzüge',
  subject: 'Betreff',
  customNumber: 'Einzug-Nummer',
  start: 'Start',
  end: 'Ende',
  nextExecution: 'nächste Ausführung',
  grossAmount: 'Brutto-Betrag',
  netAmount: 'Netto-Betrag',
  vat: 'Mehrwertsteuer',
  statusOptions: {
    [PermanentDebtStatus.CREATED]: 'Erstellt',
    [PermanentDebtStatus.ACTIVE]: 'Aktiv',
    [PermanentDebtStatus.STOPPED]: 'Beendet',
    [PermanentDebtStatus.EXPIRED]: 'Beendet'
  },
  ongoing: 'fortlaufend',
  repetition: 'Wiederholung',
  periodOptions: {
    [TimePeriod.DAILY]: 'täglich',
    [TimePeriod.WEEKLY]: 'wöchentlich',
    [TimePeriod.BIWEEKLY]: 'zwei-wöchentlich',
    [TimePeriod.MONTHLY]: 'monatlich',
    [TimePeriod.BIMONTHLY]: 'alle 2 Monate',
    [TimePeriod.THREE_MONTHLY]: 'alle 3 Monate',
    [TimePeriod.SIX_MONTHLY]: 'alle 6 Monate',
    [TimePeriod.YEARLY]: 'alle 12 Monate'
  },
  noData: 'Sie haben noch keine regelmäßigen Einzüge angelegt',
  noDataInfo:
    'Erfassen Sie einen regelmäßigen Einzug, um Ihren Kunden regelmäßige Leistungen in Rechnung zu stellen. Wir übernehmen anschließend die Rechnungserstellung und den Rechungsversand für Sie.',
  uploadFirst: 'Jetzt meinen ersten Einzug erfassen',
  noDataForLocation: 'Noch kein Einzug zum Kunden erfasst',
  details: 'Einzugsdetails',
  directDebit: 'Einzug',
  nextDirectDebit: 'Nächster Einzug',
  onDayOfMonth: 'am {day}. des Monats',
  bankDetails: 'Bankverbindung',
  noPermanentDebtVouchers: 'Es wurden noch keine Rechnungen erstellt.',
  addPermDebt: 'Regelm. Einzug hinzufügen',
  createPermanentDebt: 'Regelmäßigen Einzug erstellen',
  createdPermanentDebt: 'Regelmäßigen Einzug hinzugefügt',
  createInfo:
    'Legen Sie hier Ihre Einstellungen für <b>wiederkehrende Rechnungen</b> fest. Wir übernehmen anschließend die Rechnungserstellung und den Rechungsversand für Sie.',
  startsOn: 'Beginnt am',
  endsOn: 'Endet am',
  minStartInfo: 'Der erste Einzug kann ab dem {date} erfolgen.',
  dayOfPerformance: 'Ausführungstag',
  firstExecution: 'Erste Ausführung',
  uploadContract: 'Vertrag hochladen',
  noDebtors: 'Bitte legen Sie zunächst einen Kunden an.',
  confirmSepa: 'Ich bestätige hiermit, dass mir das SEPA-Mandat vorliegt.',
  confirm: 'Einzug bestätigen',
  progress: {
    debtor: 'Bitte wählen Sie einen Kunden und die Versandadresse.',
    contract: 'In welchem Zeitraum wird die Leistung erbracht? Halten Sie auch einen Betreff fest.',
    service: 'Halten Sie einen Betreff und ggf. weitere Zusatzinformationen fest.',
    amount: 'Geben Sie den zu zahlenden Betrag an.',
    sepa: 'Für den Lasteinzug benötigen wir die Bankverbindung Ihres Kunden.',
    summary: '@:checkData'
  },
  stop: {
    stopPermanentDebt: 'Einzug stoppen',
    requestConfirmation:
      'Möchten Sie den regelmäßigen Einzug wirklich stoppen? Sie haben im Anschluss die Möglichkeit, einen neuen Einzug zu erstellen.',

    stoppedPermanentDebt: 'Einzug gestoppt',
    confirmation:
      'Der regelmäßige Einzug wurde gestoppt. Möchten Sie jetzt einen neuen Einzug anlegen?'
  },
  stopVoucher: 'Stopp',
  pdf: {
    sepaTemplate: 'Vorlage herunterladen'
  }
};
