<template>
  <div>
    <v-tabs
      background-color="transparent"
      color="secondary"
      grow
      :value="value"
      :show-arrows="isMobile"
      data-testid="tabs"
      @change="$emit('input', $event)"
    >
      <v-tab v-for="tab in tabs" :key="tab.name" class="text-none" data-testid="tab">
        {{ tab.name }}
        <app-flat-badge v-if="tab.badge !== undefined" :value="tab.badge" />
      </v-tab>
    </v-tabs>

    <v-tabs-items :value="value" @change="$emit('input', $event)">
      <v-tab-item v-for="tab in tabs" :key="tab.name">
        <slot></slot>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import AppFlatBadge from '@/shared/components/AppFlatBadge';

export default {
  name: 'AppTabs',

  components: {
    AppFlatBadge
  },

  props: {
    value: {
      type: Number,
      default: 0
    },
    tabs: {
      type: Array,
      default: () => []
    }
  },

  watch: {
    '$route.query.tab': {
      handler() {
        this.setQueryAsValue();
      },
      immediate: true
    },

    value: {
      handler() {
        this.setValueAsQuery();
      },
      immediate: false
    }
  },

  methods: {
    setValueAsQuery() {
      if (String(this.getQueryParam(this.queryParam.TAB)) === String(this.tabs[this.value].id)) {
        return;
      }

      this.setQueryParam([this.queryParam.TAB], this.tabs[this.value].id, true);
    },

    setQueryAsValue() {
      let tabIdFromQuery = this.tabs.findIndex(
        (tab) => String(tab.id) === String(this.getQueryParam(this.queryParam.TAB))
      );

      if (tabIdFromQuery === this.value) {
        return;
      }

      if (
        (this.getQueryParam(this.queryParam.TAB) === undefined && this.value !== 0) ||
        tabIdFromQuery === -1
      ) {
        this.$emit('input', 0);
        return;
      }
      this.$emit('input', tabIdFromQuery);
    }
  },

  destroyed() {
    if (this.getQueryParam(this.queryParam.TAB) !== undefined) {
      this.$router.replace({
        ...this.$route,
        query: { ...this.$route.query, [this.queryParam.TAB]: undefined }
      });
    }
  }
};
</script>

<style scoped>
>>> .row {
  margin-bottom: 10px;
}

>>> .col {
  padding: 0;
}

>>> .v-tabs {
  border-bottom: 1px solid rgba(44, 44, 44, 0.15);
}

.v-tab {
  padding: 0 0.5rem;
  justify-content: flex-start;
  letter-spacing: unset;
}

>>> .v-slide-group__next,
>>> .v-slide-group__prev {
  min-width: 2rem;
}

.v-tab ~ .v-tab {
  justify-content: center;
}

>>> .v-tabs-slider {
  background-color: var(--c-tabs-active);
}
</style>
