<template>
  <ValidationProvider v-slot="{ errors }" v-bind="validationProps">
    <v-select
      :id="fieldId"
      v-model="selection"
      :class="{
        'v-input--is-focused primary--text v-select--is-menu-active': showMenu,
        'input--has-error': errors.length > 0,
        'input--is-filter': filter
      }"
      :error-messages="errors"
      :hide-details="errors.length === 0"
      :items="items"
      :label="fieldLabelWithSupplement"
      :menu-props="{
        'content-class': `${contentClass} select__menu`,
        'offset-y': true,
        value: showMenu
      }"
      :multiple="multiple"
      :placeholder="fieldPlaceholder"
      append-icon="mdi-chevron-down"
      flat
      height="45"
      item-color="secondary"
      item-text="label"
      item-value="value"
      outlined
      v-bind="$attrs"
      @click.native="showMenu = !showMenu"
    >
      <template #prepend-item>
        <slot name="label"></slot>
        <slot name="prepend-item"></slot>
      </template>
      <template v-if="filter" #label>
        <div class="selection__wrapper">
          <component :is="`${labelIcon}`" class="selection__icon" />
          <div class="selection__label">{{ fieldLabel }}</div>
        </div>
      </template>
      <template v-if="filter && label" #selection>
        <div class="selection__wrapper">
          <component :is="`${labelIcon}`" class="selection__icon" />
          <div class="selection__label">{{ fieldLabel }}</div>
        </div>
      </template>
      <template v-if="filter" #item="{ item }">
        <slot name="item">
          <span class="select-menu__icon-wrapper">
            <app-spinner v-if="loadingValue === item.value" size="16" class="mx-1" />
            <v-icon v-else-if="itemSelected(item.value)">mdi-check</v-icon>
          </span>
          <div class="selection__label">{{ item.label }}</div>
        </slot>
      </template>
    </v-select>
    <v-overlay v-if="showMenu" style="opacity: 0" @click.native.stop="showMenu = !showMenu" />
  </ValidationProvider>
</template>

<script>
import FormValidationMixin from '@/mixins/FormValidationMixin';

export default {
  name: 'AppSelect',

  mixins: [FormValidationMixin],

  props: {
    contentClass: {
      type: String,
      default: ''
    },
    filter: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    labelIcon: {
      type: String,
      default: ''
    },
    loadingValue: {
      type: String,
      default: undefined
    },
    multiple: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Array, Number, String],
      default: ''
    }
  },

  data: () => ({
    showMenu: false
  }),

  computed: {
    selection: {
      get() {
        return this.value;
      },
      set(value) {
        this.filterItems(value);
      }
    }
  },

  methods: {
    filterItems(value) {
      this.$emit('input', value);
      if (this.multiple) {
        return;
      }
      this.showMenu = false;
    },
    itemSelected(itemValue) {
      if (Array.isArray(this.value)) {
        return this.value.some((selection) => selection === itemValue);
      }

      return this.value.toString().includes(itemValue);
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@/styles/mixins/selectStyle';

::v-deep.v-select {
  @include SelectMixin;
}
</style>

<style lang="scss">
@import '~@/styles/mixins/selectMenuStyle';

.select__menu {
  @include SelectMenuMixin;
}
</style>
