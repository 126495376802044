<template>
  <ValidationProvider v-slot="{ errors }" v-bind="validationProps">
    <v-text-field
      :id="fieldId"
      v-model.trim="text"
      :append-icon="appendIcon"
      :class="{
        'input--align-right': alignRight,
        'input--has-error': errors.length > 0,
        'input--prepend-inner': prependInnerIcon
      }"
      :error-messages="errors"
      :hide-details="errors.length === 0 && !$attrs.hint"
      :label="fieldLabelWithSupplement"
      :placeholder="fieldPlaceholder"
      :prepend-inner-icon="prependInnerIcon"
      data-testid="app-text-field__input"
      outlined
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template v-if="appendFunction" #append>
        <v-icon @click="executeCallback(appendFunction)">{{ appendIcon }}</v-icon>
      </template>

      <template v-if="prependInnerFunction" #prepend-inner>
        <v-icon @click="executeCallback(prependInnerFunction)">{{ prependInnerIcon }}</v-icon>
      </template>
    </v-text-field>
  </ValidationProvider>
</template>

<script>
import FormValidationMixin from '@/mixins/FormValidationMixin';

export default {
  name: 'AppTextField',

  mixins: [FormValidationMixin],

  props: {
    alignRight: {
      type: Boolean,
      default: false
    },
    appendFunction: {
      type: Function,
      default: null
    },
    appendIcon: {
      type: String,
      default: ''
    },
    prependInnerFunction: {
      type: Function,
      default: null
    },
    prependInnerIcon: {
      type: String,
      default: ''
    },
    passive: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    text: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    validationProps() {
      return {
        name: this.validationName,
        rules: this.validationRules,
        mode: this.passive ? 'passive' : this.mode,
        vid: this.vid ?? this.fieldId,
        customMessages: this.customErrorMessages
      };
    }
  },
  methods: {
    executeCallback(callback) {
      if (callback === null) {
        return;
      }

      callback();
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@/styles/mixins/textFieldStyle';

::v-deep.v-text-field {
  @include AppTextFieldMixin;
}
</style>
