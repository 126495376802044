<template>
  <svg
    width="24px"
    height="25px"
    viewBox="0 0 24 25"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-36.000000, -28.000000)" fill="currentColor" fill-rule="nonzero">
        <g transform="translate(36.000000, 28.000000)">
          <g>
            <path
              d="M11.9999992,24.7386066 C11.3372792,24.7386066 10.7999992,24.1881557 10.7999992,23.5090984 L10.7999992,13.6730328 L1.1999992,13.6730328 C0.5372592,13.6730328 -8.00000001e-07,13.122582 -8.00000001e-07,12.4435246 C-8.00000001e-07,11.7645082 0.5372592,11.2140164 1.1999992,11.2140164 L10.7999992,11.2140164 L10.7999992,1.37793443 C10.7999992,0.698918033 11.3372792,0.14842623 11.9999992,0.14842623 C12.6627592,0.14842623 13.1999992,0.698918033 13.1999992,1.37793443 L13.1999992,11.2140164 L22.8000008,11.2140164 C23.4627408,11.2140164 23.9999992,11.7645082 23.9999992,12.4435246 C23.9999992,13.122582 23.4627408,13.6730328 22.8000008,13.6730328 L13.1999992,13.6730328 L13.1999992,23.5090984 C13.1999992,24.1881557 12.6627592,24.7386066 11.9999992,24.7386066 Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconPlus'
};
</script>
