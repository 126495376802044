<template>
  <v-container
    id="app-filled-view-container"
    fluid
    class="pa-0 fill-height flex-nowrap align-stretch"
    style="position: absolute"
  >
    <v-card class="pa-0 d-flex flex-column flex-grow-1" elevation="16">
      <div style="position: absolute; right: 5px; top: 5px; z-index: 5">
        <slot name="actions"></slot>
        <app-icon-btn
          v-if="!isMobile"
          class="ml-n2"
          icon="mdi-close"
          size="20"
          @click="$emit('close')"
        />
      </div>

      <v-row no-gutters style="max-height: 100%" :class="{ 'flex-column': isMobile }">
        <slot name="container">
          <v-col :cols="isMobile ? 12 : 2" class="scroll-overflow flex-auto">
            <slot name="list"></slot>
          </v-col>

          <v-col
            :cols="isMobile ? 12 : 10"
            class="d-flex justify-center align-center scroll-overflow flex-fill"
          >
            <slot name="content">
              <v-row v-if="!isMobile" no-gutters class="scroll-overflow flex-grow-1 fill-height">
                <v-col
                  class="scroll-overflow fill-height d-flex flex-column"
                  cols="5"
                  style="border-right: 1px solid rgba(233, 233, 233, 1)"
                >
                  <slot name="details"></slot>
                </v-col>

                <v-col v-if="!isMobile" cols="7" class="d-flex justify-center scroll-overflow">
                  <slot name="preview"></slot>
                </v-col>
              </v-row>
            </slot>
          </v-col>
        </slot>
      </v-row>
    </v-card>
    <slot name="dialogs"></slot>
  </v-container>
</template>

<script>
export default {
  name: 'AppFilledViewContainer'
};
</script>
