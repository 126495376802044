export const sortObjectProperties = (unorderedObject = {}, orderedKeys = []) => ({
  // spread properties for ordered keys and their values in their given order
  ...orderedKeys.reduce((previousObject, currentKey) => {
    return unorderedObject[currentKey] !== undefined
      ? { ...previousObject, [currentKey]: unorderedObject[currentKey] }
      : previousObject;
  }, {}),
  // fill up with remaining, unsorted properties
  ...unorderedObject
});

export default sortObjectProperties;
