var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"app-data-table__card",attrs:{"flat":"","tile":""}},[(_vm.showTitle && !_vm.error)?_c('v-card-title',{staticClass:"app-data-table__title",attrs:{"data-testid":"app-data-table__title"}},[_vm._t("title")],2):_vm._e(),_c('v-divider',{staticStyle:{"flex":"0 0 100%"}}),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',_vm._g(_vm._b({ref:"table",class:{ 'app-data-table': true, 'app-data-table-mobile': _vm.isMobile },attrs:{"expanded":_vm.expanded,"footer-props":{
        itemsPerPageOptions: _vm.rowsPerPageItems,
        itemsPerPageText: _vm.$t('rowsPerPage'),
        showCurrentPage: true,
        showFirstLastPage: true
      },"headers":_vm.headers,"item-key":_vm.itemKey,"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.serverItemsLength,"hide-default-header":""},on:{"update:options":function($event){_vm.options=$event},"update:page":_vm.scrollToTableHeader},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('thead',{ref:"tableHeader",class:{ 'v-data-table-header': true, 'v-data-header-mobile': _vm.isMobile }},[_c('tr',[(!_vm.isMobile)?[(_vm.selectable)?_c('th',{class:{
                  'selectable semi-bold sortable': true,
                  loading: _vm.loading
                },attrs:{"role":"columnheader","scope":"col","width":"70px"}},[_c('app-checkbox',{key:_vm.selectedAllOnPage,attrs:{"indeterminate":!_vm.selectedAllOnPage && _vm.selectedSomeOnPage,"primary":"","hide-details":""},model:{value:(_vm.selectedAllOnPage),callback:function ($$v) {_vm.selectedAllOnPage=$$v},expression:"selectedAllOnPage"}})],1):_vm._e(),_vm._l((_vm.headers),function(header,index){return _c('th',{key:header.text,class:{
                  'semi-bold sortable': true,
                  active: header.value !== undefined && header.value === _vm.sortBy,
                  desc: _vm.sortDesc && _vm.sortBy === header.value,
                  asc: !_vm.sortDesc || _vm.sortBy !== header.value,
                  loading: _vm.loading
                },attrs:{"role":"columnheader","scope":"col","data-testid":"app-data-table__header","width":header.width}},[_c('table-header-content',{attrs:{"data-testid":"app-data-table__header-content","header":header,"index":index},on:{"sort":function($event){return _vm.sortColumn($event)}}})],1)})]:_c('th',{class:{ loading: _vm.loading },attrs:{"role":"columnheader"}},[_c('v-row',{staticClass:"v-data-table-header v-data-table-header-mobile__wrapper tr",attrs:{"dense":""}},[(_vm.selectable)?_c('v-col',{class:{
                    'selectable semi-bold sortable mr-4': true,
                    loading: _vm.loading
                  },attrs:{"cols":"auto","scope":"col"}},[_c('app-checkbox',{key:_vm.selectedAllOnPage,attrs:{"indeterminate":!_vm.selectedAllOnPage && _vm.selectedSomeOnPage,"primary":"","hide-details":""},model:{value:(_vm.selectedAllOnPage),callback:function ($$v) {_vm.selectedAllOnPage=$$v},expression:"selectedAllOnPage"}})],1):_vm._e(),_vm._l((_vm.filteredHeaders),function(header,index){return _c('v-col',{key:header.text,class:{
                    'semi-bold sortable th mr-4': true,
                    active: header.value !== undefined && header.value === _vm.sortBy,
                    desc: _vm.sortDesc && _vm.sortBy === header.value,
                    asc: !_vm.sortDesc || _vm.sortBy !== header.value
                  },attrs:{"cols":"auto","width":header.width}},[_c('table-header-content',{attrs:{"header":header,"index":index},on:{"sort":function($event){return _vm.sortColumn($event)}}})],1)})],2)],1)],2)])]},proxy:true},{key:"item",fn:function(ref){
                  var item = ref.item;
                  var index = ref.index;
return [_vm._t("item",null,{"item":item,"selectable":_vm.selectable,"selectedItems":_vm.selectedItems}),(_vm.isMobile && !_vm.isLastItem(index) && !_vm.isLastOnPage(index))?_c('v-divider',{staticClass:"mx-4"}):_vm._e()]}},{key:"expanded-item",fn:function(ref){
                  var item = ref.item;
return [_vm._t("expanded-item",null,{"item":item})]}},{key:"no-data",fn:function(){return [(_vm.error)?_c('app-error-alert',{attrs:{"align-center":false}}):(_vm.totalDataLength === 0)?_vm._t("no-data"):_vm._t("no-results",function(){return [_vm._v(_vm._s(_vm.$t('noMatchingResults')))]})]},proxy:true}],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }