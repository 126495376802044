export const TimePeriod = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  BIWEEKLY: 'biweekly',
  MONTHLY: 'monthly',
  BIMONTHLY: 'bimonthly',
  THREE_MONTHLY: 'three-monthly',
  SIX_MONTHLY: 'six-monthly',
  YEARLY: 'yearly'
};

export const PERIODS_REQUIRING_PERFORMANCE_DAY = [
  TimePeriod.MONTHLY,
  TimePeriod.BIMONTHLY,
  TimePeriod.THREE_MONTHLY,
  TimePeriod.SIX_MONTHLY,
  TimePeriod.YEARLY
];

export default TimePeriod;
