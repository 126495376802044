<template>
  <v-card class="app-data-table__card" flat tile>
    <v-card-title
      v-if="showTitle && !error"
      class="app-data-table__title"
      data-testid="app-data-table__title"
    >
      <slot name="title"></slot>
    </v-card-title>
    <v-divider style="flex: 0 0 100%" />

    <v-card-text class="pa-0">
      <v-data-table
        ref="table"
        :class="{ 'app-data-table': true, 'app-data-table-mobile': isMobile }"
        :expanded="expanded"
        :footer-props="{
          itemsPerPageOptions: rowsPerPageItems,
          itemsPerPageText: $t('rowsPerPage'),
          showCurrentPage: true,
          showFirstLastPage: true
        }"
        :headers="headers"
        :item-key="itemKey"
        :items="items"
        :loading="loading"
        :options.sync="options"
        :server-items-length="serverItemsLength"
        hide-default-header
        v-bind="$attrs"
        v-on="$listeners"
        @update:page="scrollToTableHeader"
      >
        <template #header>
          <thead
            ref="tableHeader"
            :class="{ 'v-data-table-header': true, 'v-data-header-mobile': isMobile }"
          >
            <tr>
              <template v-if="!isMobile">
                <th
                  v-if="selectable"
                  role="columnheader"
                  scope="col"
                  width="70px"
                  :class="{
                    'selectable semi-bold sortable': true,
                    loading: loading
                  }"
                >
                  <app-checkbox
                    :key="selectedAllOnPage"
                    v-model="selectedAllOnPage"
                    :indeterminate="!selectedAllOnPage && selectedSomeOnPage"
                    primary
                    hide-details
                  />
                </th>
                <th
                  v-for="(header, index) in headers"
                  :key="header.text"
                  role="columnheader"
                  scope="col"
                  :class="{
                    'semi-bold sortable': true,
                    active: header.value !== undefined && header.value === sortBy,
                    desc: sortDesc && sortBy === header.value,
                    asc: !sortDesc || sortBy !== header.value,
                    loading: loading
                  }"
                  data-testid="app-data-table__header"
                  :width="header.width"
                >
                  <table-header-content
                    data-testid="app-data-table__header-content"
                    :header="header"
                    :index="index"
                    @sort="sortColumn($event)"
                  />
                </th>
              </template>

              <th v-else role="columnheader" :class="{ loading: loading }">
                <v-row dense class="v-data-table-header v-data-table-header-mobile__wrapper tr">
                  <v-col
                    v-if="selectable"
                    cols="auto"
                    scope="col"
                    :class="{
                      'selectable semi-bold sortable mr-4': true,
                      loading: loading
                    }"
                  >
                    <app-checkbox
                      :key="selectedAllOnPage"
                      v-model="selectedAllOnPage"
                      :indeterminate="!selectedAllOnPage && selectedSomeOnPage"
                      primary
                      hide-details
                    />
                  </v-col>
                  <v-col
                    v-for="(header, index) in filteredHeaders"
                    :key="header.text"
                    cols="auto"
                    :class="{
                      'semi-bold sortable th mr-4': true,
                      active: header.value !== undefined && header.value === sortBy,
                      desc: sortDesc && sortBy === header.value,
                      asc: !sortDesc || sortBy !== header.value
                    }"
                    :width="header.width"
                  >
                    <table-header-content
                      :header="header"
                      :index="index"
                      @sort="sortColumn($event)"
                    />
                  </v-col>
                </v-row>
              </th>
            </tr>
          </thead>
        </template>

        <template #item="{ item, index }">
          <slot
            name="item"
            :item="item"
            :selectable="selectable"
            :selected-items="selectedItems"
          ></slot>
          <v-divider v-if="isMobile && !isLastItem(index) && !isLastOnPage(index)" class="mx-4" />
        </template>

        <template #expanded-item="{ item }">
          <slot name="expanded-item" :item="item"></slot>
        </template>

        <template #no-data>
          <app-error-alert v-if="error" :align-center="false" />
          <slot v-else-if="totalDataLength === 0" name="no-data"></slot>
          <slot v-else name="no-results">{{ $t('noMatchingResults') }}</slot>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import TableHeaderContent from '@/shared/components/TableHeaderContent';
import TABLE_QUERY_DEFAULTS from '@/statics/tableQueryDefaults';

export default {
  name: 'AppDataTable',

  components: {
    TableHeaderContent
  },

  inheritAttrs: false,

  props: {
    error: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: Array,
      default: () => []
    },
    headers: {
      type: Array,
      default: () => []
    },
    itemKey: {
      type: String,
      default: 'id'
    },
    items: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: false
    },
    selectedItems: {
      type: Array,
      default: () => []
    },
    sortByDefault: {
      type: String,
      default: ''
    },
    sortDescDefault: {
      type: Boolean,
      default: false
    },
    totalDataLength: {
      type: Number,
      required: true
    },
    useClientSidePagination: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    rowsPerPageItems: [15, 25, 50, 100]
  }),

  computed: {
    serverItemsLength() {
      return this.useClientSidePagination ? -1 : this.totalDataLength;
    },
    showTitle() {
      return this.totalDataLength > 0 || !this.loading;
    },
    sortBy() {
      return this.options.sortBy[0];
    },
    sortDesc() {
      return this.options.sortDesc[0];
    },
    options: {
      get() {
        const sortDesc = (
          this.getQueryParam(this.queryParam.SORT_DESC) ??
          TABLE_QUERY_DEFAULTS[this.queryParam.SORT_DESC]
        ).map((value) => {
          if (typeof value === 'boolean') {
            return value;
          }

          return value === 'true';
        });

        return {
          page: parseInt(
            this.getQueryParam(this.queryParam.PAGE) ?? TABLE_QUERY_DEFAULTS[this.queryParam.PAGE]
          ),
          itemsPerPage: parseInt(
            this.getQueryParam(this.queryParam.ITEMS_PER_PAGE) ??
              TABLE_QUERY_DEFAULTS[this.queryParam.ITEMS_PER_PAGE]
          ),
          sortBy:
            this.getQueryParam(this.queryParam.SORT_BY) ??
            TABLE_QUERY_DEFAULTS[this.queryParam.SORT_BY],
          sortDesc
        };
      },
      set(options = {}) {
        this.setQueryParam(
          this.queryParam.PAGE,
          options.page ?? TABLE_QUERY_DEFAULTS[this.queryParam.PAGE],
          true
        );
        this.setQueryParam(
          this.queryParam.ITEMS_PER_PAGE,
          options.itemsPerPage ?? TABLE_QUERY_DEFAULTS[this.queryParam.ITEMS_PER_PAGE],
          true
        );
        this.setQueryParam(
          this.queryParam.SORT_BY,
          options.sortBy ?? TABLE_QUERY_DEFAULTS[this.queryParam.SORT_BY],
          true
        );
        this.setQueryParam(
          this.queryParam.SORT_DESC,
          options.sortDesc ?? TABLE_QUERY_DEFAULTS[this.queryParam.SORT_DESC],
          true
        );
      }
    },
    filteredHeaders() {
      if (!this.isMobile) {
        return this.headers;
      }
      return this.headers.filter((header) => header.sortMobile);
    },
    selectedAllOnPage: {
      get() {
        return this.itemsWithFiles.every((item) => this.selectedItems.includes(item.id));
      },
      set() {
        this.toggleAllOnPage();
      }
    },
    selectedSomeOnPage() {
      return this.itemsWithFiles.some((item) => this.selectedItems.includes(item.id));
    },
    itemsWithFiles() {
      return this.items.filter((item) => !!item.source);
    }
  },

  methods: {
    sortColumn(headerValue) {
      if (this.sortDescDefault) {
        // sort order: descending - ascending - none
        if (this.sortBy === headerValue && this.sortDesc) {
          this.setQueryParam(this.queryParam.SORT_DESC, undefined, true);
          this.setQueryParam(this.queryParam.SORT_BY, undefined, true);
          return;
        }

        if (this.sortBy === headerValue && !this.sortDesc) {
          this.setQueryParam(this.queryParam.SORT_DESC, [true], true);
          return;
        }

        this.setQueryParam(this.queryParam.SORT_DESC, [false], true);
        this.setQueryParam(this.queryParam.SORT_BY, [headerValue], true);

        return;
      }

      // default sort order: ascending - descending - none
      if (this.sortBy === headerValue && this.sortDesc) {
        this.setQueryParam(this.queryParam.SORT_DESC, [!this.sortDesc], true);
        return;
      }

      if (this.sortBy === headerValue && !this.sortDesc) {
        this.setQueryParam(
          this.queryParam.SORT_DESC,
          TABLE_QUERY_DEFAULTS[this.queryParam.SORT_DESC],
          true
        );
        this.setQueryParam(
          this.queryParam.SORT_BY,
          TABLE_QUERY_DEFAULTS[this.queryParam.SORT_BY],
          true
        );
        return;
      }

      this.setQueryParam(this.queryParam.SORT_DESC, [true], true);
      this.setQueryParam(this.queryParam.SORT_BY, [headerValue], true);
    },

    isLastOnPage(index) {
      return !((index + 1) % this.options.itemsPerPage);
    },

    isLastItem(index) {
      return index + 1 === this.totalDataLength;
    },

    scrollToTableHeader() {
      if (this.isMobile) {
        if (!this.$refs.tableHeader) {
          return;
        }

        return this.$vuetify.goTo(this.$refs.tableHeader, {
          easing: 'easeInOutCubic'
        });
      }

      if (!this.$refs.table) {
        return;
      }

      this.$vuetify.goTo(0, {
        easing: 'easeInOutCubic',
        container: '.v-data-table__wrapper'
      });
    },

    toggleAllOnPage() {
      if (!this.selectedAllOnPage) {
        // add missing items of current page
        this.$emit('update:selected-items', [
          ...this.selectedItems,
          ...this.itemsWithFiles
            .filter((item) => !this.selectedItems.includes(item.id))
            .map((item) => item.id)
        ]);
        return;
      }

      // remove items of current page from selected
      this.$emit(
        'update:selected-items',
        this.selectedItems.filter(
          (selectedItem) => !this.itemsWithFiles.some((item) => item.id === selectedItem)
        )
      );
    }
  },

  created() {
    if (this.sortBy) {
      return;
    }

    if (this.sortByDefault) {
      this.setQueryParam(this.queryParam.SORT_DESC, [this.sortDescDefault], true);
      this.setQueryParam(this.queryParam.SORT_BY, [this.sortByDefault], true);
    }
  }
};
</script>

<style lang="scss">
// wrapper and title
.app-data-table__title {
  padding: 0.5rem 1rem;
  min-height: 3.75rem;

  .table-title__content {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
  }
}

// wrapper and title desktop only
.v-application:not(.mobile) .v-data-table__wrapper {
  max-height: max(500px, 80vh);
  overflow-y: auto;
}

// wrapper and title mobile only
.v-application.mobile {
  .v-card.app-data-table__card {
    padding: 0;
    max-width: 100%;
    margin-bottom: 7rem;

    .table-title__content {
      flex-direction: column;
      align-items: flex-start;
    }

    > .v-divider {
      margin: 0 1rem;
    }
  }

  .app-data-table__title {
    padding: 1rem;
  }
}

// table
#app.v-application .theme--light.v-data-table.app-data-table {
  $background-header: #f0f0f0;
  $background--error: #ffcdd2;
  $thead__z-index: 4;
  $th__border-height: 3px;
  $td__line-height: 24px;

  max-width: 100%;
  transition: max-width 0.2s cubic-bezier(0.4, 0, 0.2, 1);

  // header
  thead {
    vertical-align: bottom;
    line-height: 12px;
    text-transform: uppercase;

    + thead {
      // contains progress-bar for loading animation
      position: absolute;
      width: 100%;
      z-index: $thead__z-index;

      tr.v-data-table__progress {
        position: absolute;
        right: 0;
        left: 0;
        bottom: $th__border-height;

        th {
          position: absolute;
          left: 0;
          right: 0;
        }
      }
    }

    tr th:not(:first-child) .table-header__content {
      text-align: right;
    }

    &.v-data-header-mobile th {
      height: 100%;
      border: none;

      .v-data-table-header-mobile__wrapper {
        padding: 0.5rem 0;
      }
    }
  }

  tr th {
    &[role='columnheader'] {
      position: sticky;
      top: 0;
      z-index: $thead__z-index;
      background-color: var(--c-white);
      border-bottom: $th__border-height solid var(--c-secondary);

      &.loading {
        border-color: transparent;
      }
    }

    &.active {
      background-color: var(--c-table-sorted);
    }
  }

  tr th.active,
  .tr .th.active {
    * {
      color: var(--c-text);
    }

    i {
      color: var(--c-primary) !important;
    }
  }

  tr th.selectable,
  .tr .th.selectable {
    vertical-align: middle;
    justify-content: center;
    text-align: center;
  }

  .table-header__content {
    color: var(--c-grey);
    padding: 0.3rem 0;
    word-break: normal;

    .v-data-table-header__icon {
      opacity: 1;
      width: 20px;
      font-size: 20px;
      margin-right: -8px;
      line-height: inherit;
    }
  }

  .v-data-table-header .th.desc .v-data-table-header__icon {
    transform: rotate(-180deg);
  }

  // row
  tbody tr {
    &.v-data-table-row--bordered td:first-of-type {
      border-left: 5px solid var(--c-error);
      padding-left: calc(1rem - 5px);
    }

    &.v-data-table-row--expanded {
      background-color: var(--c-table-expanded);
    }

    &.v-data-table__mobile-table-row:hover {
      background-color: unset !important;
    }

    &.v-data-table-row--error {
      background-color: $background--error;

      td.v-data-table-col--sorted {
        background-color: darken($background--error, 2%);
      }

      &:hover {
        background-color: darken($background--error, 4%);

        td.v-data-table-col--sorted {
          background-color: darken($background--error, 6%);
        }
      }
    }

    // row, not for mobile
    &:not(.v-data-table__mobile-table-row) {
      &:not(.data-table-row--highlighted):not(.v-data-table-row--error)
        td.v-data-table-col--sorted {
        background-color: var(--c-table-sorted);
      }

      &:not(.data-table-row--highlighted):not(.v-data-table-row--error):hover
        td.v-data-table-col--sorted {
        background-color: var(--c-table-sorted);
      }

      &.data-table-row--highlighted {
        background-color: var(--c-table-highlighted);
        color: var(--c-table-highlighted-text);

        .grey--text:not(.v-chip) {
          color: var(--c-table-highlighted-text-light) !important;
        }

        td.v-data-table-col--sorted {
          background-color: var(--c-table-highlighted-sorted);
        }
      }

      &.v-data-table-row--expanded {
        background-color: var(--c-table-expanded);
      }

      &.v-data-table-row--expanded.data-table-row--highlighted {
        background-color: var(--c-table-highlighted);
      }

      &:hover:not(.data-table-row--highlighted):not(.v-data-table-row--error) {
        background-color: var(--c-table-hover);

        &:not(.v-data-table-row--expanded) td.v-data-table-col--sorted {
          background-color: var(--c-table-hover-sorted);
        }
      }

      &.v-data-table-row--expanded.first td {
        box-shadow: inset 0 11px 8px -10px #ccc;
      }

      &.v-data-table-row--expanded.first.data-table-row--highlighted td {
        box-shadow: inset 0 11px 8px -10px var(--c-table-highlighted-shadow);
      }

      &.v-data-table-row--expanded.last td {
        box-shadow: inset 0px -11px 8px -10px #ccc;
      }

      &.v-data-table-row--expanded.last.data-table-row--highlighted td {
        box-shadow: inset 0px -11px 8px -10px var(--c-table-highlighted-shadow);
      }

      &.v-data-table-row--expanded.first.last td {
        box-shadow: inset 0 11px 8px -10px #ccc, inset 0px -11px 8px -10px #ccc;
      }

      &.v-data-table-row--expanded.first.last.data-table-row--highlighted td {
        box-shadow: inset 0 11px 8px -10px var(--c-table-highlighted-shadow),
          inset 0px -11px 8px -10px var(--c-table-highlighted-shadow);
      }
    }

    // row mobile only
    &.v-data-table__mobile-table-row {
      display: table-row;

      &.v-data-table-row--expanded.first {
        box-shadow: inset 0 11px 8px -10px #ccc;
      }

      &.v-data-table-row--expanded.last {
        box-shadow: inset 0px -11px 8px -10px #ccc;
      }

      &.v-data-table-row--expanded.first.last {
        box-shadow: inset 0 11px 8px -10px #ccc, inset 0px -11px 8px -10px #ccc;
      }
    }
  }

  // cell
  tr td {
    padding: 1.25rem 1rem;
    vertical-align: top;
    border-bottom: none !important;
    line-height: $td__line-height;
    font-size: 16px;
    border-radius: 0;

    &:not(:first-child) {
      text-align: right;
    }

    // cell mobile only
    &.v-data-table__mobile-row {
      font-size: 14px;
      padding: 0.5rem 1.25rem;
      align-items: baseline;
      text-align: right;
      height: unset;

      &:first-child {
        padding-top: 1rem;
      }

      &:last-child {
        padding-bottom: 1rem;
      }

      &.v-data-table-col--selectable {
        padding-right: 1.25rem;
        padding-top: 1.25rem;
      }
    }
  }

  tr.v-data-table-row--selectable td:nth-child(2) {
    text-align: left;
  }

  tr.v-data-table__mobile-table-row.v-data-table-row--selectable td {
    padding-left: 0.25rem;
  }

  // footer mobile only
  &.app-data-table-mobile .v-data-footer {
    padding: 0.5rem 1rem;

    > div {
      margin: 0;
      flex: 1 0 40%;
    }

    button {
      height: 3.5rem;
      width: 3.5rem;

      &:hover::before {
        opacity: 0;
      }
    }

    .v-data-footer__select {
      justify-content: flex-start;
    }

    .v-data-footer__pagination {
      justify-content: flex-end;
      text-align: end;
    }

    .v-data-footer__icons-before {
      display: flex;
      justify-content: flex-end;

      button {
        margin-right: 0.75rem;
      }
    }

    .v-data-footer__icons-after {
      display: flex;
      justify-content: flex-start;

      button {
        margin-left: 0.75rem;
      }
    }
  }

  .v-data-table__empty-wrapper {
    color: var(--c-text);
    text-align: left;

    &:hover {
      background-color: unset !important;
    }
  }

  .dot__wrapper {
    height: $td__line-height;
    display: inline-flex;
    align-items: center;

    svg {
      height: 4px;
      min-width: 4px;
      margin-right: 8px;
    }
  }
}
</style>
