<template>
  <ValidationProvider v-slot="{ errors }" v-bind="validationProps">
    <v-autocomplete
      :id="fieldId"
      v-model="selection"
      :class="{ 'input--has-error': errors.length > 0, 'input--is-filter': filter }"
      :error-messages="errors"
      :hide-details="errors.length === 0"
      :items="items"
      :label="fieldLabel"
      :menu-props="{
        'content-class': 'select__menu',
        'offset-y': true
      }"
      :multiple="multiple"
      append-icon="mdi-chevron-down"
      autocomplete="off"
      flat
      height="45"
      item-color="secondary"
      item-text="label"
      item-value="value"
      outlined
      v-bind="$attrs"
      @click.native="showMenu = !showMenu"
    >
      <template v-if="filter" #label>
        <div class="selection__wrapper">
          <component :is="`${labelIcon}`" class="selection__icon" />
          <div class="selection__label">{{ fieldLabel }}</div>
        </div>
      </template>
      <template v-if="filter && !showMenu" #selection>
        <div class="selection__wrapper">
          <component :is="`${labelIcon}`" class="selection__icon" />
          <div class="selection__label">{{ fieldLabel }}</div>
        </div>
      </template>
      <template v-if="filter" #item="{ item }">
        <span class="select-menu__icon-wrapper">
          <v-icon v-if="itemSelected(item)">mdi-check</v-icon>
        </span>
        <div class="selection__label">{{ item.label !== undefined ? item.label : item }}</div>
      </template>
    </v-autocomplete>
    <v-overlay v-if="showMenu" style="opacity: 0" @click.native="showMenu = !showMenu" />
  </ValidationProvider>
</template>

<script>
import FormValidationMixin from '@/mixins/FormValidationMixin';

export default {
  name: 'AppAutocomplete',

  mixins: [FormValidationMixin],

  props: {
    items: {
      type: Array,
      default: () => []
    },
    labelIcon: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    filter: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Array, Number, String, Object],
      default: ''
    }
  },

  data: () => ({
    showMenu: false
  }),

  computed: {
    selection: {
      get() {
        return this.value;
      },
      set(value) {
        this.filterItems(value);
      }
    }
  },

  methods: {
    filterItems(event) {
      this.$emit('input', event);
      if (this.multiple) {
        return;
      }
      this.showMenu = false;
    },
    itemSelected(itemValue) {
      if (Array.isArray(this.value)) {
        return this.value.some((selection) => selection === itemValue);
      }

      return this.value.toString().includes(itemValue);
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@/styles/mixins/selectStyle';
::v-deep.v-select {
  @include SelectMixin;
}

::v-deep .v-select__selection {
  display: none;
}
</style>

<style lang="scss">
@import '~@/styles/mixins/selectMenuStyle';

.v-autocomplete__content {
  @include SelectMenuMixin;
}
</style>
