<template>
  <v-alert
    :value="value"
    :type="type"
    :icon="icon"
    v-bind="$attrs"
    border="left"
    :colored-border="coloredBorder"
    outlined
    tile
    class="py-3 px-5"
  >
    <slot>{{ $t(text) }}</slot>
  </v-alert>
</template>

<script>
export default {
  name: 'AppAlert',

  props: {
    coloredBorder: {
      type: Boolean,
      default: true
    },
    icon: {
      type: [String, Boolean],
      default: '$iconAlert'
    },
    value: {
      type: Boolean,
      default: true
    },
    text: {
      type: String,
      default: 'serverError'
    },
    type: {
      type: String,
      default: 'error'
    }
  }
};
</script>

<style scoped>
.v-alert {
  margin-bottom: 0;
}

>>> .v-alert__icon {
  border-radius: 0;
}

>>> svg {
  width: 20px;
  height: 20px;
  margin-top: 2px; /* center vertically (line-height 24px - height 20px) */
}

.v-alert--outlined {
  background: var(--c-grey-background) !important;
  border: none !important;
}

>>> .v-alert__border {
  border-left-width: 5px;
  border-right-width: 0;
}

>>> .v-alert__border:not(.v-alert__border--has-color) {
  opacity: 0;
}
</style>
