import showSnackbar from '@/helper/showSnackbar';
import { mapList, mapItem, mapPermanentDebtDebtors } from '@/modules/PermanentDebts/models';
import qs from 'qs';
export const NAMESPACE = 'permanentDebts';

const initialState = () => ({
  list: [],
  count: 0,
  empty: true,
  detailedItem: null,
  detailedItemRaw: null,
  loadingList: false,
  loadingDetails: false,
  listError: false,
  detailsError: false
});

export default (Vue) => ({
  namespaced: true,

  state: initialState(),

  mutations: {
    SET_LIST(state, { list, count, empty }) {
      state.list = list;
      state.count = count;
      state.empty = empty;
    },
    RESET_LIST(state) {
      state.list = [];
      state.count = 0;
      state.empty = true;
    },
    SET_DETAILED_ITEM(state, item) {
      state.detailedItem = item;
    },
    SET_DETAILED_ITEM_RAW(state, item) {
      state.detailedItemRaw = item;
    },
    SET_LOADING_LIST(state) {
      state.loadingList = true;
    },
    SET_LOADING_DETAILED_ITEM(state) {
      state.loadingDetails = true;
    },
    RESET_DETAILED_ITEM(state) {
      state.detailedItem = null;
    },
    RESET_LOADING_DETAILED_ITEM(state) {
      state.loadingDetails = false;
    },
    RESET_LOADING_LIST(state) {
      state.loadingList = false;
    },
    SET_LIST_ERROR(state) {
      state.listError = true;
    },
    RESET_LIST_ERROR(state) {
      state.listError = false;
    },
    SET_DETAILS_ERROR(state) {
      state.detailsError = true;
    },
    RESET_DETAILS_ERROR(state) {
      state.detailsError = false;
    },
    RESET_STATE(state) {
      const initial = initialState();
      Object.keys(initial).forEach((key) => {
        state[key] = initial[key];
      });
    }
  },

  actions: {
    async fetchList({ commit }, { page, rowsPerPage, filters, sorting }) {
      commit('RESET_LIST_ERROR');
      commit('SET_LOADING_LIST');

      try {
        const { data } = await Vue.prototype.$http.get(`/permanent-debt/list`, {
          params: { page, rowsPerPage, filters, sorting },
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'brackets' });
          }
        });

        commit('SET_LIST', {
          list: mapList(data.vouchers),
          count: data.count,
          empty: data.empty
        });
        commit('RESET_LOADING_LIST');

        return {};
      } catch (error) {
        commit('SET_LIST_ERROR');
        commit('RESET_LIST');
        commit('RESET_LOADING_LIST');
        return { error };
      }
    },

    async fetchDetailedItem({ commit }, identifier) {
      commit('RESET_DETAILS_ERROR');
      commit('SET_LOADING_DETAILED_ITEM');
      commit('RESET_DETAILED_ITEM');

      try {
        const response = await Vue.prototype.$http.get(`/permanent-debt/${identifier}/show`);

        commit('SET_DETAILED_ITEM_RAW', response.data);
        commit('SET_DETAILED_ITEM', mapItem(response.data));
        commit('RESET_LOADING_DETAILED_ITEM');
        return {};
      } catch (error) {
        commit('SET_DETAILS_ERROR');
        commit('RESET_LOADING_DETAILED_ITEM');
        return { error };
      }
    },

    async fetchPermanentDebtDebtors() {
      try {
        const response = await Vue.prototype.$http.get('permanent-debt/debtors');
        return { debtors: mapPermanentDebtDebtors(response.data) };
      } catch (error) {
        showSnackbar({ text: 'serverError' });
        return { error };
      }
    },

    async fetchFilterLocations() {
      try {
        return await Vue.prototype.$http.get('/permanent-debt/location-list');
      } catch (error) {
        return { data: [] };
      }
    },

    async fetchFilterSubjects() {
      try {
        return await Vue.prototype.$http.get('/permanent-debt/item-list');
      } catch (error) {
        return { data: [] };
      }
    },

    async fetchFilterCustomNumbers() {
      try {
        return await Vue.prototype.$http.get('/permanent-debt/custom-number-list');
      } catch (error) {
        return { data: [] };
      }
    },

    async createPermanentDebt(_, { locationIdentifier, formData }) {
      try {
        const response = await Vue.prototype.$http.post(
          `permanent-debt/location/${locationIdentifier}/add`,
          formData
        );

        return { response: response.data.identifier };
      } catch (error) {
        showSnackbar({ text: 'serverError' });
        return { error };
      }
    },

    async stopPermanentDebt(_, permanentDebtIdentifier) {
      try {
        await Vue.prototype.$http.post(`/permanent-debt/${permanentDebtIdentifier}/stop`);
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError' });
        return { error };
      }
    }
  }
});
