import showSnackbar from '@/helper/showSnackbar';
import {
  mapVoucher,
  mapOpenWorkQueue,
  mapList,
  mapVoucherPermissions
} from '@/modules/Vouchers/models';
import { mapHistory } from '@/shared/models';
import formatLogData from '@/helper/formatLogData';
import qs from 'qs';

export const NAMESPACE = 'vouchers';

const initialState = () => ({
  vouchers: [],
  vouchersCount: 0,
  vouchersEmpty: true,
  voucherAmounts: null,
  detailedVoucher: null,
  detailedVoucherRaw: null,
  creditorLimits: null,
  unprocessedVouchersCount: 0,
  processLog: [],
  processLogRaw: [],
  loadingVouchers: false,
  loadingDetails: false,
  loadingAction: false,
  voucherNumbers: [],
  bnetIdentifiers: [],
  encashmentFileNumbers: [],
  listError: false,
  detailsError: false
});

export default (Vue) => ({
  namespaced: true,

  state: initialState(),

  getters: {
    identifiersByNumbers: (state) => (numbers) =>
      state.voucherNumbers
        .filter(({ number }) => numbers.includes(number))
        .map(({ identifier }) => identifier),
    identifiersByBnetIdentifiers: (state) => (bnetIdentifiers) =>
      state.bnetIdentifiers
        .filter(({ bnetIdentifier }) => bnetIdentifiers.includes(bnetIdentifier))
        .map(({ identifier }) => identifier),
    identifiersByEncashmentFileNumbers: (state) => (encashmentFileNumbers) =>
      state.encashmentFileNumbers
        .filter(({ encashmentFileNumber }) => encashmentFileNumbers.includes(encashmentFileNumber))
        .map(({ identifier }) => identifier)
  },

  mutations: {
    SET_VOUCHERS(state, { vouchers, creditorLimits, count, empty }) {
      state.vouchers = vouchers;
      state.vouchersCount = count;
      state.vouchersEmpty = empty;
      state.creditorLimits = creditorLimits;
    },
    RESET_VOUCHERS(state) {
      state.vouchers = [];
      state.vouchersCount = 0;
      state.vouchersEmpty = true;
      state.creditorLimits = null;
    },
    SET_VOUCHER_AMOUNTS(state, amounts) {
      state.voucherAmounts = amounts;
    },
    SET_UNPROCESSED_VOUCHERS_COUNT(state, count) {
      state.unprocessedVouchersCount = count;
    },
    SET_DETAILED_VOUCHER(state, { voucher, voucherRaw = state.detailedVoucherRaw }) {
      state.detailedVoucher = voucher;
      state.detailedVoucherRaw = voucherRaw;
    },
    RESET_DETAILED_VOUCHER(state) {
      state.detailedVoucher = null;
      state.detailedVoucherRaw = null;
    },
    SET_PROCESSLOG(state, { processLog, processLogRaw }) {
      state.processLog = processLog;
      state.processLogRaw = processLogRaw;
    },
    RESET_PROCESSLOG(state) {
      state.processLog = [];
      state.processLogRaw = [];
    },
    SET_LOADING_VOUCHERS(state) {
      state.loadingVouchers = true;
    },
    RESET_LOADING_VOUCHERS(state) {
      state.loadingVouchers = false;
    },
    SET_LOADING_DETAILS(state) {
      state.loadingDetails = true;
    },
    RESET_LOADING_DETAILS(state) {
      state.loadingDetails = false;
    },
    SET_LOADING_ACTION(state) {
      state.loadingAction = true;
    },
    RESET_LOADING_ACTION(state) {
      state.loadingAction = false;
    },
    SET_LIST_ERROR(state) {
      state.listError = true;
    },
    RESET_LIST_ERROR(state) {
      state.listError = false;
    },
    SET_DETAILS_ERROR(state) {
      state.detailsError = true;
    },
    RESET_DETAILS_ERROR(state) {
      state.detailsError = false;
    },
    SET_VOUCHER_AMOUNTS_ERROR(state) {
      state.voucherAmountsError = true;
    },
    RESET_VOUCHER_AMOUNTS_ERROR(state) {
      state.voucherAmountsError = false;
    },
    RESET_STATE(state) {
      const initial = initialState();
      Object.keys(initial).forEach((key) => {
        state[key] = initial[key];
      });
    },
    SET_VOUCHER_NUMBERS(state, numbers) {
      state.voucherNumbers = numbers;
    },
    SET_ENCASHMENT_FILE_NUMBERS(state, numbers) {
      state.encashmentFileNumbers = numbers;
    },
    SET_BNET_IDENTIFIER(state, numbers) {
      state.bnetIdentifiers = numbers;
    }
  },

  actions: {
    async fetchVouchers(
      { commit, dispatch },
      { page, rowsPerPage, filters, sorting, fetchAmounts }
    ) {
      commit('RESET_LIST_ERROR');
      commit('SET_LOADING_VOUCHERS');

      try {
        dispatch('fetchUnprocessedVouchersCount');
        if (fetchAmounts) {
          dispatch('fetchVoucherAmounts', { page, rowsPerPage, filters, sorting });
        }

        const { data } = await Vue.prototype.$http.get(`/voucher/list`, {
          params: { page, rowsPerPage, filters, sorting },
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'brackets' });
          }
        });
        const vouchers = data.vouchers;
        const creditorLimits = data.creditorLimits;
        const count = data.count;
        const empty = data.empty;

        commit('SET_VOUCHERS', { vouchers: mapList(vouchers), creditorLimits, count, empty });
        commit('RESET_LOADING_VOUCHERS');
        return {};
      } catch (error) {
        commit('SET_LIST_ERROR');
        commit('RESET_VOUCHERS');
        commit('RESET_LOADING_VOUCHERS');
        return { error };
      }
    },

    async fetchCSV(_, { page, rowsPerPage, filters, sorting }) {
      try {
        const { data } = await Vue.prototype.$http.get(`/voucher/csv`, {
          responseType: 'blob',
          params: { page, rowsPerPage, filters, sorting },
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'brackets' });
          }
        });

        return { file: data };
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async fetchVoucherAmounts({ commit, state }, { filters, sorting }) {
      commit('RESET_VOUCHER_AMOUNTS_ERROR');

      if (state.voucherAmounts) {
        commit('SET_VOUCHER_AMOUNTS', null);
      }

      try {
        const response = await Vue.prototype.$http.get('/voucher/amount-list', {
          params: { filters, sorting },
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'brackets' });
          }
        });

        commit('SET_VOUCHER_AMOUNTS', response.data);
      } catch (error) {
        commit('SET_VOUCHER_AMOUNTS_ERROR');
        return { error };
      }
    },

    async fetchUnprocessedVouchersCount({ commit }) {
      try {
        const response = await Vue.prototype.$http.get('/unprocessed-voucher/count');

        commit('SET_UNPROCESSED_VOUCHERS_COUNT', response.data.countUnprocessedVouchers);
      } catch (error) {
        commit('SET_UNPROCESSED_VOUCHERS_COUNT', 0);
        return { error };
      }
    },

    async fetchDetailedVoucher(
      { commit, dispatch, rootState },
      { identifier, fetchOpenWorkQueue }
    ) {
      commit('RESET_DETAILS_ERROR');
      commit('SET_LOADING_DETAILS');
      commit('RESET_DETAILED_VOUCHER');

      if (rootState.authentication.adminView) {
        commit('RESET_PROCESSLOG');
        dispatch('fetchProcessLog', identifier);
      }

      try {
        const [voucherResponse, historyResponse, queueResponse, permissionsResponse] =
          await Promise.all([
            Vue.prototype.$http.get(`/voucher/show/${identifier}`),
            Vue.prototype.$http.get(`/voucher/${identifier}/history/list`),
            fetchOpenWorkQueue
              ? Vue.prototype.$http.get(`/user-information/work-queue/voucher/${identifier}/list`)
              : { data: [] },
            Vue.prototype.$http.get(`/voucher/${identifier}/permissions`)
          ]);

        const voucher = {
          ...mapVoucher(voucherResponse.data),
          history: mapHistory(historyResponse.data),
          workQueue: mapOpenWorkQueue(queueResponse.data),
          permissions: mapVoucherPermissions(permissionsResponse.data)
        };

        commit('SET_DETAILED_VOUCHER', { voucher: voucher, voucherRaw: voucherResponse.data });
        commit('RESET_LOADING_DETAILS');
        return {};
      } catch (error) {
        commit('SET_DETAILS_ERROR');
        commit('RESET_LOADING_DETAILS');
        return { error };
      }
    },

    async fetchProcessLog({ commit }, identifier) {
      try {
        const response = await Vue.prototype.$http.get(`/voucher/${identifier}/process-log/list`);

        commit('SET_PROCESSLOG', {
          processLog: formatLogData(response.data),
          processLogRaw: response.data
        });

        return {};
      } catch {
        showSnackbar({ text: 'serverError', color: 'error' });
      }
    },

    async fetchBalancingSummary(_, identifier) {
      try {
        const response = await Vue.prototype.$http.get(
          `/voucher/${identifier}/list-balanced-vouchers`
        );

        return {
          balancedVouchers: mapList(response.data.balancedVouchers),
          balancingVouchers: mapList(response.data.balancingVouchers)
        };
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async getFile(_, url) {
      try {
        const response = await Vue.prototype.$http.get(url, { responseType: 'blob' });
        return { file: response.data };
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async uploadDirectPayment(_, { voucherIdentifier, data }) {
      try {
        await Vue.prototype.$http.post(`/voucher/${voucherIdentifier}/direct-payment`, data);
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async generateCredit(_, { voucherIdentifier, formData }) {
      try {
        await Vue.prototype.$http.post(`/voucher/${voucherIdentifier}/credit`, formData);
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async executeInvoiceAnnulation(_, voucherIdentifier) {
      try {
        await Vue.prototype.$http.post(`/voucher/${voucherIdentifier}/decline`);
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async executeAbandonment(_, { voucherIdentifier, data }) {
      try {
        await Vue.prototype.$http.post(`/voucher/${voucherIdentifier}/abandon`, data);
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async setDunningBlock(_, voucherIdentifier) {
      try {
        await Vue.prototype.$http.post(`/sap/voucher/${voucherIdentifier}/dunning/block`);
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError' });
        return { error };
      }
    },

    async cancelDunningBlock(_, voucherIdentifier) {
      try {
        await Vue.prototype.$http.post(`/sap/voucher/${voucherIdentifier}/dunning/reopen`);
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError' });
        return { error };
      }
    },

    async openDispute(_, { voucherIdentifier, formData }) {
      try {
        await Vue.prototype.$http.post(
          `/sap/voucher/${voucherIdentifier}/dunning/dispute/open`,
          formData
        );
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError' });
        return { error };
      }
    },

    async closeDispute(_, { voucherIdentifier, formData }) {
      try {
        await Vue.prototype.$http.post(
          `/sap/voucher/${voucherIdentifier}/dunning/dispute/close`,
          formData
        );
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError' });
        return { error };
      }
    },

    async openDelay(_, { voucherIdentifier }) {
      try {
        await Vue.prototype.$http.post(`/sap/voucher/${voucherIdentifier}/dunning/delay/open`);
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError' });
        return { error };
      }
    },

    async closeDelay(_, { voucherIdentifier }) {
      try {
        await Vue.prototype.$http.post(`/sap/voucher/${voucherIdentifier}/dunning/delay/close`);
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError' });
        return { error };
      }
    },

    async editComment(_, { voucherIdentifier, comment }) {
      try {
        await Vue.prototype.$http.post(`/voucher/${voucherIdentifier}/comment`, { comment });
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError' });
        return { error };
      }
    },

    async fetchFilterLocations() {
      try {
        return await Vue.prototype.$http.get('/voucher/location/list');
      } catch (error) {
        return { data: [] };
      }
    },

    async fetchFilterBnetIdentifier({ commit }) {
      try {
        const { data } = await Vue.prototype.$http.get('/voucher/bnet-identifier/list');

        commit('SET_BNET_IDENTIFIER', data);
      } catch (error) {
        return { data: [] };
      }
    },

    async fetchFilterEncashmentFileNumbers({ commit }) {
      try {
        const { data } = await Vue.prototype.$http.get('/voucher/encashment-file-number/list');

        commit('SET_ENCASHMENT_FILE_NUMBERS', data);
      } catch (error) {
        return { data: [] };
      }
    },

    async fetchFilterVoucherNumbers({ commit }) {
      try {
        const { data } = await Vue.prototype.$http.get('/voucher/number/list');

        commit('SET_VOUCHER_NUMBERS', data);
      } catch (error) {
        return { data: [] };
      }
    }
  }
});
