<template>
  <ValidationProvider v-slot="{ errors }" v-bind="validationProps">
    <v-textarea
      :id="fieldId"
      v-model="text"
      :class="{
        'input--align-right': alignRight,
        'input--has-error': errors.length > 0
      }"
      :error-messages="errors"
      :hide-details="errors.length === 0"
      :label="fieldLabelWithSupplement"
      :placeholder="fieldPlaceholder"
      data-testid="app-textarea__input"
      outlined
      v-bind="$attrs"
    />
  </ValidationProvider>
</template>

<script>
import FormValidationMixin from '@/mixins/FormValidationMixin';

export default {
  name: 'AppTextarea',

  mixins: [FormValidationMixin],

  props: {
    alignRight: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    text: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@/styles/mixins/textFieldStyle';

::v-deep.v-text-field {
  @include AppTextFieldMixin;

  &.v-text-field--enclosed .v-text-field__slot {
    margin: 0;
  }

  &.v-text-field--enclosed.v-textarea .v-input__slot {
    padding: 0;
  }

  textarea {
    padding: 12px 12px;
    margin-top: 0 !important;
  }

  label {
    left: 12px !important;
  }
}
</style>
