import VoucherType from '@/statics/voucherType';

/**
 * @see {@link https://wiki.move-elevator.de/pages/viewpage.action?pageId=458171643 Sachstandsübersicht SAP --> ME}
 */
export const FollowUpAction = {
  CREDIT_ADVICE: VoucherType.CREDIT_ADVICE,
  DIRECT_PAYMENT: VoucherType.DIRECT_PAYMENT,
  ABANDONMENT: VoucherType.ABANDONMENT,
  ANNULATION: VoucherType.ANNULATION,
  EXPOSURE_TYPE: 'exposure_type',
  VOUCHER_UPLOAD: 'voucher_upload'
};

export default FollowUpAction;
