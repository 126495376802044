<template>
  <svg
    width="30px"
    height="30px"
    viewBox="0 0 30 30"
    xmlns="http://www.w3.org/2000/svg"
    style="fill-rule: evenodd; clip-rule: evenodd; stroke-linejoin: round; stroke-miterlimit: 2"
  >
    <g transform="matrix(0.842746,0,0,1.03566,-0.125604,0.0206832)" fill="currentColor">
      <g transform="matrix(2.73071,0,0,2.22206,-42.0411,-16.0915)">
        <path
          d="M21.36,7.661C21.36,7.568 21.284,7.492 21.191,7.492L15.88,7.492C15.787,7.492 15.711,7.568 15.711,7.661L15.711,12.972C15.711,13.065 15.787,13.141 15.88,13.141L21.191,13.141C21.284,13.141 21.36,13.065 21.36,12.972L21.36,7.661ZM16.58,8.361L16.58,12.272L20.491,12.272L20.491,8.361L16.58,8.361Z"
        />
      </g>
      <g transform="matrix(2.10054,0,0,1.70928,-13.3924,-9.35305)">
        <path
          d="M21.36,7.661C21.36,7.568 21.284,7.492 21.191,7.492L15.88,7.492C15.787,7.492 15.711,7.568 15.711,7.661L15.711,12.972C15.711,13.065 15.787,13.141 15.88,13.141L21.191,13.141C21.284,13.141 21.36,13.065 21.36,12.972L21.36,7.661ZM16.841,8.622L16.841,12.011L20.23,12.011L20.23,8.622L16.841,8.622Z"
        />
      </g>
      <g transform="matrix(2.10054,0,0,1.70928,-13.3015,-9.64792)">
        <path
          d="M14.086,15.068C14.086,14.975 14.01,14.899 13.917,14.899L8.606,14.899C8.513,14.899 8.437,14.975 8.437,15.068L8.437,20.379C8.437,20.472 8.513,20.548 8.606,20.548L13.917,20.548C14.01,20.548 14.086,20.472 14.086,20.379L14.086,15.068ZM9.567,16.029L9.567,19.418L12.956,19.418L12.956,16.029L9.567,16.029Z"
        />
      </g>
      <g transform="matrix(2.10054,0,0,1.70928,-13.3924,-9.64792)">
        <path
          d="M21.36,15.068C21.36,14.975 21.284,14.899 21.191,14.899L15.88,14.899C15.787,14.899 15.711,14.975 15.711,15.068L15.711,20.379C15.711,20.472 15.787,20.548 15.88,20.548L21.191,20.548C21.284,20.548 21.36,20.472 21.36,20.379L21.36,15.068ZM16.841,16.029L16.841,19.418L20.23,19.418L20.23,16.029L16.841,16.029Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconAdminActions'
};
</script>
