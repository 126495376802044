/**
 * format numbers to percentage string
 * @param {String|Number} value;  e.g. 15.2
 * @param {Number} digits [fallback=2] number of fraction digits
 * @return {String} formatted | -;  e.g. '15,2%'
 */
export default function formatPercentage(value, digits = 2) {
  if (isNaN(value) || value === null) {
    return '-';
  }

  return (value / 100).toLocaleString('de-DE', {
    style: 'percent',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits
  });
}
