import PRODUCT_CONFIGS from '@/productConfigs';
import { PRODUCT_GROUPS } from '@/statics/productGroups';
import { FORM_DATA_CONSTANTS } from '@/helper/createCreditorFactory';

export default {
  computed: {
    productGroup() {
      return PRODUCT_GROUPS.find((productGroup) =>
        productGroup.includes(this.productTypeFromUrl)
      ).map((productType) => {
        let productConfig = PRODUCT_CONFIGS.find((config) => config.type === productType);

        return {
          type: productConfig.type,
          productName: productConfig.productName,
          logo: productConfig.logo,
          scaleLogosByWidth: productConfig.scaleLogosByWidth,
          partnerLogo: productConfig.partnerLogo,
          actions: [
            {
              text: 'onboarding.startRequest',
              id: `onboarding__btn-request--${productConfig.type}`,
              action: () => this.pushToRoute(this.routeName.ONBOARDING_REQUEST, productConfig.type)
            },
            {
              text: 'onboarding.startRegistration',
              id: `onboarding__btn-registration--${productConfig.type}`,
              action: () =>
                this.pushToRoute(this.routeName.ONBOARDING_REGISTRATION, productConfig.type)
            }
          ]
        };
      });
    },
    fields() {
      return FORM_DATA_CONSTANTS;
    },

    selectedProduct() {
      if (this.isOnboarding) {
        return this.product;
      }

      return PRODUCT_CONFIGS.find(
        (product) => this.getQueryParam(this.queryParam.SELECTED_PRODUCT_TYPE) === product.type
      );
    }
  },

  methods: {
    dataNotBlacklisted(fieldName) {
      return !this.selectedProduct.onboarding?.blacklist?.includes(fieldName);
    },

    fillBlacklistedDataWithDefaults(formData) {
      let filledData = Object.assign({}, formData);

      if (this.selectedProduct.onboarding && this.selectedProduct.onboarding.blacklist) {
        this.selectedProduct.onboarding.blacklist.forEach((missingKey) => {
          Object.keys(formData).forEach((key) => {
            if (!Object.keys(formData[key]).includes(missingKey)) {
              return;
            }

            filledData[key][missingKey] = this.selectedProduct.type;
          });
        });
      }

      return filledData;
    },

    getRules(fieldName, fallback = '') {
      return this.selectedProduct.onboarding &&
        this.selectedProduct.onboarding.rules &&
        this.selectedProduct.onboarding.rules[fieldName]
        ? this.selectedProduct.onboarding.rules[fieldName]
        : fallback;
    }
  }
};
