var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',_vm._b({class:{
      'v-input--is-focused primary--text v-select--is-menu-active': _vm.showMenu,
      'input--has-error': errors.length > 0,
      'input--is-filter': _vm.filter
    },attrs:{"id":_vm.fieldId,"error-messages":errors,"hide-details":errors.length === 0,"items":_vm.items,"label":_vm.fieldLabelWithSupplement,"menu-props":{
      'content-class': (_vm.contentClass + " select__menu"),
      'offset-y': true,
      value: _vm.showMenu
    },"multiple":_vm.multiple,"placeholder":_vm.fieldPlaceholder,"append-icon":"mdi-chevron-down","flat":"","height":"45","item-color":"secondary","item-text":"label","item-value":"value","outlined":""},nativeOn:{"click":function($event){_vm.showMenu = !_vm.showMenu}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_vm._t("label"),_vm._t("prepend-item")]},proxy:true},(_vm.filter)?{key:"label",fn:function(){return [_c('div',{staticClass:"selection__wrapper"},[_c(("" + _vm.labelIcon),{tag:"component",staticClass:"selection__icon"}),_c('div',{staticClass:"selection__label"},[_vm._v(_vm._s(_vm.fieldLabel))])],1)]},proxy:true}:null,(_vm.filter && _vm.label)?{key:"selection",fn:function(){return [_c('div',{staticClass:"selection__wrapper"},[_c(("" + _vm.labelIcon),{tag:"component",staticClass:"selection__icon"}),_c('div',{staticClass:"selection__label"},[_vm._v(_vm._s(_vm.fieldLabel))])],1)]},proxy:true}:null,(_vm.filter)?{key:"item",fn:function(ref){
    var item = ref.item;
return [_vm._t("item",function(){return [_c('span',{staticClass:"select-menu__icon-wrapper"},[(_vm.loadingValue === item.value)?_c('app-spinner',{staticClass:"mx-1",attrs:{"size":"16"}}):(_vm.itemSelected(item.value))?_c('v-icon',[_vm._v("mdi-check")]):_vm._e()],1),_c('div',{staticClass:"selection__label"},[_vm._v(_vm._s(item.label))])]})]}}:null],null,true),model:{value:(_vm.selection),callback:function ($$v) {_vm.selection=$$v},expression:"selection"}},'v-select',_vm.$attrs,false)),(_vm.showMenu)?_c('v-overlay',{staticStyle:{"opacity":"0"},nativeOn:{"click":function($event){$event.stopPropagation();_vm.showMenu = !_vm.showMenu}}}):_vm._e()]}}])},'ValidationProvider',_vm.validationProps,false))}
var staticRenderFns = []

export { render, staticRenderFns }