<template>
  <v-snackbar
    v-model="snackbar"
    class="pa-0"
    color="color"
    light
    bottom
    :timeout="timeout"
    v-bind="longtextFormat"
  >
    <app-alert
      :color="color"
      :icon="icon || false"
      :class="{ 'error--text semi-bold': color === 'error' }"
    >
      {{ text }}
    </app-alert>

    <template #action>
      <app-icon-btn class="mr-n1" icon="mdi-close" size="1rem" @click="snackbar = false" />
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'AppSnackbar',

  data: () => ({
    snackbar: false,
    text: '',
    color: '',
    icon: '',
    timeout: 5000
  }),

  computed: {
    longtextFormat() {
      return this.text.length > 60
        ? {
            'multi-line': true,
            timeout: 8000
          }
        : null;
    }
  },

  created() {
    // listen to global event
    this.$eventHub.$on('snackbar', this.getText);
  },

  methods: {
    getText({ text, color, icon, timeout }) {
      this.text = this.$t(text);
      this.color = color;
      this.icon = icon;
      this.timeout = timeout;
      this.snackbar = true;
    }
  },

  watch: {
    snackbar(open) {
      if (open === false) {
        this.text = '';
      }
    }
  },

  beforeDestroy() {
    this.$eventHub.$off('snackbar');
  }
};
</script>

<style scoped>
>>> .theme--light.v-sheet.v-snack__wrapper {
  align-items: stretch;
  background-color: var(--c-grey-background);
}

>>> .v-snack__content {
  padding: 0;
  display: flex;
}

>>> .v-alert {
  display: flex;
  flex: 1;
}

>>> .v-alert__wrapper {
  flex: 1;
}

>>> .v-alert__wrapper svg {
  margin-top: unset;
}

>>> .v-alert__icon {
  align-self: auto;
}

>>> .v-alert__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
