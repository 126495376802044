const CEIL_VALUE_IN_CENT = 2500000; // step interval between possible values
const TURNOVER_RATE = 0.1; // rate of turnover which is taken into account

export const calculateCreditorLimitFromTurnover = (
  turnoverInCent,
  maxValueInCent // highest possible limit, customizable via product settings onboarding[maxCreditorLimitInCent]
) => {
  if ([null, undefined, ''].includes(turnoverInCent) || isNaN(turnoverInCent)) {
    return '';
  }

  return Math.min(
    Math.ceil((turnoverInCent * TURNOVER_RATE) / CEIL_VALUE_IN_CENT) * CEIL_VALUE_IN_CENT,
    maxValueInCent
  );
};

export default calculateCreditorLimitFromTurnover;
