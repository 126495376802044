<template>
  <svg
    width="12px"
    height="10px"
    viewBox="0 0 12 10"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <polygon id="path-check" points="16.59 7.58 10 14.17 7.41 11.59 6 13 10 17 18 9" />
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-6.000000, -7.000000)">
        <mask>
          <use xlink:href="#path-check" />
        </mask>
        <use fill="currentColor" fill-rule="nonzero" xlink:href="#path-check" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconCheck'
};
</script>
