<template>
  <ValidationProvider v-slot="{ errors }" v-bind="validationProps" data-testid="app-checkbox">
    <v-checkbox
      :id="fieldId"
      v-model="checked"
      :color="$attrs.dark !== undefined ? 'white' : color"
      :label="fieldLabel"
      v-bind="fieldAttrs"
      :hide-details="errors.length === 0 || hideDetails"
      :error-messages="errors"
      :class="{ 'input--has-error': errors.length > 0 }"
      data-testid="app-checkbox__checkbox"
    >
      <template #label>
        <slot></slot>
      </template>
    </v-checkbox>
  </ValidationProvider>
</template>

<script>
import FormValidationMixin from '@/mixins/FormValidationMixin';

export default {
  name: 'AppCheckbox',

  mixins: [FormValidationMixin],

  props: {
    color: {
      type: String,
      default: 'primary'
    },
    itemValue: {
      type: String,
      default: undefined
    },
    rules: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: [Boolean, Array],
      required: true
    }
  },

  computed: {
    checked: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    fieldLabel() {
      if (this.$slots.label) {
        return;
      }

      return this.$te(this.label) ? this.$t(this.label) : this.label;
    },
    fieldAttrs() {
      return { ...this.$attrs, ...(this.itemValue !== undefined ? { value: this.itemValue } : {}) };
    },
    validationRules() {
      return this.required ? { required: { allowFalse: false }, ...this.rules } : this.rules;
    }
  }
};
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0;
  padding: 0;
}

>>> label.v-label {
  font-size: 14px;
  margin-top: 2px;
}
.theme--light >>> label.v-label {
  color: var(--c-text);
}
.theme--dark >>> label.v-label {
  color: var(--c-white);
}

.theme--dark >>> .v-input--checkbox {
  color: var(--c-white);
}

>>> .v-input__slot {
  align-items: start;
}
</style>
