import DV_FACTORING_CONFIG from '@/productConfigs/smafa';
import DV_BILLING_CONFIG from '@/productConfigs/apl';
import FW_FACTORING_CONFIG from '@/productConfigs/fw';
import DAA_BILLING_CONFIG from '@/productConfigs/daa';
import DAB_BILLING_CONFIG from '@/productConfigs/dab';
import FAS_BILLING_CONFIG from '@/productConfigs/fas';
import VHV_FACTORING_CONFIG from '@/productConfigs/vhv';
import TPF_FACTORING_CONFIG from '@/productConfigs/tpf';

const PRODUCT_CONFIGS = [
  DV_FACTORING_CONFIG,
  DV_BILLING_CONFIG,
  FW_FACTORING_CONFIG,
  DAA_BILLING_CONFIG,
  DAB_BILLING_CONFIG,
  FAS_BILLING_CONFIG,
  VHV_FACTORING_CONFIG,
  TPF_FACTORING_CONFIG
];

export default PRODUCT_CONFIGS;
