<template>
  <svg
    width="30.02px"
    height="27.28px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30.02 27.28"
  >
    <g fill="currentColor">
      <path fill="none" d="M14.45,20.26a.87.87,0,1,1,0-1.22A.87.87,0,0,1,14.45,20.26Z" />
      <path
        d="M29.79,26l-5.15-4,.09-.11A13.43,13.43,0,0,0,26,7.62,13.46,13.46,0,0,0,13.84,0h-.2a13.64,13.64,0,0,0-.2,27.27,4.82,4.82,0,0,0,.76,0H29.34a.73.73,0,0,0,.68-.76A.78.78,0,0,0,29.79,26ZM25.11,8.56A12.12,12.12,0,0,1,23.22,21.8a.56.56,0,0,0-.12.38.54.54,0,0,0,.18.35l4.55,3.53H14.18C5.58,26,1.57,19.69,1.48,13.89S5.21,1.62,13.82,1.27h.12A12.2,12.2,0,0,1,25.11,8.56Z"
      />
      <path d="M13.22,19a.87.87,0,1,0,1.23,0A.88.88,0,0,0,13.22,19Z" />
      <path
        d="M14.09,8.07A.5.5,0,0,0,13.8,8a.58.58,0,0,0-.34.13.83.83,0,0,1,.38-.1A.71.71,0,0,1,14.09,8.07Z"
      />
      <path
        d="M13.12,16.68a.77.77,0,0,0,.65.81.76.76,0,0,0,.72-.81h0V8.84a.89.89,0,0,0-.4-.77A.71.71,0,0,0,13.84,8a.83.83,0,0,0-.38.1.88.88,0,0,0-.34.73Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconDispute'
};
</script>
