<template>
  <v-expansion-panels dark class="my-5" style="border-radius: 2px">
    <v-divider />
    <v-expansion-panel>
      <v-expansion-panel-header class="pl-4">
        <p class="subtitle-1 semi-bold">{{ 'API Response ' + apiName }}</p>
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <div v-if="Array.isArray(data)" class="overflow-x-auto pl-2">
          <div v-for="(arrayValue, index) in data" :key="index">
            <div class="caption semi-bold mt-2">{{ $t('result') }} {{ index + 1 }}:</div>
            <v-list>
              <v-list-item
                v-for="(objectValue, objectKey) in arrayValue"
                :key="objectKey"
                class="pa-0"
              >
                <pre> {{ objectKey }}: {{ objectValue }}</pre>
              </v-list-item>
            </v-list>
          </div>
        </div>

        <div v-if="typeof data === 'object' && !Array.isArray(data)" class="overflow-x-auto">
          <v-list>
            <v-list-item v-for="(value, key) in data" :key="key" class="pa-0">
              <pre> {{ key }}: {{ value }}</pre>
            </v-list-item>
          </v-list>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'AppApiLog',

  props: {
    data: {
      type: [Array, Object],
      default: () => ({})
    },
    apiName: {
      type: String,
      default: ''
    }
  }
};
</script>

<style scoped>
>>> .v-expansion-panel-content__wrap {
  padding: 0.5rem;
}

.v-expansion-panel-content__wrap .v-list-item {
  min-height: auto;
  user-select: auto;
}

pre {
  font-size: 0.85rem;
}
</style>
