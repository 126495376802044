<template>
  <v-app-bar
    v-if="!$route.meta.noToolbar"
    id="toolbar"
    flat
    :dark="!isMobile"
    height="90"
    :class="{ 'v-toolbar__border': !isMobile }"
  >
    <div class="toolbar__content">
      <div v-if="!isMobile"><!-- alignment helper --></div>
      <div v-if="product.placeLogoInHeader && !isMobile">
        <partner-logos height="55px" class="toolbar__logos" />
      </div>

      <div>
        <v-row dense class="toolbar__actions">
          <v-col v-if="isMobile" cols="auto">
            <app-icon-btn icon="mdi-menu" size="35" @click="toggleSidebar" />
          </v-col>

          <v-divider v-if="isMobile" vertical class="toolbar__divider" />

          <v-col v-if="isAdmin" order="1" cols="auto">
            <app-tooltip :text="hasAdminView ? 'user.disableAdminView' : 'user.enableAdminView'">
              <app-icon-btn
                :color="hasAdminView ? 'warning' : isMobile ? 'text' : 'background-text'"
                icon="mdi-account-cog"
                large
                @click="adminViewState = !adminViewState"
              />
            </app-tooltip>
          </v-col>

          <v-col
            v-if="!isMobile && permissions.navigation[routeName.HELP_CENTER]"
            order="1"
            cols="auto"
          >
            <app-tooltip text="helpCenter.helpCenter">
              <app-tooltip text="helpCenter.helpCenter">
                <app-icon-btn
                  :color="isMobile ? 'text' : 'background-text'"
                  :to="{ name: routeName.HELP_CENTER }"
                  icon="icon-help-center"
                />
              </app-tooltip>
            </app-tooltip>
          </v-col>

          <v-col v-if="permissions.navigation[routeName.WORK_QUEUE]" order="1" cols="auto">
            <app-tooltip text="workQueue.title" :disabled="isMobile">
              <app-icon-btn
                :color="isMobile ? 'text' : 'background-text'"
                :to="{ name: routeName.WORK_QUEUE }"
              >
                <v-badge
                  id="work-queue-badge"
                  :value="unhandledWorkQueueCount > 0"
                  color="error"
                  overlap
                >
                  <template #badge>
                    {{ unhandledWorkQueueCount }}
                  </template>
                  <icon-work-queue />
                </v-badge>
              </app-icon-btn>
            </app-tooltip>
          </v-col>

          <v-col v-if="permissions.notifications.show" order="1" cols="auto">
            <notifications-activator />
          </v-col>

          <v-col v-if="!isMobile" order="2" cols="auto" class="pr-0">
            <user-menu />
          </v-col>

          <v-col v-else order="2" cols="auto" class="toolbar__logos toolbar__logos--mobile">
            <partner-logos small height="55px" reverse class="ml-2 mr-n2" />
          </v-col>
        </v-row>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import NotificationsActivator from '@/modules/User/components/Notifications/NotificationsActivator';
import PartnerLogos from '@/shared/components/PartnerLogos';
import UserMenu from '@/modules/User/components/UserMenu';
import { NAMESPACE } from '@/modules/User/store';
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'Toolbar',

  components: {
    NotificationsActivator,
    PartnerLogos,
    UserMenu
  },

  computed: {
    ...mapState(NAMESPACE, ['unhandledWorkQueueCount']),
    adminViewState: {
      get() {
        return this.adminView;
      },
      set(enabled) {
        this.setAdminView(enabled);
      }
    }
  },

  methods: {
    ...mapMutations({
      toggleSidebar: 'TOGGLE_SIDEBAR'
    })
  }
};
</script>

<style scoped lang="scss">
#app.v-application .v-toolbar {
  background-color: transparent;

  &.v-toolbar__border {
    border-bottom: 1px solid var(--c-toolbar-border);
  }

  ::v-deep .v-toolbar__content {
    padding: 0;
  }

  ::v-deep svg {
    width: 30px;
    height: 30px;
  }
}

::v-deep .v-badge .v-badge__badge {
  height: 15px;
  width: 15px;
  font-size: 9px;
  min-width: unset;
  font-weight: bold;
  top: -5px !important;
  right: -5px !important;
  left: unset !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.toolbar__content {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  height: 100%;
  width: 100%;
  margin: 0;
  padding-right: 10px;
  overflow-y: hidden;

  > * {
    flex: 1 0 33.333%;

    &:first-child:not(:last-child) {
      /* empty part for logo centering, shrinks first */
      flex: 0 9999 33.333%;
    }
  }
}

.toolbar__logos {
  flex-basis: 0;
  flex-grow: 1;
  justify-content: center;
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;

  ::v-deep img {
    min-width: 45px;
  }
}

.toolbar__actions {
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  min-width: max-content;
  max-width: calc(100% - 4px);
  margin-left: 24px;
}

#app.v-application.mobile .v-toolbar {
  background-color: var(--c-white);

  .toolbar__content {
    padding-right: 0;
  }

  .toolbar__actions {
    justify-content: flex-start;
    margin-left: 0;
  }

  .toolbar__divider {
    border-color: #d1d1d1;
    border-width: 0.25px;
    height: 55px;
    align-self: center;
  }

  .toolbar__logos--mobile {
    margin-right: 0;
  }
}
</style>
