<template>
  <svg
    width="36px"
    height="35px"
    viewBox="0 0 36 35"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-1116.000000, -366.000000)" fill="currentColor" fill-rule="nonzero">
        <g transform="translate(1116.000000, 366.000000)">
          <path
            d="M0.884356934,23.7010566 L1.87676808,24.477073 C2.12705826,24.6757585 2.33761483,24.9194995 2.49747614,25.1956068 L5.02361361,29.456512 C7.05820524,32.889773 10.7631995,34.997663 14.7672867,35 L19.097808,35 C19.5960749,35 20,34.5978768 20,34.1018328 C20,33.6057887 19.5960749,33.2036656 19.097808,33.2036656 L14.7672867,33.2036656 C11.3977018,33.2004863 8.28083588,31.4242424 6.571775,28.5331961 L4.04563753,24.2722909 C3.76802391,23.808343 3.40573601,23.4001504 2.97744226,23.0687469 L1.96698728,22.2891377 C1.85514124,22.2012237 1.78446755,22.0714411 1.77150419,21.9301595 C1.75854082,21.7888779 1.80442539,21.6484955 1.89842069,21.5418626 C2.30774078,21.0754466 2.88995548,20.7947557 3.51132728,20.7642662 C4.13269908,20.7337766 4.73981792,20.956109 5.19322569,21.3801925 L9.79079588,26.4099289 C10.1306596,26.7567909 10.685628,26.7727404 11.0450253,26.4459746 C11.4044225,26.1192089 11.4386729,25.5675388 11.1224312,25.1991995 L6.49959964,20.1694631 L6.45629443,20.1263511 C5.65176382,19.362751 4.57004219,18.9574702 3.459434,19.0035391 C2.34882581,19.049608 1.3047196,19.5430698 0.566785367,20.3706526 C0.151449059,20.8483908 -0.049568781,21.4744451 0.0104093817,22.1034419 C0.0703875443,22.7324388 0.386143533,23.3096546 0.884356934,23.7010566 Z"
          />
          <path
            d="M21.5092187,2.86695899 C21.0408556,2.86717027 20.5783937,2.97303439 20.1552857,3.17689359 C20.0965035,1.43969653 18.7163061,0.0486886137 17.0043185,0.00124632923 C15.2923309,-0.0461959552 13.8394046,1.26630096 13.6873125,2.99765431 C12.6806578,2.48604646 11.484422,2.54190821 10.5283076,3.14517335 C9.57219323,3.74843849 8.99348252,4.81248805 9,5.95510251 L9,20.0664621 C9,20.5820408 9.41186004,21 9.91984682,21 C10.4278336,21 10.8396382,20.5820408 10.8396382,20.0664621 L10.8396382,5.95510251 C10.8396382,5.16729815 11.4688757,4.52865653 12.2450795,4.52865653 C13.0212833,4.52865653 13.6505208,5.16729815 13.6505208,5.95510251 L13.6505208,16.1418685 C13.6505208,16.6574473 14.0623255,17.0754065 14.5703122,17.0754065 C15.078299,17.0754065 15.4901036,16.6574473 15.4901036,16.1418685 L15.4901036,3.32252551 C15.4901036,2.53472115 16.1193411,1.89607953 16.8955449,1.89607953 C17.6717487,1.89607953 18.3009862,2.53472115 18.3009862,3.32252551 L18.3009862,16.0858563 C18.3009862,16.601435 18.7127909,17.0193942 19.2207776,17.0193942 C19.7287644,17.0193942 20.1405691,16.601435 20.1405691,16.0858563 L20.1405691,6.10073443 C20.1405691,5.34592711 20.7434511,4.73403488 21.4871437,4.73403488 C22.2308363,4.73403488 22.8337183,5.34592711 22.8337183,6.10073443 L22.8337183,16.3323103 C22.8337184,16.847889 23.245523,17.2658482 23.7535097,17.2658482 C24.2614965,17.2658482 24.6733011,16.847889 24.6733012,16.3323103 L24.6733012,9.10299245 C24.6733012,8.40592996 25.230061,7.84084916 25.9168592,7.84084916 C26.6036573,7.84084916 27.1604172,8.40592996 27.1604172,9.10299245 L27.1604172,17.5010998 C27.1604172,18.0166785 27.5722218,18.4346377 28.0802086,18.4346377 C28.5881953,18.4346377 29,18.0166785 29,17.5010998 L29,9.10299245 C28.996242,8.05057811 28.4708887,7.07074518 27.6022321,6.49602495 C26.7335755,5.92130471 25.6382634,5.82887361 24.6880178,6.2501005 L24.6880178,6.10073443 C24.686003,4.31853037 23.2651701,2.87312462 21.5092187,2.86695899 Z"
          />
          <path
            d="M13.9420822,21 L13.891078,21 L12.9808496,21 C12.4391413,21 12,21.4148638 12,21.9266243 C12,22.4383847 12.4391413,22.8532486 12.9808496,22.8532486 L13.891078,22.8532486 L13.9263886,22.8532486 C15.9791244,22.8617926 17.7230715,24.2739652 18.045957,26.189096 C18.0905248,26.5235677 18.3238543,26.8087857 18.6552258,26.9338558 C18.9865974,27.058926 19.363725,27.0041139 19.6399797,26.7907308 C19.9162344,26.5773477 20.048027,26.2390625 19.9841158,25.9074022 C19.5104735,23.0963356 16.9550306,21.0207784 13.9420822,21 L13.9420822,21 Z"
          />
          <path
            d="M30.7603354,25.2374335 C30.6089174,25.0854281 30.4033204,25 30.1889118,25 C29.9745033,25 29.7689063,25.0854281 29.6174883,25.2374335 L28.5004683,26.3494903 L27.38022,25.2374335 C27.0603864,24.962371 26.5830116,24.9801526 26.2844701,25.2782487 C25.9859286,25.5763448 25.9668167,26.0543093 26.2406013,26.3753521 L27.3576213,27.5003397 L26.2406013,28.6091638 C25.9223383,28.924284 25.9194475,29.4380896 26.2341446,29.7567806 C26.5488416,30.0754716 27.061957,30.0783662 27.38022,29.763246 L28.5004683,28.6350256 L29.6142599,29.763246 C29.82014,29.968248 30.1196014,30.0476942 30.3998399,29.9716581 C30.6800784,29.895622 30.8985191,29.6756551 30.9728776,29.394617 C31.047236,29.1135789 30.9662154,28.8141658 30.7603354,28.6091638 L29.640087,27.5003397 L30.7603354,26.3753521 C31.072197,26.0603208 31.072197,25.5524648 30.7603354,25.2374335 Z"
          />
          <g transform="translate(21.000000, 20.000000)">
            <path
              d="M10.6063351,0 L4.39335079,0 L0,4.39335079 L0,10.6066492 L4.39335079,15 L10.6066492,15 L15,10.6063351 L15,4.39350785 L10.6063351,0 Z M13.539214,10.0244835 L10.024469,13.539267 L5.05395909,13.539267 L1.53934555,10.0244835 L1.53934555,5.10849441 L1.53934555,5.05405058 L5.05409059,1.53926702 L10.024469,1.53926702 L13.5393455,5.05405058 L13.539214,10.0244835 L13.539214,10.0244835 Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconBlock'
};
</script>
