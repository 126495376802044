<template>
  <v-app
    :class="{
      [`theme--${themeVar}`]: true,
      mobile: isMobile,
      desktop: isDesktop
    }"
  >
    <layout-onboarding v-if="isOnboarding" />
    <layout v-else />
  </v-app>
</template>

<script>
import Layout from './shared/components/layout/Layout.vue';
import LayoutOnboarding from './onboarding/layout/Layout.vue';
import PRODUCT_CONFIGS from '@/productConfigs';
import purchaseAssuranceTranslations from '@/shared/translations/purchaseAssuranceTranslations';
import reloadOnError from '@/helper/reloadOnError';
import setFullDocumentTitle from '@/helper/setFullDocumentTitle';

export default {
  name: 'App',

  components: {
    Layout,
    LayoutOnboarding
  },

  computed: {
    themeVar() {
      return (
        this.product?.theme ||
        PRODUCT_CONFIGS.find((product) => product.type === this.productTypeFromUrl).theme
      );
    }
  },

  watch: {
    $route: {
      handler() {
        this.setPageTitle();
      },
      immediate: true
    },
    productType: {
      handler() {
        if (!this.productType) {
          return;
        }
        this.configureProduct();
      },
      immediate: true
    }
  },

  methods: {
    configureProduct() {
      document.getElementById('favicon').href = this.product.favIcon;
      this.setTranslations();
    },

    setTranslations() {
      let translations = this.mergeTranslations(this.$i18n.messages.de, this.product.translations);

      if (this.product.withPurchaseAssurance) {
        translations = this.mergeTranslations(translations, purchaseAssuranceTranslations);
      }

      this.$root.$i18n.locale = this.product.type;
      this.$i18n.setLocaleMessage(this.product.type, { ...translations });
    },

    isObject(item) {
      return item && typeof item === 'object' && !Array.isArray(item);
    },

    mergeTranslations(sharedTrans, productTrans) {
      let output = Object.assign({}, sharedTrans);

      if (this.isObject(sharedTrans) && this.isObject(productTrans)) {
        Object.keys(productTrans).forEach((key) => {
          if (this.isObject(productTrans[key])) {
            if (!(key in sharedTrans)) {
              Object.assign(output, { [key]: productTrans[key] });
              return;
            }

            output[key] = this.mergeTranslations(sharedTrans[key], productTrans[key]);
            return;
          }

          Object.assign(output, { [key]: productTrans[key] });
        });
      }
      return output;
    },

    setPageTitle() {
      setFullDocumentTitle({
        pageTitle: this.$route.meta?.title,
        productName: this.productName
      });
    }
  },

  created() {
    window.addEventListener('error', (error) => {
      reloadOnError(
        error,
        /SyntaxError: Unexpected token '<'|SyntaxError: expected expression, got '<'/
      );
    });
  }
};
</script>

<style lang="scss">
@import '~@/styles/fonts.scss';
@import '~@/styles/global.scss';
@import '~@/styles/themes/index.scss';
@import '~@/styles/typography.scss';
</style>
