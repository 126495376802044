<template>
  <div :class="{ 'text-center': alignCenter }">
    <div
      :class="{ 'd-flex align-center text-h5 error--text': true, 'justify-center': alignCenter }"
    >
      <icon-alert class="mr-2" height="40" width="40" />
      {{ $t('errorAlertTitle') }}
    </div>
    <p class="mt-6">{{ $t('errorAlertInfo') }}</p>
    <app-btn class="mt-8" title="reloadPage" @click="reloadPage" />
  </div>
</template>

<script>
export default {
  name: 'AppErrorAlert',

  props: {
    alignCenter: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    reloadPage() {
      window.location.reload();
    }
  }
};
</script>
