<template>
  <v-badge color="secondary" inline :content="String(value)" :value="value !== undefined" />
</template>

<script>
export default {
  name: 'AppFlatBadge',

  props: {
    value: {
      type: [String, Number],
      default: undefined
    }
  }
};
</script>

<style scoped>
.v-badge >>> .v-badge__badge {
  margin-left: 8px;
  font-size: 14px;
  min-width: 27px;
  width: auto;
  height: auto;
  top: unset !important;
  font-weight: 400;
  padding: 3px;
}
</style>
