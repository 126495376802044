import { mapAddress } from '@/shared/models';

export const mapList = (list) =>
  list.map((item) => ({
    id: item.identifier,
    status: item.status,
    created: item.creationDate * 1000,
    customNumber: item.customNumber,
    startDate: item.start * 1000,
    endDate: item.end * 1000,
    nextExecutionDate: item.nextExecution * 1000,
    subject: item.item,
    grossAmount: item.amount,
    period: item.period,
    debtor:
      item.location && item.location.debtor
        ? {
            id: item.location.debtor.identifier,
            name: item.location.debtor.name
          }
        : null,
    location: item.location
      ? {
          id: item.location.identifier,
          name: item.location.name,
          customNumber: item.location.customNumber
        }
      : null
  }));

export const mapItem = (item) => ({
  id: item.identifier,
  status: item.status,
  customNumber: item.customNumber,
  startDate: item.start * 1000,
  endDate: item.end * 1000,
  nextExecutionDate: item.nextExecution * 1000,
  performanceDateIndex: item.performanceDateIndex,
  subject: item.item,
  description: item.comment,
  grossAmount: item.amount,
  netAmount: item.amountNet,
  vat: item.vat,
  period: item.period,
  debtor:
    item.location && item.location.debtor
      ? {
          id: item.location.debtor.identifier,
          name: item.location.debtor.name
        }
      : null,
  location: item.location
    ? {
        id: item.location.identifier,
        name: item.location.name,
        customNumber: item.location.customNumber,
        debtorId: item.location.debtor.identifier
      }
    : null,
  attachment: item.attachment
    ? {
        fileName: item.attachment.fileName,
        source: item.attachment.filePath
      }
    : null,
  officialVoucherSource: item.officialVoucherSource,
  sepa: {
    accountOwner: item.sepa.accountOwner,
    iban: item.sepa.iban
  },
  vouchers: item.vouchers.map((voucher) => ({
    id: voucher.identifier,
    numberToShow: voucher.numberToShow,
    officialVoucherNumber: voucher.officialVoucherNumber,
    requiresProcessing: true,
    amount: voucher.amount,
    type: voucher.type,
    created: voucher.creationDate * 1000
  })),
  stop: item.permanentVoucherStop
    ? {
        officialVoucherSource: item.permanentVoucherStop.officialVoucherSource,
        created: item.permanentVoucherStop.created * 1000
      }
    : null
});

export const mapLocationPermanentDebts = (list) =>
  list.map((item) => ({
    id: item.identifier,
    amount: item.amount,
    customNumber: item.customNumber,
    startDate: item.start * 1000,
    endDate: item.end * 1000,
    nextExecutionDate: item.nextExecution * 1000,
    subject: item.item
  }));

export const mapPermanentDebtDebtors = (debtors) =>
  debtors.map((debtor) => ({
    id: debtor.identifier,
    locations: debtor.locations.map((location) => ({
      id: location.identifier,
      name: location.name,
      contactPerson: location.contactPerson,
      customNumber: location.customNumber,
      address: location.address ? mapAddress(location.address) : null
    })),
    name: debtor.name,
    sepa: debtor.sepa
      ? {
          iban: debtor.sepa.iban,
          accountOwner: debtor.sepa.accountOwner,
          city: debtor.sepa.city,
          referenceNumber: debtor.sepa.referenceNumber,
          date: debtor.sepa.date * 1000
        }
      : null
  }));
