import Vue from 'vue';

/**
 * Print error and show AppSnackbarInfo with message
 * @param {String} [text='serverError'] - translation key
 * @param {String} [color='error'] - success / info
 * @param {String} [icon='$iconAlert'] - icon
 * @param {Number} [timeout='5000'] - icon
 */
export default ({ text = 'serverError', color = 'error', icon = '$iconAlert', timeout = 5000 }) => {
  Vue.prototype.$eventHub.$emit('snackbar', { text, color, icon, timeout });
};
