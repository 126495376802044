/**
 * @see {@link https://wiki.move-elevator.de/pages/viewpage.action?pageId=458189191 Factoring}, {@link https://wiki.move-elevator.de/pages/viewpage.action?pageId=458188863 Billing} for basic voucher types
 * @see {@link https://wiki.move-elevator.de/display/DVKMU/Belegarten+und+Belegattribute DAA / DAB} for additional types
 */
export const CreditorVoucherType = {
  BILLING_REPORT: 'FF',
  CREDIT: 'VH',
  DIRECT_DEBIT: 'ZP',
  INVOICE: 'VB',
  MONTHLY_INVOICE: 'VM',
  PAYMENT: 'ZA',
  PAYMENT_ADVICE: 'VA',
  ACCOUNTS_RECEIVABLE: 'FA',
  REVERSED_TRANSACTION: 'XA',
  SET_UP_FEE: 'VR'
};

export const BASIC_CREDITOR_VOUCHER_TYPE_GROUPS = {
  [CreditorVoucherType.BILLING_REPORT]: [CreditorVoucherType.BILLING_REPORT],
  [CreditorVoucherType.CREDIT]: [CreditorVoucherType.CREDIT],
  [CreditorVoucherType.DIRECT_DEBIT]: [CreditorVoucherType.DIRECT_DEBIT],
  [CreditorVoucherType.INVOICE]: [CreditorVoucherType.INVOICE],
  [CreditorVoucherType.MONTHLY_INVOICE]: [CreditorVoucherType.MONTHLY_INVOICE],
  [CreditorVoucherType.PAYMENT_ADVICE]: [
    CreditorVoucherType.PAYMENT_ADVICE,
    CreditorVoucherType.PAYMENT
  ],
  [CreditorVoucherType.SET_UP_FEE]: [CreditorVoucherType.SET_UP_FEE]
};

export default CreditorVoucherType;
