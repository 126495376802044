import QueryParam from '@/statics/queryParam';

export const TABLE_QUERY_DEFAULTS = {
  [QueryParam.PAGE]: 1,
  [QueryParam.ITEMS_PER_PAGE]: 15,
  [QueryParam.SORT_BY]: [],
  [QueryParam.SORT_DESC]: []
};

export default TABLE_QUERY_DEFAULTS;
