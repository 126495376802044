<template>
  <v-list-item
    :class="{
      'column--bordered ': $route.params.id !== item.id,
      'list-item--selected': $route.params.id === item.id,
      'list-item--error': item.showError,
      'list-item--warning': item.showWarning
    }"
    @click="setDetailedParams(item.id)"
  >
    <v-list-item-content>
      <div class="overline text-uppercase muted">
        <slot name="overline" :item="item">{{ overline }}</slot>
      </div>
      <v-list-item-title class="caption semi-bold">
        <div class="list__icon"><slot name="icon" :item="item"></slot></div>
        <slot name="title" :item="item">{{ title }}</slot>
      </v-list-item-title>
      <v-list-item-subtitle class="caption">
        <slot name="subtitle" :item="item">{{ subtitle }}</slot>
      </v-list-item-subtitle>
      <v-list-item-subtitle class="mt-2 caption d-flex justify-space-between muted">
        <slot name="date" :item="item">{{ date }}</slot>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'AppListItem',

  props: {
    item: {
      type: Object,
      required: true
    },
    overline: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: ''
    }
  },

  methods: {
    setDetailedParams(identifier) {
      if (identifier === this.$route.params.id) {
        if (this.isMobile) {
          this.$router.push({ params: { id: undefined }, query: { ...this.$route.query } });
          return;
        }

        return;
      }

      this.$router.push({ params: { id: identifier }, query: { ...this.$route.query } });
    }
  }
};
</script>
