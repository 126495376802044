/**
 *Formatting large Numbers to readable form, no decimals enforced
 * @param {String|Number} value
 * @return {String} formatted | -
 */
export default function formatNumber(value) {
  if (isNaN(value) || value === null) {
    return '-';
  }

  return Number(value).toLocaleString('de-DE');
}
