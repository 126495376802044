<template>
  <svg
    width="12px"
    height="12px"
    viewBox="0 0 12 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="6" cy="6" r="6" fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'IconDot'
};
</script>
