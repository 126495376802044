<template>
  <div class="activator__wrapper">
    <v-avatar v-if="$auth.user().isTerminated" size="30" color="error" class="mr-3">
      <app-tooltip text="user.hintTerminated">
        <v-icon class="white--text">mdi-account-alert</v-icon>
      </app-tooltip>
    </v-avatar>

    <v-avatar v-else size="30" class="activator__avatar mr-3">
      <v-icon class="avatar__icon">mdi-account</v-icon>
    </v-avatar>

    <div data-cy="user-name">
      {{ $auth.user().name }}
      <span v-if="isAdmin">{{ ` (${$t('user.admin')})` }}</span>
    </div>
    <v-icon v-if="!isMobile" small class="activator__chevron">mdi-chevron-down</v-icon>
  </div>
</template>

<script>
export default {
  name: 'UserMenuActivator'
};
</script>

<style scoped lang="scss">
.activator__wrapper {
  @include caption-font-style;
  display: flex;
  align-items: center;
  padding: 8px;
  color: var(--c-background-text);

  .v-application.mobile & {
    color: var(--c-text);
  }
}

.activator__chevron {
  color: inherit;
  margin-left: 8px;
}

.activator__avatar {
  background-color: currentColor;

  .avatar__icon {
    color: var(--c-background-gradient-light);

    .v-application.mobile & {
      color: var(--c-white);
    }
  }
}
</style>
