import ProductType from '@/statics/productType';
import TutorialTag from '@/modules/User/statics/tutorialTag';

export default {
  computed: {
    fullTutorialsList() {
      // this list contains tutorials for all products
      // use tutorials below to get a list of only product related videos
      return [
        {
          title: 'Überblick über das Portal',
          info: 'In diesem Video erfahren Sie, wie das Portal aufgebaut ist und welche Funktionen Ihnen als Nutzer grundsätzlich zur Verfügung stehen.',
          tags: [TutorialTag.OVERVIEW],
          videoId: {
            [ProductType.DV_FACTORING]: 'y7JIdhnolI0',
            [ProductType.DV_BILLING]: 'Yxh75JPhNZs'
          },
          condition: this.$route.name === this.routeName.DASHBOARD
        },
        {
          title: 'Erste Schritte im Portal',
          info: 'In diesem Video erfahren Sie, wie sich das Dashboard auf der Startseite zusammensetzt und welche Kennzahlen Ihnen zur Verfügung stehen.',
          tags: [TutorialTag.OVERVIEW],
          videoId: {
            [ProductType.DV_FACTORING]: 'jS21ka4WIgo',
            [ProductType.DV_BILLING]: 'wQ5iChqUM3o'
          },
          condition: this.$route.name === this.routeName.DASHBOARD
        },
        {
          title: 'Belegübersicht und Belegaktionen',
          info: 'In diesem Video erfahren Sie, wie Sie verfolgen können, was mit Ihren hochgeladenen Belegen passiert. Sie erfahren außerdem, welche zusätzlichen Belegaktionen Ihnen zur Verfügung stehen.',
          tags: [TutorialTag.VOUCHERS],
          videoId: {
            [ProductType.DV_FACTORING]: 'LFRBXGdshSo',
            [ProductType.DV_BILLING]: 'RbpxS9WfGK4',
            [ProductType.DAA_BILLING]: 'UZV23PhxUJM',
            [ProductType.DAB_BILLING]: 'UZV23PhxUJM'
          },
          condition: this.$route.name === this.routeName.VOUCHERS
        },
        {
          title: 'Hochladen von Rechnungen',
          info: 'In diesem Video erfahren Sie, wie Sie Rechnungen im Portal hochladen, Anhänge hinzufügen und den Versandweg der Rechnung einstellen können.',
          tags: [TutorialTag.VOUCHERS],
          videoId: {
            [ProductType.DV_FACTORING]: '1ppFlKF6Aqw',
            [ProductType.DV_BILLING]: '1rvsfdVPk9U'
          },
          condition: !!this.$route.query[this.queryParam.UPLOAD_VOUCHER]
        },
        {
          title: 'Die 4 goldenen Regeln für den Ankauf von Rechnungen',
          info: 'In diesem Video erfahren Sie, welche Kriterien für den Ankauf von Rechnungen entscheidend sind.',
          tags: [TutorialTag.VOUCHERS],
          videoId: {
            [ProductType.DV_FACTORING]: 'o5sz6BCdx-U'
          },
          condition: !!this.$route.query[this.queryParam.UPLOAD_VOUCHER]
        },
        {
          title: 'Anlage und Limitprüfung Ihrer Kunden',
          info: 'In diesem Video erfahren Sie, wie Sie Ihre Kunden im Portal anlegen und deren Limit anfragen können. Sie erfahren außerdem, was bei der Kundenanlage zu beachten ist, um eine möglichst hohe Limitquote zu erreichen.',
          tags: [TutorialTag.DEBTORS],
          videoId: {
            [ProductType.DV_FACTORING]: 'SgyRa-MK5dU'
          },
          condition: !!this.$route.query[this.queryParam.CREATE_DEBTOR]
        },
        {
          title: 'Anlage und Bonitätsprüfung Ihrer Kunden',
          info: 'In diesem Video erfahren Sie, wie Sie Ihre Kunden im Portal anlegen und deren Bonität prüfen können.',
          tags: [TutorialTag.DEBTORS],
          videoId: {
            [ProductType.DV_BILLING]: '4FzScpgBTcc'
          },
          condition: !!this.$route.query[this.queryParam.CREATE_DEBTOR]
        },
        {
          title: 'Anlage eines SEPA-Lastschriftmandats zu Ihren Kunden',
          info: 'In diesem Video erfahren Sie, wie Sie bei Ihren Kunden ein SEPA-Lastschriftmandat für den Lastschrifteinzug fälliger Rechnungen hinterlegen können.',
          tags: [TutorialTag.DEBTORS],
          videoId: {
            [ProductType.DV_FACTORING]: 'jlSG-1M4nlU',
            [ProductType.DV_BILLING]: '1a-J-xey7ng'
          },
          condition: !!this.$route.query[this.queryParam.CREATE_DEBTOR]
        },
        {
          title: 'Regelmäßige Einzüge einrichten',
          info: 'In diesem Video erfahren Sie, wie Sie regelmäßige Einzüge zu Ihren Kunden anlegen können.',
          tags: [TutorialTag.PERMANENT_DEBTS],
          videoId: {
            [ProductType.DV_FACTORING]: 'WGzidcddc48',
            [ProductType.DV_BILLING]: 'M_AylUw50t0'
          },
          condition: !!this.$route.query[this.queryParam.CREATE_PERMANENT_DEBT]
        },
        {
          title: 'Benachrichtigungen und Entscheidungen',
          info: 'In diesem Video erfahren Sie, wie Sie im Portal Entscheidungen zu konkreten Anfragen und Sachverhalten treffen können.',
          tags: [TutorialTag.USER_INFO],
          videoId: {
            [ProductType.DV_FACTORING]: 'K2t4v0f3SCU',
            [ProductType.DV_BILLING]: 'ip81aaMyaWo',
            [ProductType.DAA_BILLING]: 'TW2egEwsHKw',
            [ProductType.DAB_BILLING]: 'TW2egEwsHKw'
          },
          condition:
            this.$route.name === this.routeName.WORK_QUEUE ||
            !!this.$route.query[this.queryParam.SHOW_NOTIFICATIONS]
        },
        {
          title: 'Ihre persönlichen Daten und Abrechnungen',
          info: 'In diesem Video erfahren Sie, wo Sie Ihre persönlichen Abrechnungen finden. Sie erfahren außerdem, wie Sie für sich relevante Einstellungen vornehmen können, die Ihnen die Nutzung des Portals erleichtern.',
          tags: [TutorialTag.MASTER_DATA],
          videoId: {
            [ProductType.DV_FACTORING]: 'OAeNLq15YVc',
            [ProductType.DV_BILLING]: 'Ic2FrZLIOYE',
            [ProductType.DAA_BILLING]: 'Olm65dbMeeU',
            [ProductType.DAB_BILLING]: 'Olm65dbMeeU'
          },
          condition: this.$route.name === this.routeName.MASTER_DATA
        }
      ];
    },
    tutorials() {
      // list of tutorials for current product,
      // videoId matches value for current product
      return this.fullTutorialsList.reduce((tutorialsForCurrentProduct, tutorial) => {
        if (tutorial.videoId[this.productType]) {
          tutorialsForCurrentProduct.push({
            ...tutorial,
            videoId: tutorial.videoId[this.productType]
          });
        }
        return tutorialsForCurrentProduct;
      }, []);
    }
  }
};
