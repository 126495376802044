<template>
  <svg
    width="25px"
    height="29px"
    viewBox="0 0 25 29"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-566.000000, -248.000000)" fill="#000000" fill-rule="nonzero">
        <g transform="translate(566.000000, 248.000000)">
          <g>
            <path
              d="M11.655,28.44 C11.79,28.575 11.97,28.62 12.15,28.62 C12.33,28.62 12.51,28.575 12.645,28.44 L20.61,20.475 C25.245,15.84 25.245,8.28 20.61,3.6 C15.975,-1.035 8.415,-1.035 3.735,3.6 C1.485,5.85 0.225,8.865 0.225,12.015 C0.225,15.21 1.485,18.18 3.735,20.43 L11.655,28.44 Z M4.68,4.59 C6.75,2.52 9.45,1.485 12.15,1.485 C14.85,1.485 17.55,2.52 19.62,4.59 C23.76,8.73 23.76,15.435 19.62,19.53 L12.15,27.045 L4.68,19.53 C2.7,17.55 1.575,14.895 1.575,12.06 C1.575,9.225 2.7,6.57 4.68,4.59 Z"
            />
            <path
              d="M12.15,18.09 C15.48,18.09 18.225,15.345 18.225,12.015 C18.225,8.685 15.525,5.94 12.15,5.94 C8.775,5.94 6.075,8.685 6.075,12.015 C6.075,15.345 8.82,18.09 12.15,18.09 Z M12.15,7.29 C14.76,7.29 16.875,9.405 16.875,12.015 C16.875,14.625 14.76,16.74 12.15,16.74 C9.54,16.74 7.425,14.625 7.425,12.015 C7.425,9.405 9.54,7.29 12.15,7.29 Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconMapMarker'
};
</script>
