import TutorialsMixin from '@/mixins/TutorialsMixin';
import { isValid, parseISO, endOfDay, startOfDay } from 'date-fns';

export const infoDialogStorage = 'user-information';

export default {
  mixins: [TutorialsMixin],

  computed: {
    infoDialogs() {
      /**
       * @type {object[]}
       * array of config objects with following properties
       * @property {String} key - unique key used to save confirmation in local storage
       * @property {String} title - card title
       * @property {String} text - card text
       * @property {Boolean} isAlert - whether to show error icon in title
       * @property {Number} startDate  - date in format yyyy-MM-dd (or with specific hour/minute: yyyy-MM-ddThh:mm+02:00 in summer or +01:00 in winter time), first day to show dialog
       * @property {Number} endDate - date in format yyyy-MM-dd (or with specific hour/minute: yyyy-MM-ddThh:mm+02:00 in summer or +01:00 in winter time), last day to show dialog
       * @property {Boolean} condition - set to false if dialog should not be displayed
       */
      return [
        {
          key: '2024-07-16-service-times',
          title: 'Erreichbarkeit am 16.07.2024',
          text: `Auf Grund einer Betriebsveranstaltung werden wir am Dienstag, den 16.07.2023, im Zeitraum von 8:15 Uhr bis 12:45 Uhr, telefonisch und per E-Mail nicht erreichbar sein und Belege lediglich eingeschränkt verarbeiten.

            Wir stehen Ihnen an diesem Tag zwischen 7:00 Uhr und 8:15 Uhr und spätestens wieder ab 12:45 Uhr bis zum Ende unserer üblichen Servicezeit zur Verfügung.

            Wir bitten um Verständnis und verbleiben mit besten Grüßen

            Ihr Kundenservice`,
          startDate: '2024-07-09',
          endDate: '2024-07-16T13:00+02:00'
        }
      ];
    },
    activeInfoDialogs() {
      return this.infoDialogs.filter((dialog) => {
        const hasFulfilledCondition = this.hasFulfilledCondition(dialog);

        const startDateIsInPast =
          !dialog.startDate ||
          (isValid(parseISO(dialog.startDate)) &&
            (dialog.startDate.length > 10
              ? Date.parse(dialog.startDate)
              : startOfDay(Date.parse(dialog.startDate))) < Date.now());

        const endDateIsInFuture =
          !dialog.endDate ||
          (isValid(parseISO(dialog.endDate)) &&
            (dialog.endDate.length > 10
              ? Date.parse(dialog.endDate)
              : endOfDay(Date.parse(dialog.endDate))) > Date.now());

        return hasFulfilledCondition && startDateIsInPast && endDateIsInFuture;
      });
    }
  }
};
