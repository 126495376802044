export const PermanentDebtStatus = {
  CREATED: 'created',
  ACTIVE: 'active',
  STOPPED: 'stopped',
  EXPIRED: 'expired'
};

export const STATUS_GROUPS = {
  [PermanentDebtStatus.CREATED]: [PermanentDebtStatus.CREATED],
  [PermanentDebtStatus.ACTIVE]: [PermanentDebtStatus.ACTIVE],
  [PermanentDebtStatus.STOPPED]: [PermanentDebtStatus.STOPPED, PermanentDebtStatus.EXPIRED]
};

export default PermanentDebtStatus;
