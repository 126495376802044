<template>
  <v-tooltip
    :nudge-bottom="5"
    color="white"
    top
    max-width="300"
    content-class="elevation-8 text--text tooltip--active"
    v-bind="$attrs"
    open-delay="200"
  >
    <template #activator="{ on }">
      <span :class="inline ? 'tooltip--inline' : 'tooltip--flex'" v-on="disabled ? undefined : on">
        <slot>
          <icon-info class="ml-2 icon--inline grey--text" />
        </slot>
      </span>
    </template>

    <slot name="text">
      {{ $te(text) ? $t(text) : text }}
    </slot>
  </v-tooltip>
</template>

<script>
export default {
  name: 'AppTooltip',

  props: {
    text: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.tooltip {
  &--inline {
    display: inline;
    vertical-align: middle;
  }

  &--flex {
    display: flex;
    align-items: baseline;
    flex: 0 0 auto;
  }
}
.v-tooltip {
  z-index: 200;
}
.v-tooltip__content {
  border-radius: 2px;

  &.tooltip--active {
    opacity: 1 !important;
  }
}
</style>
