/**
 * @see {@link https://wiki.move-elevator.de/pages/viewpage.action?pageId=458189191 Factoring}, {@link https://wiki.move-elevator.de/pages/viewpage.action?pageId=458188863 Billing} for basic voucher types
 * @see {@link https://wiki.move-elevator.de/display/DVKMU/Belegarten+und+Belegattribute DAA / DAB} for additional types
 */

export const VoucherType = {
  INVOICE: 'invoice', // DR
  PERMANENT_DEBT_INVOICE: 'permanent_debt', // RD
  ENCASHMENT_INVOICE: 'invoice_encashment_direct', // DI
  EPOD_INVOICE: 'epod', // PO
  ABANDONMENT: 'abandonment', // DV
  ANNULATION: 'annulation', // DS
  CREDIT_ADVICE: 'credit_advice', // DG
  DEBIT_PAYMENT: 'debit_payment', // ZP
  DEPOSIT: 'deposit', // DZ
  DEPOSIT_ENCASHMENT: 'deposit_encashment', // ZI
  DEPOSIT_INSURANCE: 'deposit_insurance', // ZV
  DIRECT_PAYMENT: 'direct_payment', // DD
  IMPERSONAL_ACCOUNT_VOUCHER: 'impersonal_account_voucher', // SA
  PURCHASE_RETIREMENT: 'voucher_retirement_degener', // DU
  VAT_RETIREMENT: 'vat_retirement', // DK
  VOUCHER_RETIREMENT: 'voucher_retirement', // WK,
  EPOD_SETTLEMENT: 'epod_settlement' // PR
};

export const VOUCHER_TYPES = Object.values(VoucherType);

export const INVOICE_TYPES = [
  VoucherType.INVOICE,
  VoucherType.PERMANENT_DEBT_INVOICE,
  VoucherType.ENCASHMENT_INVOICE,
  VoucherType.EPOD_INVOICE
];

/**
 * Map labeled types (visible to user) to one or more voucher types (backend values) which are summarized under this label.
 * Used in product configs as base value for restriction or extension of supported voucher types.
 */
export const BASIC_VOUCHER_TYPE_GROUPS = {
  [VoucherType.INVOICE]: [VoucherType.INVOICE, VoucherType.ENCASHMENT_INVOICE],
  [VoucherType.PERMANENT_DEBT_INVOICE]: [VoucherType.PERMANENT_DEBT_INVOICE],
  [VoucherType.DEPOSIT]: [
    VoucherType.DEPOSIT,
    VoucherType.DEPOSIT_ENCASHMENT,
    VoucherType.DEBIT_PAYMENT
  ],
  [VoucherType.DIRECT_PAYMENT]: [VoucherType.DIRECT_PAYMENT],
  [VoucherType.CREDIT_ADVICE]: [VoucherType.CREDIT_ADVICE],
  [VoucherType.ABANDONMENT]: [VoucherType.ABANDONMENT],
  [VoucherType.ANNULATION]: [VoucherType.ANNULATION],
  [VoucherType.IMPERSONAL_ACCOUNT_VOUCHER]: [
    VoucherType.IMPERSONAL_ACCOUNT_VOUCHER,
    VoucherType.VOUCHER_RETIREMENT
  ],
  [VoucherType.VAT_RETIREMENT]: [VoucherType.VAT_RETIREMENT]
};

export default VoucherType;
