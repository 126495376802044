import { mapCreditorStatistic } from '@/modules/Dashboard/models';
import { mapCreditorVouchers } from '@/modules/User/models';

export const NAMESPACE = 'dashboard';

const initialState = () => ({});

export default (Vue) => ({
  namespaced: true,

  state: initialState(),

  mutations: {
    RESET_STATE(state) {
      const initial = initialState();
      Object.keys(initial).forEach((key) => {
        state[key] = initial[key];
      });
    }
  },

  actions: {
    async fetchCreditorStatistic() {
      try {
        const response = await Vue.prototype.$http.get('/creditor/statistic');
        return { data: mapCreditorStatistic(response.data) };
      } catch (error) {
        return { error };
      }
    },

    async fetchCreditorVoucherStatistic() {
      try {
        const response = await Vue.prototype.$http.get('/creditor/statistic/billing-vouchers');
        return { data: mapCreditorVouchers(response.data) };
      } catch (error) {
        return { error };
      }
    },

    async fetchBalancingStatistic() {
      try {
        const response = await Vue.prototype.$http.get('/creditor/statistic/balancing');
        return { data: response.data };
      } catch (error) {
        return { error };
      }
    },

    async fetchTurnoverVolumeStatistic() {
      try {
        const response = await Vue.prototype.$http.get('/creditor/statistic/uploaded-volume');
        return { data: response.data };
      } catch (error) {
        return { error };
      }
    },

    async fetchTurnoverProgressionStatistic() {
      try {
        const response = await Vue.prototype.$http.get('/creditor/statistic/turnover');
        return { data: response.data };
      } catch (error) {
        return { error };
      }
    },

    async fetchOverdueDaysStatistic() {
      try {
        const response = await Vue.prototype.$http.get(
          '/creditor/statistic/payment-deadline-delay'
        );
        return { data: response.data };
      } catch (error) {
        return { error };
      }
    },

    async fetchDaysSalesOutstandingStatistic() {
      try {
        const response = await Vue.prototype.$http.get(
          '/creditor/statistic/days-sales-outstanding'
        );
        return { data: response.data };
      } catch (error) {
        return { error };
      }
    },

    async fetchServiceFeePeriodStatistic() {
      try {
        const response = await Vue.prototype.$http.get('/creditor/statistic/service-fee-period');
        return { data: response.data };
      } catch (error) {
        return { error };
      }
    },

    async fetchDunningTypeCountStatistic(_, type) {
      try {
        const response = await Vue.prototype.$http.get(
          `/creditor/statistic/count-dunning-type/${type}`
        );
        return { data: response.data };
      } catch (error) {
        return { error };
      }
    },

    async fetchDunningTypeOpenInvoiceStatistic() {
      try {
        const response = await Vue.prototype.$http.get('/creditor/statistic/open-voucher');
        return { data: response.data };
      } catch (error) {
        return { error };
      }
    }
  }
});
