export default {
  onboardingProcess: 'Antragsprozess',
  titleLandingPage: 'Willkommen in der Antragsstrecke für {productName}',
  titleRequest: 'Willkommen bei der Produktprüfung für {productName}',
  subtitleRequest:
    'Bitte erfassen Sie alle erforderlichen Daten, um für das KMU eine unverbindliche Produktprüfung vorzunehmen.',
  subtitleRegistration:
    'Bitte erfassen Sie alle erforderlichen Daten, um für das KMU einen Produktantrag vorzunehmen.',
  chooseProduct: 'Bitte wählen Sie eine Aktion aus!',
  selectInfo:
    'Starten Sie eine „Anfrage“, wenn Sie unverbindlich prüfen wollen, ob der KMU für {productName} in Frage kommt. Sofern Sie einen verbindlichen Antrag stellen wollen, wählen Sie bitte „Antrag starten“ aus.',
  startRequest: 'Anfrage starten',
  startRegistration: 'Antrag starten',
  sendRequest: 'Anfrage für Produkt senden!',
  fillRequest: 'Füllen Sie die Felder aus, um eine Anfrage für {productName} zu starten.',
  foundingDate: 'Gründungsdatum',
  foundingDateNotValid:
    'Gründungsdatum der Firma ist jünger als 6 Monate. Verwenden Sie das Datum der Gewerbeanmeldung oder der Eintragung im Handelsregister.',
  maxOnlineTurnoverRateInvalid:
    'Der Anteil Onlineumsatz darf maximal {max}% vom Jahresumsatz betragen.',
  intermediaryInfo: 'Informationen über den Vermittler',
  privacyPolicyConfirmation: 'Bestätigung der Datenschutzhinweise',
  privacyPolicyAccepted:
    'Ich bestätige, dass ich die Firma über die Verarbeitung ihrer personenbezogenen Daten durch die FinTecrity GmbH informiert habe. Die Datenschutzhinweise für gewerbliche Interessenten und Kunden (B2B) sind hier hinterlegt: {link}  und in ihrer aktuellen Fassung als PDF abrufbar.',
  requiredFields: 'Benötigte Felder',
  submitRequest: 'Anfrage jetzt senden',
  legalFormalities: 'rechtliche Formalitäten',
  sepa: 'SEPA',
  intermediary: 'Vermittler',
  entitledToDeductVat: 'Vorsteuerabzugsberechtigt',
  vobLabel: 'Rechnungen nach Vergabe- und Vertragsordnung für Bauleistungen (@:onboarding.vob)',
  vob: 'VOB',
  vobInfoHtml:
    'Die Vergabe- und Vertragsordnung für Bauleistungen (<b>VOB</b>) ist ein Regelwerk für die Vergabe von Bauaufträgen samt Vertragsbedingungen, die für Bauaufträge der öffentlichen Hand in Deutschland verpflichtend ist und vielfach auch bei privaten Bauverträgen angewendet wird.',
  commercialRegisterExcerpt: 'Handelsregisterauszug',
  identityCard: 'Personalausweis',
  sepaMandate: 'SEPA-Firmenlastschrift-Mandat',
  sepaAuthorization:
    'Wir ermächtigen die {name}, Zahlungen von unserem Konto mittels Lastschrift einzuziehen. Zugleich weisen wir unser Kreditinstitut an, die von der {name} auf unser Konto gezogenen Lastschriften einzulösen. Hinweis: Dieses Lastschriftmandat dient nur dem Einzug von Lastschriften, die auf Konten von Unternehmen gezogen sind. Wir sind nicht berechtigt, nach der erfolgten Einlösung eine Erstattung des belasteten Betrages zu verlangen. Wir sind berechtigt, unser Kreditinstitut bis zum Fälligkeitstag anzuweisen, Lastschriften nicht einzulösen.',
  accountOwner: 'Kontoinhaber',
  mediatedByAssetConsultant: 'Vermittelt durch Vermögensberater',
  department: 'Direktion',
  assetConsultantData: 'Angaben zum Vermögensberater',
  intermediaryData: 'Angaben zum Vermittler',
  intermediaryNumber: 'VM-Nummer',
  intermediaryNumberFull: 'Vermittler-Nummer',
  assetConsultantNumber: 'VB-Nummer',
  successTitleRequest: 'Vielen Dank für Ihre Anfrage!',
  successTitleRegistration: 'Vielen Dank für Ihren Antrag!',
  successTextRequest:
    'Sie erhalten eine Bestätigung per E-Mail. Die eingereichten Daten werden geprüft und bewertet. Zur weiteren Verarbeitung der Anfrage treten wir mit Ihnen in Kontakt.',
  successTextRegistration:
    'Wir werden die von Ihnen übermittelten Daten schnellstmöglich prüfen. Den vorausgefüllten Antrag erhalten Sie von uns per Mail. Bitte schicken Sie unterschriebene Anträge an: antrag@fintecrity.de.',
  finishedRegistration: 'Sie können dieses Fenster schließen',
  correctMistakes: 'Bitte korrigieren Sie die Fehler, bevor Sie fortfahren.',
  notes: 'Anmerkungen',
  determinedCreditorLimit: 'Ermittelter Ankaufrahmen',
  annualMaximumCompensation: 'Jahreshöchstentschädigung',
  annualOnlineTurnoverRate: 'Onlineumsatz in %',
  factorableAnnualTurnover: 'Factoringfähiger Jahresumsatz'
};
