import formatDateTime from '@/helper/filter/formatDateTime';
import sortObjectProperties from '@/helper/sortObjectProperties';

/**
 * gets an array of unsorted process logs,
 * returns array of process logs sorted by updateDate,
 * formats timestamps to readable format,
 * rearranges object keys of each entry in certain order
 */
export default (data) => {
  const receivedData = JSON.parse(JSON.stringify(data));
  let formattedData = [];

  receivedData
    .sort((a, b) => b.updateDate - a.updateDate)
    .map(({ creationDate, updateDate, identifier, ...entry }) => {
      entry.created = formatDateTime(creationDate * 1000);
      entry.updated = formatDateTime(updateDate * 1000);
      entry.id = identifier;

      // array of desired key order to begin with, any other keys will be added behind
      const orderedKeys = ['channel', 'context', 'message', 'extra'];

      const sortedEntry = sortObjectProperties(entry, orderedKeys);

      formattedData.push(sortedEntry);
    });

  return formattedData;
};
