/**
 * @see {@link https://wiki.move-elevator.de/display/DVKMU/Ankaufstatus+-+Wertedefinition Definitions for most products}
 * @see {@link https://wiki.move-elevator.de/display/DVKMU/Allgemeine+Einstellungen+und+Definitionen Definitions with purchase assurance}
 */
export const PurchaseStatus = {
  PURCHASED: 'purchased',
  NOT_PURCHASED: 'notPurchased',
  COUNT_BACK: 'countBack',
  IN_REVIEW: 'inReview',
  ACCEPTED: 'accepted',
  DISCLOSED: 'disclosed',
  DAMAGE_REPORT_MUST_BE_ENSUE: 'damageReportMustBeEnsue',
  DAMAGE_REPORT_ENSUED: 'damageReportEnsued'
};

export const BackendStatus = {
  UNVERIFIED: 'NICHT_GEPRUEFT',
  PURCHASABLE: 'ANKAUFBAR',
  NOT_PURCHASABLE: 'NICHT_ANKAUFBAR',
  IN_REVIEW: 'VERITAETSPRUEFUNG',
  PURCHASED: 'ANGEKAUFT',
  PURCHASED_MANUALLY: 'MANUELLE_ANKAUFSLISTE',
  DISCLOSED: 'OFFENGELEGT',
  PURCHASED_LATER: 'NACHGEKAUFT',
  NOT_PURCHASED: 'NICHT_ANGEKAUFT',
  NOT_PURCHASABLE_ANYMORE: 'NICHT_MEHR_ANKAUFBAR',
  COUNT_BACK: 'ZURUECKRECHNEN',
  COUNTED_BACK: 'ZURUECKGERECHNET',
  DAMAGE_REPORT_MUST_BE_ENSUE: 'SCHADENSMELDUNG_MUSS_ERFOLGEN',
  DAMAGE_REPORT_ENSUED: 'SCHADENSMELDUNG_ERFOLGT'
};

export const PURCHASE_STATUS_GROUPS = {
  [PurchaseStatus.PURCHASED]: [
    BackendStatus.PURCHASED,
    BackendStatus.PURCHASED_LATER,
    BackendStatus.PURCHASED_MANUALLY,
    BackendStatus.DISCLOSED,
    BackendStatus.DAMAGE_REPORT_MUST_BE_ENSUE,
    BackendStatus.DAMAGE_REPORT_ENSUED
  ],
  [PurchaseStatus.NOT_PURCHASED]: [
    BackendStatus.PURCHASABLE,
    BackendStatus.NOT_PURCHASABLE,
    BackendStatus.NOT_PURCHASABLE_ANYMORE,
    BackendStatus.COUNTED_BACK,
    BackendStatus.NOT_PURCHASED
  ],
  [PurchaseStatus.COUNT_BACK]: [BackendStatus.COUNT_BACK],
  [PurchaseStatus.IN_REVIEW]: [BackendStatus.IN_REVIEW],
  [PurchaseStatus.ACCEPTED]: [BackendStatus.UNVERIFIED]
};

export const PURCHASE_STATUS_GROUPS_WITH_PURCHASE_ASSURANCE = {
  [PurchaseStatus.PURCHASED]: [
    BackendStatus.PURCHASED,
    BackendStatus.PURCHASED_LATER,
    BackendStatus.PURCHASED_MANUALLY,
    BackendStatus.DISCLOSED,
    BackendStatus.DAMAGE_REPORT_MUST_BE_ENSUE,
    BackendStatus.DAMAGE_REPORT_ENSUED
  ],
  [PurchaseStatus.NOT_PURCHASED]: [
    BackendStatus.NOT_PURCHASABLE,
    BackendStatus.NOT_PURCHASABLE_ANYMORE,
    BackendStatus.COUNTED_BACK,
    BackendStatus.NOT_PURCHASED
  ],
  [PurchaseStatus.IN_REVIEW]: [BackendStatus.IN_REVIEW],
  [PurchaseStatus.ACCEPTED]: [
    BackendStatus.COUNT_BACK,
    BackendStatus.PURCHASABLE,
    BackendStatus.UNVERIFIED
  ]
};

export default PurchaseStatus;
