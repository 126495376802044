import { render, staticRenderFns } from "./AppTextarea.vue?vue&type=template&id=3f5f3aaa&scoped=true&"
import script from "./AppTextarea.vue?vue&type=script&lang=js&"
export * from "./AppTextarea.vue?vue&type=script&lang=js&"
import style0 from "./AppTextarea.vue?vue&type=style&index=0&id=3f5f3aaa&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f5f3aaa",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VTextarea})
