import { INVOICE_TYPES } from '@/statics/voucherType';
import RouteName from '@/statics/routeName';

export default {
  computed: {
    routeName() {
      return RouteName;
    }
  },

  methods: {
    getVoucherRoute(
      { id, numberToShow, officialVoucherNumber, requiresProcessing, type },
      keepQuery = false
    ) {
      if (!requiresProcessing || officialVoucherNumber) {
        return {
          name: this.routeName.VOUCHERS,
          params: {
            id: id,
            type: INVOICE_TYPES.includes(type) ? undefined : 'sub'
          },
          query: {
            ...(this.$route.name === this.routeName.VOUCHERS && keepQuery
              ? { ...this.$route.query }
              : undefined),
            customNumber: numberToShow ? [numberToShow] : undefined
          }
        };
      }

      return {
        name: this.routeName.UNPROCESSED,
        params: {
          id: id
        },
        query:
          this.$route.name === this.routeName.UNPROCESSED && keepQuery
            ? { ...this.$route.query }
            : undefined
      };
    },

    getLocationRoute({ id, debtorId, name }, keepQuery = false) {
      return {
        name: this.routeName.DEBTORS,
        params: {
          type: 'sub',
          id: `${debtorId},${id}`
        },
        query: {
          ...(this.$route.name === this.routeName.DEBTORS && keepQuery
            ? { ...this.$route.query }
            : undefined),
          debtor: name ? [name] : undefined
        }
      };
    },

    getDebtorRoute({ id, name }, keepQuery = false) {
      return {
        name: this.routeName.DEBTORS,
        params: {
          id: id
        },
        query: {
          ...(this.$route.name === this.routeName.DEBTORS && keepQuery
            ? { ...this.$route.query }
            : undefined),
          debtor: name ? [name] : undefined
        }
      };
    },

    getPermanentDebtRoute({ id, location }, keepQuery = false) {
      return {
        name: this.routeName.PERMANENT_DEBTS,
        params: {
          id: id
        },
        query: {
          ...(this.$route.name === this.routeName.PERMANENT_DEBTS && keepQuery
            ? { ...this.$route.query }
            : undefined),
          location: location ? [location] : undefined
        }
      };
    }
  }
};
