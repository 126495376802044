<template>
  <div
    v-if="header.text"
    class="table-header__content"
    @click="header.sortable !== false ? $emit('sort', header.value) : null"
  >
    <v-row
      no-gutters
      class="flex-nowrap"
      align="end"
      :justify="index === 0 || header.alignLeft ? 'start' : 'end'"
    >
      {{ header.text }}
      <v-icon
        v-if="header.sortable !== false"
        class="v-data-table-header__icon grey--text align-self-end"
      >
        mdi-chevron-up
      </v-icon>
    </v-row>
    <v-row
      v-if="!isMobile"
      no-gutters
      :class="{ 'flex-nowrap': true, 'text-left': header.alignLeft }"
    >
      <v-col class="table-header-info">
        {{ header.subText }}
      </v-col>
      <v-col class="shrink">
        <div v-if="header.sortable !== false" class="v-data-table-header__icon"></div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'TableHeaderContent',

  props: {
    header: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  }
};
</script>

<style scoped>
>>> .table-header-info {
  font-size: 10px;
  font-weight: 600;
  opacity: 0.5;
  text-transform: none;
  min-height: 12px;
}
</style>
