/* eslint-disable sonarjs/no-duplicate-string */
import PurchaseStatus from '@/modules/Vouchers/statics/purchaseStatus';
import VoucherStatusToShow from '@/statics/voucherStatusToShow';
import VoucherSubStatus from '@/statics/voucherSubStatus';
import VoucherType from '@/statics/voucherType';
import BnetStatus from '@/modules/Unprocessed/statics/bnetStatus';

export default {
  title: 'Ihre Belege',
  subtitle: 'Hier finden Sie einen Überblick über Ihre Belege',
  customNo: 'Beleg-Nr.',
  customNumber: 'Belegnummer',
  officialVoucherNumber: 'Referenz',
  uploadUser: 'Upload-User',
  referenceVoucher: 'Zugehöriger Beleg',
  date: 'Datum des Belegs',
  paymentDeadline: 'Fällig am',
  dueDaysCountedPast: 'heute fällig | fällig seit gestern | fällig seit {count} Tagen',
  dueDaysCountedFuture: 'heute fällig | morgen fällig | fällig in {count} Tagen',
  maturity: 'Fälligkeit',
  noData: 'Sie haben noch keine Belege hochgeladen',
  noDataInfo:
    'Laden Sie jetzt einen Beleg hoch, um den Status Ihrer Rechnung zu verfolgen, offene Beträge sowie Ihre Einnahmen einzusehen.',
  uploadFirst: 'Jetzt meinen ersten Beleg hochladen',
  statusToShowOptions: {
    [VoucherStatusToShow.OPEN]: 'Offen',
    [VoucherStatusToShow.CLOSED]: 'Geschlossen',
    [VoucherStatusToShow.UNKNOWN]: 'Unbekannt',
    [VoucherStatusToShow.FAILED]: 'Fehler',
    [VoucherStatusToShow.PENDING]: 'Warten auf Feedback',
    [VoucherStatusToShow.TPF_ACCEPTED]: 'Akzeptiert',
    [VoucherStatusToShow.TPF_NOT_PURCHASED]: 'Nicht angekauft',
    [VoucherStatusToShow.TPF_IN_REVIEW]: 'In Prüfung',
    [VoucherStatusToShow.TPF_BALANCED]: 'Ausgeglichen',
    document_in_review: 'Dokument wird geprüft',
    debtor_will_be_identified: 'Kunde wird erkannt',
    document_will_be_identified: 'Dokument wird erkannt',
    document_will_be_manual_reviewed: 'Dokument wird manuell geprüft',
    document_selected: 'Dokument ausgelesen',
    debtor_in_review: 'Kunde wird geprüft',
    review_completed: 'Überprüfung abgeschlossen',
    voucher_creation_started: 'Belegerstellung läuft',
    ready_to_deliver: 'Zum Versand übergeben'
  },
  purchaseStatus: 'Ankaufstatus',
  purchaseStatusOptions: {
    [PurchaseStatus.PURCHASED]: 'Angekauft',
    [PurchaseStatus.NOT_PURCHASED]: 'Nicht angekauft',
    [PurchaseStatus.COUNT_BACK]: 'Rückkauf',
    [PurchaseStatus.IN_REVIEW]: 'In Prüfung',
    [PurchaseStatus.ACCEPTED]: 'Gebucht'
  },
  subStatusOptions: {
    [VoucherSubStatus.ACCEPTED]: '@:(vouchers.purchaseStatusOptions.accepted)',
    [VoucherSubStatus.UNVERIFIED]: '@:(vouchers.purchaseStatusOptions.accepted)',
    [VoucherSubStatus.PURCHASABLE]: '@:(vouchers.purchaseStatusOptions.notPurchased)',
    [VoucherSubStatus.NOT_PURCHASABLE]: '@:(vouchers.purchaseStatusOptions.notPurchased)',
    [VoucherSubStatus.IN_REVIEW]: '@:(vouchers.purchaseStatusOptions.inReview)',
    [VoucherSubStatus.PURCHASED]: '@:(vouchers.purchaseStatusOptions.purchased)',
    [VoucherSubStatus.PURCHASED_LATER]: '@:(vouchers.purchaseStatusOptions.purchased)',
    [VoucherSubStatus.PURCHASED_MANUALLY]: '@:(vouchers.purchaseStatusOptions.purchased)',
    [VoucherSubStatus.NOT_PURCHASABLE_ANYMORE]: '@:(vouchers.purchaseStatusOptions.notPurchased)',
    [VoucherSubStatus.COUNT_BACK]: '@:(vouchers.purchaseStatusOptions.countBack)',
    [VoucherSubStatus.COUNTED_BACK]: '@:(vouchers.purchaseStatusOptions.notPurchased)',
    [VoucherSubStatus.NOT_PURCHASED]: '@:(vouchers.purchaseStatusOptions.notPurchased)',
    [VoucherSubStatus.PAYMENT_REMINDER]: 'An Zahlung erinnert',
    [VoucherSubStatus.DELAYED]: 'Verzug',
    [VoucherSubStatus.DUNNING]: 'Angemahnt',
    [VoucherSubStatus.HALT]: 'Mahngestoppt',
    [VoucherSubStatus.ENCASHMENT]: 'Inkasso',
    [VoucherSubStatus.DEFERRED_PAYMENT]: 'Ratenzahlung',
    [VoucherSubStatus.CUSTOMER_COMPLAINT]: 'Reklamiert',
    [VoucherSubStatus.PENDING_DUPLICATE]: `@:bnetStatusOptions.${BnetStatus.DUPLICATED_VOUCHER_NUMBER_221}.title`,
    [VoucherSubStatus.PENDING_ENCASHMENT_DIRECT]: `@:bnetStatusOptions.${BnetStatus.ENCASHMENT_NOT_POSSIBLE_ERROR_935}.title`,
    [VoucherSubStatus.DISCLOSED]: '@:(vouchers.purchaseStatusOptions.purchased)',
    [VoucherSubStatus.UNKNOWN]: 'Unbekannt'
  },
  voucherDate: 'Belegdatum',
  dueDate: 'Fälligkeitsdatum',
  uploadDate: 'Uploaddatum',
  performanceDate: 'Leistungsdatum',
  leftover: 'davon offen',
  sum: 'Summe',
  leftoverAmount: 'Offener Betrag',
  outstandingAmount: 'Ausstehender Betrag',
  outstanding: 'ausstehend',
  invoiceAmount: 'Rechnungsbetrag',
  initialLeftover: 'Offener Brutto-Betrag',
  attachment: {
    print: 'Drucken',
    download: 'Herunterladen',
    openInNewTab: 'Anhang in neuem Tab öffnen'
  },
  paymentAmount: 'Zahlbetrag',
  voucherType: 'Belegart',
  voucherTypes: {
    [VoucherType.INVOICE]: 'Rechnung',
    [VoucherType.PERMANENT_DEBT_INVOICE]: 'Einzugsrechnung',
    [VoucherType.ENCASHMENT_INVOICE]: `@:vouchers.voucherTypes.${VoucherType.INVOICE}`,
    [VoucherType.EPOD_INVOICE]: 'Auftrag',
    [VoucherType.ABANDONMENT]: 'Verzicht',
    [VoucherType.ANNULATION]: 'Stornierung',
    [VoucherType.CREDIT_ADVICE]: 'Gutschrift',
    [VoucherType.DEBIT_PAYMENT]: 'Zahlung',
    [VoucherType.DEPOSIT]: 'Zahlung',
    [VoucherType.DEPOSIT_ENCASHMENT]: 'Zahlung',
    [VoucherType.DEPOSIT_INSURANCE]: 'Entschädigung durch Versicherung',
    [VoucherType.DIRECT_PAYMENT]: 'Direktzahlung',
    [VoucherType.PURCHASE_RETIREMENT]: 'Ankauf',
    [VoucherType.IMPERSONAL_ACCOUNT_VOUCHER]: 'Ausbuchung',
    [VoucherType.VAT_RETIREMENT]: 'Rückbelastung Umsatzsteuer',
    [VoucherType.VOUCHER_RETIREMENT]: 'Ausbuchung',
    [VoucherType.EPOD_SETTLEMENT]: 'Auftrag - Ausgleich'
  },

  moreVouchersCounted: '1 weiterer Beleg | {count} weitere Belege',
  processing: 'in Verarbeitung',
  invoiceNumber: 'Rechnungs-Nr.',
  yourOriginalVoucher: 'Ihr Original-Beleg',
  officialVoucher: 'Anschreiben',
  yourOriginalVoucherNumber: 'Ihre Original-Belegnr.',
  encashmentFileNumber: 'Inkasso-Aktenzeichen',
  customisedLabel: 'Kennzeichen',
  dateOfFirstDunning: 'Datum der ersten Mahnung',
  generateCredit: 'Gutschriftsbeleg hochladen',
  reportDirectPayment: 'Direktzahlung melden',
  executeInvoiceAnnulation: 'Rechnung stornieren',
  executeVoucherAnnulation: 'Beleg stornieren',
  abandon: 'Verzichten',
  setDunningBlock: 'Mahnstopp setzen',
  cancelDunningBlock: 'Mahnstopp aufheben',
  openDispute: 'Reklamation melden',
  closeDispute: 'Reklamation erledigen',
  openDelay: 'Verzug melden',
  closeDelay: 'Verzug erledigen',
  sendOfficialVoucherNumberToBnet: 'Referenz an bnet übergeben',
  uploadedOn: 'Hochgeladen am',
  relatedVouchers: 'Zugehörige Belege',
  noChildVouchers: 'Keine zugehörigen Belege (wie z. B. Gutschriften oder Stornierungen) erfasst.',
  takeLeftoverAmount: 'Offenen Restbetrag übernehmen',
  permanentDebtInvoice: 'regelmäßige Rechnung',
  permanentDebtInvoiceInfo:
    'Die Rechnung wurde automatisch durch den regelmäßigen Einzug {number} am {date} erstellt und per Lastschriftmandat eingezogen.',
  openWorkQueueInfo:
    'Zu diesem Beleg liegt eine offene Rückfrage vor. Bitte treffen Sie eine Entscheidung.',
  purchaseStatusInfo: 'Dieser Beleg kann aus folgendem Grund nicht angekauft werden:',
  bnetPurchaseStatusOptions: {
    0: 'Der Beleg wurde noch nicht geprüft',
    1: 'Der Beleg ist nicht angekauft',
    2: 'Der Beleg ist unvollständig',
    3: 'Die Belegart ist falsch (z. B. Lieferschein statt Rechnung)',
    4: 'Das Leistungsdatum liegt in der Zukunft',
    5: 'Das Fälligkeitsdatum ist bereits vergangen oder zu nah in der Zukunft',
    6: 'Das Zahlungsziel liegt zu weit in der Zukunft',
    7: 'Das Belegdatum liegt in der Zukunft',
    8: 'Das Leistungsdatum liegt zu weit vor dem Rechnungsdatum',
    9: 'Die Belegnummer ist bereits vorhanden',
    10: 'Der Beleg ist unvollständig',
    12: 'Der Beleg ist bereits fällig',
    13: 'Das Zahlungsziel beträgt mehr als 90 Tage',
    15: 'Das Leistungsdatum liegt mehr als 30 Tage vor dem Rechnungsdatum'
  },
  reviewInfo:
    'Der Beleg wird derzeit noch final geprüft. Belegaktionen sind erst nach Abschluss der Überprüfung möglich.',
  balancing: {
    balanced: 'Ausgeglichen',
    balancingSummary: 'Ausgleichsübersicht',
    balancedInfo:
      'Die Rechnung wurde ausgeglichen. Erfahren Sie alle relevanten Informationen in der Ausgleichsübersicht.',
    showBalancingSummary: 'Ausgleichsübersicht anzeigen',
    followingBalanced: 'Unten stehende Positionen wurden zusammen ausgeglichen.',
    withhold: 'Einbehalt',
    withholdInfo:
      'Unter Einbehalte sind Abzüge wie Skonti, Rundungsdifferenzen oder Kulanzverzichte zu verstehen. Diese werden von uns manuell geprüft, freige-geben und buchhalterisch ausgeglichen.'
  },
  credit: {
    checkCredit: 'Gutschrift prüfen',
    confirmCredit: 'Gutschrift bestätigen',
    info: 'In welcher Höhe möchten Sie eine Gutschrift erzeugen? Die Gutschrift wird an Ihren Kunden versandt.',
    voucherNumberInfo: 'Bitte vergeben Sie eine eindeutige Belegnummer (max. 20 Zeichen).',
    confirmedCredit: 'Gutschrift bestätigt',
    creditReceived:
      'Vielen Dank! Wir haben die Meldung Ihrer Gutschrift erhalten. Ein Beleg wurde erzeugt.'
  },
  directPayment: {
    checkPayment: 'Direktzahlung prüfen',
    confirmPayment: 'Direktzahlung bestätigen',
    info: 'Sie haben eine Direktzahlung erhalten? Melden Sie die Höhe der Direktzahlung, um den offenen Betrag zu korrigieren. ',
    confirmedPayment: 'Direktzahlung bestätigt',
    paymentReceived:
      'Vielen Dank! Wir haben die Meldung Ihrer Direktzahlung erhalten. Ein Beleg wurde erzeugt.',
    noPermissionTitle: 'Direktzahlungsmeldung nicht möglich',
    noPermissionText:
      'Ihr Kunde hat direkt an Sie gezahlt? Leider steht die Funktion zur Meldung einer Direktzahlung aktuell nicht zur Verfügung. Bitte leiten Sie die Zahlung Ihres Kunden daher per Überweisung an uns weiter. Bei Fragen wenden Sie sich gerne an unseren Support.'
  },
  invoiceAnnulation: {
    info: 'Möchten Sie Ihre Rechnung wirklich stornieren? Der Storno wird an Ihren Kunden versandt.',
    approveAnnulation: 'Ja, ich möchte meine Rechnung stornieren',
    confirmAnnulation: 'Stornierung bestätigen',
    confirmedAnnulation: 'Stornierung bestätigt',
    annulationReceived: 'Vielen Dank! Wir haben Ihre Stornierung erhalten. Ein Beleg wurde erzeugt.'
  },
  abandonment: {
    info: 'Sie möchten auf einen Teil Ihrer Forderung verzichten? Bitte geben Sie an, um welchen Betrag Sie die aktuelle Restforderung reduzieren möchten.',
    infoTotalLeftover:
      'Sie möchten auf den Restbetrag Ihrer Forderung verzichten? Bitte bestätigen Sie Ihre Entscheidung.',
    newLeftover: 'Neue Restforderung',
    approveAbandonment: 'Ja, ich verzichte auf den Restbetrag.',
    confirmAbandonment: 'Verzicht bestätigen',
    confirmedAbandonment: 'Verzicht bestätigt',
    abandonmentReceived:
      'Vielen Dank! Wir haben Ihre Verzichtserklärung erhalten. Ein Beleg wurde erzeugt.'
  },
  exposureType: {
    title: 'Art der Forderung',
    info: 'Bitte geben Sie den Kundentyp an und klassifizieren Sie die Art der Leistung, die Sie gegenüber Ihrem Kunden erbracht haben.',
    selectExposureType: 'Forderungsgrund auswählen',
    selectDebtorType: 'Kundentyp auswählen',
    setExposureTypeAsDefault:
      'Auswahl merken und in zukünftigen Inkassofällen als Voreinstellung übernehmen'
  },
  subsequentVoucherUpload: {
    info: 'Bitte laden Sie die zu dem genannten Beleg bzw. Kunden zugehörige Rechnung hoch.',
    confirmed: 'Rechnung hochgeladen',
    voucherReceived:
      'Vielen Dank! Wir haben Ihren Beleg erhalten. Sie finden diesen in Kürze bei Ihren unverarbeiteten Dokumenten.'
  },
  dunningBlock: {
    infoSet:
      'Ihr Kunde soll (noch) keine Mahnung erhalten? Kein Problem: Mit Setzen des Mahnstopps können Sie den Beleg für bis zu 10 Tage vom Mahnverfahren ausschließen.',
    infoSetAgain:
      'Sie wollen das Mahnverfahren für diese Rechnung erneut unterbrechen? Sie hatten bereits vor Kurzem die Mahnungen für diese Rechnung ausgesetzt.',
    warningPurchaseReversal:
      'Sollte der Mahnstopp länger als 10 Tage dauern, so wird die angekaufte Rechnung rückabgewickelt und die bereits gezahlte Bevorschussung zurückgebucht. Bei Fragen hierzu wenden Sie sich bitte an unseren Kundenservice.',
    warningPurchaseReversalSetAgain:
      'Sollten Sie diese Meldung mit „Ja“ bestätigen, wird erneut ein Mahnstopp eingerichtet. Parallel dazu wird die angekaufte Rechnung sofort rückabgewickelt und die bereits gezahlte Bevorschussung zurückgebucht. Bei Fragen hierzu wenden Sie sich bitte an unseren Kundenservice.',
    warningPurchaseAssuranceReversal:
      'Sollte der Mahnstopp länger als 10 Tage dauern, so erlischt die Ankaufzusage für die Rechnung. Bei Fragen hierzu wenden Sie sich bitte an unseren Kundenservice.',
    warningPurchaseAssuranceReversalSetAgain:
      'Sollten Sie diese Meldung mit „Ja“ bestätigen, wird erneut ein Mahnstopp eingerichtet. Parallel dazu erlischt die Ankaufszusage unmittelbar. Bei Fragen hierzu wenden Sie sich bitte an unseren Kundenservice.',
    infoSetBilling:
      'Ihr Kunde soll (noch) keine Mahnung erhalten? Kein Problem: Mit Setzen des Mahnstopps können Sie den Beleg vom Mahnverfahren ausschließen.',
    warningBilling:
      'Sollten Sie den Mahnstopp nicht wieder deaktivieren, wird die Rechnung dauerhaft vom Mahnverfahren ausgeschlossen. Bei Fragen hierzu wenden Sie sich bitte an unseren Kundenservice.',
    approveDunningBlock: 'Ja, vorerst keine Mahnung an den Kunden senden.',
    blockSet: 'Mahnstopp gesetzt',
    infoCancel:
      'Mit Aufheben des Mahnstopps kümmern wir uns wieder um Ihren Beleg. Bei Zahlungsverzug leiten wir für Sie den Mahnprozess ein und kümmern uns um alles weitere. ',
    blockCancelled: 'Mahnstopp aufgehoben'
  },
  dispute: {
    infoOpen:
      'Sie möchten zum ausgewählten Auftrag eine Reklamation melden? Kein Problem: Bitte beschreiben Sie kurz den vorliegenden Sachverhalt, damit wir diesen besser einschätzen können. Sofern Ihnen Unterlagen oder Schriftverkehr zur Reklamation vorliegen, laden Sie diese bitte direkt als Dokument mit hoch.',
    warningPurchaseReversal:
      'Sollte die Reklamation länger als 10 Tage bestehen, wird der angekaufte Auftrag rückabgewickelt und die bereits gezahlte Bevorschussung zurückgebucht. Bei Fragen hierzu wenden Sie sich bitte an unseren Kundenservice.',
    confirmOpenDispute:
      'Ich bestätige, dass eine Reklamation vorliegt und ich diese nachweisen kann.',
    disputeOpened: 'Reklamation gemeldet',
    infoClose:
      'Die Reklamation ist erledigt? Das freut uns! Bitte beschreiben Sie kurz die Lösung des Sachverhalts, damit wir diese auch nachvollziehen können. Sofern Ihnen Unterlagen oder Schriftverkehr zur Lösung vorliegen, laden Sie diese bitte direkt als Dokument mit hoch.',
    confirmCloseDispute: 'Ich bestätige, dass die Reklamation nicht mehr besteht.',
    disputeClosed: 'Reklamation erledigt'
  },
  delay: {
    infoOpen: 'Sie möchten zum ausgewählten Auftrag einen Verzug melden?',
    warningPurchaseReversal:
      'Sie sind verpflichtet, Ihren Auftraggeber innerhalb von 45 Tagen nach Fälligkeit drei Mal schriftlich zu mahnen. Sollte Ihre Auftraggeber nach Abschluss Ihres Mahnverfahrens noch nicht bezahlt haben, müssen Sie uns Ihre Rechnung sowie die Durchführung des Mahnverfahrens nachweisen. Andernfalls wird der angekaufte Auftrag rückabgewickelt und die bereits gezahlte Bevorschussung zurückgebucht. Bei Fragen hierzu wenden Sie sich bitte an unseren Kundenservice.',
    confirmOpenDelay:
      'Ich bestätige, dass sich mein Auftraggeber in Verzug befindet und ich das kaufmännische Mahnverfahren durchführen werde.',
    delayOpened: 'Verzug gemeldet',
    infoClose: 'Mein Auftraggeber hat bezahlt, ein Verzug liegt nicht mehr vor.',
    confirmCloseDelay: 'Ich bestätige, dass mein Auftraggeber an mich bezahlt hat.',
    delayClosed: 'Verzug erledigt'
  },
  comment: {
    create: 'Erstellen Sie eine persönliche Notiz zu Ihrem Beleg.',
    deposited: 'Sie haben eine persönliche Notiz hinterlegt.',
    myComment: 'Meine Notiz',
    deleteRequest: 'Möchten Sie die Notiz wirklich löschen?'
  }
};
