<template>
  <svg width="30px" height="30px" viewBox="0 0 30 30">
    <defs>
      <path
        id="path-icon-doc"
        d="M3.45964034,30 L26.5403597,30 C26.6820522,30.0004367 26.8174678,29.9407703 26.9138253,29.8354422 C27.0101828,29.7301141 27.0586987,29.5887256 27.0476282,29.4455039 L27.0476282,6.76751564 C27.0465983,6.62755888 26.9893556,6.49407224 26.8891068,6.39785318 L20.4689891,0.113591371 L20.4689891,0.113591371 L20.3897284,0.065374528 L20.3025416,0.00912154509 C20.2397004,-0.00304051503 20.1751572,-0.00304051503 20.1123159,0.00912154509 L3.46756642,0.00912154509 C3.33302565,0.00697349684 3.20315538,0.0591103659 3.10653403,0.154059246 C3.00991268,0.249008125 2.95445757,0.378988742 2.95237179,0.515398392 L2.95237179,29.4455039 C2.94130135,29.5887256 2.98981721,29.7301141 3.08617471,29.8354422 C3.18253222,29.9407703 3.31794784,30.0004367 3.45964034,30 Z M20.6557377,2.45901639 L24.7463642,6.38385034 L20.6557377,6.38385034 L20.6557377,2.45901639 Z M4.26771274,1.30937758 L19.4716332,1.30937758 L19.4716332,6.95073357 C19.4716332,7.22845032 19.692551,7.45358424 19.9650668,7.45358424 L25.7243612,7.45358424 L25.7243612,28.667597 L4.27542264,28.667597 L4.26771274,1.30937758 Z"
      />
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <mask fill="white">
        <use xlink:href="#path-icon-doc" />
      </mask>
      <use fill="currentColor" fill-rule="nonzero" xlink:href="#path-icon-doc" />
      <text
        font-family="OpenSans, Open Sans"
        x="5.918014"
        y="24.426943"
        transform="scale(0.94293844,1.0605146)"
      >
        <tspan
          x="5.918014"
          y="24.426943"
          style="
            font-style: normal;
            font-variant: normal;
            font-weight: bold;
            font-stretch: normal;
            font-size: 9.16039px;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-variant-numeric: normal;
            font-variant-east-asian: normal;
            fill: #2c2c36;
            fill-opacity: 1;
            stroke-width: 0.687031;
          "
        >
          DOC
        </tspan>
      </text>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconFileDoc'
};
</script>
