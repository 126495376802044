<template>
  <v-toolbar class="contact-bar" flat height="auto">
    <contact-bar-content />
  </v-toolbar>
</template>

<script>
import ContactBarContent from '@/shared/components/layout/ContactBarContent';

export default {
  name: 'ContactBar',

  components: {
    ContactBarContent
  }
};
</script>

<style scoped lang="scss">
.contact-bar {
  min-height: 36px;
  display: flex;
  align-items: center;
  background-color: rgba(44, 44, 44, 0.2) !important;
  color: var(--c-background-text);

  ::v-deep .v-toolbar__content {
    width: 100%;
    padding-left: 0;
  }
}
</style>
