<template>
  <div>
    <v-menu
      v-if="$auth.user().id && !isMobile"
      v-model="menu"
      content-class="app-menu"
      offset-y
      right
      min-width="300"
      :close-on-content-click="false"
    >
      <template #activator="{ on }">
        <a v-on="on">
          <user-menu-activator />
        </a>
      </template>

      <v-card>
        <v-list>
          <user-menu-content :items="items" @close="closeMenu" />
        </v-list>
      </v-card>
    </v-menu>

    <v-list v-if="$auth.user() && $auth.user().id && isMobile" class="py-0">
      <v-list-group v-model="menu">
        <template #activator>
          <user-menu-activator />
        </template>
        <v-divider class="mx-4 mt-2" />

        <user-menu-content :items="items" @close="closeMenu" />
      </v-list-group>
    </v-list>

    <change-password-dialog v-model="showChangePasswordDialog" />
  </div>
</template>

<script>
import UserMenuContent from '@/modules/User/components/UserMenuContent';
import UserMenuActivator from '@/modules/User/components/UserMenuActivator';
import { NAMESPACE as AUTHENTICATION_NAMESPACE } from '@/modules/Authentication/store';
import {
  ADMIN_REFRESH_TOKEN_KEY,
  ADMIN_TOKEN_KEY,
  AUTH_TOKEN_KEY,
  REFRESH_TOKEN_KEY
} from '@/plugins/vue-auth';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'UserMenu',

  components: {
    UserMenuActivator,
    UserMenuContent,
    ChangePasswordDialog: () =>
      import(
        '@/modules/User/components/ChangePasswordDialog' /* webpackChunkName: "ChangePasswordDialog" */
      )
  },

  data: () => ({
    menu: false
  }),

  computed: {
    ...mapState(AUTHENTICATION_NAMESPACE, ['current']),
    items() {
      return [
        {
          title: 'user.viewMasterData',
          to: this.routeName.MASTER_DATA,
          condition:
            !this.$auth.check(this.role.ADMIN) &&
            this.permissions.navigation[this.routeName.MASTER_DATA]
        },
        {
          title: 'settings.title',
          to: this.routeName.SETTINGS,
          condition:
            !this.$auth.check(this.role.ADMIN) &&
            this.permissions.navigation[this.routeName.SETTINGS]
        },
        {
          title: 'user.changePassword',
          action: () => {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.showChangePasswordDialog = true;
          }
        },
        {
          title: 'user.changeBack',
          action: this.changeBack,
          condition: this.isAdminAsCreditor
        },
        { title: 'login.logout', action: this.logout, color: 'error' }
      ];
    },
    showChangePasswordDialog: {
      get() {
        return this.getQueryParam(this.queryParam.CHANGE_PASSWORD);
      },
      set(value) {
        this.setQueryParam(this.queryParam.CHANGE_PASSWORD, value);
      }
    }
  },

  methods: {
    ...mapActions(['reset']),

    changeBack() {
      this.setLoadingCurrent();
      // sometimes duplicated tokens were set and new ones will override only one storage
      // so tokens must be completely removed first from both storages, before new ones are set
      this.$auth.token(AUTH_TOKEN_KEY, null);
      this.$auth.token(REFRESH_TOKEN_KEY, null);
      this.$auth.token(AUTH_TOKEN_KEY, this.$auth.token(ADMIN_TOKEN_KEY));
      this.$auth.token(REFRESH_TOKEN_KEY, this.$auth.token(ADMIN_REFRESH_TOKEN_KEY));
      this.$auth
        .refresh({})
        .then(() => this.$auth.fetch({}))
        .then(async () => {
          this.$auth.token(ADMIN_TOKEN_KEY, null);
          this.$auth.token(ADMIN_REFRESH_TOKEN_KEY, null);
          this.setCurrent(this.$auth.user().id);
          await this.$router.replace({ name: this.routeName.USERS });
          this.reset();
        })
        .catch(() => {
          this.logoutAndHide();
          this.resetLoadingCurrent();
        });
    },

    closeMenu() {
      this.menu = false;
    },

    logoutAndHide() {
      this.logout();
      this.closeMenu();
    }
  }
};
</script>
