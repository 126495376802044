<template>
  <svg
    width="8px"
    height="13px"
    viewBox="0 0 8 13"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="matrix(-0.946756,0,0,-0.946756,159.268,24.9617)">
      <path d="M161,13L167,19.5L161,26" style="fill: none; stroke: currentColor; stroke-width: 2" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconChevronLeft'
};
</script>
