import qs from 'qs';
import showSnackbar from '@/helper/showSnackbar';
import { mapHistory, mapCreditorPermissions } from '@/shared/models';
import {
  mapCreditor,
  mapWorkQueue,
  mapNotifications,
  mapCreditorVouchers,
  mapEmailNotificationSettings,
  mapCompanyUsers
} from '@/modules/User/models';

export const NAMESPACE = 'user';

const initialState = () => ({
  creditorDetails: null,
  creditorDetailsError: false,
  creditorDetailsRaw: null,
  loadingCreditorDetails: false,
  workQueue: [],
  unhandledWorkQueueCount: 0,
  workQueueError: false,
  loadingWorkQueue: false,
  notifications: [],
  notificationsCount: 0,
  notificationsEmpty: true,
  unreadNotificationsCount: 0,
  creditorVoucherSapNumbers: [],
  creditorVoucherReferenceNumbers: [],
  creditorPermissions: null
});

export default (Vue) => ({
  namespaced: true,

  state: initialState(),

  getters: {
    unreadNotificationsCount(state) {
      return state.notifications.filter((entry) => entry.status === 'new').length;
    },
    creditorVoucherIdentifiersBySapNumbers: (state) => (sapNumbers) =>
      state.creditorVoucherSapNumbers
        .filter(({ sapNumber }) => sapNumbers.includes(sapNumber))
        .map(({ identifier }) => identifier)
  },

  mutations: {
    SET_WORK_QUEUE(state, queue) {
      state.workQueue = queue;
    },
    SET_LOADING_WORK_QUEUE(state) {
      state.loadingWorkQueue = true;
    },
    RESET_LOADING_WORK_QUEUE(state) {
      state.loadingWorkQueue = false;
    },
    SET_UNHANDLED_WORK_QUEUE_COUNT(state, count) {
      state.unhandledWorkQueueCount = count;
    },
    SET_WORK_QUEUE_ERROR(state) {
      state.workQueueError = true;
    },
    RESET_WORK_QUEUE_ERROR(state) {
      state.workQueueError = false;
    },
    SET_NOTIFICATIONS(state, { notifications, count, empty }) {
      state.notifications = notifications;
      state.notificationsCount = count;
      state.notificationsEmpty = empty;
    },
    SET_UNREAD_NOTIFICATIONS_COUNT(state, count) {
      state.unreadNotificationsCount = count;
    },
    SET_CREDITOR_DETAILS(state, creditorDetails) {
      state.creditorDetails = creditorDetails;
    },
    SET_CREDITOR_DETAILS_RAW(state, creditorDetails) {
      state.creditorDetailsRaw = creditorDetails;
    },
    SET_CREDITOR_DETAILS_ERROR(state) {
      state.creditorDetailsError = true;
    },
    RESET_CREDITOR_DETAILS_ERROR(state) {
      state.creditorDetailsError = false;
    },
    SET_LOADING_CREDITOR_DETAILS(state) {
      state.loadingCreditorDetails = true;
    },
    RESET_LOADING_CREDITOR_DETAILS(state) {
      state.loadingCreditorDetails = false;
    },
    SET_CREDITOR_VOUCHER_SAP_NUMBERS(state, numbers) {
      state.creditorVoucherSapNumbers = numbers;
    },
    SET_CREDITOR_VOUCHER_REFERENCE_NUMBERS(state, numbers) {
      state.creditorVoucherReferenceNumbers = numbers;
    },
    SET_CREDITOR_PERMISSIONS(state, permissions) {
      state.creditorPermissions = permissions;
    },
    RESET_STATE(state) {
      const initial = initialState();
      Object.keys(initial).forEach((key) => {
        state[key] = initial[key];
      });
    }
  },

  actions: {
    async fetchCreditorDetails({ commit }) {
      commit('RESET_CREDITOR_DETAILS_ERROR');
      commit('SET_LOADING_CREDITOR_DETAILS');

      try {
        const [creditorResponse, historyResponse] = await Promise.all([
          Vue.prototype.$http.get('/creditor/show'),
          Vue.prototype.$http.get('/creditor/history/list')
        ]);

        const creditorDetails = {
          ...mapCreditor(creditorResponse.data),
          history: mapHistory(historyResponse.data)
        };
        commit('SET_CREDITOR_DETAILS_RAW', creditorResponse.data);
        commit('SET_CREDITOR_DETAILS', creditorDetails);
        commit('RESET_LOADING_CREDITOR_DETAILS');
        return {};
      } catch (error) {
        commit('SET_CREDITOR_DETAILS_ERROR');
        commit('RESET_LOADING_CREDITOR_DETAILS');
        return { error };
      }
    },

    async fetchLimitAndStatus() {
      try {
        const response = await Vue.prototype.$http.get('/current-limit-and-status');
        return { response: response.data };
      } catch (error) {
        return { error };
      }
    },

    async fetchApiVersion() {
      try {
        const { data } = await Vue.prototype.$http.get('/version');
        return { version: data.version };
      } catch (error) {
        return { error };
      }
    },

    async fetchUnhandledWorkQueueCount({ commit }) {
      try {
        const response = await Vue.prototype.$http.get(
          `/user-information/work-queue/count-unhandled`
        );

        commit('SET_UNHANDLED_WORK_QUEUE_COUNT', response.data.count);
        return {};
      } catch (error) {
        return { error };
      }
    },

    async fetchWorkQueue({ commit }) {
      commit('RESET_WORK_QUEUE_ERROR');
      commit('SET_LOADING_WORK_QUEUE');
      try {
        const response = await Vue.prototype.$http.get(`/user-information/work-queue/list`);

        commit('SET_WORK_QUEUE', mapWorkQueue(response.data));
        return {};
      } catch (error) {
        commit('SET_WORK_QUEUE_ERROR');
      } finally {
        commit('RESET_LOADING_WORK_QUEUE');
      }
    },

    async sendWorkQueueAnswer(
      { dispatch },
      { workQueueIdentifier, answerCode, followUpAction, payload }
    ) {
      try {
        await Vue.prototype.$http.post(
          `/user-information/work-queue/${workQueueIdentifier}/${answerCode}/${followUpAction}`,
          payload
        );
        await Promise.all([dispatch('fetchWorkQueue'), dispatch('fetchUnhandledWorkQueueCount')]);
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async fetchNotifications({ commit }, params) {
      try {
        const { data } = await Vue.prototype.$http.get('/user-information/notification/list', {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'brackets' });
          }
        });

        const notifications = data.entries;
        const count = data.count;
        const empty = data.empty;

        commit('SET_NOTIFICATIONS', {
          notifications: mapNotifications(notifications),
          count,
          empty
        });
        return {};
      } catch (error) {
        return { error };
      }
    },

    async fetchUnreadNotificationsCount({ commit }) {
      try {
        const response = await Vue.prototype.$http.get('user-information/notification/count-new');
        commit('SET_UNREAD_NOTIFICATIONS_COUNT', response.data.count);
      } catch (error) {
        return { error };
      }
    },

    async readNotification({ dispatch }, notificationIdentifier) {
      try {
        await Vue.prototype.$http.post('/user-information/command/notification/read', {
          notificationIdentifier
        });

        await dispatch('fetchUnreadNotificationsCount');
        return {};
      } catch (error) {
        return { error };
      }
    },

    async readAllNotifications({ dispatch }) {
      try {
        await Vue.prototype.$http.get('/user-information/command/notification/read-all');

        await dispatch('fetchUnreadNotificationsCount');
        return {};
      } catch (error) {
        return { error };
      }
    },

    async readMultipleNotifications({ dispatch }, notificationIdentifiers) {
      try {
        await Vue.prototype.$http.post('/user-information/command/notification/read-multiple', {
          notificationIdentifiers
        });

        await dispatch('fetchUnreadNotificationsCount');
        return {};
      } catch (error) {
        return { error };
      }
    },

    async unreadNotification({ dispatch }, notificationIdentifier) {
      try {
        await Vue.prototype.$http.post('/user-information/command/notification/unread', {
          notificationIdentifier
        });

        await dispatch('fetchUnreadNotificationsCount');
        return {};
      } catch (error) {
        return { error };
      }
    },

    async fetchCreditorVouchers(_, { page, rowsPerPage, filters, sorting }) {
      try {
        const { data } = await Vue.prototype.$http.get(`/creditor/creditor-voucher/list`, {
          params: { page, rowsPerPage, filters, sorting },
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'brackets' });
          }
        });

        const creditorVouchers = mapCreditorVouchers(data.creditorVouchers);
        const count = data.count;
        const empty = data.empty;
        return { creditorVouchers, count, empty };
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async readCreditorVouchers(_, creditorVoucherIdentifiers) {
      try {
        await Vue.prototype.$http.post('/creditor/creditor-voucher/command/status/read-multiple', {
          creditorVoucherIdentifiers
        });

        return {};
      } catch (error) {
        return { error };
      }
    },

    async readAllCreditorVouchers() {
      try {
        await Vue.prototype.$http.post('/creditor/creditor-voucher/command/status/read-all');

        return {};
      } catch (error) {
        return { error };
      }
    },

    async unreadCreditorVouchers(_, creditorVoucherIdentifiers) {
      try {
        await Vue.prototype.$http.post(
          '/creditor/creditor-voucher/command/status/unread-multiple',
          {
            creditorVoucherIdentifiers
          }
        );

        return {};
      } catch (error) {
        return { error };
      }
    },

    async fetchCreditorVoucherFiles(_, vouchers) {
      try {
        const { data } = await Vue.prototype.$http.get(`/creditor-voucher/list-download`, {
          responseType: 'blob',
          params: { vouchers },
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'brackets' });
          }
        });

        return { file: data };
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async fetchCreditorVoucherFilterSapNumbers({ commit }) {
      try {
        const { data } = await Vue.prototype.$http.get(
          '/creditor/creditor-voucher/sap-number/list'
        );

        commit('SET_CREDITOR_VOUCHER_SAP_NUMBERS', data);
      } catch (error) {
        return { data: [] };
      }
    },

    async fetchCreditorVoucherFilterReferenceNumbers({ commit }) {
      try {
        const { data } = await Vue.prototype.$http.get(
          '/creditor/creditor-voucher/sap-reference-number/list'
        );

        commit('SET_CREDITOR_VOUCHER_REFERENCE_NUMBERS', data);
      } catch (error) {
        return { data: [] };
      }
    },

    async fetchCreditorPermissions({ commit }) {
      try {
        const response = await Vue.axios.get(`/creditor/permissions`);
        commit('SET_CREDITOR_PERMISSIONS', mapCreditorPermissions(response.data));
      } catch (error) {
        commit('SET_CREDITOR_PERMISSIONS', null);
      }
    },

    async fetchWebAppKey({ dispatch }) {
      try {
        const response = await Vue.prototype.$http.get('/creditor/web-app-key');
        dispatch('fetchCreditorDetails');
        return { key: response.data };
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async fetchEmailNotificationSettings() {
      try {
        const response = await Vue.prototype.$http.get(
          '/user-information/creditor-settings/details'
        );
        return { settings: mapEmailNotificationSettings(response.data) };
      } catch (error) {
        return { error };
      }
    },

    async setEmailNotificationSettings(_, settings) {
      try {
        await Vue.prototype.$http.post('/user-information/command/creditor-setting/update', {
          emailReminderSetting: settings
        });
        return {};
      } catch (error) {
        return { error };
      }
    },

    async fetchDefaultExposureType() {
      try {
        const response = await Vue.prototype.$http.get('creditor/default-exposure-type');
        return { defaultExposureType: response.data.defaultExposureType ?? '' };
      } catch (error) {
        return { error };
      }
    },

    async setDefaultExposureType(_, type) {
      try {
        await Vue.prototype.$http.put('creditor/default-exposure-type', {
          defaultExposureType: type
        });
        return {};
      } catch (error) {
        return { error };
      }
    },

    async fetchCompanyUsers() {
      try {
        const response = await Vue.prototype.$http.get(
          '/user-management/creditor-company-user/list'
        );
        return { users: mapCompanyUsers(response.data) };
      } catch (error) {
        return { error };
      }
    },

    async createCompanyUser(_, payload) {
      try {
        await Vue.prototype.$http.post(
          '/user-management/command/register-creditor-company-user',
          payload
        );
        return {};
      } catch (error) {
        return { error };
      }
    },

    async editCompanyUser(_, payload) {
      try {
        await Vue.prototype.$http.post(
          '/user-management/command/update-creditor-company-user',
          payload
        );
        return {};
      } catch (error) {
        return { error };
      }
    },

    async deleteCompanyUser(_, payload) {
      try {
        await Vue.prototype.$http.post(
          '/user-management/command/delete-creditor-company-user',
          payload
        );
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    }
  }
});
