import showSnackbar from '@/helper/showSnackbar';
import { mapServiceFeeMatrix } from '@/shared/models';

export const NAMESPACE = 'onboarding';

const initialState = () => ({});

export default (Vue) => ({
  namespaced: true,

  state: initialState(),

  mutations: {
    RESET_STATE(state) {
      const initial = initialState();
      Object.keys(initial).forEach((key) => {
        state[key] = initial[key];
      });
    }
  },

  actions: {
    async submitRequest(_, payload) {
      try {
        await Vue.prototype.$http.post('/onboarding/command/register-creditor', payload);
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async submitRegistration(_, payload) {
      try {
        await Vue.prototype.$http.post('/onboarding/command/create-claim', payload);
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async fetchDefaultServiceFeeMatrix(_, productType) {
      try {
        const { data } = await Vue.prototype.$http.get(
          `/creditor-service-fee/sap-matrix/${productType}/show-default`
        );
        return { data: mapServiceFeeMatrix(data) };
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    }
  }
});
