import RouteName from '@/statics/routeName';
import admin from '@/modules/Admin/translations';
import authentication from '@/modules/Authentication/translations';
import dashboard from '@/modules/Dashboard/translations';
import debtors from '@/modules/Debtors/translations';
import events from '@/shared/translations/events';
import failure from '@/modules/Failure/translations';
import onboarding from '@/onboarding/translations';
import permanentDebts from '@/modules/PermanentDebts/translations';
import unprocessed from '@/modules/Unprocessed/translations';
import upload from '@/modules/Upload/translations';
import user from '@/modules/User/translations';
import vouchers from '@/modules/Vouchers/translations';
import DeliveryType from '@/statics/deliveryType';

export default {
  ...admin,
  ...authentication,
  ...events,
  ...user,
  dashboard,
  debtors,
  failure,
  onboarding,
  permanentDebts,
  unprocessed,
  upload,
  vouchers,

  voucher: 'Beleg',
  voucherPl: 'Belege',
  vouchersCounted: '1 Beleg | {count} Belege',
  document: 'Dokument',
  documentPl: 'Dokumente',
  invoice: 'Rechnung',
  invoices: 'Rechnungen',
  amount: 'Betrag',
  leftoverAmount: 'Restbetrag',
  address: 'Adresse',
  addressesPl: 'Adressen',
  customisedLabel: 'Kennzeichen',
  attachment: 'Anhang',
  attachmentPl: 'Anhänge',
  files: 'Dateien',
  debtor: 'Kunde',
  debtorsCounted: '1 Kunde | {count} Kunden',
  location: 'Versandadresse',
  creditor: 'KMU',
  permDebts: 'Regelmäßige Einzüge',
  voucherDetails: 'Belegdetails',
  debtorDetails: 'Kundendetails',
  companyOrName: 'Firma/Name',
  email: 'E-Mail',
  emailCc: 'E-Mail Cc',
  emailBcc: 'E-Mail Bcc',
  emailAddress: 'E-Mail-Adresse',
  tel: 'Tel.',
  landlineNumber: 'Festnetznummer',
  mobileNumber: 'Mobilnummer',
  taxNumber: 'Steuernummer',
  vatId: 'USt-ID',
  sapNumber: 'SAP-Nr.',
  contactPerson: 'Ansprechpartner',
  pdf: 'PDF',
  password: 'Passwort',
  help: 'Hilfe',
  actions: 'Aktionen',
  firstName: 'Vorname',
  lastName: 'Nachname',
  tradeName: 'Handelsname',
  name: 'Name',
  company: 'Firma',
  salutation: 'Anrede',
  salutationOptions: {
    mr: 'Herr',
    mrs: 'Frau'
  },
  privatePerson: 'Privatperson',
  dateOfBirth: 'Geburtsdatum',
  contact: 'Kontakt',
  phone: 'Telefon',
  fax: 'Fax',
  mobile: 'Mobil',
  phoneNumber: 'Telefonnummer',
  street: 'Straße',
  house: 'Nr.',
  streetAndHouse: 'Straße und Hausnummer',
  zipCode: 'Postleitzahl',
  zip: 'PLZ',
  city: 'Ort',
  poBoxZip: 'PLZ Postfach',
  poBox: 'Postfach-Nr.',
  annualTurnover: 'Jahresumsatz',
  number: 'Nummer',
  date: 'Datum',
  type: 'Typ',
  optional: 'optional',
  info: 'Info',
  download: 'Herunterladen',
  status: 'Status',
  errorDetected: 'Fehler gefunden',
  serverError: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
  errorOccured: 'Es ist ein Fehler aufgetreten',
  technicalError:
    'Es ist ein technischer Fehler aufgetreten. Wir kümmern uns sofort darum. Ihr Beleg wird schnellstmöglich weiterverarbeitet.',
  saveChanges: 'Änderungen speichern',
  changesSaved: 'Änderungen gespeichert',
  changesNotSaved: 'Änderungen konnten nicht gespeichert werden',
  rowsPerPage: 'Zeilen pro Seite',
  result: 'Eintrag',
  resultsCounted: '1 Eintrag | {count} Einträge',
  noResults: 'Keine Einträge',
  noMatchingResults: 'Es wurden keine Einträge gefunden, die den Suchkriterien entsprechen.',
  noProcessLogResults: 'Keine Prozess Log Einträge',
  showMoreActions: 'Weitere Aktionen anzeigen',
  hideMoreActions: 'Weniger Aktionen anzeigen',
  viewPdf: 'Pdf anzeigen',
  showSource: 'Originalbeleg anzeigen',
  bnetIdentifier: 'bnet-Identifier',
  encashmentFileNumber: 'Inkasso-Aktenzeichen',
  bnetStatus: 'bnet-Status',
  documentIdentifier: 'document-Identifier',
  history: 'Historie',
  processLog: 'Prozess Log',
  yes: 'Ja',
  no: 'Nein',
  or: 'oder',
  add: 'Hinzufügen',
  edit: 'Bearbeiten',
  delete: 'Löschen',
  all: 'Alle',
  none: 'Keiner',
  total: 'Total',
  hint: 'Hinweis',
  hints: 'Hinweise',
  loadingData: 'Ihre Daten werden geladen...',
  importantHint: 'Wichtiger Hinweis',
  pleaseNote: 'Bitte beachten Sie',
  lastActivities: 'Letzte Aktivitäten',
  case: 'Vorgang',
  pos: 'Pos.',
  timePeriod: 'Zeitraum',
  timePeriodOptions: {
    today: 'Heute',
    lastWeek: 'Letzte 7 Tage',
    lastMonth: 'Letzte 30 Tage',
    lastQuarter: 'Letzte 90 Tage',
    lastYear: 'Letztes Jahr'
  },
  dueDatePeriodOptions: {
    nextQuarter: 'Nächste 90 Tage',
    nextMonth: 'Nächste 30 Tage',
    nextWeek: 'Nächste 7 Tage',
    today: 'Heute',
    sinceLastWeek: 'Seit 7 Tagen',
    sinceLastMonth: 'Seit 30 Tagen',
    sinceLastQuarter: 'Seit 90 Tagen',
    sinceLastYear: 'Seit einem Jahr'
  },
  filter: 'Filtern',
  resetFilter: 'Filter zurücksetzen',
  currencyNotValid: '{field} muss eine gültige Dezimalzahl sein',
  minCurrencyNotValid: '{field} muss mehr als {amount} betragen',
  minEqualCurrencyNotValid: '{field} muss mindestens {amount} betragen',
  maxCurrencyNotValid: '{field} darf höchstens {amount} betragen',
  factorableTurnoverNotValid:
    'Der factoringfähige Jahresumsatz muss mindestens {min} und darf höchstens {max} betragen.',
  dateNotValid: '{field} ist kein gültiges Datum',
  minDateNotValid: 'Nur Angaben ab dem {date} zulässig',
  maxDateNotValid: 'Nur Angaben bis zum {date} zulässig',
  minPastMonthsNotValidCounted:
    '{field} ist jünger als ein Monat | {field} ist jünger als {n} Monate',
  percentNotValid: 'Nur Prozentangaben zwischen 0 und 100 sind zulässig',
  fileIsEmpty: 'Die Datei ist leer',
  fileTooLarge: 'Die Datei ist zu groß (max. {max} MB)',
  passwordConstraints: 'muss mindestens eine Zahl und ein Sonderzeichen enthalten',
  valueAlreadyExistent: 'Der Wert für {name} ist bereits vergeben',
  phoneNotValid: '{field} enthält unzulässige Zeichen',
  ibanNotValid: 'Die IBAN ist ungültig',
  product: 'Produkt',
  for: 'für',
  of: 'von',
  to: 'bis',
  from: 'ab',
  since: 'seit',
  on: 'am',
  and: 'und',
  worth: 'über',
  accountOwner: 'Kontoinhaber',
  registerNumber: 'Handelsregisternummer / Ausweisnummer',
  placeOfAuthority: 'Amtsgericht / ausstellende Behörde',
  proprietor: 'Inhaber / gesetzlicher Vertreter',
  iban: 'IBAN',
  referenceNumber: 'Referenz',
  sepaDirectDebitMandate: 'SEPA-Lastschriftmandat',
  contractNumber: 'Vertragsnummer',
  description: 'Beschreibung',
  subject: 'Betreff',
  dateHint: 'TT.MM.JJJJ',
  firstAndLastName: 'Vor- und Nachname',
  lastUpdate: 'Letzte Aktualisierung',
  createdOn: 'Angelegt am',
  notSpecified: 'Keine Angabe',
  listFilter: 'Listenfilter',
  loadMore: 'Weitere {number} Einträge laden',
  hotline: 'Hotline',
  uploadInvoice: 'Rechnung hochladen',
  file: 'Datei',
  chooseFile: 'Datei auswählen',
  choosePDF: 'PDF auswählen',
  dontShowAnymore: 'Nicht erneut anzeigen',
  year: 'Jahr',
  day: 'Tag',
  days: 'Tage',
  exposureType: 'Forderungsgrund',
  errorAlertTitle: 'Fehler beim Laden der Daten',
  errorAlertInfo:
    'Bitte versuchen Sie es später erneut. Sollte das Problem weiterhin bestehen, kontaktieren Sie bitte unseren Kundenservice.',
  reloadPage: 'Seite neu laden',
  checkData:
    'Alles korrekt? Prüfen Sie nochmal Ihre Angaben und bestätigen Sie diese anschließend.',
  select: 'Auswählen',
  unknown: 'Unbekannt',
  readAll: 'Alle gelesen',
  showSelect: 'Auswahl anzeigen',
  selectAndDownload: 'Elemente auswählen und herunterladen',
  selectedItemsToDownloadCounted:
    '1 Element zum Download ausgewählt | {count} Elemente zum Download ausgewählt',
  saveReportToClipboard: 'Diagnosedaten in Zwischenablage speichern',
  savedReportToClipboard: 'Diagnosedaten in Zwischenablage gespeichert',
  saveReportFailed: 'Speichern in Zwischenablage fehlgeschlagen',
  portal: 'Portal',
  csvExportFilename: '{prefix}-Export-{date}.csv',
  csvExportInfo: 'Liste als CSV exportieren',

  routes: {
    [RouteName.DASHBOARD]: 'Dashboard',
    [RouteName.VOUCHERS]: 'Belege',
    [RouteName.DEBTORS]: 'Kunden',
    [RouteName.PERMANENT_DEBTS]: 'Regelm. Einzüge',
    [RouteName.UNPROCESSED]: 'Wird verarbeitet',
    [RouteName.CREDITORS]: 'KMUs',
    [RouteName.USERS]: 'Benutzer',
    [RouteName.ADMINS]: 'Admins',
    [RouteName.REQUESTS]: 'Anfragen',
    [RouteName.ADMIN_ACTIONS]: 'Einstellungen',
    [RouteName.HELP_CENTER]: 'Hilfe-Center'
  },

  cookieLayer: {
    text: 'Cookies helfen uns bei der Bereitstellung unserer Inhalte und Dienste. Durch die weitere Nutzung der Webseite stimmen Sie der Verwendung von Cookies zu.',
    accept: 'Cookies erlauben',
    decline: 'Cookies ablehnen',
    learnMore: 'Mehr erfahren'
  },

  addresses: {
    changeDeliveryType: 'Versandart ändern',
    deliveryTypes: {
      [DeliveryType.EMAIL]: 'Per E-Mail',
      [DeliveryType.POST]: 'Per Post'
    },
    to: 'an',
    addressAdded: 'Adresse übermittelt',
    addressNotAdded: 'Adressinformationen unvollständig oder Server nicht erreichbar'
  },

  serviceFees: {
    fee: 'Gebühr',
    fees: 'Gebühren',
    annualServiceFee: 'Jahresmindestgebühr',
    serviceFee: 'Verwaltungsgebühr',
    purchasedFee: 'Ankaufgebühr',
    notPurchasedFee: 'Nichtankaufgebühr',
    purchasedLaterFee: 'Nachkaufgebühr',
    minimumServiceFee: 'Minimumgebühr je Beleg',
    conditions: 'Konditionen',
    of: 'von',
    periodStart: 'Periodenstart',
    periodEnd: 'Periodenende'
  },

  navigation: {
    backToDebtor: 'Zurück zum Kunden',
    backToLocation: 'Zurück zur Adresse',
    backToOverview: 'Zurück zur Übersicht',
    backToVoucher: 'Zurück zum Beleg',
    cancel: 'Abbrechen',
    close: 'Schließen',
    confirm: 'Bestätigen',
    confirmAndContinue: 'Bestätigen und weiter',
    continue: 'Weiter',
    finish: 'Beenden',
    goBack: 'Zurück',
    noBackToPermanentDebt: 'Nein, zurück zum Einzug',
    noCancel: 'Nein, abbrechen',
    ok: 'OK',
    yes: 'Ja',
    yesContinue: 'Ja, weiter',
    request: 'Beantragen',
    save: 'Speichern',
    send: 'Absenden',
    show: 'Anzeigen',
    showMore: 'Mehr anzeigen',
    start: 'Starten',
    take: 'Übernehmen',
    takeSelected: 'Auswahl übernehmen',
    toDebtor: 'Zum Kunden',
    toLocation: 'Zur Adresse',
    toLogin: 'Zum Login',
    toPermanentDebt: 'Zum Einzug',
    toPortal: 'Zum Portal',
    toVoucher: 'Zum Beleg',
    toVouchers: 'Zu Ihren Belegen',
    toWorkQueue: 'Zu Ihren Entscheidungen',
    view: 'Ansehen',
    yesAdd: 'Ja, hinzufügen',
    yesCreate: 'Ja, anlegen',
    yesDelete: 'Ja, löschen',
    yesSend: 'Ja, absenden',
    yesStop: 'Ja, stoppen',
    yesUnsubscribe: 'Ja, abbestellen'
  }
};
