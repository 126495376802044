var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',_vm._b({class:{
      'input--align-right': _vm.alignRight,
      'input--small': _vm.small,
      'input--has-error': errors.length > 0,
      'input--prepend-inner': _vm.prependInnerIcon
    },attrs:{"id":_vm.fieldId,"outlined":"","append-icon":_vm.appendIcon && !_vm.file ? 'mdi-paperclip' : null,"clearable":false,"label":_vm.fieldLabelWithSupplement,"placeholder":_vm.fieldPlaceholder,"accept":_vm.accept,"error-messages":errors,"prepend-icon":_vm.prependIcon ? 'mdi-paperclip' : null,"prepend-inner-icon":_vm.prependInnerIcon && !_vm.file ? 'mdi-paperclip' : null,"hide-details":errors.length === 0},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
    var text = ref.text;
return [_c('span',{staticClass:"d-flex justify-space-between flex-grow-1",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(text)+" "),_c('v-icon',{on:{"click":function($event){$event.stopPropagation();_vm.file = null}}},[_vm._v("mdi-close")])],1)]}}],null,true),model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}},'v-file-input',_vm.$attrs,false))]}}])},'ValidationProvider',_vm.validationProps,false))}
var staticRenderFns = []

export { render, staticRenderFns }