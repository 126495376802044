import RouteName from '@/statics/routeName';

/**
 * @see {@link https://move-elevator.atlassian.net/wiki/x/QRTIAg}
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
export const createPermissionsFactory = (permissions = {}) => ({
  dashboard: {
    availableCreditorLimit: permissions.dashboard?.availableCreditorLimit ?? false,
    balancingChart: permissions.dashboard?.balancingChart ?? false,
    cashback: permissions.dashboard?.cashback ?? false,
    daysOverdueChart: permissions.dashboard?.daysOverdueChart ?? false,
    daysSalesOutstandingChart: permissions.dashboard?.daysSalesOutstandingChart ?? false,
    debtorLimitRate: permissions.dashboard?.debtorLimitRate ?? false,
    delay: permissions.dashboard?.delay ?? false,
    dispute: permissions.dashboard?.dispute ?? false,
    dunningBlock: permissions.dashboard?.dunningBlock ?? false,
    overdueRate: permissions.dashboard?.overdueRate ?? false,
    turnoverProgressionChart: permissions.dashboard?.turnoverProgressionChart ?? false,
    turnoverVolumeChart: permissions.dashboard?.turnoverVolumeChart ?? false,
    showOpenDunningTypeInvoices: permissions.dashboard?.showOpenDunningTypeInvoices ?? false
  },
  debtorDetails: {
    activateDebtor: permissions.debtorDetails?.activateDebtor ?? false,
    activateLocation: permissions.debtorDetails?.activateLocation ?? false,
    addLocation: permissions.debtorDetails?.addLocation ?? false,
    checkSolvency: permissions.debtorDetails?.checkSolvency ?? false,
    createPermanentDebt: permissions.debtorDetails?.createPermanentDebt ?? false,
    deactivateDebtor: permissions.debtorDetails?.deactivateDebtor ?? false,
    deactivateLocation: permissions.debtorDetails?.deactivateLocation ?? false,
    deletePostAddress: permissions.debtorDetails?.deletePostAddress ?? false,
    editDebtor: permissions.debtorDetails?.editDebtor ?? false,
    editLocation: permissions.debtorDetails?.editLocation ?? false,
    editPaymentData: permissions.debtorDetails?.editPaymentData ?? false,
    editPostAddress: permissions.debtorDetails?.editPostAddress ?? false,
    increaseLimit: permissions.debtorDetails?.increaseLimit ?? false,
    refreshLimit: permissions.debtorDetails?.refreshLimit ?? false,
    showPaymentData: permissions.debtorDetails?.showPaymentData ?? false,
    showSepa: permissions.debtorDetails?.showSepa ?? false,
    showSolvency: permissions.debtorDetails?.showSolvency ?? false,
    tabLimit: permissions.debtorDetails?.tabLimit ?? false,
    tabPermanentDebts: permissions.debtorDetails?.tabPermanentDebts ?? false,
    uploadInvoice: permissions.debtorDetails?.uploadInvoice ?? false
  },
  debtors: {
    createDebtor: permissions.debtors?.createDebtor ?? false,
    showLimit: permissions.debtors?.showLimit ?? false,
    showSumOfPurchasedInvoices: permissions.debtors?.showSumOfPurchasedInvoices ?? false
  },
  helpCenter: {
    tabFAQ: permissions.helpCenter?.tabFAQ ?? false,
    tabTutorials: permissions.helpCenter?.tabTutorials ?? false
  },
  masterData: {
    enableDoc2Portal: permissions.masterData?.enableDoc2Portal ?? false,
    enableLetter2Post: permissions.masterData?.enableLetter2Post ?? false,
    enableWebApp: permissions.masterData?.enableWebApp ?? false,
    serviceFeesWithPurchaseFees: permissions.masterData?.serviceFeesWithPurchaseFees ?? false,
    serviceFeesWithoutPurchaseFees: permissions.masterData?.serviceFeesWithoutPurchaseFees ?? false,
    tabAboutMe: permissions.masterData?.tabAboutMe ?? false,
    tabCreditorVouchers: permissions.masterData?.tabCreditorVouchers ?? false,
    tabExtensions: permissions.masterData?.tabExtensions ?? false,
    tabMyContract: permissions.masterData?.tabMyContract ?? false
  },
  navigation: {
    [RouteName.DASHBOARD]: permissions.navigation?.dashboard ?? false,
    [RouteName.MASTER_DATA]: permissions.navigation?.masterData ?? false,
    [RouteName.PERMANENT_DEBTS]: permissions.navigation?.permanentDebts ?? false,
    [RouteName.SETTINGS]: permissions.navigation?.settings ?? false,
    [RouteName.HELP_CENTER]: permissions.navigation?.helpCenter ?? false,
    [RouteName.UNPROCESSED]: permissions.navigation?.unprocessed ?? false,
    [RouteName.WORK_QUEUE]: permissions.navigation?.workQueue ?? false
  },
  notifications: {
    show: permissions.notifications?.show ?? false
  },
  optIn: {
    accept: permissions.optIn?.accept ?? false
  },
  permanentDebtDetails: {
    showSepa: permissions.permanentDebtDetails?.showSepa ?? false,
    stop: permissions.permanentDebtDetails?.stop ?? false,
    tabInvoices: permissions.permanentDebtDetails?.tabInvoices ?? false
  },
  permanentDebts: {
    create: permissions.permanentDebts?.create ?? false
  },
  settings: {
    tabCompanyUsers: permissions.settings?.tabCompanyUsers ?? false,
    tabEmailNotifications: permissions.settings?.tabEmailNotifications ?? false,
    tabGeneral: permissions.settings?.tabGeneral ?? false
  },
  unprocessedDetails: {
    addAttachment: permissions.unprocessedDetails?.addAttachment ?? false,
    cancel: permissions.unprocessedDetails?.cancel ?? false,
    continue: permissions.unprocessedDetails?.continue ?? false,
    deleteAttachment: permissions.unprocessedDetails?.deleteAttachment ?? false,
    editDeliveryType: permissions.unprocessedDetails?.editDeliveryType ?? false,
    reupload: permissions.unprocessedDetails?.reupload ?? false
  },
  voucherDetails: {
    abandonment: permissions.voucherDetails?.abandonment ?? false,
    annulation: permissions.voucherDetails?.annulation ?? false,
    cancelDunningBlock: permissions.voucherDetails?.cancelDunningBlock ?? false,
    closeDispute: permissions.voucherDetails?.closeDispute ?? false,
    closeDelay: permissions.voucherDetails?.closeDelay ?? false,
    creditAdvice: permissions.voucherDetails?.creditAdvice ?? false,
    directPayment: permissions.voucherDetails?.directPayment ?? false,
    openDispute: permissions.voucherDetails?.openDispute ?? false,
    openDelay: permissions.voucherDetails?.openDelay ?? false,
    openWorkQueue: permissions.voucherDetails?.openWorkQueue ?? false,
    setDunningBlock: permissions.voucherDetails?.setDunningBlock ?? false,
    showPurchaseReversalWarning: permissions.voucherDetails?.showPurchaseReversalWarning ?? false
  },
  vouchers: {
    openWorkQueue: permissions.vouchers?.openWorkQueue ?? false,
    sum: permissions.vouchers?.sum ?? false,
    upload: permissions.vouchers?.upload ?? false
  },
  workQueue: {
    answer: permissions.workQueue?.answer ?? false
  }
});

export const checkRoutePermissionByTabs = (routePermissions) => {
  // Some views like master data, settings and help center have multiple tabs with different permissions.
  // Therefore, route access is permitted, if at least one tab is permitted.
  return Object.entries(routePermissions)
    .filter(([permissionKey]) => permissionKey.startsWith('tab'))
    .some(([, hasPermission]) => hasPermission);
};

export const mergePermissions = ({
  mergedPermissions = createPermissionsFactory(),
  productPermissions,
  userPermissions
}) => {
  const output = Object.assign(mergedPermissions, {});

  Object.keys(output).forEach((key) => {
    if (typeof output[key] === 'object') {
      output[key] = mergePermissions({
        mergedPermissions: mergedPermissions[key],
        productPermissions: productPermissions[key],
        userPermissions: userPermissions[key]
      });

      return;
    }
    output[key] = userPermissions[key] !== false && productPermissions[key] !== false;
  });
  return output;
};

export default createPermissionsFactory;
