export default {
  historyEvents: {
    // voucher
    voucherValidationWasStarted: '@:(bnetStatusOptions.102.title)',
    voucherEditingWasStarted: '@:(bnetStatusOptions.103.title)',
    debtorProcessingWasStarted: '@:(bnetStatusOptions.104.title)',
    debtorProcessingWasCompleted: '@:(bnetStatusOptions.105.title)',
    debtorQualityManagementWasStarted: '@:(bnetStatusOptions.106.title)',
    voucherProcessingWasStarted: '@:(bnetStatusOptions.107.title)',
    generalQualityManagementWasStarted: '@:(bnetStatusOptions.108.title)',
    voucherWasPaused: '@:(bnetStatusOptions.109.title)',
    voucherWasRead: '@:(bnetStatusOptions.110.title)',
    debtorWasVerified: '@:(bnetStatusOptions.111.title)',
    officialVoucherNumberWasReceived: '@:(bnetStatusOptions.112.title)',
    voucherWasSendToSap: '@:(bnetStatusOptions.115.title)',
    sendMailWasCommitted: '@:(bnetStatusOptions.120.title)',
    sendEmailWasCommitted: '@:(bnetStatusOptions.121.title)',
    mailWasSendToProduction: '@:(bnetStatusOptions.123.title)',
    mailWasProduced: '@:(bnetStatusOptions.124.title)',
    emailWasShipped: '@:(bnetStatusOptions.125.title)',
    sendEmailWasFailed: '@:(bnetStatusOptions.150.title)',
    sendEmailccWasFailed: '@:(bnetStatusOptions.151.title)',
    sendEmailbccWasFailed: '@:(bnetStatusOptions.152.title)',
    emailUnknownErrorWasThrown: '@:(bnetStatusOptions.153.title)',

    internalErrorWasThrown: '@:(bnetStatusOptions.901.title)',
    sapErrorWasThrown: '@:(bnetStatusOptions.902.title)',
    printErrorWasThrown: '@:(bnetStatusOptions.904.title)',
    generalErrorWasThrown: '@:(bnetStatusOptions.920.title)',
    voucherAddressErrorWasThrown: '@:(bnetStatusOptions.921.title)',
    voucherDateErrorWasThrown: '@:(bnetStatusOptions.922.title)',
    voucherNumberErrorWasThrown: '@:(bnetStatusOptions.923.title)',
    voucherFormatErrorWasThrown: '@:(bnetStatusOptions.925.title)',
    voucherTypeErrorWasThrown: '@:(bnetStatusOptions.926.title)',
    voucherDeliveryPerformanceErrorWasThrown: '@:(bnetStatusOptions.928.title)',
    voucherNotReadableErrorWasThrown: '@:(bnetStatusOptions.929.title)',
    voucherPaymentConditionErrorWasThrown: '@:(bnetStatusOptions.930.title)',
    voucherAmountErrorWasThrown: '@:(bnetStatusOptions.931.title)',

    dunningLevelWasSetToPaymentReminder: 'An Zahlung erinnert',
    dunningLevelWasSetToDunning: 'Angemahnt',
    dunningTypeWasSetToHalt: 'Mahnverfahren wurde unterbrochen',
    dunningTypeWasSetToEncashment: 'Inkassoverfahren eingeleitet',
    dunningTypeWasSetToDeferredPayment: 'Ratenzahlung',
    dunningTypeWasSetToCustomerComplaint: 'Reklamiert',

    voucherWasBlocked: 'Mahnverfahren wurde unterbrochen',
    voucherWasReopened: 'Mahnverfahren wurde fortgesetzt',
    voucherDisputeWasOpened: 'Reklamation gemeldet',
    voucherDisputeWasClosed: 'Reklamation erledigt',
    voucherDelayWasOpened: 'Verzug gemeldet',
    voucherDelayWasClosed: 'Verzug erledigt',
    voucherWasBalanced: 'Der Beleg wurde ausgeglichen',
    balancedVoucherWasReopened: 'Ausgleich zurückgenommen',
    voucherWasHalted: 'Verarbeitung des Belegs gestoppt',
    voucherWasContinued: 'Weiterverarbeitung des Belegs bestätigt',

    factoring: {
      purchaseStatusWasMarkedAsUnverified: 'Der Beleg wurde gebucht',
      purchaseStatusWasMarkedAsPurchasable: 'Limit nicht ausreichend um Beleg anzukaufen',
      purchaseStatusWasMarkedAsInReview: 'Belegankauf wird geprüft',
      voucherWasPurchased: 'Der Beleg wurde angekauft',
      voucherWasDisclosed: 'Die Abtretung der Rechnung wurde offengelegt',
      voucherWasPurchasedLater: 'Der Beleg wurde nachgekauft',
      purchaseStatusWasMarkedAsManualPurchaseList: 'Der Beleg wurde angekauft',
      purchaseStatusWasMarkedAsCountedBack: 'Der Beleg wurde zurückgerechnet',
      purchaseStatusWasMarkedAsDisclosed: 'Die Abtretung des Belegs wurde offengelegt',
      voucherWasIncomplete: 'Kein Ankauf - der Beleg ist unvollständig',
      voucherWasNotPurchasableDateOfSupplyInFuture:
        'Kein Ankauf - das Leistungsdatum liegt in der Zukunft',
      voucherWasLowerThanThreeDays: 'Kein Ankauf - die Restfälligkeit beträgt weniger als 3 Tage',
      voucherWasTermOfCreditMoreThanNinetyDays:
        'Kein Ankauf - das Zahlungsziel beträgt mehr als 90 Tage',
      voucherDateInFuture: 'Kein Ankauf - das Belegdatum liegt in der Zukunft',
      voucherDateOfSupplyBeforeInvoiceDate:
        'Kein Ankauf - das Leistungsdatum liegt mehr als 30 Tage vor dem Rechnungsdatum',
      duplicatedVoucherNumberWasFound: 'Kein Ankauf – die Belegnummer ist bereits vorhanden'
    },

    billing: {
      purchaseStatusWasMarkedAsUnknown: 'Der Beleg wurde gebucht',
      purchaseStatusWasMarkedAsInReview: 'Der Beleg wurde gebucht',
      purchaseStatusWasMarkedAsUnverified: 'Der Beleg wurde gebucht',
      purchaseStatusWasMarkedAsPurchasable: 'Der Beleg wurde gebucht',
      purchaseStatusWasMarkedAsNotPurchasable: 'Der Beleg wurde gebucht',
      voucherWasPurchased: 'Der Beleg wurde gebucht',
      voucherWasPurchasedLater: 'Der Beleg wurde gebucht',
      purchaseStatusWasMarkedAsManualPurchaseList: 'Der Beleg wurde gebucht',
      purchaseStatusWasMarkedAsNotPurchasableAnymore: 'Der Beleg wurde gebucht',
      voucherWasNotPurchased: 'Der Beleg wurde gebucht',
      purchaseStatusWasMarkedAsCountedBack: 'Der Beleg wurde gebucht'
    },

    // debtor
    limitKoWasSet: 'Limit wurde gestrichen, es liegen Negativmerkmale vor',
    limitWasApproved: 'Limiterhöhung bewilligt',
    debtorWasAddToCustomerList: 'Debitor wurde zur Kundenliste hinzugefügt',
    debtorWasFoundAtCustomerList: 'Debitor wurde in der Kundenliste gefunden',
    availableLimitWasInitialSet: 'Verfügbares Limit wurde initial gesetzt',
    approvedLimitWasUpdated: 'Limit wurde auf {limit} aktualisiert',
    requestForCreditLimitIncreaseSend: 'Limiterhöhung beantragt',
    requestForCreditLimitIncreaseApproved: 'Limiterhöhung bewilligt',
    requestForCreditLimitIncreaseRejected: 'Limiterhöhung abgelehnt',
    requestForCreditLimitIncreasePartlyApproved: 'Limiterhöhung teilweise bewilligt',

    limitReasonDebtorPersonHasANegativeSolvency: '@:(debtors.limitReasonCodes.customer.ID_1)',
    limitReasonDebtorPersonWasNotIdentified: '@:(debtors.limitReasonCodes.customer.ID_4)',
    limitReasonDebtorPersonWasFoundOnBlacklist: '@:(debtors.limitReasonCodes.customer.ID_9)',
    limitReasonDebtorPersonWasDied: '@:(debtors.limitReasonCodes.customer.ID_10)',
    limitReasonDebtorCompanyHasANegativeSolvency: '@:(debtors.limitReasonCodes.business.ID_1)',
    limitReasonDebtorCompanyWasNotIdentified: '@:(debtors.limitReasonCodes.business.ID_4)',
    limitReasonDebtorCompanyWasFoundOnBlacklist: '@:(debtors.limitReasonCodes.business.ID_9)',
    limitReasonDebtorCompanyWasTerminated: '@:(debtors.limitReasonCodes.business.ID_10)',

    // creditor
    customerConditionAccepted: 'Allgemeine Dienstleistungsbedingungen akzeptiert',
    customerConditionAcceptedUpdate:
      'Aktualisierte allgemeine Dienstleistungsbedingungen akzeptiert',
    goldenRulesApproved: 'Factoring-Regeln akzeptiert'
  }
};
