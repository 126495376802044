export const numberToCent = (value) => {
  if (
    (value.includes('.') && !value.includes(',')) ||
    (!value.includes('.') && value.includes(','))
  ) {
    return Math.round(+value.toString().replace(/[,]/g, '.') * 100);
  }

  value = value.toString().replace(/[.]/g, '');

  const parts = value.split(',');

  if (parts.length === 1) {
    return Math.round(+value * 100);
  }

  const [euro, cent] = parts;

  return Math.round(+euro * 100 + +cent);
};

export default numberToCent;
