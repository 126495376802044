<template>
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        id="path-info"
        d="M10,0 C15.52,0 20,4.48 20,10 C20,15.52 15.52,20 10,20 C4.48,20 0,15.52 0,10 C0,4.48 4.48,0 10,0 Z M10,2 C5.59,2 2,5.59 2,10 C2,14.41 5.59,18 10,18 C14.41,18 18,14.41 18,10 C18,5.59 14.41,2 10,2 Z M11.0424805,7.35742188 L11.0424805,15 L8.95751953,15 L8.95751953,7.35742188 L11.0424805,7.35742188 Z M10.003418,4.36328125 C10.7599322,4.36328125 11.1381836,4.70279608 11.1381836,5.38183594 C11.1381836,5.70540526 11.0436207,5.95719311 10.8544922,6.13720703 C10.6653636,6.31722095 10.3816751,6.40722656 10.003418,6.40722656 C9.24690377,6.40722656 8.86865234,6.06543311 8.86865234,5.38183594 C8.86865234,4.70279608 9.24690377,4.36328125 10.003418,4.36328125 Z"
      />
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-2.000000, -2.000000)">
        <g transform="translate(2.000000, 2.000000)">
          <mask fill="white">
            <use xlink:href="#path-info" />
          </mask>
          <use fill="currentColor" fill-rule="nonzero" xlink:href="#path-info" />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconInfo'
};
</script>
