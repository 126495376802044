import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css';
import de from 'vuetify/es5/locale/de';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    disable: true,
    options: {}
  },
  lang: {
    locales: { de },
    current: 'de'
  },
  icons: {
    iconfont: 'mdi',
    values: {
      // custom icon components, accessible via $ (e.g. $iconCalendar) instead of mdi- prefix
      iconAlert: {
        component: 'icon-alert'
      },
      iconCalendar: {
        component: 'icon-calendar'
      },
      iconCheck: {
        component: 'icon-check'
      },
      iconEuro: {
        component: 'icon-euro'
      },
      iconHourglass: {
        component: 'icon-hourglass'
      },
      iconInfo: {
        component: 'icon-info'
      },
      iconSearch: {
        component: 'icon-search'
      }
    }
  }
});
