<template>
  <div class="flex-grow-1 d-flex align-center justify-center">
    <v-sheet
      min-width="20rem"
      min-height="12rem"
      class="d-flex flex-column align-center justify-center pa-4"
      elevation="12"
    >
      <app-spinner />
      <p class="subtitle-1 mt-8">{{ $t('loadingData') }}</p>
    </v-sheet>
  </div>
</template>

<script>
export default {
  name: 'AppLoadingSheet'
};
</script>
