/**
 * @see {@link https://move-elevator.atlassian.net/wiki/x/QRTIAg}
 */
export const UnprocessedPermission = {
  CANCEL: 'cancel',
  CONTINUE: 'continue_voucher',
  EDIT_DELIVERY_TYPE: 'edit_delivery_type'
};

export default UnprocessedPermission;
