<template>
  <v-container fluid class="app-container app-container--onboarding pa-0 white">
    <toolbar />
    <app-header :dark="$route.name === routeName.ONBOARDING_REGISTRATION" />
    <v-main v-if="initiated">
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>
  </v-container>
</template>

<script>
import Toolbar from '@/onboarding/layout/Toolbar';
import AppHeader from '@/onboarding/layout/AppHeader';
import { PRODUCT_TYPES } from '@/statics/productType';

export default {
  name: 'Layout',

  data: () => ({
    initiated: false
  }),

  components: {
    Toolbar,
    AppHeader
  },

  async created() {
    if (this.$route.params.type && !PRODUCT_TYPES.includes(this.$route.params.type)) {
      await this.$router.replace({ name: this.routeName.ONBOARDING });
    }
    this.initiated = true;
  }
};
</script>

<style lang="scss" scoped>
#app.v-application .app-container--onboarding {
  position: relative;

  ::v-deep .page__container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto 0 auto;
    padding: 3rem;
  }
}
</style>
