import Vue from 'vue';

import AppActionButtons from '@/shared/components/base/AppActionButtons';
import AppAlert from '@/shared/components/base/AppAlert';
import AppApiLog from '@/shared/components/base/AppApiLog';
import AppAutocomplete from '@/shared/components/base/AppAutocomplete';
import AppBtn from '@/shared/components/base/AppBtn';
import AppTextBtn from '@/shared/components/base/AppTextBtn';
import AppCheckbox from '@/shared/components/base/AppCheckbox';
import AppDataTable from '@/shared/components/base/AppDataTable';
import AppDatePicker from '@/shared/components/base/AppDatePicker';
import AppDialog from '@/shared/components/base/AppDialog';
import AppErrorAlert from '@/shared/components/base/AppErrorAlert';
import AppFileInput from '@/shared/components/base/AppFileInput';
import AppFilledViewContainer from '@/shared/components/base/AppFilledViewContainer';
import AppIconBtn from '@/shared/components/base/AppIconBtn';
import AppInputRow from '@/shared/components/base/AppInputRow';
import AppLabeledData from '@/shared/components/base/AppLabeledData';
import AppLinkBtn from '@/shared/components/base/AppLinkBtn';
import AppList from '@/shared/components/base/AppList';
import AppListItem from '@/shared/components/base/AppListItem';
import AppLoadingSheet from '@/shared/components/base/AppLoadingSheet';
import AppRadioGroup from '@/shared/components/base/AppRadioGroup';
import AppSelect from '@/shared/components/base/AppSelect';
import AppSpinner from '@/shared/components/base/AppSpinner';
import AppStatusChip from '@/shared/components/base/AppStatusChip';
import AppTableData from '@/shared/components/base/AppTableData';
import AppTableRow from '@/shared/components/base/AppTableRow';
import AppTabs from '@/shared/components/base/AppTabs';
import AppTextarea from '@/shared/components/base/AppTextarea';
import AppTextField from '@/shared/components/base/AppTextField';
import AppTooltip from '@/shared/components/base/AppTooltip';
import AppNumberField from '@/shared/components/base/AppNumberField';
import AppViewContainer from '@/shared/components/base/AppViewContainer';

Vue.component('AppActionButtons', AppActionButtons);
Vue.component('AppAlert', AppAlert);
Vue.component('AppApiLog', AppApiLog);
Vue.component('AppAutocomplete', AppAutocomplete);
Vue.component('AppBtn', AppBtn);
Vue.component('AppCheckbox', AppCheckbox);
Vue.component('AppDataTable', AppDataTable);
Vue.component('AppDatePicker', AppDatePicker);
Vue.component('AppDialog', AppDialog);
Vue.component('AppErrorAlert', AppErrorAlert);
Vue.component('AppFileInput', AppFileInput);
Vue.component('AppFilledViewContainer', AppFilledViewContainer);
Vue.component('AppIconBtn', AppIconBtn);
Vue.component('AppInputRow', AppInputRow);
Vue.component('AppLabeledData', AppLabeledData);
Vue.component('AppLinkBtn', AppLinkBtn);
Vue.component('AppList', AppList);
Vue.component('AppListItem', AppListItem);
Vue.component('AppLoadingSheet', AppLoadingSheet);
Vue.component('AppRadioGroup', AppRadioGroup);
Vue.component('AppSelect', AppSelect);
Vue.component('AppSpinner', AppSpinner);
Vue.component('AppStatusChip', AppStatusChip);
Vue.component('AppTableData', AppTableData);
Vue.component('AppTableRow', AppTableRow);
Vue.component('AppTabs', AppTabs);
Vue.component('AppTextarea', AppTextarea);
Vue.component('AppTextBtn', AppTextBtn);
Vue.component('AppTextField', AppTextField);
Vue.component('AppNumberField', AppNumberField);
Vue.component('AppTooltip', AppTooltip);
Vue.component('AppViewContainer', AppViewContainer);
