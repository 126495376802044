<template>
  <v-row
    v-if="product"
    :class="{
      'partner-logos__row': true,
      'partner-logos__row--vertical': vertical,
      'flex-row-reverse': reverse
    }"
  >
    <v-col
      cols="auto"
      :class="{ 'partner-logos__col': true, 'partner-logos__col--full-width': !hasPartnerLogo }"
    >
      <img
        id="logo"
        :src="small && product.logoSmall ? product.logoSmall : product.logo"
        v-bind="logoProps"
        @load="$emit('loaded')"
      />
    </v-col>
    <v-divider
      id="logo-divider"
      :vertical="!vertical"
      :class="{ 'mx-1 my-1': true, hidden: !hasPartnerLogo }"
    />
    <template v-if="hasPartnerLogo">
      <v-col cols="auto" class="partner-logos__col">
        <img
          :src="small && product.partnerLogoSmall ? product.partnerLogoSmall : product.partnerLogo"
          v-bind="logoProps"
        />
      </v-col>
    </template>
  </v-row>
</template>

<script>
export default {
  name: 'PartnerLogos',

  data: () => ({
    scaleByWidth: false
  }),

  props: {
    height: {
      type: String,
      default: '45px'
    },
    width: {
      type: String,
      default: '45px'
    },
    reverse: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    vertical: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    logoProps() {
      return this.product?.scaleLogosByWidth
        ? {
            width: this.width
          }
        : { height: this.height };
    },
    hasPartnerLogo() {
      return (
        this.productType &&
        (this.product.partnerLogo || (this.small && this.product.partnerLogoSmall))
      );
    }
  }
};
</script>

<style scoped lang="scss">
.partner-logos__row {
  img {
    display: block;
    max-width: 100%;
    object-fit: contain;
  }

  &:not(.partner-logos__row--vertical) {
    align-items: center;
    flex-wrap: nowrap;

    .partner-logos__col {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-basis: auto;
      flex-shrink: 1;
      flex-grow: 0;

      &:not(.partner-logos__col--full-width) {
        max-width: 50%;
      }
    }
  }
}

.partner-logos__row--vertical {
  flex-direction: column;
}

#app hr.v-divider {
  border-color: var(--c-border);
  border-width: 1px;
  min-height: revert;

  &.hidden {
    opacity: 0;
  }
}
</style>
