/**
 * @see {@link https://move-elevator.atlassian.net/wiki/x/QRTIAg}
 */
export const FACTORING_PERMISSIONS = {
  dashboard: {
    availableCreditorLimit: true,
    balancingChart: true,
    cashback: false,
    daysOverdueChart: true,
    daysSalesOutstandingChart: true,
    debtorLimitRate: true,
    overdueRate: true,
    turnoverProgressionChart: true,
    turnoverVolumeChart: true,
    dispute: false,
    dunningBlock: true,
    showOpenDunningTypeInvoices: false
  },
  debtorDetails: {
    activateDebtor: true,
    activateLocation: true,
    addLocation: true,
    checkSolvency: false,
    createPermanentDebt: true,
    deactivateDebtor: true,
    deactivateLocation: true,
    deletePostAddress: true,
    editDebtor: true,
    editLocation: true,
    editPaymentData: true,
    editPostAddress: true,
    increaseLimit: true,
    refreshLimit: true,
    showPaymentData: true,
    showSepa: true,
    showSolvency: false,
    tabLimit: true,
    tabPermanentDebts: true,
    uploadInvoice: true
  },
  debtors: {
    createDebtor: true,
    showLimit: true,
    showSumOfPurchasedInvoices: true
  },
  helpCenter: {
    tabFAQ: true,
    tabTutorials: false
  },
  masterData: {
    enableDoc2Portal: true,
    enableLetter2Post: true,
    enableWebApp: false,
    serviceFeesWithPurchaseFees: true,
    serviceFeesWithoutPurchaseFees: false,
    tabAboutMe: true,
    tabCreditorVouchers: true,
    tabExtensions: true,
    tabMyContract: true
  },
  navigation: {
    dashboard: true,
    helpCenter: true,
    masterData: true,
    permanentDebts: true,
    settings: true,
    unprocessed: true,
    workQueue: true
  },
  notifications: {
    show: true
  },
  optIn: {
    accept: true
  },
  permanentDebtDetails: {
    showSepa: true,
    stop: true,
    tabInvoices: true
  },
  permanentDebts: {
    create: true
  },
  settings: {
    tabCompanyUsers: true,
    tabEmailNotifications: true,
    tabGeneral: true
  },
  unprocessedDetails: {
    addAttachment: true,
    cancel: true,
    continue: true,
    deleteAttachment: true,
    editDeliveryType: true,
    reupload: true
  },
  voucherDetails: {
    abandonment: true,
    annulation: true,
    cancelDunningBlock: true,
    closeDispute: false,
    closeDelay: false,
    creditAdvice: true,
    directPayment: true,
    openDispute: false,
    openDelay: false,
    openWorkQueue: true,
    setDunningBlock: true,
    showPurchaseReversalWarning: true
  },
  vouchers: {
    openWorkQueue: true,
    sum: true,
    upload: true
  },
  workQueue: {
    answer: true
  }
};

export const BILLING_PERMISSIONS = {
  dashboard: {
    availableCreditorLimit: false,
    balancingChart: true,
    cashback: false,
    daysOverdueChart: true,
    daysSalesOutstandingChart: true,
    debtorLimitRate: false,
    delay: false,
    dispute: false,
    dunningBlock: true,
    overdueRate: true,
    turnoverProgressionChart: true,
    turnoverVolumeChart: true,
    showOpenDunningTypeInvoices: false
  },
  debtorDetails: {
    activateDebtor: true,
    activateLocation: true,
    addLocation: true,
    checkSolvency: true,
    createPermanentDebt: true,
    deactivateDebtor: true,
    deactivateLocation: true,
    deletePostAddress: true,
    editDebtor: true,
    editLocation: true,
    editPaymentData: true,
    editPostAddress: true,
    increaseLimit: false,
    refreshLimit: false,
    showPaymentData: true,
    showSepa: true,
    showSolvency: true,
    tabLimit: false,
    tabPermanentDebts: true,
    uploadInvoice: true
  },
  debtors: {
    createDebtor: true,
    showLimit: false,
    showSumOfPurchasedInvoices: false
  },
  helpCenter: {
    tabFAQ: true,
    tabTutorials: false
  },
  masterData: {
    enableDoc2Portal: true,
    enableLetter2Post: true,
    enableWebApp: false,
    serviceFeesWithPurchaseFees: false,
    serviceFeesWithoutPurchaseFees: true,
    tabAboutMe: true,
    tabCreditorVouchers: true,
    tabExtensions: true,
    tabMyContract: true
  },
  navigation: {
    dashboard: true,
    masterData: true,
    permanentDebts: true,
    settings: true,
    unprocessed: true,
    workQueue: true
  },
  notifications: {
    show: true
  },
  optIn: {
    accept: true
  },
  permanentDebtDetails: {
    showSepa: true,
    stop: true,
    tabInvoices: true
  },
  permanentDebts: {
    create: true
  },
  settings: {
    tabCompanyUsers: true,
    tabEmailNotifications: true,
    tabGeneral: true
  },
  unprocessedDetails: {
    addAttachment: true,
    cancel: true,
    continue: true,
    deleteAttachment: true,
    editDeliveryType: true,
    reupload: true
  },
  voucherDetails: {
    abandonment: true,
    annulation: true,
    cancelDunningBlock: true,
    closeDispute: false,
    closeDelay: false,
    creditAdvice: true,
    directPayment: true,
    openDispute: false,
    openDelay: false,
    openWorkQueue: true,
    setDunningBlock: true,
    showPurchaseReversalWarning: false
  },
  vouchers: {
    openWorkQueue: true,
    sum: true,
    upload: true
  },
  workQueue: {
    answer: true
  }
};

export const DEGENER_PERMISSIONS = {
  ...BILLING_PERMISSIONS,
  debtorDetails: {
    ...BILLING_PERMISSIONS.debtorDetails,
    checkSolvency: false,
    createPermanentDebt: false,
    showSolvency: false,
    tabPermanentDebts: false
  },
  helpCenter: {
    tabFAQ: true,
    tabTutorials: true
  },
  masterData: {
    ...BILLING_PERMISSIONS.masterData,
    enableLetter2Post: false,
    enableWebApp: true
  },
  navigation: {
    ...BILLING_PERMISSIONS.navigation,
    permanentDebts: false
  },
  permanentDebts: {
    ...BILLING_PERMISSIONS.permanentDebts,
    create: false
  }
};
