<template>
  <v-btn
    :color="color"
    :depressed="!fab"
    :disabled="disabled || loading"
    :fab="fab"
    :icon="!fab"
    :loading="loading"
    class="icon-btn"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot>
      <component :is="isVuetifyIcon ? 'v-icon' : icon" v-if="icon" :style="{ '--size': iconSize }">
        <template v-if="isVuetifyIcon">{{ icon }}</template>
      </component>
    </slot>
  </v-btn>
</template>

<script>
import { VIcon } from 'vuetify/lib';

export default {
  name: 'AppIconBtn',

  components: {
    VIcon
  },

  props: {
    color: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fab: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    size: {
      type: [String, Number],
      default: undefined
    }
  },

  computed: {
    isVuetifyIcon() {
      return this.icon.startsWith('mdi');
    },
    iconSize() {
      return isNaN(this.size) ? this.size : this.size + 'px';
    }
  }
};
</script>

<style scoped lang="scss">
@mixin icon-styles($size) {
  ::v-deep .v-icon,
  ::v-deep svg {
    width: var(--size, $size);
    height: var(--size, $size);
    font-size: var(--size, $size);
  }
}

.icon-btn {
  @include icon-styles(1.5rem);

  &.v-size--small {
    @include icon-styles(1rem);
  }

  &.v-size--large {
    @include icon-styles(2.25rem);
  }

  &.v-btn--is-elevated {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  }

  &.v-btn--icon {
    color: inherit;
  }
}
</style>
