<template>
  <div :class="{ content: true, 'content--mobile': isMobile }">
    <v-row no-gutters class="content__row">
      <v-col v-if="hasAdminView" class="content__version">
        <span>Version:</span>
        Frontend v{{ appVersion }}{{ apiVersion && ', ' }}
        <template v-if="apiVersion">
          <span>Backend v{{ apiVersion || '-' }}</span>
        </template>
      </v-col>

      <v-spacer v-if="!isMobile" />

      <v-col class="service-times">
        <v-icon small>mdi-clock-outline</v-icon>
        <div class="service-times__data">
          <span class="service-times__label">{{ $t('user.serviceTimes') }}: &nbsp;</span>
          <div class="service-times__data">
            <template v-for="(timeSlot, index) in serviceTimes">
              <span :key="`time-${index}`">
                <span class="service-times__highlight">{{ timeSlot.days }}</span>
                {{ timeSlot.time }} {{ $t('user.oClock') }}
              </span>
              <span
                v-if="index + 1 < serviceTimes.length"
                :key="`divider-${index}`"
                class="service-times__divider"
              >
                &nbsp;|&nbsp;
              </span>
            </template>
          </div>
        </div>
      </v-col>

      <v-col class="pa-0 ma-0">
        <v-row no-gutters class="content__row">
          <v-col v-if="product.serviceHotline" class="d-flex">
            <v-icon small>mdi-phone-outline</v-icon>
            {{ product.serviceHotline }}
          </v-col>

          <v-col v-if="product.serviceMail">
            <a :href="`mailto:${product.serviceMail}`" class="d-flex">
              <v-icon small>mdi-email-outline</v-icon>
              {{ product.serviceMail }}
            </a>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { NAMESPACE } from '@/modules/User/store';
import { SERVICE_TIMES } from '@/statics/customerServiceInformation';
import { mapActions } from 'vuex';

export default {
  name: 'ContactBarContent',

  data: () => ({
    apiVersion: null,
    serviceTimes: SERVICE_TIMES
  }),

  computed: {
    appVersion() {
      return process.env.VUE_APP_VERSION;
    }
  },

  watch: {
    hasAdminView: {
      async handler() {
        if (!this.hasAdminView) {
          return;
        }

        const { version } = await this.fetchApiVersion();
        this.apiVersion = version;
      },
      immediate: true
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['fetchApiVersion'])
  }
};
</script>

<style scoped lang="scss">
.content {
  @include subtitle-1-font-style;
  padding: 4px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  letter-spacing: 0.25px !important;

  &--mobile {
    @include caption-font-style;
  }

  a {
    text-decoration: unset;
  }

  &__row {
    max-width: 100%;
    justify-content: flex-end;
    gap: 5px;
    word-break: break-word;
    white-space: normal;

    .content--mobile & {
      flex-direction: column;
      justify-content: flex-start;
      gap: 0;
    }

    .col {
      flex: 0 0 max-content;
      margin-left: 32px;

      .content--mobile & {
        margin-left: 0;
        padding-block: 4px;
      }

      &.content__version {
        margin-right: 16px;
        margin-left: 0;

        .content--mobile & {
          margin-right: 0;
          margin-bottom: 16px;

          > span {
            display: block;
          }
        }
      }
    }
  }
}

.service-times {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  .content--mobile & {
    justify-content: flex-start;
    margin-bottom: 12px;
  }

  &__data {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 450px) {
      flex-direction: column;

      .service-times__divider {
        display: none;
      }
    }
  }

  &__label {
    font-weight: var(--font-weight-semi-bold);

    .content--mobile & {
      flex: 0 0 100%;
    }
  }

  &__highlight {
    font-weight: var(--font-weight-semi-bold);
    color: var(--c-contact-highlight);

    .content--mobile & {
      color: inherit;
    }
  }
}

::v-deep .v-icon {
  align-self: flex-start;
  margin-top: 2px;
  margin-right: 8px;
  color: inherit !important;

  .content--mobile & {
    margin-top: 0;
    margin-right: 26px;
  }
}
</style>
