import { envInfo } from '@/statics/environment';

export default function setFullDocumentTitle({ pageTitle, productName }) {
  if (document.getElementById('failure-view')) {
    return;
  }

  let title = '';

  if (envInfo) {
    title += `${envInfo} - `;
  }

  if (pageTitle) {
    title += pageTitle;

    if (productName) {
      title += ` - `;
    }
  }

  title += productName;
  document.title = title;
}
