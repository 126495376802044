<template>
  <div v-if="filteredActions.length > 0">
    <p v-if="showTitle" class="mt-7">{{ $t('actions') }}</p>

    <v-row
      :no-gutters="!isMobile"
      :dense="isMobile"
      align="stretch"
      data-testid="action-buttons__row"
    >
      <v-col
        v-for="(action, index) in filteredActions.slice(0, maxActions)"
        :key="index"
        :cols="isMobile ? 6 : 3"
        class="d-flex flex-column"
      >
        <tile-btn
          :color="action.active ? 'button-link' : 'secondary'"
          :depressed="!isMobile"
          :disabled="!action.callback || action.permission === false"
          :icon="action.icon"
          :loading="action.loading"
          :title="action.text"
          data-testid="action-buttons__button"
          @click="executeCallback(action.callback)"
        />
      </v-col>
    </v-row>

    <app-link-btn
      v-if="filteredActions.length > maxActionsInRow"
      :title="!showAllActions ? $t('showMoreActions') : $t('hideMoreActions')"
      class="my-4"
      color="button-link"
      data-testid="action-buttons__show-all"
      variant="muted"
      @click="showAllActions = !showAllActions"
    />
  </div>
</template>

<script>
import TileBtn from '@/shared/components/TileBtn';

export default {
  name: 'AppActionButtons',

  components: { TileBtn },

  data: () => ({
    showAllActions: false,
    maxActionsInRow: 4
  }),

  props: {
    actions: {
      type: Array,
      default: () => []
    },
    showTitle: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    filteredActions() {
      return this.filterItemsWithFulfilledCondition(this.actions);
    },
    maxActions() {
      return this.showAllActions ? this.filteredActions.length : this.maxActionsInRow;
    }
  },

  methods: {
    executeCallback(callback) {
      if (callback === null) {
        return;
      }

      callback();
    }
  }
};
</script>
