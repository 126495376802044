/**
 * @see {@link https://move-elevator.atlassian.net/wiki/x/QRTIAg}
 */
export const DebtorPermission = {
  ADD_LOCATION: 'add_address',
  EDIT_DEBTOR: 'update_debtor_data',
  EDIT_PAYMENT_DATA: 'update_payment_data',
  REQUEST_LIMIT_INCREASE: 'debtor_limit_increase',
  REFRESH_LIMIT: 'debtor_limit_refresh',
  CHECK_SOLVENCY: 'determine_solvency'
};

export default DebtorPermission;
