import DunningLevel from '@/modules/Dashboard/statics/dunningLevel';
import VoucherSubStatus from '@/statics/voucherSubStatus';
import DunningType from '@/statics/dunningType';

export default {
  title: 'Guten Tag!',
  subtitle: 'Hier finden Sie eine Übersicht Ihrer wichtigsten Kennzahlen',
  factsOverview: 'Das Wichtigste auf einen Blick',
  activitiesAndNews: 'Letzte Aktivitäten und Neuigkeiten',
  statisticsInDetail: 'Ihre Statistiken im Detail',
  debtorLimitRate: 'Kundenlimitquote',
  overdueRate: 'Überfälligkeitsquote',
  cashbackCredit: 'Cashback-Guthaben',
  vouchersInDunningBlockCounted: 'Beleg im Mahnstopp | Belege im Mahnstopp',
  amountToNextCashbackLevel: 'Nur noch <b>{amount}</b> Umsatz bis zur nächsten Cashback-Stufe!',
  openWorkQueue: 'Offene Entscheidungen',
  lastCreditorVouchers: 'Letzte Abrechnungen',
  toCreditorVouchers: 'Zu Ihren Abrechnungen',
  openInvoices: 'Offene Rechnungen',
  openInvoiceChartLabels: {
    [DunningLevel.NOT_DUNNED]: 'Nicht gemahnt',
    [DunningLevel.HALT]: `@:(vouchers.subStatusOptions.${VoucherSubStatus.HALT})`,
    [DunningLevel.PAYMENT_REMINDER]: `@:(vouchers.subStatusOptions.${VoucherSubStatus.PAYMENT_REMINDER})`,
    [DunningLevel.DUNNING]: `@:(vouchers.subStatusOptions.${VoucherSubStatus.DUNNING})`,
    [DunningLevel.ENCASHMENT]: `@:(vouchers.subStatusOptions.${VoucherSubStatus.ENCASHMENT})`,
    [DunningType.DISPUTE.toLowerCase()]: `@:(vouchers.subStatusOptions.${VoucherSubStatus.CUSTOMER_COMPLAINT})`,
    [DunningType.DELAY.toLowerCase()]: `@:(vouchers.subStatusOptions.${VoucherSubStatus.DELAYED})`,
    [DunningType.ENCASHMENT.toLowerCase()]: `@:(vouchers.subStatusOptions.${VoucherSubStatus.ENCASHMENT})`
  },
  turnoverVolumeChart: 'Gesamtvolumen / Jahr',
  turnoverVolumeChartLabels: {
    open: 'Offen',
    balanced: 'Ausgeglichen'
  },
  turnoverProgressionChart: 'Umsatzentwicklung / Monat',
  balancingChart: 'Ausgleichsübersicht',
  daysOverdueChart: 'Überfälligkeitstage',
  daysSalesOutstandingChart: 'Zahlverhalten',
  paymentTarget: 'Zahlungsziel',
  noData: 'Derzeit liegen noch keine Daten vor',
  months: {
    jan: 'Jan',
    feb: 'Feb',
    mar: 'Mrz',
    apr: 'Apr',
    may: 'Mai',
    jun: 'Jun',
    jul: 'Jul',
    aug: 'Aug',
    sep: 'Sep',
    oct: 'Okt',
    nov: 'Nov',
    dec: 'Dez'
  },
  monthsFull: {
    jan: 'Januar',
    feb: 'Februar',
    mar: 'März',
    apr: 'April',
    may: 'Mai',
    jun: 'Juni',
    jul: 'Juli',
    aug: 'August',
    sep: 'September',
    oct: 'Oktober',
    nov: 'November',
    dec: 'Dezember'
  },
  notReachedServiceFeeAnnual: 'Mindestgebühr <b>noch nicht</b> erreicht',
  reachedServiceFeeAnnual: 'Mindestgebühr <b>erreicht</b>',
  serviceFeeAnnualOf: 'von {amount}',
  of: 'von'
};
