<template>
  <v-list
    id="appList"
    v-resize="setCssVars"
    class="unprocessed-list"
    color="transparent"
    :style="cssVars"
  >
    <v-subheader
      :class="{
        'subtitle-1 text--text flex-grow-0 flex-shrink-0': true,
        'justify-space-between': !isMobile
      }"
    >
      <slot name="header">{{ listHeader }}</slot>
      <div class="grey--text subtitle-1 ml-2">
        {{ list.length }}
      </div>
    </v-subheader>
    <v-divider />
    <div id="appListContainer" class="scroll-overflow--hide-scrollbar">
      <template v-for="item in list">
        <app-list-item
          :key="item.id"
          v-bind="item.id === $route.params.id ? { ref: 'activeItem' } : null"
          :item="item"
        >
          <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
            <slot :name="name" v-bind="slotData"></slot>
          </template>
        </app-list-item>
        <div v-if="item.id === $route.params.id" :key="`detailed_${item.id}`" class="white">
          <slot name="details"></slot>
        </div>
        <v-divider :key="`divider_${item.id}`" />
      </template>
    </div>
    <v-spacer class="v-list-item" style="min-height: 0" />
  </v-list>
</template>

<script>
export default {
  name: 'AppList',

  props: {
    list: {
      type: Array,
      required: true
    },
    header: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    listWidth: 0
  }),

  watch: {
    '$route.params.id'() {
      if (!this.isMobile) {
        return;
      }
      this.scrollToActiveItem();
    }
  },

  computed: {
    listHeader() {
      return this.$te(this.header) ? this.$t(this.header) : this.header;
    },
    cssVars() {
      return {
        '--list__width': this.listWidth + 'px'
      };
    }
  },

  methods: {
    setCssVars() {
      this.listWidth = document.querySelector('.unprocessed-list').offsetWidth;
    },

    scrollToActiveItem() {
      setTimeout(() => {
        if (!this.$refs.activeItem) {
          return;
        }

        this.$vuetify.goTo(this.$refs.activeItem, {
          duration: 500,
          offset: 0,
          easing: 'easeInOutCubic',
          container: '#appListContainer'
        });
      }, 300);
    }
  },

  created() {
    this.scrollToActiveItem();
  }
};
</script>

<style scoped lang="scss">
$border-width__selected: 10px;
$list-item__height: 6.5rem;
$list--reduced-width: calc(100% - #{$border-width__selected});
$list__padding-right: 16px;

:root {
  --list__width: 0px;
}

.v-application:not(.mobile) .v-subheader,
.v-application:not(.mobile) hr.v-divider {
  max-width: $list--reduced-width;
}

.v-subheader {
  height: 3.5rem;
  background-color: white;
  border-right: 1px solid var(--c-border);
}

.v-application.mobile .v-subheader {
  border-right: none;
}

.unprocessed-list {
  padding: 0;
  background-color: var(--c-list) !important;
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 100%;

  ::v-deep .v-list-item__subtitle:not(.muted) {
    color: inherit !important;
  }

  &::after {
    right: 0;
    top: 0;
    bottom: 0;
    width: 10px;
    content: '';
    background: white;
    position: absolute;
  }
}

.v-application.mobile .unprocessed-list {
  min-height: unset;
  height: auto;

  &::after {
    width: 0;
  }
}

.v-list-item {
  height: $list-item__height;
  min-height: unset;
  z-index: 1;
  max-width: $list--reduced-width;
  border-right: 1px solid var(--c-border);
  padding-right: $list__padding-right;
  padding-left: 40px;

  ::v-deep div {
    overflow: hidden;
    word-wrap: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  ::v-deep .muted {
    color: var(--c-grey);
  }

  ::v-deep svg,
  ::v-deep i {
    width: 12px;
    height: 12px;
  }

  ::v-deep svg {
    color: var(--c-primary);
  }

  ::v-deep .list__icon {
    position: absolute;
    left: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
  }

  &.list-item--error {
    ::v-deep .v-list-item__title,
    ::v-deep svg {
      color: var(--c-orange);
    }
  }
}

.v-list-item.list-item--warning {
  ::v-deep .v-list-item__title,
  ::v-deep svg {
    color: var(--c-warning);
  }
}

.v-application.mobile .theme--light.v-list-item {
  height: auto;
  margin-right: 0;
  max-width: 100%;
  border-right: none;
}

.v-application .theme--light.v-list-item.list-item--selected {
  background-color: var(--c-list-highlighted);
  padding-right: calc(#{$list__padding-right} + #{$border-width__selected});
  animation: expand-item--right 0.2s forwards;
  max-width: 100%;
  color: var(--c-list-highlighted-text) !important;
  border: none;

  ::v-deep .list-title--text {
    color: var(--c-list-highlighted-title) !important;
  }

  &.list-item--error,
  &.list-item--warning {
    ::v-deep .v-list-item__title,
    ::v-deep svg {
      color: var(--c-list-highlighted-error) !important;
    }
  }

  ::v-deep svg,
  ::v-deep i {
    color: var(--c-list-highlighted-icon);
  }

  ::v-deep .muted,
  ::v-deep .muted > div {
    color: var(--c-list-highlighted-text-light);
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 0;
    border-style: solid;
    border-color: white white white transparent;
    z-index: 10;
    animation: expand-border--right 0.2s forwards;
  }

  @keyframes expand-item--right {
    from {
      width: $list--reduced-width;
      padding-right: $list__padding-right;
    }
    to {
      width: 100%;
      padding-right: calc(#{$list__padding-right} + #{$border-width__selected});
    }
  }

  @keyframes expand-border--right {
    from {
      border-width: calc(#{$list-item__height} / 2) 0 calc(#{$list-item__height} / 2) 0;
    }
    to {
      border-width: calc(#{$list-item__height} / 2) 0 calc(#{$list-item__height} / 2)
        $border-width__selected;
    }
  }
}

.v-application.mobile .theme--light.v-list-item.list-item--selected {
  padding-right: $list__padding-right;
  padding-bottom: calc(#{$list__padding-right} + #{$border-width__selected});
  animation: expand-item--bottom 0.2s forwards;

  &:after {
    border-color: transparent white white white;
    bottom: 0;
    width: 100%;
    animation: expand-border--bottom 0.2s forwards;
  }

  @keyframes expand-item--bottom {
    from {
      padding-bottom: 0;
    }
    to {
      padding-bottom: $border-width__selected;
    }
  }

  @keyframes expand-border--bottom {
    from {
      border-width: 0 calc(#{var(--list__width)} / 2) 0 calc(#{var(--list__width)} / 2);
    }
    to {
      border-width: 10px calc(#{var(--list__width)} / 2) 0 calc(#{var(--list__width)} / 2);
    }
  }
}
</style>
