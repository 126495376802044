import * as Sentry from '@sentry/vue';
import packageInfo from '@/../package.json';

export default (Vue, router) => {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY,
    environment: process.env.VUE_APP_ENV,
    release: packageInfo.version,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      })
    ],
    tracesSampleRate: 0.1
  });
};
