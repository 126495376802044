<template>
  <svg
    width="19px"
    height="19px"
    viewBox="0 0 19 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        id="path-search"
        d="M18.43825,12.427375 L17.64425,12.427375 L17.36825,12.701375 C18.34325,13.838375 18.93625,15.312375 18.93625,16.927375 C18.93625,20.517375 16.02625,23.427375 12.43625,23.427375 C8.84625,23.427375 5.93625,20.517375 5.93625,16.927375 C5.93625,13.337375 8.84625,10.427375 12.43625,10.427375 C14.05125,10.427375 15.52425,11.019375 16.66125,11.993375 L16.93725,11.719375 L16.93725,10.927375 L21.93525,5.936375 L23.42625,7.427375 L18.43825,12.427375 L18.43825,12.427375 Z M12.43625,12.427375 C9.95025,12.427375 7.93625,14.441375 7.93625,16.927375 C7.93625,19.412375 9.95025,21.427375 12.43625,21.427375 C14.92125,21.427375 16.93625,19.412375 16.93625,16.927375 C16.93625,14.441375 14.92125,12.427375 12.43625,12.427375 L12.43625,12.427375 Z"
      />
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-5.000000, -44.000000)">
        <g>
          <g transform="translate(0.000000, 29.000000)">
            <g transform="translate(0.000000, 10.000000)">
              <mask fill="currentColor">
                <use xlink:href="#path-search" />
              </mask>
              <use
                fill="currentColor"
                fill-rule="evenodd"
                transform="translate(14.681250, 14.681875) scale(1, -1) translate(-14.681250, -14.681875) "
                xlink:href="#path-search"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconSearch'
};
</script>
