import showSnackbar from '@/helper/showSnackbar';
import {
  mapUnprocessed,
  mapList,
  mapBnetErrors,
  mapUnprocessedPermissions
} from '@/modules/Unprocessed/models';
import formatLogData from '@/helper/formatLogData';

export const NAMESPACE = 'unprocessed';

const initialState = () => ({
  unprocessedList: [],
  detailedUnprocessed: null,
  detailedUnprocessedRaw: null,
  processLog: [],
  processLogRaw: [],
  loadingList: false,
  loadingDetails: false,
  loadingAction: false,
  listError: false,
  detailsError: false
});

export default (Vue) => ({
  namespaced: true,

  state: initialState(),

  mutations: {
    SET_UNPROCESSED_LIST(state, unprocessedList) {
      state.unprocessedList = unprocessedList;
    },
    SET_DETAILED_UNPROCESSED(
      state,
      { unprocessed, unprocessedRaw = state.detailedUnprocessedRaw }
    ) {
      state.detailedUnprocessed = unprocessed;
      state.detailedUnprocessedRaw = unprocessedRaw;
    },
    RESET_DETAILED_UNPROCESSED(state) {
      state.detailedUnprocessed = null;
      state.detailedUnprocessedRaw = null;
    },
    SET_PROCESSLOG(state, { processLog, processLogRaw }) {
      state.processLog = processLog;
      state.processLogRaw = processLogRaw;
    },
    RESET_PROCESSLOG(state) {
      state.processLog = [];
      state.processLogRaw = [];
    },
    SET_LOADING_LIST(state) {
      state.loadingList = true;
    },
    RESET_LOADING_LIST(state) {
      state.loadingList = false;
    },
    SET_LOADING_DETAILS(state) {
      state.loadingDetails = true;
    },
    RESET_LOADING_DETAILS(state) {
      state.loadingDetails = false;
    },
    SET_LOADING_ACTION(state) {
      state.loadingAction = true;
    },
    RESET_LOADING_ACTION(state) {
      state.loadingAction = false;
    },
    SET_LIST_ERROR(state) {
      state.listError = true;
    },
    RESET_LIST_ERROR(state) {
      state.listError = false;
    },
    SET_DETAILS_ERROR(state) {
      state.detailsError = true;
    },
    RESET_DETAILS_ERROR(state) {
      state.detailsError = false;
    },
    RESET_STATE(state) {
      const initial = initialState();
      Object.keys(initial).forEach((key) => {
        state[key] = initial[key];
      });
    }
  },

  actions: {
    async fetchUnprocessedList({ commit, dispatch, state }) {
      commit('RESET_LIST_ERROR');
      commit('SET_LOADING_LIST');

      try {
        const response = await Vue.prototype.$http.get(`/unprocessed-voucher/list`);

        commit('SET_UNPROCESSED_LIST', mapList(response.data));

        // if a detailed unprocessed is shown, it also needs to be reloaded
        if (state.detailedUnprocessed !== null) {
          dispatch('fetchDetailedUnprocessed', state.detailedUnprocessed.id);
        }

        commit('RESET_LOADING_LIST');
        return {};
      } catch (error) {
        commit('SET_LIST_ERROR');
        commit('RESET_LOADING_LIST');
        return { error };
      }
    },

    async fetchDetailedUnprocessed({ commit, dispatch, rootState }, identifier) {
      commit('RESET_DETAILS_ERROR');
      commit('SET_LOADING_DETAILS');
      commit('RESET_DETAILED_UNPROCESSED');

      if (rootState.authentication.adminView) {
        commit('RESET_PROCESSLOG');
        dispatch('fetchProcessLog', identifier);
      }

      try {
        const [voucherResponse, bnetStatusResponse, permissionsResponse] = await Promise.all([
          Vue.prototype.$http.get(`/voucher/show/${identifier}`),
          Vue.prototype.$http.get(`/voucher/${identifier}/bnet-status`),
          Vue.prototype.$http.get(`/voucher/${identifier}/permissions`)
        ]);

        const unprocessed = {
          ...mapUnprocessed(voucherResponse.data),
          bnetErrors: mapBnetErrors(bnetStatusResponse.data),
          permissions: mapUnprocessedPermissions(permissionsResponse.data)
        };

        commit('SET_DETAILED_UNPROCESSED', {
          unprocessed: unprocessed,
          unprocessedRaw: voucherResponse.data
        });
        commit('RESET_LOADING_DETAILS');
        return {};
      } catch (error) {
        commit('SET_DETAILS_ERROR');
        commit('RESET_LOADING_DETAILS');
        return { error };
      }
    },

    async fetchProcessLog({ commit }, identifier) {
      try {
        const response = await Vue.prototype.$http.get(`/voucher/${identifier}/process-log/list`);

        commit('SET_PROCESSLOG', {
          processLog: formatLogData(response.data),
          processLogRaw: response.data
        });

        return {};
      } catch (error) {
        return { error };
      }
    },

    async executeCancel(_, voucherIdentifier) {
      try {
        await Vue.prototype.$http.post(`/bnet/voucher/${voucherIdentifier}/cancel`);
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async continueProcessing(_, voucherIdentifier) {
      try {
        await Vue.prototype.$http.get(`/voucher/continue/${voucherIdentifier}`);
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async transferBasicProcessing(_, voucherIdentifier) {
      try {
        const { data } = await Vue.prototype.$http.post(
          `/voucher/transfer-basic-process/${voucherIdentifier}`
        );
        return { newVoucherIdentifier: data.identifier };
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async editDeliveryType(_, { identifier, addressData }) {
      const data = { ...addressData };
      try {
        await Vue.prototype.$http.post(`/voucher/${identifier}/delivery-type`, data);
        return {};
      } catch (error) {
        showSnackbar({ text: 'addresses.addressNotAdded' });
        return { error };
      }
    },

    async editCustomisedLabel(_, { identifier, customisedLabelData }) {
      const data = { ...customisedLabelData };
      try {
        await Vue.prototype.$http.post(`/voucher/${identifier}/customised-label`, data);
        return {};
      } catch (error) {
        showSnackbar({ text: 'changesNotSaved' });
        return { error };
      }
    },

    async editLocationEmail(_, { identifier, email }) {
      try {
        await Vue.prototype.$http.post(`/location/${identifier}/email-address`, { email });
        return {};
      } catch (error) {
        showSnackbar({ text: 'changesNotSaved' });
        return { error };
      }
    },

    async deleteAttachment({ dispatch }, { voucherIdentifier, attachmentIdentifier }) {
      try {
        await Vue.prototype.$http.delete(
          `/voucher/${voucherIdentifier}/attachment/${attachmentIdentifier}`
        );

        dispatch('fetchDetailedUnprocessed', voucherIdentifier);
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError' });
        return { error };
      }
    }
  }
});
