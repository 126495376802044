<template>
  <td
    v-if="!isMobile || hasContent"
    :class="{
      'v-data-table-col--sorted': sorted,
      'v-data-table__mobile-row': isMobile,
      'text-left': header.alignLeft
    }"
  >
    <div v-if="isMobile && header" class="table-header__content text-left">
      <span :class="{ 'text-uppercase font-weight-regular': true, 'mr-4': !!header.text }">
        {{ header.text }}
      </span>
      <div class="caption grey--text table-header__text">{{ header.subText }}</div>
    </div>
    <span :class="{ 'text-right d-flex flex-column align-end': isMobile }">
      <slot></slot>
    </span>
  </td>
</template>

<script>
export default {
  name: 'AppTableData',

  props: {
    header: {
      type: Object,
      default: () => ({})
    },
    sorted: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    hasContent() {
      return !!this.$slots.default;
    }
  }
};
</script>
