export const TutorialTag = {
  OVERVIEW: 'overview',
  VOUCHERS: 'vouchers',
  DEBTORS: 'debtors',
  PERMANENT_DEBTS: 'permanentDebts',
  USER_INFO: 'userInfo',
  MASTER_DATA: 'masterData'
};

export const TUTORIAL_TAGS = Object.values(TutorialTag);

export default TutorialTag;
