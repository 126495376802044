<template>
  <svg
    width="30px"
    height="30px"
    viewBox="0 0 30 30"
    style="fill-rule: evenodd; clip-rule: evenodd; stroke-linejoin: round; stroke-miterlimit: 2"
  >
    <g fill="currentColor">
      <path
        d="M9.809,19.865c-0.651,-0 -1.164,0.231 -1.537,0.692c-0.373,0.462 -0.559,1.099 -0.559,1.912c-0,0.85 0.179,1.494 0.538,1.93c0.359,0.437 0.878,0.655 1.558,0.655c0.294,-0 0.579,-0.03 0.854,-0.088c0.275,-0.059 0.561,-0.134 0.858,-0.225l0,0.972c-0.544,0.206 -1.16,0.308 -1.85,0.308c-1.015,0 -1.794,-0.307 -2.338,-0.922c-0.544,-0.615 -0.816,-1.495 -0.816,-2.64c0,-0.721 0.132,-1.351 0.396,-1.892c0.264,-0.541 0.646,-0.955 1.146,-1.243c0.499,-0.287 1.086,-0.431 1.759,-0.431c0.709,-0 1.363,0.148 1.964,0.446l-0.408,0.943c-0.234,-0.11 -0.481,-0.208 -0.742,-0.291c-0.261,-0.084 -0.535,-0.126 -0.823,-0.126Z"
        style="fill-rule: nonzero"
      />
      <path
        d="M17.013,24.043c0,0.617 -0.223,1.101 -0.668,1.452c-0.446,0.351 -1.061,0.526 -1.845,0.526c-0.785,0 -1.426,-0.121 -1.926,-0.365l0,-1.072c0.316,0.149 0.652,0.266 1.008,0.351c0.356,0.086 0.687,0.128 0.994,0.128c0.449,0 0.78,-0.085 0.993,-0.256c0.214,-0.171 0.32,-0.4 0.32,-0.688c0,-0.259 -0.098,-0.479 -0.294,-0.659c-0.196,-0.18 -0.601,-0.394 -1.214,-0.64c-0.632,-0.256 -1.078,-0.549 -1.337,-0.878c-0.26,-0.328 -0.389,-0.724 -0.389,-1.185c-0,-0.579 0.205,-1.034 0.616,-1.366c0.411,-0.332 0.963,-0.498 1.655,-0.498c0.664,-0 1.325,0.145 1.983,0.436l-0.36,0.925c-0.617,-0.259 -1.167,-0.389 -1.651,-0.389c-0.367,-0 -0.645,0.08 -0.835,0.24c-0.189,0.159 -0.284,0.37 -0.284,0.633c-0,0.18 0.038,0.334 0.114,0.462c0.075,0.128 0.2,0.249 0.374,0.363c0.174,0.114 0.487,0.264 0.939,0.451c0.509,0.211 0.882,0.409 1.12,0.592c0.237,0.184 0.411,0.391 0.521,0.622c0.111,0.231 0.166,0.502 0.166,0.815Z"
        style="fill-rule: nonzero"
      />
      <path
        d="M22.306,18.992l1.177,0l-2.429,6.934l-1.195,0l-2.419,-6.934l1.167,0l1.447,4.312c0.075,0.205 0.156,0.47 0.241,0.794c0.086,0.324 0.141,0.565 0.166,0.723c0.042,-0.24 0.105,-0.518 0.19,-0.834c0.086,-0.317 0.155,-0.551 0.209,-0.702l1.446,-4.293Z"
        style="fill-rule: nonzero"
      />
      <path
        d="M4.275,0.009l15.837,0c0.063,-0.012 0.128,-0.012 0.191,0l0.166,0.105l6.42,6.284c0.1,0.096 0.158,0.23 0.159,0.37l-0,22.678c0.011,0.143 -0.038,0.284 -0.134,0.389c-0.097,0.106 -0.232,0.165 -0.374,0.165l-23.08,0c-0.142,0 -0.277,-0.059 -0.374,-0.165c-0.096,-0.105 -0.145,-0.246 -0.134,-0.389l0,-10.373l0.001,-0.973c0,-0.003 0,-0.005 0,-0.008l0,-3.39c0,-1.339 0.303,-2.369 0.996,-3.059c0.694,-0.689 1.73,-0.992 3.075,-0.992l7.023,-0l-2.239,-2.232c-0.256,-0.253 -0.254,-0.671 -0.001,-0.924c0.254,-0.254 0.675,-0.254 0.929,0l3.04,3.028c0.231,0.23 0.394,0.485 0.394,0.782c0,0.297 -0.164,0.549 -0.394,0.78l-0,0.002l-3.04,3.029c-0.254,0.253 -0.675,0.252 -0.929,-0c-0.001,-0.001 -0.001,-0.001 -0.002,-0.002c-0.125,-0.126 -0.19,-0.296 -0.19,-0.46c0,-0.167 0.066,-0.337 0.193,-0.463l2.24,-2.234l-7.024,0c-1.065,0 -1.734,0.206 -2.141,0.612c-0.408,0.406 -0.614,1.072 -0.614,2.13l0,3.369c0.003,0.01 0.004,0.021 0.004,0.032l0.002,10.568l21.449,-0l0,-21.214l-5.759,-0c-0.272,-0 -0.493,-0.226 -0.493,-0.503l-0,-5.642l-15.197,0l-0.002,6.655c-0,0.365 -0.296,0.66 -0.66,0.66c-0.364,0 -0.66,-0.295 -0.66,-0.659l-0.001,-0l0,-7.965l1.323,0l-0,0.009Zm16.381,2.45l4.09,3.925l-4.09,-0l-0,-3.925Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconCsvImport'
};
</script>
