<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 142.34 144.54">
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <path
          class="cls-1"
          d="M133.54,74.06h-3.61v-3.6a8.84,8.84,0,0,0-8.83-8.83H109.36V8.8A8.83,8.83,0,0,0,100.53,0H25A8.83,8.83,0,0,0,16.2,8.8V61.63H8.83A8.84,8.84,0,0,0,0,70.46v52.83a8.84,8.84,0,0,0,8.83,8.83h3.61v3.59a8.84,8.84,0,0,0,8.83,8.83H133.54a8.83,8.83,0,0,0,8.8-8.83V82.89A8.83,8.83,0,0,0,133.54,74.06ZM22.06,8.8a3,3,0,0,1,3-2.93h75.5a3,3,0,0,1,3,2.93V61.63H80.32V56.28H74.45v5.35H22.06ZM5.87,123.29V70.46a3,3,0,0,1,3-3H121.1a3,3,0,0,1,3,3v52.83a3,3,0,0,1-3,3H8.83A3,3,0,0,1,5.87,123.29Zm130.6,12.42a3,3,0,0,1-2.93,3H21.27a3,3,0,0,1-3-3v-3.59H121.1a8.84,8.84,0,0,0,8.83-8.83V79.93h3.61a3,3,0,0,1,2.93,3Z"
        />
        <path
          class="cls-1"
          d="M34.53,83.83A11.61,11.61,0,0,1,45.4,90.76h4A15.29,15.29,0,0,0,34.57,80.29,15.58,15.58,0,0,0,19.05,91.88H13.24v2.86h5.27c0,.58-.08,1.12-.08,1.7s0,1.16.08,1.74H13.24v2.91h5.81a15.64,15.64,0,0,0,15.52,11.5,15.29,15.29,0,0,0,14.85-10.47h-4a11.63,11.63,0,0,1-10.87,6.93,12,12,0,0,1-11.54-8H36.36V98.18h-14a11.34,11.34,0,0,1-.14-1.74c0-.58.05-1.16.09-1.7H36.36V91.88H22.94A12,12,0,0,1,34.53,83.83Z"
        />
        <rect class="cls-1" x="62.78" y="87.29" width="48.88" height="5.87" />
        <rect class="cls-1" x="62.78" y="102.65" width="48.88" height="5.87" />
        <rect class="cls-1" x="74.45" y="12.82" width="5.87" height="17.65" />
        <rect class="cls-1" x="74.45" y="34.55" width="5.87" height="17.65" />
        <rect class="cls-1" x="32.29" y="14.32" width="5.87" height="11.81" />
        <rect class="cls-1" x="40.98" y="14.32" width="5.87" height="7.66" />
        <rect class="cls-1" x="49.68" y="14.32" width="5.87" height="11.81" />
        <rect class="cls-1" x="49.68" y="29.85" width="5.87" height="11.81" />
        <rect class="cls-1" x="40.98" y="34" width="5.87" height="7.66" />
        <rect class="cls-1" x="32.28" y="29.85" width="5.87" height="11.81" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconSepa'
};
</script>

<style scoped>
.cls-1 {
  fill: currentColor;
}
</style>
