<template>
  <app-text-btn
    :class="{ 'link-btn': true, [`link-btn--${variant}`]: variant }"
    :variant="variant"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot></slot>
  </app-text-btn>
</template>

<script>
export default {
  name: 'AppLinkBtn',

  props: {
    variant: {
      type: String,
      default: 'highlighted',
      validator: (value) => ['highlighted', 'muted'].includes(value)
    }
  }
};
</script>

<style scoped lang="scss">
.link-btn {
  &--highlighted {
    font-weight: var(--font-weight-bold);
    color: var(--c-button-link);
  }

  &--muted {
    @include caption-font-style;
    letter-spacing: var(--button-letter-spacing);
    text-decoration: underline;
    color: var(--c-grey);
  }
}
</style>
