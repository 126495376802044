<template>
  <svg
    width="30px"
    height="30px"
    viewBox="0 0 30 30"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        id="path-file"
        d="M3.45964034,30 L26.5403597,30 C26.6820522,30.0004367 26.8174678,29.9407703 26.9138253,29.8354422 C27.0101828,29.7301141 27.0586987,29.5887256 27.0476282,29.4455039 L27.0476282,6.76751564 C27.0465983,6.62755888 26.9893556,6.49407224 26.8891068,6.39785318 L20.4689891,0.113591371 L20.4689891,0.113591371 L20.3897284,0.065374528 L20.3025416,0.00912154509 C20.2397004,-0.00304051503 20.1751572,-0.00304051503 20.1123159,0.00912154509 L3.46756642,0.00912154509 C3.33302565,0.00697349684 3.20315538,0.0591103659 3.10653403,0.154059246 C3.00991268,0.249008125 2.95445757,0.378988742 2.95237179,0.515398392 L2.95237179,29.4455039 C2.94130135,29.5887256 2.98981721,29.7301141 3.08617471,29.8354422 C3.18253222,29.9407703 3.31794784,30.0004367 3.45964034,30 Z M20.6557377,2.45901639 L24.7463642,6.38385034 L20.6557377,6.38385034 L20.6557377,2.45901639 Z M4.26771274,1.30937758 L19.4716332,1.30937758 L19.4716332,6.95073357 C19.4716332,7.22845032 19.692551,7.45358424 19.9650668,7.45358424 L25.7243612,7.45358424 L25.7243612,28.667597 L4.27542264,28.667597 L4.26771274,1.30937758 Z"
      />
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <mask fill="white">
        <use xlink:href="#path-file" />
      </mask>
      <use fill="currentColor" fill-rule="nonzero" xlink:href="#path-file" />
      <path
        d="M7.70263672,26 L7.70263672,23.3291016 L8.44482422,23.3291016 C9.34651693,23.3291016 10.0415039,23.1305339 10.5297852,22.7333984 C11.0180664,22.336263 11.262207,21.764974 11.262207,21.0195312 C11.262207,20.3098958 11.0400391,19.7727865 10.5957031,19.4082031 C10.1513672,19.0436198 9.48323568,18.8613281 8.59130859,18.8613281 L8.59130859,18.8613281 L6.53564453,18.8613281 L6.53564453,26 L7.70263672,26 Z M8.32275391,22.3476562 L7.70263672,22.3476562 L7.70263672,19.8378906 L8.51318359,19.8378906 C9.04378255,19.8378906 9.43440755,19.9388021 9.68505859,20.140625 C9.93570964,20.3424479 10.0610352,20.6484375 10.0610352,21.0585938 C10.0610352,21.5013021 9.9210612,21.8268229 9.64111328,22.0351562 C9.36116536,22.2434896 8.92171224,22.3476562 8.32275391,22.3476562 L8.32275391,22.3476562 Z M14.6850586,26 C15.9155273,26 16.8579102,25.6883138 17.512207,25.0649414 C18.1665039,24.441569 18.4936523,23.5406901 18.4936523,22.3623047 C18.4936523,21.2522786 18.1778971,20.391276 17.5463867,19.7792969 C16.9148763,19.1673177 16.0310872,18.8613281 14.8950195,18.8613281 L14.8950195,18.8613281 L12.6879883,18.8613281 L12.6879883,26 L14.6850586,26 Z M14.6850586,25.0185547 L13.8549805,25.0185547 L13.8549805,19.8378906 L14.8657227,19.8378906 C16.4640299,19.8378906 17.2631836,20.6923828 17.2631836,22.4013672 C17.2631836,24.1461589 16.4038086,25.0185547 14.6850586,25.0185547 L14.6850586,25.0185547 Z M21.184082,26 L21.184082,23.0703125 L23.8745117,23.0703125 L23.8745117,22.0791016 L21.184082,22.0791016 L21.184082,19.8476562 L24.0551758,19.8476562 L24.0551758,18.8613281 L20.0268555,18.8613281 L20.0268555,26 L21.184082,26 Z"
        fill="currentColor"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconFilePdf'
};
</script>
