import ProductType from '@/statics/productType';

export const PRODUCT_GROUPS = [
  [ProductType.DV_BILLING, ProductType.DV_FACTORING],
  [ProductType.VHV_FACTORING],
  [ProductType.FW_FACTORING],
  [ProductType.DAB_BILLING, ProductType.DAA_BILLING],
  [ProductType.FAS_BILLING],
  [ProductType.TPF_FACTORING]
];
