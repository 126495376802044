import formatBnetStatus from '@/helper/filter/formatBnetStatus';
import formatCreditorStatus from '@/helper/filter/formatCreditorStatus';
import formatCreditorVoucherType from '@/helper/filter/formatCreditorVoucherType';
import formatCurrency from './formatCurrency';
import formatDate from './formatDate';
import formatDateTime from './formatDateTime';
import formatDebtorStatus from '@/helper/filter/formatDebtorStatus';
import formatDebtorType from '@/helper/filter/formatDebtorType';
import formatLimitSelection from '@/helper/filter/formatLimitSelection';
import formatNumber from './formatNumber';
import formatPercentage from '@/helper/filter/formatPercentage';
import formatPeriod from '@/helper/filter/formatPeriod';
import formatPermanentDebtStatus from '@/helper/filter/formatPermanentDebtStatus';
import formatProduct from '@/helper/filter/formatProduct';
import formatReadStatus from '@/helper/filter/formatReadStatus';
import formatRole from '@/helper/filter/formatRole';
import formatSepaRequestStatus from '@/helper/filter/formatSepaRequestStatus';
import formatVoucherStatusToShow from './formatVoucherStatusToShow';
import formatSubStatus from './formatSubStatus';
import formatTime from './formatTime';
import formatTimePeriods from '@/helper/filter/formatTimePeriods';
import formatUserStatus from '@/helper/filter/formatUserStatus';
import formatVoucherType from './formatVoucherType';

export default (Vue) => {
  Vue.filter('formatBnetStatus', formatBnetStatus);
  Vue.filter('formatCreditorStatus', formatCreditorStatus);
  Vue.filter('formatCreditorVoucherType', formatCreditorVoucherType);
  Vue.filter('formatCurrency', formatCurrency);
  Vue.filter('formatDate', formatDate);
  Vue.filter('formatDateTime', formatDateTime);
  Vue.filter('formatDebtorStatus', formatDebtorStatus);
  Vue.filter('formatDebtorType', formatDebtorType);
  Vue.filter('formatLimitSelection', formatLimitSelection);
  Vue.filter('formatNumber', formatNumber);
  Vue.filter('formatPercentage', formatPercentage);
  Vue.filter('formatPeriod', formatPeriod);
  Vue.filter('formatPermanentDebtStatus', formatPermanentDebtStatus);
  Vue.filter('formatProduct', formatProduct);
  Vue.filter('formatReadStatus', formatReadStatus);
  Vue.filter('formatRole', formatRole);
  Vue.filter('formatSepaRequestStatus', formatSepaRequestStatus);
  Vue.filter('formatVoucherStatusToShow', formatVoucherStatusToShow);
  Vue.filter('formatSubStatus', formatSubStatus);
  Vue.filter('formatTime', formatTime);
  Vue.filter('formatTimePeriods', formatTimePeriods);
  Vue.filter('formatUserStatus', formatUserStatus);
  Vue.filter('formatVoucherType', formatVoucherType);
};
