<template>
  <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <g fill="currentColor">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          d="M24.48,24.92H21.24v-3.3A.5.5,0,0,0,21,21.2a.45.45,0,0,0-.25-.07h0a.53.53,0,0,0-.23.06.5.5,0,0,0-.29.44v3.8a.5.5,0,0,0,.29.45.63.63,0,0,0,.21.05h3.74a.51.51,0,0,0,.5-.5A.5.5,0,0,0,24.48,24.92Z"
        />
        <path d="M3.77,19.44h0l6.08,0h.38a.51.51,0,0,0,0-1h0l-6,0H3.77a.52.52,0,0,0,0,1Z" />
        <path
          d="M21.27,31.78a7.19,7.19,0,1,1,7.19-7.19A7.2,7.2,0,0,1,21.27,31.78Zm0-13.36a6.18,6.18,0,1,0,6.17,6.17A6.18,6.18,0,0,0,21.27,18.42Z"
        />
        <path
          d="M14.54,24.47H3.76a.51.51,0,1,0,0,1h0l10.82,0v-1C14.38,24.5,13.55,24.48,14.54,24.47Z"
        />
        <path d="M14.59,24.49v0h-.05Z" />
        <path d="M4.26,21.45h-.5a.51.51,0,1,0,0,1h0l11.38.09v-1L4.26,21.45Z" />
        <path d="M14.59,24.51v0l-.05,0C13.55,24.48,14.38,24.5,14.59,24.51Z" />
        <path
          d="M12.64,9a.46.46,0,0,0,.62-.27.44.44,0,0,0,0-.37A.47.47,0,0,0,13,8.06a4.23,4.23,0,0,0-5.53,2.6l0,.08h-.9a.49.49,0,0,0,0,1h.72v.13a4,4,0,0,0,0,.69l0,.14H6.53a.49.49,0,0,0-.48.48.48.48,0,0,0,.48.48h1l0,.08a4.22,4.22,0,0,0,3.86,2.53A4.32,4.32,0,0,0,13,16a.49.49,0,0,0-.35-.91,3.23,3.23,0,0,1-3.88-1.22l-.13-.2h3.08a.47.47,0,0,0,.48-.48.48.48,0,0,0-.48-.48H8.27l0-.1a3.7,3.7,0,0,1,0-.75v-.11h3.85a.49.49,0,1,0,0-1H8.45l.09-.17A3.24,3.24,0,0,1,12.64,9Z"
        />
        <polygon points="16.64 2.12 16.64 2.2 19.41 4.94 19.49 4.94 16.64 2.12" />
        <path
          d="M21.39,5.15,16.5.25l0,0A.63.63,0,0,0,16,0H.61A.63.63,0,0,0,0,.64v27a.64.64,0,0,0,.61.65l14.75-.05V27l-14.14,0V1.23h14.2V5.58a.59.59,0,0,0,.61.58h4.33V17.55h1.22v-12A.52.52,0,0,0,21.39,5.15ZM19.44,5H16.6V2.15l0,.05V2.12l2.85,2.82h-.08Z"
        />
        <polygon
          points="16.64 2.2 16.6 2.15 16.6 4.97 19.44 4.97 19.41 4.94 16.64 4.94 16.64 2.2"
        />
        <path d="M20.36,17.6h0S20.27,17.61,20.36,17.6Z" />
        <path d="M20.36,17.58Z" />
        <path d="M20.36,17.58h0S20.35,17.59,20.36,17.58Z" />
        <path d="M20.35,17.59h0S20.26,17.6,20.35,17.59Z" />
        <path d="M20.36,17.58h0S20.4,17.58,20.36,17.58Z" />
        <path d="M14.63,24.51l0,0v0Z" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconDelay'
};
</script>
