export const mapCreditorStatistic = (data) => ({
  annualTurnover: data.cashback,
  creditorLimit: data.creditorLimit
    ? {
        approvedLimit: data.creditorLimit.approvedLimit,
        availableLimit: data.creditorLimit.availableLimit
      }
    : null,
  oldestWorkQueue: data.oldestWorkQueue
    ? {
        id: data.oldestWorkQueue.identifier,
        debtor: data.oldestWorkQueue.debtor?.name ?? '',
        title: data.oldestWorkQueue.stateOfAffairs.title,
        vouchers: data.oldestWorkQueue.vouchers,
        updated: data.oldestWorkQueue.updated * 1000
      }
    : null,
  vouchersInDunningBlock: data.dunningBlock,
  invoices: data.invoices,
  overdueRate: data.paymentDeadlineDelayRate,
  debtorLimitRate: data.debtorLimitRate
});
