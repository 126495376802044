<template>
  <ValidationProvider v-slot="{ errors }" v-bind="validationProps">
    <slot></slot>
    <v-radio-group
      :id="fieldId"
      v-model="selection"
      :row="row"
      v-bind="$attrs"
      :hide-details="errors.length === 0"
      :error-messages="errors"
      :class="{ 'input--has-error': errors.length > 0 }"
      :style="cssVars"
    >
      <v-col
        v-if="label || $slots.label"
        cols="12"
        md="auto"
        class="body-1 text--text mr-12 px-0 py-md-0"
      >
        <slot name="label">{{ fieldLabelWithSupplement }}</slot>
      </v-col>
      <slot name="radio">
        <v-radio
          v-for="(item, index) in items"
          :id="item.id || item.label"
          :key="index"
          :label="item.label"
          :value="item.value"
          :class="{ 'my-2': !row }"
        />
      </slot>
    </v-radio-group>
  </ValidationProvider>
</template>

<script>
import FormValidationMixin from '@/mixins/FormValidationMixin';

export default {
  name: 'AppRadioGroup',

  mixins: [FormValidationMixin],

  props: {
    items: {
      type: Array,
      default: () => []
    },
    required: {
      type: Boolean,
      default: false
    },
    row: {
      type: Boolean,
      default: true
    },
    value: {
      type: [String, Number, Object],
      default: ''
    },
    mode: {
      type: String,
      default: 'lazy'
    },
    justify: {
      type: String,
      default: 'flex-start'
    }
  },

  computed: {
    selection: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    cssVars() {
      return {
        '--justify-content': this.justify
      };
    }
  },

  created() {
    if (this.value) {
      return;
    }

    const defaultItem = this.items.find((item) => item.isDefault);
    if (defaultItem) {
      this.selection = defaultItem.value;
    }
  }
};
</script>

<style scoped>
:root {
  --justify-content: flex-start;
}

>>> .theme--light.v-label {
  color: var(--c-text);
}

.v-input--radio-group.v-input--radio-group--row .v-radio {
  margin-right: 2rem;
}

.v-input--selection-controls {
  margin-top: 0;
}

>>> .v-input__slot {
  margin-bottom: 0;
}

.v-input--radio-group >>> .v-input--radio-group__input {
  justify-content: var(--justify-content);
}

>>> .v-messages {
  padding: 0.25rem 0;
}
</style>
