<template>
  <v-toolbar flat height="90px">
    <v-row align="center" class="mx-12">
      <v-col cols="auto">
        <router-link :to="{ name: routeName.ONBOARDING }">
          <partner-logos />
        </router-link>
      </v-col>
    </v-row>
  </v-toolbar>
</template>

<script>
import PartnerLogos from '@/shared/components/PartnerLogos';

export default {
  name: 'Toolbar',

  components: {
    PartnerLogos
  }
};
</script>
