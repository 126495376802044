import { UserManagementRole } from '@/statics/role';
import CreditorVoucherType from '@/modules/User/statics/creditorVoucherType';
import ReadStatus from '@/modules/User/statics/readStatus';
import FollowUpAction from '@/modules/User/statics/followUpAction';
import TutorialTag from '@/modules/User/statics/tutorialTag';

export default {
  user: {
    myCustomerId: 'Meine Kundennr.',
    viewMasterData: 'Stammdaten ansehen',
    employeeAdmissions: 'Mitarbeiter-Zugänge',
    changePassword: 'Passwort ändern',
    changeBack: 'Anmeldung rückgängig machen',
    admin: 'Admin',
    enableAdminView: 'Detail-Information anzeigen',
    disableAdminView: 'Detail-Information ausblenden',
    hintTerminated:
      'Ihr Zugang wurde gekündigt. Sie können Ihre Daten weiterhin einsehen und bearbeiten, jedoch keine neuen Belege oder Kunden erstellen.',
    updatesAvailable: 'Neue Inhalte verfügbar.',
    refreshNow: 'Jetzt neuladen',
    serviceTimes: 'Servicezeiten',
    oClock: 'Uhr'
  },

  masterData: {
    title: 'Ihre Stammdaten',
    subtitle: 'Sehen Sie Ihre Verträge, Zahlungen und Dokumente ein',
    myContract: 'Mein Vertrag',
    creditorVoucher: 'Abrechnung',
    creditorVoucherPl: 'Abrechnungen',
    creditorVouchers: {
      myCreditorVouchers: 'Meine Abrechnungen',
      readStatus: 'Lesestatus',
      readStatusOptions: {
        [ReadStatus.READ]: 'Gelesen',
        [ReadStatus.NEW]: 'Ungelesen'
      },
      creditorVoucherTypes: {
        [CreditorVoucherType.BILLING_REPORT]: 'Abrechnungsbericht',
        [CreditorVoucherType.CREDIT]: 'Gutschrift',
        [CreditorVoucherType.DIRECT_DEBIT]: 'Lastschriftavis',
        [CreditorVoucherType.INVOICE]: 'Rechnung',
        [CreditorVoucherType.MONTHLY_INVOICE]: 'Monatsrechnung',
        [CreditorVoucherType.PAYMENT]: 'Zahlungsavis',
        [CreditorVoucherType.PAYMENT_ADVICE]: 'Zahlungsavis',
        [CreditorVoucherType.ACCOUNTS_RECEIVABLE]: 'Auszahlung Ankauf',
        [CreditorVoucherType.REVERSED_TRANSACTION]: 'Rückbelastung Ankauf',
        [CreditorVoucherType.SET_UP_FEE]: 'Einrichtungsgebühr'
      },
      creditorVouchersCounted: '1 Abrechnung | {count} Abrechnungen',
      noData: 'Keine Abrechnungen vorhanden',
      downloadFilename: 'Abrechnungen-{date}.zip'
    },
    aboutMe: 'Über mich',
    extensions: 'Erweiterungen',
    generalInformation: 'Allgemeine Informationen',
    customerNumber: 'Kundennummer',
    contractNumber: 'Vertragsnummer',
    startOfContract: 'Vertragsbeginn',
    approvedLimit: 'Ankaufrahmen',
    increaseCreditorLimitForm: 'Antrag Erhöhung Ankaufrahmen',
    increaseCreditorLimitInfo:
      'Ihr Ankaufrahmen ist ausgeschöpft? Unter "Dokumente" können Sie einen Antrag zur Erhöhrung Ihres Ankaufrahmens herunterladen. Bei Fragen hierzu wenden Sie sich bitte an unseren Kundenservice.',
    infoChangeBankDetails: 'Information über Kontoänderung',
    notificationLetter: 'Notifikationsschreiben',
    dataProtectionRegulation: 'Hinweise zum Datenschutz',
    productSpecificDataProtectionRegulation: 'Produktbezogene Hinweise zum Datenschutz',
    contractObligations: 'Vertragspflichten',
    purchaseRules: 'Ankaufregeln',
    insuranceCoverageRules: 'Regeln für den Erhalt des Versicherungsschutzes',
    myBankDetails: 'Meine Bankverbindung',
    editProfile: 'Profil bearbeiten',
    inProgress: 'In Verarbeitung',
    doc2Portal: 'DOC2Portal',
    doc2PortalInfo:
      'Mit DOC2Portal können Sie einfach und bequem Ihre erstellten Rechnungen an das Portal übergeben. Dabei spielt es keine Rolle, ob Sie Ihre Rechnungen in Excel, Word oder einer anderen Software erstellen oder Ihre Rechnungen in einem eigenen System verwalten.',
    doc2PortalHelp:
      'Sie brauchen Unterstützung, um DOC2Portal zu verwenden? Dann melden Sie sich bei unserer Hotline. Die KollegInnen helfen Ihnen gerne weiter.',
    printerDriver: 'Druckertreiber',
    printerDriverInfo:
      'Nutzen Sie künftig nach der Rechnungserstellung einfach den angezeigten Drucker "DOC2Portal" und Ihre Rechnungen kommen automatisch bei uns an. Der Upload von PDFs direkt im Portal ist nicht mehr erforderlich.',
    downloadDriver: 'Laden Sie den Druckertreiber herunter',
    yourDriver: 'Ihr Druckertreiber',
    doc2PortalHints: {
      setup: {
        title: 'Einrichtung',
        text: 'Laden Sie sich einfach die Software im Bereich "DOC2Portal" herunter und installieren diese auf Ihrem Rechner. Folgen Sie hierbei den Installationsschritten, die Ihnen nach dem Öffnen der heruntergeladenen Datei angezeigt werden. Der Dateiname der Software darf für eine erfolgreiche Installation nicht verändert werden.'
      },
      usage: {
        title: 'Verwendung',
        text: 'Sobald Sie DOC2Portal erfolgreich installiert haben, können Sie DOC2Portal in jeder Anwendung wie einen Standarddrucker verwenden. Wenn Sie eine Rechnung an uns übergeben möchten, gehen Sie hierfür wie gewohnt auf Drucken. Anstatt Ihres Standarddruckers wählen Sie "DOC2Portal" aus. Wenn Sie nun den Druck ausführen, wird Ihre Rechnung automatisch an Ihr DV-Portal übergeben. Sie müssen also kein PDF mehr erstellen und hochladen. <br/><br/> Den Verarbeitungsstatus können Sie wie gewohnt hier im Portal verfolgen.'
      },
      systemRequirements: {
        title: 'Systemvoraussetzungen',
        text: 'DOC2Portal funktioniert auf allen Windows-Rechnern mit einem 64-Bit-Betriebssystem ab der Version Windows 7. Eine Nutzung mit anderen Betriebssystemen, wie z.B. macOS (Apple), ist leider nicht möglich.'
      }
    },
    letter2Post: 'LETTER2Post',
    noDriverEnabled:
      'Der Download des Druckertreibers wird individuell für Sie aktiviert. Bitte wenden Sie sich hierfür an unseren Support. Wir unterstützen Sie dann auch gleich bei den ersten Schritten.',
    webAppKey: 'Schnittstellen-Schlüssel',
    webAppInfo:
      'Nutzen Sie künftig nach der Rechnungserstellung in Ihrem System die automatische Übergabe der Belege in unser Portal. Dabei wird einmalig von Ihrem System eine Schnittstelle zu unserem Portal eingerichtet. Nach der Einrichtung werden die die von Ihnen gewünschten Belege automatisch in unser Portal übergegeben und der aktuelle Status des Belegs kann in Ihrem System automatisch übernommen werden.',
    furtherServices: 'Weiterhin können folgende Services genutzt werden',
    serviceTransferDebtor: 'Übergabe von Debitoren ans Portal',
    serviceCheckDebtorLimit: 'Bonität- und Limit-Prüfung von Debitoren',
    webAppHints: {
      setup: {
        title: 'Einrichtung',
        text: 'Zum Start der Installation werden Sie aufgefordert, einen "Key" hier im Portal zu generieren.'
      },
      usage: {
        title: 'Verwendung',
        text: 'Die technische Einrichtung der notwendigen Schnittstellen wird von Ihrem IT-Dienstleister umgesetzt. Der hier erstellte Key muss an Ihren IT-Dienstleister übermittelt werden und dient zur Identifikation bei der Einrichtung und Verwendung der Schnittstellen und Services.<br/> <br/>Die Dokumentation der Schnittstellen wird Ihnen auf Anfrage zur Verfügung gestellt.'
      }
    },
    yourKey: 'Ihr Schlüssel',
    generateKey: 'Schlüssel generieren'
  },

  settings: {
    title: 'Einstellungen',
    subtitle: 'Verwalten Sie Ihre Einstellungen, E-Mail-Benachrichtigungen und Zugänge',
    general: 'Allgemein',
    defaults: {
      title: 'Voreinstellungen',
      info: 'Erforderliche Angaben werden Ihren Voreinstellungen entsprechend für Sie vorausgefüllt.',
      tutorial: 'Video-Tutorials',
      tutorialProposals: 'Videovorschläge'
    },
    dataProtection: {
      title: 'Datenschutz',
      cookieSettings: 'Cookie-Einstellungen',
      cookiesEnabled: 'Cookies aktiviert',
      cookiesDisabled: 'Cookies deaktiviert',
      revoke: 'Entscheidung widerrufen'
    },
    emailNotifications: {
      title: 'E-Mail-Benachrichtigungen',
      addEmail: 'Weitere E-Mail hinzufügen',
      addedEmail: 'E-Mail hinzugefügt',
      unsubscribe: 'Benachrichtigungen abbstellen',
      confirmDeleteRequest:
        'Möchten Sie zukünftig keine Benachrichtigungen mehr an {email} versenden?',
      info: 'Erfassen Sie die E-Mail-Adressen und die Art der Benachrichtigungen, die diese jeweils erhalten sollen.',
      settings: {
        workQueue: {
          title: '@:workQueue.title',
          tooltip: 'Sie werden über den Eingang neuer wichtiger Entscheidungen informiert.'
        },
        bnetStatus: {
          title: 'Ereignisse',
          tooltip:
            'Sie erhalten Informationen über aktuelle Ereignisse zu Ihren Belegen oder Kunden.'
        },
        bnetPurchaseStatus: {
          title: 'Ankaufbarkeit',
          tooltip: 'Wir benachrichtigen Sie, wenn ein Beleg nicht angekauft werden kann.'
        }
      }
    },
    companyUsers: {
      title: 'Zugänge',
      info: 'In diesem Bereich können Sie weitere Benutzer anlegen und diesen Zugriff auf Ihr Portal geben. Hierbei stehen Ihnen auch unterschiedliche Berechtigungsrollen zur Verfügung, die Sie den Benutzern zuordnen können.',
      rolesInfo: {
        [UserManagementRole.COMPANY_USER_OBSERVER]:
          'Benutzer mit dieser Rolle können im Portal alles sehen, aber keinerlei Aktionen vornehmen. Das Herunterladen von Abrechnungen ist möglich. Diese Rolle ist beispielsweise geeignet für Ihren Steuerberater.',
        [UserManagementRole.COMPANY_USER_ASSISTANT]:
          'Benutzer mit dieser Rolle können Belege hochladen, Kunden anlegen und Entscheidungen treffen. Das Dashboard, Verträge und Abrechnungen werden nicht angezeigt.',
        [UserManagementRole.COMPANY_USER_MANAGEMENT]:
          'Benutzer mit dieser Rolle haben vollen Zugriff auf alle Portalfunktionen.'
      },
      inactive: 'Nicht aktiviert',
      addUser: 'Einen neuen Zugang einrichten',
      limitReached: 'Sie haben die maximale Anzahl an Zugängen erreicht.',
      passwordInfo:
        'Das Passwort für den ersten Login wird automatisch vergeben und dem Benutzer per E-Mail zugeschickt. Meldet sich der Benutzer das erste Mal an, kann ein individuelles Passwort vergeben werden.',
      deleteUser: 'Möchten Sie den Benutzer wirklich löschen?',
      resetPassword:
        'Möchten Sie dem Benutzer eine E-Mail zum Zurücksetzen seines Passwortes zusenden lassen?',
      sentMail:
        'Wir haben Ihre Anfrage erhalten und senden dem Benutzer eine E-Mail mit weiteren Anweisungen zum Zurücksetzen seines Passwortes.'
    }
  },

  workQueue: {
    title: 'Entscheidungen',
    openDecisionsCounted:
      'Sie haben keine offenen Entscheidungen | Sie haben 1 offene Entscheidung | Sie haben {count} offene Entscheidungen',
    chooseEntry: 'Wählen Sie einen Eintrag aus der Liste, um die Details anzuzeigen.',
    transactionKey: 'Akte',
    noDataTitle: 'Super Job!',
    noDataText: 'Sie haben keine offenen Entscheidungen',
    decisionReceived: 'Wir haben Ihre Rückmeldung erhalten. Vielen Dank!',
    addAttachment: 'Anhang hinzufügen',
    addMessage: 'Mitteilung hinzufügen',
    followUpInfo: {
      [FollowUpAction.CREDIT_ADVICE]:
        'Erzeugen Sie eine Gutschrift, um den Vorgang abzuschließen. | Erzeugen Sie zu jeder Rechnung eine Gutschrift, um den Vorgang abzuschließen.',
      [FollowUpAction.DIRECT_PAYMENT]:
        'Erfassen Sie eine Direktzahlung, um den Vorgang abzuschließen. | Erfassen Sie zu jeder Rechnung eine Direktzahlung, um den Vorgang abzuschließen.',
      [FollowUpAction.ABANDONMENT]:
        'Erfassen Sie einen Verzicht, um den Vorgang abzuschließen. | Erfassen Sie zu jeder Rechnung einen Verzicht, um den Vorgang abzuschließen.',
      [FollowUpAction.ANNULATION]:
        'Erfassen Sie eine Stornierung, um den Vorgang abzuschließen. | Erfassen Sie zu jeder Rechnung eine Stornierung, um den Vorgang abzuschließen.',
      [FollowUpAction.EXPOSURE_TYPE]:
        'Erfassen Sie den Forderungsgrund zu Ihrer Rechnung, um den Vorgang abzuschließen. | Erfassen Sie zu jeder Rechnung den Forderungsgrund, um den Vorgang abzuschließen.',
      [FollowUpAction.VOUCHER_UPLOAD]:
        'Laden Sie die entsprechende Rechnung hoch, um den Vorgang abzuschließen. | Laden Sie zu jedem Beleg die entsprechende Rechnung hoch, um den Vorgang abzuschließen.',
      default: 'Bitte bestätigen Sie Ihre Entscheidung, um den Vorgang abzuschließen.'
    },
    followUpInfoSend: 'Ihre Entscheidung wird erst mit der anschließenden Bestätigung übermittelt.',
    checkAnswer: 'Entscheidung prüfen',
    confirmed: 'Entscheidung gesendet',
    yourAnswer: 'Ihre Antwort',
    message: 'Mitteilung',
    messageFromCreditor: 'Ihre Mitteilung',
    messageFromSap: 'Hinweis zum Vorgang',
    furtherDocumentsToFile: 'Weitere Dokumente zum Vorgang',
    submit: 'Entscheidung senden',
    isDone:
      'Der Vorgang wurde bearbeitet. Alle Einzelheiten zum Vorgang können Sie dem Beleg in der Beleghistorie entnehmen.',
    isSent: 'Ihre Rückmeldung wird bearbeitet.',
    counterVouchersCounted:
      'Sie haben folgenden Beleg erstellt | Sie haben folgende Belege erstellt',
    uploadedAttachment: 'Sie haben folgenden Anhang eingereicht',
    submitError:
      'Es ist ein Systemfehler beim Übertragen Ihrer Rückmeldung aufgetreten. Wir kümmern uns sofort darum.',
    previewNotAvailable: 'Vorschau nicht verfügbar'
  },

  notifications: {
    title: 'Benachrichtigungen',
    showMore: 'Weitere anzeigen',
    allNotifications: 'Alle Benachrichtigungen',
    noData: 'Keine Benachrichtigungen vorhanden.',
    selected: 'ausgewählt',
    selectAll: 'Alle wählen',
    readSelected: 'Gelesen markieren',
    youReadAll: 'Sie haben alle gelesen',
    selectedNotificationsWereRead: 'Sie haben die ausgewählten Nachrichten als gelesen markiert'
  },

  helpCenter: {
    title: 'Willkommen im Hilfe-Center',
    subtitle:
      'Hier erhalten Sie hilfreiche Informationen und Anleitungen zu den Funktionen des Portals',
    helpCenter: 'Hilfe-Center',
    tutorials: {
      title: 'Willkommen in unserem Video-Center',
      subtitle:
        'Lassen Sie sich von unseren hilfreichen Tutorials zeigen, wie Sie die Funktionen des Portals optimal nutzen können',
      videoTutorials: 'Video-Hilfe',
      learnMore: 'Worüber möchten Sie mehr erfahren?',
      watchVideo: 'Video ansehen',
      recommendedVideosCounted:
        'Wenn Sie zusätzliche Hilfe benötigen, empfehlen wir Ihnen folgendes Video | Wenn Sie zusätzliche Hilfe benötigen, empfehlen wir Ihnen folgende Videos',
      dontShowAgain: 'Nicht mehr anzeigen',
      tags: {
        [TutorialTag.OVERVIEW]: 'Überblick',
        [TutorialTag.VOUCHERS]: 'Belege',
        [TutorialTag.DEBTORS]: 'Kunden',
        [TutorialTag.PERMANENT_DEBTS]: 'Regelmäßige Einzüge',
        [TutorialTag.MASTER_DATA]: 'Stammdaten',
        [TutorialTag.USER_INFO]: 'Benachrichtigungen und Entscheidungen'
      }
    },
    faq: {
      faq: 'FAQ',
      faqHeadline: 'FAQ - Häufig gestellte Fragen',
      infoHtml:
        'Für Fragen stehen Ihnen die verlinkten Hilfeseiten unserer Produktpartner zur Verfügung. <br />Dort finden Sie Antworten auf die häufigsten Fragen (FAQ).',
      links: {
        debtors: 'Debitorenmanagement',
        encashment: 'Inkassobearbeitung',
        factoring: 'Factoring'
      }
    }
  }
};
