<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.98 28.97">
    <g fill="currentColor">
      <g>
        <path
          d="M22.9,20.52H22l.25-.67a.5.5,0,1,0-.94-.35l-.38,1H19.29l.22-.58a.51.51,0,0,0-1-.35l-.34.93H16.8a.51.51,0,0,0,0,1h1l-.55,1.5H16a.5.5,0,0,0-.5.5.51.51,0,0,0,.51.5h.95l-.25.67a.51.51,0,0,0,.3.65.47.47,0,0,0,.17,0,.5.5,0,0,0,.47-.33l.39-1h1.58l-.22.58a.5.5,0,0,0,.3.64.57.57,0,0,0,.18,0A.51.51,0,0,0,20.3,25l.34-.93h1.42a.51.51,0,0,0,0-1H21l.55-1.5h1.34a.5.5,0,1,0,0-1Zm-3,2.51H18.36l.56-1.5H20.5Z"
        />
        <path
          d="M20.43,15.71v-10a.43.43,0,0,0-.14-.32L14.85.1l-.07,0-.07,0H.44A.43.43,0,0,0,.13.13.44.44,0,0,0,0,.44V25a.44.44,0,0,0,.11.33.45.45,0,0,0,.32.14h13a6.67,6.67,0,1,0,7-9.72ZM15,2.08l3.47,3.33H15ZM1.12,24.3V1.11H14V5.89a.42.42,0,0,0,.42.43h4.89v9.29a6.68,6.68,0,0,0-6.37,8.69Zm18.19,3.48a5.48,5.48,0,0,1-4.5-2.35,5.43,5.43,0,0,1-1-3.14,5.49,5.49,0,0,1,5.49-5.49,5.91,5.91,0,0,1,1.12.11,5.49,5.49,0,0,1-1.12,10.87Z"
        />
        <path d="M3.47,4.76h7.86a.59.59,0,0,0,0-1.18H3.47a.59.59,0,1,0,0,1.18Z" />
        <path d="M3.47,7.58h7.86a.59.59,0,0,0,0-1.18H3.47a.59.59,0,0,0,0,1.18Z" />
        <path d="M3.47,10.4H17.12a.59.59,0,0,0,0-1.18H3.47a.59.59,0,0,0,0,1.18Z" />
        <path d="M3.47,13.22H17.12a.59.59,0,1,0,0-1.18H3.47a.59.59,0,0,0,0,1.18Z" />
        <path d="M3.47,16h10a.59.59,0,1,0,0-1.18h-10a.59.59,0,0,0,0,1.18Z" />
        <path d="M11.33,17.68H3.47a.59.59,0,1,0,0,1.18h7.86a.59.59,0,1,0,0-1.18Z" />
        <path d="M10.21,20.5H3.47a.59.59,0,1,0,0,1.18h6.74a.59.59,0,1,0,0-1.18Z" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconVoucherNumber'
};
</script>
