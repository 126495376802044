<template>
  <div :class="{ 'app-header': $vuetify.breakpoint.mdAndUp, 'gradient-diagonal--primary': dark }">
    <v-slide-x-reverse-transition mode="out-in">
      <v-row
        v-if="dark"
        transition="slide-x-reverse"
        align="center"
        mode="out-in"
        class="flex-grow-1 mx-12"
      >
        <v-col md="6" cols="12" class="text-center text-md-left">
          <h1 class="background-text--text">{{ $t('onboarding.onboardingProcess') }}</h1>
          <h1 class="background-text--text">
            {{ $t('for') }}
            {{ productName }}
          </h1>
        </v-col>
        <v-col md="6" cols="12">
          <v-img src="@/onboarding/assets/header.png" max-height="350px" contain />
        </v-col>
      </v-row>

      <v-row
        v-else
        transition="slide-x-reverse"
        mode="out-in"
        class="flex-grow-1 mx-12 mt-4 mb-8 align-self-start"
      >
        <v-col cols="12" md="6" class="text-center text-md-left">
          <h2 class="header__title">
            {{ $t(content.title, { productName: content.product }) }}
          </h2>
          <p class="mt-4">
            {{ $t(content.subtitle) }}
          </p>
        </v-col>
        <template v-if="$vuetify.breakpoint.mdAndUp">
          <div class="header__wrapper">
            <v-img src="@/onboarding/assets/header-background.svg" position="bottom left" />
          </div>
          <div class="header__wrapper">
            <v-img src="@/onboarding/assets/header.png" position="bottom left" max-height="330px" />
          </div>
        </template>
      </v-row>
    </v-slide-x-reverse-transition>
  </div>
</template>

<script>
import OnboardingProductMixin from '@/mixins/OnboardingProductMixin';

export default {
  name: 'AppHeader',

  mixins: [OnboardingProductMixin],

  props: {
    dark: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    content() {
      if (this.$route.name === this.routeName.ONBOARDING) {
        return {
          title: 'onboarding.titleLandingPage',
          subtitle: '',
          product: this.productGroupNames
        };
      }

      return {
        title: 'onboarding.titleRequest',
        subtitle: 'onboarding.subtitleRequest',
        product: this.productName
      };
    },
    productGroupNames() {
      return this.productGroup.reduce((string, product, index) => {
        let productNames = string + product.productName;

        if (this.productGroup.length > 2 && this.productGroup.length > index + 2) {
          productNames += `, `;
          return productNames;
        }

        if (this.productGroup.length > 1 && this.productGroup.length > index + 1) {
          productNames += ` ${this.$t('and')} `;
          return productNames;
        }

        return productNames;
      }, '');
    }
  }
};
</script>

<style scoped lang="scss">
.app-header {
  display: flex;
  align-items: center;
  min-height: 250px;
}

.header {
  &__title {
    hyphens: initial !important;
    font-weight: var(--font-weight-semi-bold);
  }

  &__wrapper {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 50vw;
    width: 500px;
    z-index: 5;
  }
}

.v-image {
  max-height: 380px;
}
</style>
