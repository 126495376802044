<template>
  <ValidationProvider v-slot="{ errors }" v-bind="validationProps" ref="fieldRef">
    <v-text-field
      :id="fieldId"
      v-model="number"
      outlined
      :label="fieldLabelWithSupplement"
      :placeholder="fieldPlaceholder"
      :error-messages="errors"
      v-bind="$attrs"
      :append-icon="appendIcon"
      :prepend-inner-icon="prependInnerIcon"
      :hide-details="errors.length === 0"
      data-testid="app-number-field__input"
      :class="{
        'input--align-right': alignRight,
        'input--has-error': errors.length > 0,
        'input--prepend-inner': prependInnerIcon
      }"
      @blur="formatAmountToGermanLocale"
    >
      <template v-if="appendFunction" #append>
        <v-icon @click="executeCallback(appendFunction)">{{ appendIcon }}</v-icon>
      </template>

      <template v-if="prependInnerFunction" #prepend-inner>
        <v-icon @click="executeCallback(prependInnerFunction)">{{ prependInnerIcon }}</v-icon>
      </template>
    </v-text-field>
  </ValidationProvider>
</template>

<script>
import FormValidationMixin from '@/mixins/FormValidationMixin';
import numberToCent from '@/helper/currency';

export default {
  name: 'AppNumberField',

  data: () => ({
    number: null
  }),

  mixins: [FormValidationMixin],

  props: {
    alignRight: {
      type: Boolean,
      default: false
    },
    appendFunction: {
      type: Function,
      default: null
    },
    appendIcon: {
      type: String,
      default: ''
    },
    prependInnerFunction: {
      type: Function,
      default: null
    },
    prependInnerIcon: {
      type: String,
      default: ''
    }
  },

  computed: {
    valueIsNaN() {
      return (
        this.value === '' || this.value === undefined || this.value === null || isNaN(this.value)
      );
    },
    validationRules() {
      let rules = 'currency|';

      const minValueRules = ['min_currency', 'min_equal_currency'];

      if (!minValueRules.some((rule) => this.rules.includes(rule))) {
        rules += 'min_currency:0|';
      }

      if (this.rules) {
        rules += `${this.rules}|`;
      }

      if (this.required) {
        rules = 'required|' + rules;
      }
      return rules;
    }
  },

  methods: {
    executeCallback(callback) {
      if (callback === null) {
        return;
      }

      callback();
    },

    formatAmountToGermanLocale() {
      if (this.value === '') {
        this.number = '';
        return;
      }

      if (this.valueIsNaN) {
        return;
      }

      this.number = Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(this.value / 100);
    }
  },

  watch: {
    number(number) {
      if (number === '') {
        this.$emit('input', '');
        return;
      }
      this.$emit('input', numberToCent(number));
    },
    value() {
      // checks if value is manipulated from outside (e.g. insert leftover amount via app-btn)
      if (!this.number || this.value !== numberToCent(this.number)) {
        this.formatAmountToGermanLocale();
      }
    }
  },
  created() {
    if (!this.valueIsNaN) {
      this.formatAmountToGermanLocale();
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@/styles/mixins/textFieldStyle';

::v-deep.v-text-field {
  @include AppTextFieldMixin;
}
</style>
