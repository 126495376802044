<template>
  <app-icon-btn icon="mdi-close" class="ma-2" v-bind="$attrs" v-on="$listeners" />
</template>

<script>
export default {
  name: 'CloseBtnMobile'
};
</script>

<style scoped>
.v-btn {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
