import ProductType from '@/statics/productType';
import RouteName from '@/statics/routeName';
import VoucherStatusToShow, {
  BASIC_VOUCHER_STATUS_TO_SHOW_GROUPS
} from '@/statics/voucherStatusToShow';
import i18n from '@/helper/i18n';
import sortObjectProperties from '@/helper/sortObjectProperties';
import { ASSET_CONSULTANT_NUMBER, TURNOVER } from '@/helper/createCreditorFactory';
import { BASIC_CREDITOR_VOUCHER_TYPE_GROUPS } from '@/modules/User/statics/creditorVoucherType';
import { TPF_VOUCHER_SUB_STATUS_FILTER_OPTIONS } from '@/statics/voucherSubStatus';
import { BASIC_VOUCHER_TYPE_GROUPS, VoucherType } from '@/statics/voucherType';
import { FACTORING_PERMISSIONS } from '@/statics/productPermissions';
import {
  IMPRINT_TEAMFAKTOR,
  PRIVACY_POLICY_TEAMFAKTOR,
  PRODUCT_INFO_TEAMFAKTOR,
  SERVICE_HOTLINE_TEAMFAKTOR,
  SERVICE_MAIL_TRANSPOREON
} from '@/statics/customerServiceInformation';

const PRODUCT_NAME = 'Transporeon-Factoring';

const TPF_FACTORING_CONFIG = {
  type: ProductType.TPF_FACTORING,
  productName: PRODUCT_NAME,
  logo: require('@/assets/transporeon-logo.svg'),
  logoSmall: require('@/assets/transporeon-logo-small.svg'),
  partnerLogoSmall: require('@/assets/teamfaktor-logo-small.svg'),
  favIcon: '/favicons/favicon-Transporeon.ico',
  theme: 'transporeon' /* matches key of $theme-transporeon in styles/themes/index.scss */,
  background: '/assets/images/background-transporeon.png',
  placeLogoInHeader: true,
  serviceHotline: SERVICE_HOTLINE_TEAMFAKTOR,
  serviceMail: SERVICE_MAIL_TRANSPOREON,
  infoUrl: PRODUCT_INFO_TEAMFAKTOR,
  privacyPolicyUrl: PRIVACY_POLICY_TEAMFAKTOR,
  imprintUrl: IMPRINT_TEAMFAKTOR,
  withPurchaseAssurance: false,

  voucherStatusToShowGroups: {
    ...BASIC_VOUCHER_STATUS_TO_SHOW_GROUPS,
    open: [
      VoucherStatusToShow.TPF_IN_REVIEW,
      VoucherStatusToShow.TPF_ACCEPTED,
      VoucherStatusToShow.TPF_NOT_PURCHASED
    ],
    closed: [VoucherStatusToShow.TPF_BALANCED]
  },
  voucherSubStatusFilter: TPF_VOUCHER_SUB_STATUS_FILTER_OPTIONS,
  voucherTypeGroups: sortObjectProperties(
    {
      ...BASIC_VOUCHER_TYPE_GROUPS,
      // additionally support ePOD voucher types
      [VoucherType.EPOD_INVOICE]: [VoucherType.EPOD_INVOICE],
      [VoucherType.EPOD_SETTLEMENT]: [VoucherType.EPOD_SETTLEMENT],
      // no support for usual invoice types
      [VoucherType.INVOICE]: [],
      [VoucherType.PERMANENT_DEBT_INVOICE]: []
    },
    [VoucherType.EPOD_INVOICE, VoucherType.EPOD_SETTLEMENT]
  ),
  creditorVoucherTypeGroups: BASIC_CREDITOR_VOUCHER_TYPE_GROUPS,

  permissions: {
    ...FACTORING_PERMISSIONS,
    dashboard: {
      ...FACTORING_PERMISSIONS.dashboard,
      balancingChart: false,
      daysOverdueChart: false,
      daysSalesOutstandingChart: false,
      debtorLimitRate: false,
      delay: true,
      dispute: true,
      dunningBlock: false,
      overdueRate: false,
      turnoverProgressionChart: false,
      turnoverVolumeChart: false,
      showOpenDunningTypeInvoices: true
    },
    debtorDetails: {
      ...FACTORING_PERMISSIONS.debtorDetails,
      createPermanentDebt: false,
      editPaymentData: false,
      increaseLimit: false,
      refreshLimit: false,
      showPaymentData: false,
      tabLimit: false,
      tabPermanentDebts: false,
      uploadInvoice: false
    },
    debtors: {
      ...FACTORING_PERMISSIONS.debtors,
      createDebtor: false,
      showLimit: false
    },
    helpCenter: {
      ...FACTORING_PERMISSIONS.helpCenter,
      tabFAQ: false
    },
    masterData: {
      ...FACTORING_PERMISSIONS.masterData,
      serviceFeesWithPurchaseFees: false
    },
    navigation: {
      ...FACTORING_PERMISSIONS.navigation,
      permanentDebts: false,
      unprocessed: false
    },
    permanentDebts: {
      ...FACTORING_PERMISSIONS.permanentDebts,
      create: false
    },
    unprocessedDetails: {
      ...FACTORING_PERMISSIONS.unprocessedDetails,
      reupload: false
    },
    voucherDetails: {
      ...FACTORING_PERMISSIONS.voucherDetails,
      annulation: false,
      cancelDunningBlock: false,
      closeDispute: true,
      closeDelay: true,
      creditAdvice: false,
      openDispute: true,
      openDelay: true,
      setDunningBlock: false
    },
    vouchers: {
      ...FACTORING_PERMISSIONS.vouchers,
      upload: false
    }
  },

  onboarding: {
    maxCreditorLimitInCent: 15000000,
    useCalculatedCreditorLimit: true,
    rules: {
      [ASSET_CONSULTANT_NUMBER]: 'required|digits:6',
      [TURNOVER]: 'required|min_equal_currency:10000000'
    }
  },

  faq: [],

  documents: {
    masterData: [
      {
        title: i18n.t('masterData.increaseCreditorLimitForm'),
        path: '/assets/masterData/TPF/TF_Antrag Erhöhung Ankaufrahmen Transporeon-Factoring_2022-11.pdf'
      },
      {
        title: i18n.t('masterData.infoChangeBankDetails'),
        path: '/assets/masterData/TPF/TF_Vertragsergänzungen Transporeon Factoring_Änderung Bankverbindung_2022-11.pdf'
      }
    ],

    sepa: {
      template: {
        title: i18n.t('permanentDebts.pdf.sepaTemplate'),
        path: '/assets/sepa/Vorlage_Einzugsermächtigung.pdf'
      }
    },

    upload: {}
  },

  translations: {
    routes: {
      [RouteName.VOUCHERS]: 'Aufträge',
      [RouteName.DEBTORS]: 'Auftraggeber'
    },
    vouchersCounted: '1 Auftrag | {count} Aufträge',
    debtorsCounted: '{count} Auftraggeber',
    debtor: 'Auftraggeber',
    companyOrName: 'Firmenname',
    voucherDetails: 'Details',
    dashboard: {
      vouchersInDunningBlockCounted: 'Auftrag im Mahnstopp | Aufträge im Mahnstopp',
      vouchersInDisputeBlockCounted: 'Reklamierter Auftrag | Reklamierte Aufträge',
      vouchersInDelayBlockCounted: 'Auftrag im Verzug | Aufträge im Verzug',
      openInvoices: 'Offene Aufträge',
      turnoverVolumeChart: 'Umsatzentwicklung pro Jahr',
      turnoverProgressionChart: 'Umsatzentwicklung pro Monat'
    },
    debtors: {
      title: 'Ihre Auftraggeber',
      subtitle: 'Hier finden Sie einen Überblick über Ihre Auftraggeber',
      approvedCreditorLimit: 'Mein Ankaufbetrag',
      availableCreditorLimit: 'Verfügbarer Ankaufbetrag',
      customerId: 'Auftraggebernr.',
      customDebtorNumber: 'Auftraggebernr.',
      paymentMethod: 'Zahlungsmethode',
      createDebtor: 'Auftraggeber hinzufügen',
      dontCreateDebtor: 'Auftraggeber nicht hinzufügen',
      debtorCreated: 'Auftraggeber hinzugefügt',
      linkOptions: {
        closed: 'Ausgeglichene'
      }
    },
    vouchers: {
      title: 'Aufträge',
      subtitle: 'Hier finden Sie einen Überblick über Ihre Aufträge',
      customNo: 'Auftragsnr.',
      customNumber: 'Auftragsnr.',
      voucherDate: 'Datum',
      dueDate: 'Frist',
      paymentDeadline: 'Frist'
    },
    historyEvents: {
      factoring: {
        purchaseStatusWasMarkedAsUnverified: 'Der Auftrag wurde gebucht',
        purchaseStatusWasMarkedAsPurchasable: 'Limit nicht ausreichend um Auftrag anzukaufen',
        purchaseStatusWasMarkedAsInReview: 'Ankauf wird geprüft',
        voucherWasPurchased: 'Der Autrag wurde angekauft',
        voucherWasDisclosed: 'Die Abtretung des Auftrags wurde offengelegt',
        voucherWasPurchasedLater: 'Der Autrag wurde nachgekauft',
        purchaseStatusWasMarkedAsManualPurchaseList: 'Der Autrag wurde angekauft',
        purchaseStatusWasMarkedAsCountedBack: 'Der Auftrag wurde zurückgerechnet',
        purchaseStatusWasMarkedAsDisclosed: 'Die Abtretung des Auftrags wurde offengelegt',
        voucherWasIncomplete: 'Kein Ankauf - der Auftrag ist unvollständig',
        voucherDateInFuture: 'Kein Ankauf - das Auftragsdatum liegt in der Zukunft',
        voucherDateOfSupplyBeforeInvoiceDate:
          'Kein Ankauf - das Leistungsdatum liegt mehr als 30 Tage vor dem Auftragsdatum',
        duplicatedVoucherNumberWasFound: 'Kein Ankauf – die Auftragsnummer ist bereits vorhanden'
      }
    }
  }
};

export default TPF_FACTORING_CONFIG;
