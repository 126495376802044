<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 29 27"
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 1.5;
    "
  >
    <g transform="matrix(0.966986,0,0,0.950855,-0.0358212,-1.39113)">
      <g>
        <g transform="matrix(1.04582,0,0,1.06356,0.0920898,1.55297)">
          <path
            d="M5.876,5.471L0.337,9.179L1.177,10.433L5.876,7.287L5.876,5.471ZM9.625,2.962L12.339,2.962L14.276,1.665L27.376,10.433L28.215,9.179L14.691,0.126L14.561,0.058L14.436,0.02L14.31,0.003L14.217,0.005L14.11,0.021L13.991,0.058L13.862,0.126L9.625,2.962Z"
            style="fill: currentColor"
          />
        </g>
        <g transform="matrix(1.04582,0,0,1.06356,0.0920898,1.55297)">
          <path
            d="M16.688,17.345L14.691,18.701L14.041,18.699L11.997,17.33L16.688,17.345ZM8.516,15L9.216,15.468L19.157,15.5C19.403,15.505 19.632,15.377 19.757,15.166C19.882,14.956 19.884,14.694 19.762,14.481C19.641,14.268 19.413,14.136 19.168,14.137L9.197,14.105C9.175,14.104 9.154,14.104 9.133,14.105C8.889,14.117 8.67,14.257 8.558,14.474C8.473,14.639 8.46,14.829 8.516,15ZM4.861,12.553L4.861,2.885C4.861,2.831 4.869,2.777 4.882,2.725C4.885,2.711 4.889,2.697 4.893,2.683L4.893,2.661L4.925,2.597C4.931,2.587 4.939,2.576 4.946,2.565C4.949,2.555 4.953,2.544 4.957,2.533C4.963,2.523 4.97,2.512 4.978,2.502C4.981,2.494 4.985,2.487 4.989,2.48C4.999,2.469 5.009,2.459 5.021,2.448C5.024,2.441 5.027,2.434 5.031,2.427L5.042,2.416C5.157,2.288 5.317,2.208 5.489,2.193L16.451,2.193C16.641,2.191 16.822,2.268 16.952,2.406L23.088,8.975C23.34,9.082 23.504,9.328 23.504,9.602L23.504,12.718L22.14,13.644L22.14,10.284L17.549,10.273C16.515,10.273 15.844,9.333 15.844,8.389L15.844,3.555L6.224,3.555L6.224,13.466L4.861,12.553ZM9.133,10.901C8.889,10.912 8.67,11.053 8.558,11.269C8.446,11.486 8.458,11.746 8.59,11.951C8.722,12.156 8.953,12.275 9.197,12.263L13.149,12.285C13.394,12.29 13.623,12.162 13.749,11.952C13.874,11.741 13.876,11.478 13.754,11.265C13.632,11.052 13.405,10.921 13.16,10.922L9.133,10.901ZM17.329,8.802C17.335,8.81 17.342,8.818 17.348,8.826L17.347,8.824C17.341,8.817 17.335,8.81 17.329,8.802ZM17.407,8.882C17.411,8.885 17.414,8.887 17.418,8.89C17.414,8.887 17.411,8.885 17.407,8.882ZM17.402,8.878L17.397,8.874C17.398,8.875 17.4,8.876 17.402,8.878ZM17.393,8.871C17.378,8.858 17.364,8.844 17.35,8.828C17.364,8.844 17.38,8.859 17.395,8.872L17.393,8.871ZM17.326,8.798C17.283,8.74 17.247,8.666 17.227,8.579L17.226,8.576C17.247,8.665 17.283,8.741 17.327,8.8L17.326,8.798ZM17.225,8.57L17.226,8.573C17.215,8.524 17.208,8.472 17.208,8.416L17.208,4.674L21.171,8.921L17.549,8.911C17.474,8.863 17.398,8.815 17.323,8.766L17.208,8.389L17.208,8.416C17.208,8.47 17.214,8.522 17.225,8.57Z"
            style="fill: currentColor"
          />
        </g>
        <g transform="matrix(1.28864,0,0,1.1992,-0.977314,10.2167)">
          <path
            d="M23.388,1.472L12.492,9.554L1.444,1.472L1.444,15.687L23.388,15.687L23.388,1.472Z"
            style="fill: none"
          />
          <path
            d="M23.388,1.472L12.492,9.554L1.444,1.472L1.444,15.687L23.388,15.687L23.388,1.472Z"
            style="fill: none; stroke: currentColor; stroke-width: 1.26px"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconMessage'
};
</script>
