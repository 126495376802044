<template>
  <svg
    width="30px"
    height="30px"
    viewBox="0 0 30 30"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g fill="currentColor" fill-rule="nonzero">
        <path
          d="M19.5426754,0.543774386 C23.3512239,0.543774386 27.0172913,2.03042559 29.7647705,4.77790484 C30.0784098,5.09154413 30.0784098,5.6000539 29.7647705,5.91369319 C29.4511312,6.22733248 28.9426215,6.22733248 28.6289822,5.91369319 C26.185829,3.47053997 22.9294437,2.15002168 19.5426754,2.15002168 C16.155907,2.15002168 12.8995218,3.47053997 10.4563686,5.91369319 C8.71601976,7.65404199 7.54685637,9.80630808 7.01709761,12.119681 L18.3379899,12.1201701 C18.8554683,12.1201701 19.2749675,12.5396693 19.2749675,13.0571477 C19.2749675,13.5746261 18.8554683,13.9941253 18.3379899,13.9941253 L6.73143932,13.9941982 C6.69018623,14.5253245 6.68164365,15.0611482 6.7065685,15.5988866 C6.74445761,16.416321 6.85911213,17.2229947 7.04761679,18.0094297 L18.3379899,18.0097435 C18.8554683,18.0097435 19.2749675,18.4292427 19.2749675,18.9467211 C19.2749675,19.4641995 18.8554683,19.8836987 18.3379899,19.8836987 L7.65281481,19.8836931 C8.28876362,21.4328543 9.23194086,22.8618791 10.4563686,24.0863068 C15.4745972,29.1045355 23.6107535,29.1045355 28.6289822,24.0863068 C28.9426215,23.7726675 29.4511312,23.7726675 29.7647705,24.0863068 C30.0784098,24.3999461 30.0784098,24.9084559 29.7647705,25.2220952 C24.1192633,30.8676024 14.9660875,30.8676024 9.32058021,25.2220952 C7.78439647,23.6859114 6.64125697,21.8618649 5.9324887,19.8835235 L0.936977586,19.8836987 C0.419499154,19.8836987 6.33728305e-17,19.4641995 0,18.9467211 C-6.33728305e-17,18.4292427 0.419499154,18.0097435 0.936977586,18.0097435 L5.40117731,18.0096987 C5.23907125,17.2441978 5.13865418,16.4631028 5.10204389,15.6732583 C5.07597973,15.11094 5.08249407,14.5503986 5.12089447,13.9941734 L0.936977586,13.9941253 C0.419499154,13.9941253 6.33728305e-17,13.5746261 0,13.0571477 C-6.33728305e-17,12.5396693 0.419499154,12.1201701 0.936977586,12.1201701 L5.37405457,12.1196815 C5.92836391,9.38081089 7.27346787,6.82501717 9.32058021,4.77790484 C12.0680595,2.03042559 15.7341268,0.543774386 19.5426754,0.543774386 Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconEuro'
};
</script>
