/* eslint-disable prettier/prettier */ // prevent long-lines warning
import RouteName from '@/statics/routeName';
import Router from 'vue-router';
import Vue from 'vue';
import qs from 'qs';
import reloadOnError from '@/helper/reloadOnError';
import { ADMIN_TOKEN_KEY, AUTH_TOKEN_KEY } from '@/plugins/vue-auth';
import { CREDITOR_ROLES, Role } from '@/statics/role';

Vue.use(Router);

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/onboarding',
      name: RouteName.ONBOARDING,
      component: () =>
        import('@/onboarding/views/LandingPage' /* webpackChunkName: "Onboarding" */),
      meta: {
        title: 'Antragsstrecke'
      }
    },
    {
      path: '/onboarding/request/:type',
      name: RouteName.ONBOARDING_REQUEST,
      component: () => import('@/onboarding/views/Request.vue' /* webpackChunkName: "Request" */),
      meta: {
        title: 'Produktanfrage'
      }
    },
    {
      path: '/onboarding/register/:type',
      name: RouteName.ONBOARDING_REGISTRATION,
      component: () =>
        import('@/onboarding/views/Registration.vue' /* webpackChunkName: "Registration" */),
      meta: {
        title: 'Produktantrag'
      }
    },
    {
      path: '/login',
      name: RouteName.LOGIN,
      component: () =>
        import('@/modules/Authentication/views/Login.vue' /* webpackChunkName: "Login" */),
      meta: {
        title: 'Login',
        auth: false
      }
    },
    {
      path: '/login/:userId/reset-password/:requestId',
      name: RouteName.RESET_PASSWORD,
      component: () =>
        import(
          '@/modules/Authentication/views/ResetPassword.vue' /* webpackChunkName: "ResetPassword" */
        ),
      meta: {
        title: 'Passwort zurücksetzen',
        auth: false
      }
    },
    {
      path: '/',
      component: () =>
        import(
          '@/modules/Authentication/components/Authorized.vue' /* webpackChunkName: "Authorized" */
        ),
      meta: {
        auth: true
      },
      beforeEnter(to, from, next) {
        if (Vue.prototype.$auth.token(ADMIN_TOKEN_KEY)) {
          next();
          return;
        }

        if (
          Vue.prototype.$auth.user() &&
          Vue.prototype.$auth.user().optIn &&
          Vue.prototype.$auth.user().optIn !== 'accepted' &&
          to.name !== RouteName.OPT_IN
        ) {
          next({ name: RouteName.OPT_IN });
          return;
        }

        if (
          Vue.prototype.$auth.user().passwordUpdateRequired &&
          to.name !== RouteName.UPDATE_PASSWORD &&
          to.name !== RouteName.OPT_IN
        ) {
          next({ name: RouteName.UPDATE_PASSWORD });
          return;
        }

        next();
      },
      children: [
        {
          path: '/',
          name: RouteName.DASHBOARD,
          component: () =>
            import('@/modules/Dashboard/views/Dashboard.vue' /* webpackChunkName: "Dashboard" */),
          meta: {
            auth: true,
            title: 'Dashboard'
          },
          beforeEnter(to, from, next) {
            if (
              Vue.prototype.$auth.check(Role.ADMIN) &&
              !Vue.prototype.$auth.token(ADMIN_TOKEN_KEY)
            ) {
              next({ name: RouteName.CREDITORS });
              return;
            }
            next();
          }
        },
        {
          path: '/update-password',
          name: RouteName.UPDATE_PASSWORD,
          component: () =>
            import(
              '@/modules/Authentication/views/UpdatePassword.vue' /* webpackChunkName: "UpdatePassword" */
            ),
          meta: {
            auth: true,
            title: 'Passwort ändern',
            noHeader: true,
            noToolbar: true,
            noSidebar: true
          },
          beforeEnter(to, from, next) {
            if (!Vue.prototype.$auth.user().passwordUpdateRequired) {
              next({ name: from.name || RouteName.DASHBOARD });
              return;
            }
            next();
          }
        },
        {
          path: '/opt-in',
          name: RouteName.OPT_IN,
          component: () =>
            import('@/modules/Authentication/views/OptIn.vue' /* webpackChunkName: "OptIn" */),
          meta: {
            auth: true,
            title: 'Dienstleistungsbedingungen',
            noHeader: true,
            noToolbar: true,
            noSidebar: true
          },
          beforeEnter(to, from, next) {
            if (Vue.prototype.$auth.user().optIn === 'accepted') {
              next({ name: from.name || RouteName.DASHBOARD });
              return;
            }
            next();
          }
        },
        {
          path: '/user-info/:key?',
          name: RouteName.USER_INFO,
          component: () =>
            import(
              '@/modules/Authentication/views/UserInfoDialog.vue' /* webpackChunkName: "UserInfoDialog" */
            ),
          meta: {
            auth: true,
            title: 'Wichtige Informationen',
            noHeader: true
          }
        },
        {
          path: '/users',
          name: RouteName.USERS,
          component: () =>
            import('@/modules/Admin/views/Users.vue' /* webpackChunkName: "Users" */),
          meta: {
            auth: [Role.ADMIN],
            title: 'Benutzer'
          }
        },
        {
          path: '/creditors',
          name: RouteName.CREDITORS,
          component: () =>
            import('@/modules/Admin/views/Creditors.vue' /* webpackChunkName: "Creditors" */),
          meta: {
            auth: [Role.ADMIN],
            title: 'KMUs'
          }
        },
        {
          path: '/admins',
          name: RouteName.ADMINS,
          component: () =>
            import('@/modules/Admin/views/Admins.vue' /* webpackChunkName: "Admins" */),
          meta: {
            auth: [Role.SUPER_ADMIN],
            title: 'Administratoren'
          }
        },
        {
          path: '/requests',
          name: RouteName.REQUESTS,
          component: () =>
            import('@/modules/Admin/views/Requests.vue' /* webpackChunkName: "Requests" */),
          meta: {
            auth: [Role.ADMIN],
            title: 'Anfragen'
          }
        },
        {
          path: '/admin-actions',
          name: RouteName.ADMIN_ACTIONS,
          component: () =>
            import('@/modules/Admin/views/AdminActions.vue' /* webpackChunkName: "AdminActions" */),
          meta: {
            auth: [Role.ADMIN],
            title: 'Funktionen und Einstellungen'
          }
        },
        {
          path: '/assign-debtors',
          name: RouteName.ASSIGN_DEBTORS,
          component: () =>
            import(
              '@/modules/Admin/views/AssignDebtors.vue' /* webpackChunkName: "AssignDebtors" */
            ),
          meta: {
            auth: [Role.ADMIN],
            title: 'Debitoren zuweisen'
          }
        },
        {
          path: '/action-log/:id?',
          name: RouteName.ACTION_LOG,
          component: () =>
            import('@/modules/Admin/views/ActionLog.vue' /* webpackChunkName: "ActionLog" */),
          meta: {
            auth: [Role.ADMIN],
            title: 'Aktionslog'
          }
        },
        {
          path: '/vouchers/:type(sub|main)?/:id?',
          name: RouteName.VOUCHERS,
          component: () =>
            import('@/modules/Vouchers/views/Vouchers.vue' /* webpackChunkName: "Vouchers" */),
          meta: {
            auth: CREDITOR_ROLES,
            title: 'Belege'
          }
        },
        {
          path: '/debtors/:type(sub|main)?/:id?/',
          name: RouteName.DEBTORS,
          component: () =>
            import('@/modules/Debtors/views/Debtors.vue' /* webpackChunkName: "Debtors" */),
          meta: {
            auth: CREDITOR_ROLES,
            title: 'Kunden'
          }
        },
        {
          path: '/permanent-debts/:id?/',
          name: RouteName.PERMANENT_DEBTS,
          component: () =>
            import(
              '@/modules/PermanentDebts/views/PermanentDebts.vue' /* webpackChunkName: "PermanentDebts" */
            ),
          meta: {
            auth: CREDITOR_ROLES,
            title: 'Regelmäßige Einzüge'
          }
        },
        {
          path: '/unprocessed/:id?',
          name: RouteName.UNPROCESSED,
          component: () =>
            import(
              '@/modules/Unprocessed/views/Unprocessed.vue' /* webpackChunkName: "UnprocessedVouchers" */
            ),
          meta: {
            auth: CREDITOR_ROLES,
            title: 'In Verarbeitung',
            noHeader: true
          }
        },
        {
          path: '/work-queue/:id?',
          name: RouteName.WORK_QUEUE,
          component: () =>
            import('@/modules/User/views/WorkQueue.vue' /* webpackChunkName: "WorkQueue" */),
          meta: {
            auth: CREDITOR_ROLES,
            title: 'Entscheidungen',
            noHeader: true
          }
        },
        {
          path: '/master-data',
          name: RouteName.MASTER_DATA,
          component: () =>
            import('@/modules/User/views/MasterData.vue' /* webpackChunkName: "MasterData" */),
          meta: {
            auth: CREDITOR_ROLES,
            title: 'Stammdaten'
          }
        },
        {
          path: '/settings',
          name: RouteName.SETTINGS,
          component: () =>
            import('@/modules/User/views/Settings.vue' /* webpackChunkName: "Settings" */),
          meta: {
            auth: CREDITOR_ROLES,
            title: 'Einstellungen'
          }
        },
        {
          path: '/help-center',
          name: RouteName.HELP_CENTER,
          component: () =>
            import('@/modules/User/views/HelpCenter.vue' /* webpackChunkName: "HelpCenter" */),
          meta: {
            auth: CREDITOR_ROLES,
            title: 'Hilfe-Center'
          }
        }
      ]
    },
    {
      path: '*',
      redirect: '/'
    }
  ],

  parseQuery(query) {
    return qs.parse(query, {
      arrayLimit: 1000 /* prevent conversion of arrays with > 20 entries to objects */
    });
  },
  stringifyQuery(query) {
    const result = qs.stringify(query);
    return result ? '?' + result : '';
  }
});

router.beforeEach((to, from, next) => {
  if (typeof _sz === 'undefined') {
    return next();
  }

  if (to.name !== from.name) {
    // eslint-disable-next-line no-undef
    _sz.push([
      'trackdynamic',
      {
        url: `${window.location.origin}${to.path}`,
        ref: `${window.location.origin}${from.path}`,
        title: to.name
      }
    ]);
  }
  next();
});

const originalPush = router.push;
router.push = function push(location) {
  return originalPush.call(this, location).catch((error) => {
    if (
      Router.isNavigationFailure(error, Router.NavigationFailureType.duplicated) ||
      (Router.isNavigationFailure(error, Router.NavigationFailureType.redirected) &&
        !localStorage.getItem(AUTH_TOKEN_KEY))
    ) {
      return;
    }

    throw error;
  });
};

const originalReplace = router.replace;
router.replace = function replace(location) {
  return originalReplace.call(this, location).catch((error) => {
    if (
      Router.isNavigationFailure(error, Router.NavigationFailureType.redirected) &&
      !localStorage.getItem(AUTH_TOKEN_KEY)
    ) {
      return;
    }

    throw error;
  });
};

router.onError((error) => {
  reloadOnError(error, /Loading (CSS )?chunk .*? failed./);
});

export default router;
