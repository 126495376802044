export default {
  props: {
    customErrorMessages: {
      type: Object,
      default: () => ({})
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    hideSupplement: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'eager'
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    },
    vid: {
      type: String,
      default: undefined
    }
  },

  computed: {
    validationProps() {
      return {
        name: this.validationName,
        rules: this.validationRules,
        mode: this.mode,
        vid: this.vid ?? this.fieldId, // unique across form, used to identify field in validation-observer and handle cross-field validation
        ref: 'fieldRef',
        customMessages: this.customErrorMessages
      };
    },
    validationName() {
      // uses label string as validation name, if no name prop is provided
      return this.$te(this.name) ? this.$t(this.name) : this.name ? this.name : this.fieldLabel;
    },
    validationRules() {
      return this.required ? 'required|' + this.rules : this.rules;
    },
    fieldId() {
      return this.id || this.name || this.label;
    },
    fieldLabel() {
      return this.$te(this.label) ? this.$t(this.label) : this.label;
    },
    labelSupplement() {
      if (this.isOnboarding) {
        return this.required || (this.rules && this.rules.includes('required')) ? '*' : '';
      }

      return this.required || (this.rules && this.rules.includes('required'))
        ? ''
        : ` (${this.$t('optional')})`;
    },
    fieldLabelWithSupplement() {
      if (!this.label) {
        return '';
      }

      if (this.hideSupplement) {
        return this.fieldLabel;
      }

      return `${this.fieldLabel}${this.labelSupplement}`;
    },
    fieldPlaceholder() {
      return this.$te(this.placeholder) ? this.$t(this.placeholder) : this.placeholder;
    }
  },

  methods: {
    async validateField() {
      await this.$refs.fieldRef?.validate();
    }
  }
};
