export default function formatDateTime(date, fallback = '-') {
  if (!date) {
    return fallback;
  }

  return new Date(date).toLocaleDateString('de-DE', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  });
}
