<template>
  <v-container id="failure-view" fluid class="pa-0">
    <div class="container section--top">
      <div class="section__content">
        <partner-logos class="product-logos__row" />
        <div>
          <h2 class="h1 margin--top">{{ $t('failure.backSoon') }}</h2>
        </div>
      </div>
      <span class="plug-img">
        <svg
          width="130px"
          height="81px"
          viewBox="0 0 130 81"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient id="linearGradient" x1="0%" y1="50%" x2="100%" y2="50%">
              <stop stop-color="var(--c-background-gradient-light)" offset="0%" />
              <stop stop-color="var(--c-background-gradient-dark)" offset="100%" />
            </linearGradient>
          </defs>
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-404.000000, -211.000000)" fill="url(#linearGradient)">
              <g transform="translate(404.000000, 211.000000)">
                <path
                  d="M88.6607989,1.66291222 C93.9953818,-2.9682337 106.773148,2.39428052 117.379148,13.7678227 C128.092279,25.2562491 132.532703,38.5273183 127.297112,43.4095854 C126.900416,43.779511 126.459551,44.0906627 125.978563,44.344466 C108.629463,56.5044637 94.9221811,60.4913196 84.8571555,56.3037913 C74.7989131,66.3316356 64.2857835,74.5683927 53.3340098,80.9959663 L0.000309175679,80.9961802 C26.4244202,78.87309 50.706216,66.7627459 73.0986733,44.3821898 C68.8481529,33.901432 73.6408271,20.1261362 87.476512,3.05614519 C87.7257012,2.6552752 88.0156293,2.28735809 88.3472648,1.95528754 L88.3472648,1.95528754 L88.5014331,1.80631306 Z M118.394658,27.1187905 C116.031467,24.5845788 113.020423,23.5515773 111.669303,24.8115172 C110.318183,26.0714571 111.138629,29.1472247 113.501819,31.6814364 C115.86501,34.2156481 118.876054,35.2486495 120.227174,33.9887097 C121.578294,32.7287698 120.757849,29.6530022 118.394658,27.1187905 Z M104.702065,12.4352826 C102.338875,9.90107088 99.3278309,8.86806947 97.9767108,10.1280094 C96.6255907,11.3879493 97.4460359,14.4637168 99.8092266,16.9979285 C102.172417,19.5321403 105.183461,20.5651417 106.534581,19.3052018 C107.885701,18.0452619 107.065256,14.9694943 104.702065,12.4352826 Z"
                />
              </g>
            </g>
          </g>
        </svg>
      </span>
    </div>

    <div class="container section--bottom">
      <img src="@/modules/Failure/assets/plug-white.svg" class="plug-img" />
      <div class="section__content">
        <p class="subtitle-1" v-html="info"></p>

        <app-btn class="margin--top" @click="reloadPage">
          {{ $t('reload.reloadPage') }}
        </app-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
import { ERROR_STATES, ErrorStatus } from '@/modules/Failure/statics/errorStatus';
import PartnerLogos from '@/shared/components/PartnerLogos';
import setFullDocumentTitle from '@/helper/setFullDocumentTitle';
import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/Failure/store';

export default {
  name: 'FailurePage',

  components: { PartnerLogos },

  props: {
    errorStatus: {
      type: Number,
      default: undefined,
      validator: (value) => ERROR_STATES.includes(value)
    }
  },

  computed: {
    info() {
      if (this.errorStatus === ErrorStatus.MAINTENANCE) {
        return this.$t('failure.infoMaintenance');
      }

      if (this.errorStatus === ErrorStatus.DISTURBANCE) {
        return this.$t('failure.infoDisturbance');
      }

      return '';
    },
    title() {
      if (this.errorStatus === ErrorStatus.MAINTENANCE) {
        return this.$t('failure.titleMaintenance');
      }

      if (this.errorStatus === ErrorStatus.DISTURBANCE) {
        return this.$t('failure.titleDisturbance');
      }

      return '';
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['checkStatus']),

    reloadPage() {
      window.location.reload();
    }
  },

  created() {
    setFullDocumentTitle({
      pageTitle: this.title,
      productName: this.productName
    });

    setInterval(async () => {
      const { status } = await this.checkStatus();

      if (status === 200) {
        this.reloadPage();
      }
    }, 30000);
  }
};
</script>

<style lang="scss" scoped>
::v-deep .container {
  text-align: center;

  & > * {
    max-width: 500px;
  }
}

p {
  color: var(--c-background-text);
}

.margin--top {
  margin-top: 45px;
}

.container,
.section__content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section__content {
  margin: 0 auto;
  flex: 1;
}

.section--bottom,
.section--top {
  padding: 2rem;
  min-width: 100%;
  min-height: 50vh;
}

.section--top {
  background: white;
}

::v-deep .section--top .plug-img {
  position: absolute;
  bottom: 0;
  padding-right: 7rem;
  line-height: 0;
}

.section--top .section__content {
  margin-bottom: 100px;
  justify-content: center;
}

.section--bottom {
  background: linear-gradient(
    135deg,
    var(--c-background-gradient-light) 0%,
    var(--c-background-gradient-dark) 100%
  );
}

::v-deep .section--bottom .plug-img {
  position: absolute;
  top: 0;
  padding-left: 7rem;
}

.section--bottom .section__content {
  margin-top: 100px;
  justify-content: flex-start;
}

.product-logos__row {
  flex: 0 0 auto;
  justify-content: center;
  width: 100%;
}
</style>
