export const QueryParam = {
  // shared
  ACTION: 'action',
  CREATE_DEBTOR: 'createDebtor',
  CREATE_PERMANENT_DEBT: 'createPermanentDebt',
  PREVIEW: 'preview',
  REDIRECT: 'redirect',
  SHOW_NOTIFICATIONS: 'showNotifications',
  SHOW_VIDEO: 'showVideo',
  TAB: 'tab',
  TAG: 'tag',
  UPLOAD_VOUCHER: 'uploadVoucher',
  DELETE: 'delete',
  RELOAD_ON_ERROR: 'reloadOnError',

  // tables
  PAGE: 'page',
  ITEMS_PER_PAGE: 'itemsPerPage',
  SORT_BY: 'sortBy',
  SORT_DESC: 'sortDesc',

  // overlayed
  OVERLAY__PAGE: 'overlay__page',
  OVERLAY__STATUS: 'overlay__status',
  OVERLAY__DATE: 'overlay__date',

  // admin
  ANSWER_REQUEST: 'answerRequest',
  ASSIGN_DEBTOR: 'assignDebtor',
  CREATE_ADMIN_USER: 'createAdminUser',
  CREATE_CREDITOR: 'createCreditor',
  CREATE_USER: 'createUser',
  CREATE_EXTERNAL_VOUCHER: 'createExternalVoucher',
  DISABLE_USER: 'disable',
  EDIT_CREDITOR: 'edit',
  IMPORT_CSV: 'import',
  IMPORT_STATES_OF_AFFAIRS: 'importStatesOfAffairs',
  RESET_PASSWORD: 'resetPassword',
  SAP_REGISTRATION: 'sapRegistration',
  SELECTED_PRODUCT_TYPE: 'selectedProductType',
  SEND_REGISTRATION_CASE_FILE: 'sendRegistrationCaseFile',
  SEND_REGISTRATION_MAIL: 'sendRegistrationMail',
  SET_CREDITOR_PERMISSIONS: 'setCreditorPermissions',
  SET_DRIVER_PERMISSIONS: 'setDriverPermissions',
  SET_UP_FEE: 'setUpFee',
  STEP: 'step',
  TERMINATE_CREDITOR: 'terminateCreditor',
  UPDATE_USER: 'updateUser',
  MANAGE_CONDITIONS: 'manageConditions',
  REQUEST_SEPA_UPDATE: 'requestSepaUpdate',

  // auth
  CHANGE_PASSWORD: 'changePassword',
  EXPIRED: 'expired',
  USE_TOKEN: 'useToken',

  // debtors
  ACTIVATE: 'activate',
  ADD_LOCATION: 'addLocation',
  CHECK_SOLVENCY: 'checkSolvency',
  DEACTIVATE: 'deactivate',
  DELETE_POST_ADDRESS: 'deletePostAddress',
  EDIT_DEBTOR: 'editDebtor',
  EDIT_LOCATION: 'editLocation',
  EDIT_PAYMENT_DATA: 'editPaymentData',
  EDIT_POST_ADDRESS: 'editPostAddress',
  REQUEST_LIMIT_INCREASE: 'requestLimitIncrease',
  UPLOAD_INVOICE: 'uploadInvoice',
  REFRESH_LIMIT: 'refreshLimit',

  // permanent debts
  STOP_PERMANENT_DEBT: 'stopPermanentDebt',

  // settings
  CREATE_COMPANY_USER: 'createCompanyUser',
  EDIT_COMPANY_USER: 'editCompanyUser',
  DELETE_COMPANY_USER: 'deleteCompanyUser',
  RESET_COMPANY_USER_PASSWORD: 'resetCompanyUserPassword',

  // unprocessed
  CANCEL: 'cancel',
  CONTINUE: 'continue',
  REUPLOAD: 'reupload',
  TRANSFER_BASIC_PROCESSING: 'transferBasicProcessing',
  DELETE_ATTACHMENT: 'deleteAttachment',

  // vouchers
  ABANDONMENT: 'abandonment',
  ANNULATION: 'annulation',
  CANCEL_DUNNING_BLOCK: 'cancelDunningBlock',
  CLOSE_DISPUTE: 'closeDispute',
  CLOSE_DELAY: 'closeDelay',
  CREDIT_ADVICE: 'creditAdvice',
  DIRECT_PAYMENT: 'directPayment',
  EDIT_COMMENT: 'editComment',
  OPEN_DISPUTE: 'openDispute',
  OPEN_DELAY: 'openDelay',
  SET_DUNNING_BLOCK: 'setDunningBlock',
  SHOW_BALANCING_SUMMARY: 'showBalancingSummary'
};

export default QueryParam;
