var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{ref:"modal",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('ValidationProvider',_vm._b({ref:"provider",staticClass:"app-date-picker__wrapper",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({class:{
          'input--align-right': _vm.alignRight,
          'input--has-error': errors.length > 0,
          'input--prepend-inner': _vm.prependInnerIcon
        },attrs:{"id":_vm.fieldId,"append-icon":_vm.appendIcon ? '$iconCalendar' : null,"error-messages":_vm.errorMessage && errors.length > 0 ? [_vm.errorMessage] : errors,"hide-details":errors.length === 0,"label":_vm.fieldLabelWithSupplement,"placeholder":_vm.fieldPlaceholder,"prepend-inner-icon":_vm.prependInnerIcon ? '$iconCalendar' : null,"autocomplete":"off","data-testid":"app-date-picker__input","outlined":""},on:{"blur":_vm.changed,"click:append":function($event){_vm.modal = !_vm.modal},"click:prepend-inner":function($event){_vm.modal = !_vm.modal}},model:{value:(_vm.dateFormatted),callback:function ($$v) {_vm.dateFormatted=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"dateFormatted"}},'v-text-field',_vm.$attrs,false),on))]}}],null,true)},'ValidationProvider',_vm.validationProps,false))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',_vm._b({ref:"date-picker",attrs:{"min":_vm.min,"max":_vm.max,"color":"primary","first-day-of-week":"1","locale":"de-DE","no-title":""},on:{"input":function($event){_vm.modal = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-date-picker',_vm.$attrs,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }