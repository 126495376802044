import {
  CREDITOR_LIMIT,
  FACTORABLE_TURNOVER,
  FOUNDING_DATE,
  ONLINE_TURNOVER_RATE,
  VOB
} from '@/helper/createCreditorFactory';
import FAQLinks from '@/modules/User/statics/faqLinks';
import ProductType from '@/statics/productType';
import i18n from '@/helper/i18n';
import { BASIC_CREDITOR_VOUCHER_TYPE_GROUPS } from '@/modules/User/statics/creditorVoucherType';
import { BASIC_VOUCHER_STATUS_TO_SHOW_GROUPS } from '@/statics/voucherStatusToShow';
import { BASIC_VOUCHER_SUB_STATUS_FILTER_OPTIONS } from '@/statics/voucherSubStatus';
import { BASIC_VOUCHER_TYPE_GROUPS } from '@/statics/voucherType';
import { BILLING_PERMISSIONS } from '@/statics/productPermissions';
import {
  IMPRINT_FINTECRITY,
  PRIVACY_POLICY_FINTECRITY,
  PRODUCT_INFO_DV,
  SERVICE_HOTLINE_FINTECRITY,
  SERVICE_MAIL_FINTECRITY
} from '@/statics/customerServiceInformation';

const PRODUCT_NAME = 'DV AbrechnungPlus';

const DV_BILLING_CONFIG = {
  type: ProductType.DV_BILLING,
  productName: PRODUCT_NAME,
  logo: require('@/assets/DV-logo.svg'),
  partnerLogo: require('@/assets/FinTecrity-logo.svg'),
  favIcon: '/favicons/faviconDV.ico',
  theme: 'dv' /* matches key of $theme-base in styles/themes/index.scss */,
  background: null,
  placeLogoInHeader: false,
  serviceHotline: SERVICE_HOTLINE_FINTECRITY,
  serviceMail: SERVICE_MAIL_FINTECRITY,
  infoUrl: PRODUCT_INFO_DV,
  privacyPolicyUrl: PRIVACY_POLICY_FINTECRITY,
  imprintUrl: IMPRINT_FINTECRITY,
  withPurchaseAssurance: false,

  voucherStatusToShowGroups: BASIC_VOUCHER_STATUS_TO_SHOW_GROUPS,
  voucherSubStatusFilter: BASIC_VOUCHER_SUB_STATUS_FILTER_OPTIONS,
  voucherTypeGroups: BASIC_VOUCHER_TYPE_GROUPS,
  creditorVoucherTypeGroups: BASIC_CREDITOR_VOUCHER_TYPE_GROUPS,

  permissions: {
    ...BILLING_PERMISSIONS,
    dashboard: {
      ...BILLING_PERMISSIONS.dashboard,
      cashback: true
    },
    helpCenter: {
      ...BILLING_PERMISSIONS.helpCenter,
      tabTutorials: true
    }
  },

  onboarding: {
    blacklist: [ONLINE_TURNOVER_RATE, FACTORABLE_TURNOVER, CREDITOR_LIMIT, FOUNDING_DATE, VOB]
  },

  faq: [FAQLinks.DEBTORS, FAQLinks.ENCASHMENT],

  documents: {
    masterData: [
      {
        title: i18n.t('masterData.dataProtectionRegulation'),
        path: '/assets/masterData/APL/FTY_Datenschutzhinweise_Art.13 14_072024.pdf'
      },
      {
        title: i18n.t('masterData.productSpecificDataProtectionRegulation'),
        path: '/assets/masterData/APL/FTY_Datenschutz ergänzend_DV AbrechnungPlus_2024-07.pdf'
      },
      {
        title: i18n.t('masterData.infoChangeBankDetails'),
        path: '/assets/masterData/APL/Bankdatenänderung_FTY_2024.docx'
      },
      {
        title: i18n.t('masterData.notificationLetter'),
        path: '/assets/masterData/APL/Notifikationsschreiben_Zusammenarbeit_FinTecrity.docx'
      }
    ],

    sepa: {
      template: {
        title: i18n.t('permanentDebts.pdf.sepaTemplate'),
        path: '/assets/sepa/Vorlage_Einzugsermächtigung.pdf'
      }
    },

    upload: {
      tips: '/assets/upload/DV/Hinweise_zum_Rechnungsaufbau.pdf'
    }
  },

  translations: {}
};

export default DV_BILLING_CONFIG;
