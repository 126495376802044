/**
 * @see {@link https://wiki.move-elevator.de/display/DVKMU/Mahnstufen+-+Wertedefinition }
 */
export const DunningLevel = {
  NOT_DUNNED: 'notDunned',
  HALT: 'halt',
  PAYMENT_REMINDER: 'paymentReminder',
  DUNNING: 'dunning',
  ENCASHMENT: 'encashment'
};

export default DunningLevel;
