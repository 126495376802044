/**
 * @see {@link https://wiki.move-elevator.de/display/DVKMU/Fachkonzept+-+Verzug+ePOD}
 * @see {@link https://wiki.move-elevator.de/display/DVKMU/Fachkonzept+-+Dispute+ePOD}
 * @see {@link https://wiki.move-elevator.de/pages/viewpage.action?pageId=458179426}
 */
export const DunningType = {
  DELAY: 'OVERDUE',
  DISPUTE: 'DISPUTE',
  ENCASHMENT: 'INKASSO'
};

export default DunningType;
