<template>
  <svg width="48px" height="48px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path
        d="M43.015579,6.34863154 C43.015579,1.69010522 39.2033685,-5.00000006e-08 36.6391579,-5.00000006e-08 L6.94736839,-5.00000006e-08 C5.83117184,-5.00000006e-08 4.92631575,0.904856036 4.92631575,2.02105259 L4.92631575,46.8404211 C4.92631575,47.5452632 5.20421049,47.9292632 5.62105259,47.9292632 C5.93628016,47.9005829 6.2313858,47.7619727 6.45473681,47.5376843 L18.2122105,37.3642106 C19.1011775,36.6363558 20.3800856,36.6363558 21.2690526,37.3642106 L33.0265263,47.5376843 C33.2501278,47.7616166 33.5450971,47.9001628 33.8602105,47.9292632 C34.279579,47.9292632 34.5549474,47.5452632 34.5549474,46.8404211 L34.5549474,20.7915789 L43.015579,20.7915789 L43.015579,6.34863154 Z M39.4787369,17.2547368 L34.5549474,17.2547368 L34.5549474,3.53684206 L36.624,3.53684206 C37.1843056,3.56550197 37.7324403,3.71092546 38.2332632,3.96378943 C38.7688421,4.24926312 39.4787369,4.80757891 39.4787369,6.34863154 L39.4787369,17.2547368 Z"
        fill="currentColor"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconBookmarkFull'
};
</script>
