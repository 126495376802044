export default {
  computed: {
    routes() {
      return {
        // creditor views
        [this.routeName.DASHBOARD]: {
          route: this.routeName.DASHBOARD,
          icon: 'icon-dashboard',
          exact: true,
          condition:
            !this.$auth.check(this.role.ADMIN) &&
            this.permissions.navigation[this.routeName.DASHBOARD]
        },
        [this.routeName.VOUCHERS]: {
          route: this.routeName.VOUCHERS,
          icon: 'icon-vouchers',
          condition: !this.$auth.check(this.role.ADMIN)
        },
        [this.routeName.DEBTORS]: {
          route: this.routeName.DEBTORS,
          icon: 'icon-clients',
          condition: !this.$auth.check(this.role.ADMIN)
        },
        [this.routeName.PERMANENT_DEBTS]: {
          route: this.routeName.PERMANENT_DEBTS,
          icon: 'icon-permanent-debt',
          condition:
            !this.$auth.check(this.role.ADMIN) &&
            this.permissions.navigation[this.routeName.PERMANENT_DEBTS]
        },
        [this.routeName.UNPROCESSED]: {
          route: this.routeName.UNPROCESSED,
          icon: 'icon-hourglass',
          condition:
            !this.$auth.check(this.role.ADMIN) &&
            this.permissions.navigation[this.routeName.UNPROCESSED]
        },
        [this.$t('upload.title')]: {
          name: this.$t('upload.title'),
          action: this.openVoucherUploadDialog,
          icon: 'icon-plus',
          condition: !this.$auth.check(this.role.ADMIN) && this.permissions.vouchers.upload
        },
        // admin views
        [this.routeName.CREDITORS]: {
          route: this.routeName.CREDITORS,
          icon: 'icon-clients',
          condition: this.showCreditorUserList
        },
        [this.routeName.USERS]: {
          route: this.routeName.USERS,
          icon: 'icon-clients',
          condition: this.$auth.check(this.role.ADMIN)
        },
        [this.routeName.ADMINS]: {
          route: this.routeName.ADMINS,
          icon: 'icon-clients',
          condition: this.isSuperAdmin
        },
        [this.routeName.REQUESTS]: {
          route: this.routeName.REQUESTS,
          icon: 'icon-work-queue',
          condition: this.$auth.check(this.role.ADMIN)
        },
        [this.routeName.ADMIN_ACTIONS]: {
          route: this.routeName.ADMIN_ACTIONS,
          icon: 'icon-admin-actions',
          condition: this.$auth.check(this.role.ADMIN)
        }
      };
    },

    defaultRoute() {
      return Object.values(this.routes).find((route) => route.route && route.condition !== false);
    }
  }
};
