/**
 * Unique keys to identify terms and conditions document by type
 * Used in OptIn and MasterData/TabContract
 * @see {@link https://wiki.move-elevator.de/x/XVxPGw}
 */
export const OptInDocumentType = {
  DATA_PROCESSING_AGREEMENT: 'dataProcessingAgreement',
  ENCASHMENT_CONDITIONS: 'encashmentConditions',
  TERMS_AND_CONDITIONS: 'termsAndConditions',
  TERMS_OF_USE: 'termsOfUse'
};
