<template>
  <v-dialog
    v-model="dialog"
    :width="width"
    :persistent="persistent"
    v-bind="$attrs"
    :fullscreen="isMobile"
    data-testid="dialog"
    @click:outside="closeOutside"
  >
    <div v-if="!isMobile && hasPrependInfo" class="mb-2">
      <slot name="prepend"></slot>
    </div>
    <v-card>
      <slot v-if="isMobile" name="prepend"></slot>

      <v-row v-if="persistent" class="flex-grow-0" no-gutters :justify="isMobile ? 'start' : 'end'">
        <v-col cols="auto">
          <app-text-btn
            v-if="isMobile && mobileCloseTitle"
            :class="{ 'mx-4 mt-2 semi-bold subtitle-1': true, 'mb-4': !dense }"
            :title="mobileCloseTitle"
            icon-size="10"
            prepend-icon="icon-chevron-left"
            @click="close"
          />
          <app-icon-btn
            v-else
            class="ma-2"
            data-testid="dialog-close-button"
            icon="mdi-close"
            @click="close"
          />
        </v-col>
      </v-row>
      <div class="scroll-overflow white" data-testid="dialog-content">
        <v-card-title
          :class="{ 'd-flex text-h3 semi-bold pa-0': true, 'mb-6': !dense }"
          data-testid="dialog-title"
        >
          <v-row justify="center" no-gutters class="break-word px-4 text-center">
            <v-col cols="auto">
              <slot name="title">{{ $te(title) ? $t(title) : title }}</slot>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text
          :class="{
            'body-1 py-2 d-flex flex-column': true,
            'px-6': !isMobile,
            'v-card__text--stretched': stretch
          }"
          data-testid="dialog-text"
        >
          <div :class="isMobile ? 'subtitle-1 text-center' : 'body-1'">
            <slot name="text">{{ $te(text) ? $t(text) : text }}</slot>
          </div>
          <slot></slot>
        </v-card-text>
      </div>

      <v-spacer />
      <slot name="append"></slot>

      <v-divider v-if="hasActions" />
      <v-card-actions v-if="hasActions" class="d-block px-6">
        <slot name="actions">
          <v-row justify="end">
            <v-col
              v-if="hasSecondaryAction"
              :cols="fillActions ? 6 : 'auto'"
              class="d-flex flex-column"
            >
              <slot name="secondaryAction">
                <app-btn
                  :block="fillActions"
                  outlined
                  data-testid="secondary-action-button"
                  @click="cancel"
                >
                  {{ $te(secondaryActionText) ? $t(secondaryActionText) : secondaryActionText }}
                </app-btn>
              </slot>
            </v-col>

            <v-col
              v-if="hasPrimaryAction"
              :cols="fillActions ? 6 : 'auto'"
              class="d-flex flex-column"
            >
              <slot name="primaryAction">
                <app-btn
                  :block="fillActions"
                  :loading="loading"
                  :disabled="disabled"
                  data-testid="primary-action-button"
                  @click="submit"
                >
                  {{ $te(primaryActionText) ? $t(primaryActionText) : primaryActionText }}
                </app-btn>
              </slot>
            </v-col>
          </v-row>
        </slot>
      </v-card-actions>
    </v-card>

    <template #activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>
  </v-dialog>
</template>

<script>
export default {
  name: 'AppDialog',

  props: {
    fillActions: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    persistent: {
      type: Boolean,
      default: true
    },
    primaryActionText: {
      type: String,
      default: ''
    },
    secondaryActionText: {
      type: String,
      default: ''
    },
    stretch: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    value: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '555'
    },
    useCancelAction: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    hasActions() {
      return (
        this.secondaryActionText ||
        this.primaryActionText ||
        this.$scopedSlots.primaryAction ||
        this.$scopedSlots.secondaryAction ||
        this.$scopedSlots.actions
      );
    },
    hasSecondaryAction() {
      return this.secondaryActionText || this.$scopedSlots.secondaryAction;
    },
    hasPrimaryAction() {
      return this.primaryActionText || this.$scopedSlots.primaryAction;
    },
    hasPrependInfo() {
      return this.$scopedSlots.prepend;
    },
    mobileCloseTitle() {
      if (this.$route.params.id && this.$route.name === this.routeName.VOUCHERS) {
        return this.$t('voucherDetails');
      }

      if (this.$route.params.id && this.$route.name === this.routeName.DEBTORS) {
        return this.$t('debtorDetails');
      }

      return this.$route.meta.title;
    }
  },

  methods: {
    submit() {
      this.$emit('submit', true);
    },

    cancel() {
      if (this.useCancelAction) {
        this.$emit('cancel', true);
        return;
      }

      this.close();
    },

    close() {
      this.$emit('close', true);
      this.dialog = false;
    },

    closeOutside() {
      if (this.persistent) {
        return;
      }
      this.close();
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  border-radius: 2px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.v-card {
  display: flex;
  flex-direction: column;
  border-radius: inherit;
  overflow-x: hidden;

  &.min-height {
    min-height: 70vh;
  }
}

::v-deep hr.v-divider {
  border-color: rgb(240, 240, 240);
}

#app.v-application.mobile {
  .v-card {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .v-card__text {
    overflow: hidden;

    &.v-card__text--stretched {
      flex: 1 1 100%;
    }
  }
}
</style>
