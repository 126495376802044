<template>
  <tr
    v-bind="$attrs"
    :class="{
      'data-table-row--highlighted': highlighted,
      'v-data-table-row--bordered': bordered,
      'v-data-table-row--selectable': selectable,
      'v-data-table__mobile-table-row': isMobile,
      'v-data-table-row--expanded': expanded
    }"
    v-on="$listeners"
  >
    <template v-if="isMobile">
      <v-row no-gutters>
        <v-col v-if="selectable" cols="auto">
          <app-table-data class="v-data-table-col--selectable">
            <app-checkbox
              v-if="item.source"
              :key="item.id"
              v-model="selected"
              :item-value="item.id"
              @click.native.stop=""
            />
            <span v-else></span>
          </app-table-data>
        </v-col>
        <v-col>
          <slot></slot>
        </v-col>
      </v-row>
    </template>

    <template v-else>
      <app-table-data v-if="selectable" class="v-data-table-col--selectable">
        <app-checkbox
          v-if="item.source"
          :key="item.id"
          v-model="selected"
          :disabled="!item.source"
          :item-value="item.id"
          @click.native.stop=""
        />
      </app-table-data>
      <slot></slot>
    </template>
  </tr>
</template>

<script>
export default {
  name: 'AppTableRow',

  props: {
    bordered: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: Boolean,
      default: false
    },
    highlighted: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    },
    selectable: {
      type: Boolean,
      default: false
    },
    selectedItems: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    selected: {
      get() {
        return this.selectedItems;
      },
      set(value) {
        this.$emit('update:selected-items', value);
      }
    }
  }
};
</script>
