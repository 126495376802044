/**
 * @see {@link https://move-elevator.atlassian.net/l/cp/u8c6M0QH}
 */
export const ManualDecisionReason = {
  IDENTIFY_WITH_CSP_IDENTIFIER: 'IdentifyWithCspIdentifier',
  IDENTIFY_WITH_LIMIT_ADDRESS_AND_NAME: 'IdentifyWithLimitAddressAndName',
  IDENTIFY_WITH_LOCATION_ADDRESS_AND_NAME: 'IdentifyWithLocationAddressAndName',
  IDENTIFY_WITH_LIMIT_ADDRESS_AND_CUSTOM_DEBTOR_NUMBER:
    'IdentifyWithLimitAddressAndCustomDebtorNumber',
  IDENTIFY_WITH_LOCATION_ADDRESS_AND_CUSTOM_DEBTOR_NUMBER:
    'IdentifyWithLocationAddressAndCustomDebtorNumber',
  IDENTIFY_WITH_CUSTOMER_LIST: 'IdentifyWithCustomerList'
};

export default ManualDecisionReason;
