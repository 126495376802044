import { mapAddress, mapTypeRelatedDebtorData } from '@/shared/models';
import DebtorStatus from '@/modules/Debtors/statics/debtorStatus';
import DebtorType from '@/modules/Debtors/statics/debtorType';
import DebtorPermission from '@/modules/Debtors/statics/debtorPermission';
import LocationPermission from '@/modules/Debtors/statics/locationPermission';

const calculateDebtorLeftover = (vouchers) => {
  if (!vouchers) {
    return;
  }

  return vouchers.reduce((acc, voucher) => voucher.leftoverAmount + acc, 0);
};

const mapVouchers = (vouchers) =>
  vouchers.map((voucher) => ({
    amount: voucher.amount,
    id: voucher.identifier,
    leftoverAmount: voucher.leftoverAmount,
    numberToShow: voucher.numberToShow,
    officialVoucherNumber: voucher.officialVoucherNumber,
    requiresProcessing: true,
    status: voucher.status,
    statusToShow: voucher.statusToShow,
    subStatus: voucher.subStatus,
    type: voucher.type,
    uploaded: voucher.uploadDate * 1000,
    voucherDate: voucher.voucherDate * 1000
  }));

export const mapList = (debtors) =>
  debtors.map((debtor) => ({
    city: debtor.city,
    id: debtor.identifier,
    invoiceVolume: debtor.invoiceVolume,
    limit: {
      approvedLimit: debtor.businessRelationship.approvedLimit,
      availableLimit: debtor.businessRelationship.availableLimit,
      expirationDate: debtor.businessRelationship.limitExpiredDate * 1000,
      isExpired: debtor.businessRelationship.limitExpired,
      isOnBlacklist: debtor.businessRelationship.isOnBlackList
    },
    locations: mapLocations(debtor.locations),
    name: debtor.name ?? '-',
    notPurchasedInvoiceVolume: debtor.notPurchasedInvoiceVolume,
    openInvoiceVolume: debtor.openInvoiceVolume,
    openNotPurchasedInvoiceVolume: debtor.openNotPurchasedInvoiceVolume,
    openPurchasedInvoiceVolume: debtor.openPurchasedInvoiceVolume,
    openPurchasedInvoices: mapOpenPurchasedInvoices(debtor.openPurchasedInvoices),
    purchasedInvoiceVolume: debtor.purchasedInvoiceVolume,
    status: debtor.status,
    type: debtor.debtorType
  }));

const mapLocations = (locations) =>
  locations.map((location) => ({
    address: mapAddress(location.normalizedAddress),
    customNumber: location.customDebtorNumber,
    id: location.identifier,
    invoiceVolume: location.invoiceVolume,
    name: location.name,
    notPurchasedInvoiceVolume: location.notPurchasedInvoiceVolume,
    openInvoiceVolume: location.openInvoiceVolume,
    openNotPurchasedInvoiceVolume: location.openNotPurchasedInvoiceVolume,
    openPurchasedInvoiceVolume: location.openPurchasedInvoiceVolume,
    purchasedInvoiceVolume: location.purchasedInvoiceVolume,
    status: location.status
  }));

const mapOpenPurchasedInvoices = (invoices) =>
  invoices.map((invoice) => ({
    amount: invoice.amount,
    type: invoice.type,
    numberToShow: invoice.numberToShow
  }));

export const mapDebtor = (debtor) => ({
  ...mapTypeRelatedDebtorData(debtor),
  address: mapAddress(debtor.limitAddress),
  city: debtor.city,
  created: debtor.creationDate * 1000,
  id: debtor.identifier,
  isBusiness: debtor.debtorType === DebtorType.BUSINESS,
  isInactive: debtor.status === DebtorStatus.INACTIVE,
  leftoverAmount: calculateDebtorLeftover(debtor.vouchers),
  limit: {
    approvedLimit: debtor.businessRelationship.approvedLimit,
    availableLimit: debtor.businessRelationship.availableLimit,
    expirationDate: debtor.businessRelationship.limitExpiredDate * 1000,
    isExpired: debtor.businessRelationship.limitExpired,
    isOnBlacklist: debtor.businessRelationship.isOnBlackList,
    limitRequestActive: debtor.limitRequestActive,
    limitUpdated: debtor.businessRelationship.limitUpdateDate * 1000
  },
  locations: mapLocations(debtor.locations),
  openPurchasedInvoices: mapOpenPurchasedInvoices(debtor.openPurchasedInvoices),
  paymentMethod: debtor.paymentMethod,
  permanentDebts: debtor.permanentDebts.map((permanentDebt) => ({
    id: permanentDebt.identifier,
    location: permanentDebt.location
  })),
  sapNumber: debtor.sapNumber,
  sepaMandate: debtor.directDebitMandate
    ? {
        accountOwner: debtor.directDebitMandate.accountOwner,
        city: debtor.directDebitMandate.city,
        date: debtor.directDebitMandate.date * 1000,
        expirationDate: debtor.directDebitMandate.expiredDate * 1000,
        iban: debtor.directDebitMandate.iban,
        referenceNumber: debtor.directDebitMandate.referenceNumber
      }
    : null,
  solvency: debtor.solvencyDecision
    ? {
        color: debtor.solvencyDecision.color,
        reasonCode: debtor.solvencyDecision.reasonCode,
        updated: debtor.solvencyDecision.date * 1000
      }
    : null,
  status: debtor.status,
  type: debtor.debtorType,
  updated: debtor.updateDate * 1000,
  vouchers: mapVouchers(debtor.vouchers)
});

export const mapDebtorPermissions = (permissions) => ({
  addLocation: permissions[DebtorPermission.ADD_LOCATION],
  editDebtor: permissions[DebtorPermission.EDIT_DEBTOR],
  editPaymentData: permissions[DebtorPermission.EDIT_PAYMENT_DATA],
  requestLimitIncrease: permissions[DebtorPermission.REQUEST_LIMIT_INCREASE],
  refreshLimit: permissions[DebtorPermission.REFRESH_LIMIT],
  checkSolvency: permissions[DebtorPermission.CHECK_SOLVENCY]
});

export const mapLocation = (location) => ({
  address: mapAddress(location.normalizedAddress),
  availableLimit: location.availableLimit,
  contactPerson: location.contactPerson,
  created: location.creationDate * 1000,
  customNumber: location.customDebtorNumber,
  debtor: {
    id: location.debtor.identifier,
    type: location.debtor.type,
    name: location.debtor.name,
    availableLimit: location.debtor.availableLimit,
    leftoverAmount: calculateDebtorLeftover(location.vouchers)
  },
  debtorId: location.debtor.identifier,
  deliveryType: location.deliveryType,
  email: location.email,
  id: location.identifier,
  invoiceVolume: location.invoiceVolume,
  isBusiness: location.debtor.type === DebtorType.BUSINESS,
  isInactive: location.status === DebtorStatus.INACTIVE,
  isLastActiveLocation: location.debtorLocationsCount === 1,
  isLocation: true,
  name: location.name,
  notPurchasedInvoiceVolume: location.notPurchasedInvoiceVolume,
  openInvoiceVolume: location.openInvoiceVolume,
  openNotPurchasedInvoiceVolume: location.openNotPurchasedInvoiceVolume,
  openPurchasedInvoiceVolume: location.openPurchasedInvoiceVolume,
  permanentDebts: location.permanentDebts.map((permanentDebt) => ({
    id: permanentDebt,
    location: location.name
  })),
  phone: location.telephone,
  postAddress:
    location.postAddress && location.postAddress.value.zip
      ? mapLocationPostAddress(location.postAddress)
      : null,
  purchasedInvoiceVolume: location.purchasedInvoiceVolume,
  status: location.status,
  updated: location.updateDate * 1000,
  vouchers: mapVouchers(location.vouchers)
});

const mapLocationPostAddress = (address) => ({
  ...mapAddress(address),
  postBox: address.value.postboxNumber
});

export const mapLocationPermissions = (permissions) => ({
  createPermanentDebt: permissions[LocationPermission.CREATE_PERMANENT_DEBT],
  deletePostAddress: permissions[LocationPermission.DELETE_POST_ADDRESS],
  editLocation: permissions[LocationPermission.EDIT_LOCATION],
  editPostAddress: permissions[LocationPermission.EDIT_POST_ADDRESS],
  uploadInvoice: permissions[LocationPermission.LOCATION_INVOICE_UPLOAD],
  activateLocation: permissions[LocationPermission.ACTIVATE_LOCATION]
});

export const mapNewDebtorData = (data) => ({
  debtor: data.debtor
    ? {
        address: data.debtor.limitAddress ? mapAddress(data.debtor.limitAddress) : null,
        approvedLimit: data.debtor.businessRelationship.approvedLimit,
        availableLimit: data.debtor.businessRelationship.availableLimit,
        id: data.debtor.identifier,
        name: data.debtor.name,
        type: data.debtor.type
      }
    : null,
  hasBlacklistEntry: data.hasBlacklistEntry,
  isNewLocation: data.isNewLocation,
  limitAddress: mapAddress(data.limitAddress),
  location: data.locationAddress
    ? {
        id: data.locationAddress.identifier,
        name: data.locationAddress.name,
        address: mapAddress(data.locationAddress.normalizedAddress)
      }
    : null,
  newDebtorLimit: data.debtorLimit,
  newDebtorLimitColor: data.debtorLimitColor,
  reasonCodes: data.reasonCodes ? data.reasonCodes.map((code) => code.code) : [],
  requiresDebtorAssignment: !!data.debtorDecisionIdentifier,
  requestData: {
    city: data.requestData.city,
    contact: data.requestData.contact,
    dateOfBirth: data.requestData.dateOfBirth,
    email: data.requestData.email,
    name: data.requestData.business
      ? data.requestData.name
      : `${data.requestData.firstName} ${data.requestData.name}`,
    phone: data.requestData.phone,
    streetAndHouse: data.requestData.streetAndHouse,
    type: data.requestData.business ? DebtorType.BUSINESS : DebtorType.CUSTOMER,
    zip: data.requestData.zip
  }
});

export const mapOpenDebtorAssignments = (list) => list.map((entry) => mapAddress(entry));
