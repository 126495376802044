<template>
  <svg
    width="24px"
    height="30px"
    viewBox="0 0 24 30"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-3.000000, 0.000000)" fill="currentColor" fill-rule="nonzero">
        <path
          d="M26.2496614,27.1871332 L26.2496614,29.9995485 L3.75033861,29.9995485 L3.75033861,27.1871332 L26.2496614,27.1871332 Z M16.4249571,0.000451484525 L16.4249571,19.087377 L21.2998104,14.2125237 L23.2872506,16.1999639 L15,24.4872144 L6.71274945,16.1999639 L8.70018962,14.2125237 L13.6125418,19.087377 L13.6125418,0.000451484525 L16.4249571,0.000451484525 Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconDownload'
};
</script>
