import { isLocal, isProduction, envInfo } from '@/statics/environment';

export default {
  computed: {
    isLocal() {
      return isLocal;
    },
    isProduction() {
      return isProduction;
    },
    envInfo() {
      return envInfo;
    }
  }
};
