/**
 * @see {@link https://move-elevator.atlassian.net/wiki/x/QRTIAg}
 */
export const VoucherPermission = {
  ABANDONMENT: 'abandonment',
  ANNULATION: 'annulation',
  CANCEL_DUNNING_BLOCK: 'process_resumption',
  CLOSE_DISPUTE: 'dispute_close',
  CLOSE_DELAY: 'delay_close',
  CREDIT_ADVICE: 'credit_advice',
  DIRECT_PAYMENT: 'direct_payment',
  OPEN_DISPUTE: 'dispute_open',
  OPEN_DELAY: 'delay_open',
  SET_DUNNING_BLOCK: 'process_stop',
  SHOW_BALANCING_SUMMARY: 'show_balancing_list'
};

export default VoucherPermission;
