/**
 * @see {@link https://move-elevator.atlassian.net/wiki/x/QRTIAg}
 */

export const LocationPermission = {
  EDIT_LOCATION: 'update_location_data',
  LOCATION_INVOICE_UPLOAD: 'add_voucher',
  CREATE_PERMANENT_DEBT: 'add_permanent_debt',
  EDIT_POST_ADDRESS: 'add_post_address',
  DELETE_POST_ADDRESS: 'add_post_address',
  ACTIVATE_LOCATION: 'activate_location'
};

export default LocationPermission;
