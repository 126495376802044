<template>
  <svg
    width="35px"
    height="34px"
    viewBox="0 0 35 34"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-1135.000000, -235.000000)" fill="currentColor" fill-rule="nonzero">
        <g transform="translate(1135.000000, 235.000000)">
          <g>
            <path
              d="M29.5669231,0.330340909 C29.4996154,0.247659091 29.36,0.247659091 29.2930769,0.330340909 L24.2919231,6.90856818 C24.2484615,6.96227273 24.2396154,7.03568182 24.2684615,7.09711364 C24.2976923,7.15970455 24.36,7.1995 24.4288462,7.1995 L27.26,6.48356818 C26.6292308,15.3633636 18.1592308,21.5223864 0.166153846,21.3747955 C0.0676923077,21.3736364 -0.00615384615,21.4366136 0.000769230769,21.5142727 C0.00730769231,21.5927045 0.0919230769,21.6668864 0.189615385,21.6796364 C11.0146154,23.1180682 17.5869231,23.1099545 22.8065385,20.5866136 C28.5526923,17.8078864 31.6123077,12.1526818 31.9123077,6.48356818 L34.4315385,7.1995 C34.5365385,7.1995 34.6157692,7.11990909 34.6157692,7.02177273 C34.6157692,6.96265909 34.5880769,6.9105 34.5438462,6.87843182 L29.5669231,0.330340909 Z"
            />
            <path
              d="M20.4369231,22.6497955 L20.4369231,33.3509091 C20.4369231,33.5607045 20.6057692,33.7310909 20.8153846,33.7310909 L24.8723077,33.7310909 C25.0811538,33.7310909 25.2503846,33.5607045 25.2503846,33.3509091 L25.2503846,20.4567955 C24.6215385,20.8516591 23.9619231,21.2198636 23.2703846,21.5540682 C22.3753846,21.9867955 21.4342308,22.3499773 20.4369231,22.6497955 Z"
            />
            <path
              d="M27.0126923,19.2123182 L27.0126923,33.2539318 C27.0126923,33.5185909 27.1823077,33.7314773 27.3915385,33.7314773 L31.4480769,33.7314773 C31.6569231,33.7314773 31.8261538,33.5182045 31.8261538,33.2539318 L31.8261538,13.2248409 C30.7430769,15.5047727 29.1107692,17.5420682 27.0126923,19.2123182 Z"
            />
            <path
              d="M13.7457692,33.3509091 C13.7457692,33.5607045 13.9165385,33.7310909 14.1253846,33.7310909 L18.1823077,33.7310909 C18.3911538,33.7310909 18.5607692,33.5607045 18.5607692,33.3509091 L18.5607692,23.1227045 C17.0903846,23.4275455 15.4976923,23.6141591 13.7453846,23.6864091 L13.7453846,33.3509091 L13.7457692,33.3509091 Z"
            />
            <path
              d="M7.05769231,33.3509091 C7.05769231,33.5607045 7.22807692,33.7310909 7.43653846,33.7310909 L11.4923077,33.7310909 C11.7019231,33.7310909 11.8711538,33.5607045 11.8711538,33.3509091 L11.8711538,23.7211818 C10.4265385,23.7169318 8.83846154,23.6473864 7.05730769,23.5082955 L7.05730769,33.3509091 L7.05769231,33.3509091 Z"
            />
            <path
              d="M0.368076923,33.3509091 C0.368076923,33.5607045 0.537307692,33.7310909 0.746923077,33.7310909 L4.80230769,33.7310909 C5.01269231,33.7310909 5.18153846,33.5607045 5.18153846,33.3509091 L5.18153846,23.3433182 C3.71076923,23.2003636 2.11230769,23.0145227 0.367692308,22.7857955 L0.367692308,33.3509091 L0.368076923,33.3509091 Z"
            />
            <path
              d="M3.88884615,15.6751591 C4.315,15.9235909 4.97884615,16.2446591 5.50884615,16.2697727 L5.50884615,17.0220227 C5.50884615,17.1819773 5.63884615,17.3114091 5.79846154,17.3125682 L6.28076923,17.3125682 C6.44038462,17.3125682 6.56961538,17.1819773 6.56961538,17.0220227 L6.56961538,16.1940455 C7.68461538,15.9753636 8.24115385,15.1400455 8.24115385,14.1667955 C8.24115385,13.17925 7.77653846,12.5761364 6.62153846,12.1140455 C5.79692308,11.7647727 5.45807692,11.5348864 5.45807692,11.1720909 C5.45807692,10.8668636 5.66269231,10.5585455 6.29153846,10.5585455 C6.70038462,10.5585455 7.015,10.7428409 7.205,10.8958409 C7.33153846,10.9959091 7.49884615,10.9974545 7.60230769,10.87575 L7.99884615,10.4094091 C8.10346154,10.2884773 8.10307692,10.0922045 7.985,9.98209091 C7.72615385,9.74679545 7.23692308,9.38593182 6.61846154,9.32990909 L6.61846154,8.65686364 C6.61846154,8.49575 6.48884615,8.36593182 6.32730769,8.36593182 L5.84730769,8.36593182 C5.68807692,8.36593182 5.55884615,8.49575 5.55884615,8.65686364 L5.55884615,9.40795455 C4.52,9.639 3.975,10.3951136 3.975,11.36025 C3.975,12.4223636 4.68230769,12.9713864 5.71884615,13.36625 C6.43692308,13.6397955 6.74692308,13.9029091 6.74692308,14.3178636 C6.74692308,14.7579318 6.36923077,14.9978636 5.81615385,14.9978636 C5.30961538,14.9978636 4.91076923,14.7780227 4.56192308,14.5612727 C4.42653846,14.4739545 4.24961538,14.5168409 4.16653846,14.6551591 L3.79269231,15.2748864 C3.70923077,15.4116591 3.75038462,15.5944091 3.88884615,15.6751591 Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconIncreaseLimit'
};
</script>
