/**
 * @see {@link https://move-elevator.atlassian.net/wiki/x/QRTIAg}
 */
export const CreditorPermission = {
  CHECK_SOLVENCY: 'debtor_solvency_request',
  DIRECT_PAYMENT: 'invoice_direct_payment',
  VRS_MANUAL_CHECK_MANDATORY: 'verity_risk_manual_check_mandatory'
};

export default CreditorPermission;
