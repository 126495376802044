/**
 * @see {@link https://wiki.move-elevator.de/x/GU9PGw m:e Doc2Portal - Push - Wertedefinition}, "DsDokumenteQuelle" descriptions
 */
export const ExternalVoucherSource = {
  DOKUGUIDE_EPOST: 'DokuguideEPost',
  DEGENER: 'Degener',
  PORTAL_UPLOAD: 'Portalupload',
  RENTAS: 'Rentas',
  MINDFACTORY: 'Mindfactory',
  SAGE_SCHWINDT: 'SageSchwindt',
  ANWALT_FACTORING: 'AnwaltFactoring',
  BILLOMAT: 'Billomat',
  OPENHANDWERK: 'Openhandwerk',
  POINT_S: 'PointS',
  DATAC: 'Datac',
  MEIN_ONLINE_BUERO: 'MeinOnlineBuero',
  VHV: 'Vhv',
  FACTORIX: 'Factorix'
};

export const EXTERNAL_VOUCHER_SOURCES = Object.values(ExternalVoucherSource);

export default ExternalVoucherSource;
