/**
 * @see {@link https://wiki.move-elevator.de/display/DVKMU/Ankaufstatus+-+Wertedefinition Definitions for most products}
 * @see {@link https://wiki.move-elevator.de/display/DVKMU/Allgemeine+Einstellungen+und+Definitionen Definitions with purchase assurance}
 */
export const VoucherSubStatus = {
  ACCEPTED: 'accepted',
  COUNTED_BACK: 'counted_back',
  COUNT_BACK: 'count_back',
  CUSTOMER_COMPLAINT: 'customer_complaint',
  DEFERRED_PAYMENT: 'deferred_payment',
  DUNNING: 'dunning',
  ENCASHMENT: 'encashment',
  HALT: 'halt',
  IN_REVIEW: 'in_review',
  NOT_PURCHASABLE: 'not_purchasable',
  NOT_PURCHASABLE_ANYMORE: 'not_purchasable_anymore',
  NOT_PURCHASED: 'not_purchased',
  PAYMENT_REMINDER: 'payment_reminder',
  DELAYED: 'delayed',
  DISCLOSED: 'disclosed',
  PURCHASABLE: 'purchasable',
  PURCHASED: 'purchased',
  PURCHASED_LATER: 'purchased_later',
  PURCHASED_MANUALLY: 'manual_purchase_list',
  UNKNOWN: 'unknown',
  UNVERIFIED: 'unverified',

  // pending states
  PENDING_DUPLICATE: 'pending_duplicate',
  PENDING_ENCASHMENT_DIRECT: 'pending_encashment_direct',
  PENDING_IN_FUTURE_BILLING: 'pending_in_future_billing',
  PENDING_IN_FUTURE_FACTORING: 'pending_in_future_factoring',
  PENDING_IN_FUTURE_PERFORMANCE_DATE: 'pending_in_future_performance_date',
  PENDING_NOT_PURCHASABLE: 'pending_not_purchasable',
  PENDING_INSURANCE_INVOICE_INCOMPLETE: 'pending_insurance_invoice_incomplete',
  PENDING_INSURANCE_PAYMENT_DEADLINE_TO_SHORT: 'pending_insurance_payment_deadline_to_short',
  PENDING_INSURANCE_PAYMENT_DEADLINE_TO_LONG: 'pending_insurance_payment_deadline_to_long',
  PENDING_INSURANCE_SERVICE_DATE_TO_OLD: 'pending_insurance_date_to_old'
};

export const BASIC_VOUCHER_SUB_STATUS_FILTER_OPTIONS = [
  VoucherSubStatus.PAYMENT_REMINDER,
  VoucherSubStatus.DUNNING,
  VoucherSubStatus.ENCASHMENT,
  VoucherSubStatus.HALT
];

export const TPF_VOUCHER_SUB_STATUS_FILTER_OPTIONS = [
  VoucherSubStatus.CUSTOMER_COMPLAINT,
  VoucherSubStatus.DELAYED
];

export default VoucherSubStatus;
