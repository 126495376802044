const DURATION_MS = 500;
const CANCELLING_EVENTS = ['touchmove', 'wheel', 'contextmenu'];

export const longPressDirectiveOptions = {
  bind(el, { value }) {
    el.dataset.longPressTimeoutId = '0';

    const onpointerup = () => {
      clearTimeout(parseInt(el.dataset.longPressTimeoutId));
      document.removeEventListener('pointerup', onpointerup);
    };

    const onpointerdown = () => {
      document.addEventListener('pointerup', onpointerup);
      CANCELLING_EVENTS.forEach((event) => window.addEventListener(event, onCancellingEvent));

      const timeout = setTimeout(() => {
        value();
      }, DURATION_MS);

      el.dataset.longPressTimeoutId = timeout.toString();
    };

    const onCancellingEvent = () => {
      clearTimeout(parseInt(el.dataset.longPressTimeoutId));
      document.removeEventListener('pointerup', onpointerup);
      CANCELLING_EVENTS.forEach((event) => window.removeEventListener(event, onCancellingEvent));
    };

    el.$_long_press_pointerdown_handler = onpointerdown;
    el.addEventListener('pointerdown', onpointerdown);
  },
  unbind(el) {
    clearTimeout(parseInt(el.dataset.longPressTimeoutId));
    el.removeEventListener('pointerdown', el.$_long_press_pointerdown_handler);
  }
};

export default longPressDirectiveOptions;
