import Vue from 'vue';
import { extend, localize, ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  confirmed,
  digits,
  double,
  email,
  excluded,
  ext,
  length,
  max,
  max_value,
  min,
  numeric,
  required
} from 'vee-validate/dist/rules';
import i18n from '@/helper/i18n';
import de from 'vee-validate/dist/locale/de';
import IBAN from 'iban';
import formatCurrency from '@/helper/filter/formatCurrency';
import { numberToCent } from '@/helper/currency';
import {
  formatIsoDateOfMonthsFromToday,
  formatIsoToLocal,
  formatLocalToIso
} from '@/helper/filter/formatDate';
import formatBytesToMegabytes from '@/helper/formatBytesToMegabytes';
import { parseISO, isValid } from 'date-fns';
import * as fileConditions from '@/statics/fileConditions';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

localize('de', de);

// register available rules from VeeValidate
const rules = {
  confirmed,
  digits,
  double,
  email,
  excluded,
  ext,
  length,
  max,
  max_value,
  min,
  numeric,
  required
};
Object.keys(rules).forEach((key) => extend(key, rules[key]));

extend('is_date', {
  message: (field) => i18n.t('dateNotValid', { field }),
  validate: (value) => {
    return isValid(parseISO(formatLocalToIso(value)));
  }
});

extend('percent', {
  message: i18n.t('percentNotValid'),
  validate: (value) => {
    const matchesRegex = value.match(/([0-9]*[.,])?[0-9]+/);

    if (matchesRegex) {
      const asFloat = parseFloat(value);
      return asFloat >= 0 && asFloat <= 100;
    }

    return false;
  }
});

extend('min_date', {
  message: (field, { minValue }) => i18n.t('minDateNotValid', { date: formatIsoToLocal(minValue) }),
  validate: (value, { minValue }) => {
    return formatLocalToIso(value) >= minValue;
  },
  params: ['minValue']
});

extend('max_date', {
  validate: (value, { maxValue }) => {
    return formatLocalToIso(value) <= maxValue;
  },
  params: ['maxValue'],
  message: (field, { maxValue }) => i18n.t('maxDateNotValid', { date: formatIsoToLocal(maxValue) })
});

extend('min_past_months', {
  message: (field, { months }) => {
    return i18n.tc('minPastMonthsNotValidCounted', months, { field });
  },
  validate: (value, { months }) => {
    return formatLocalToIso(value) <= formatIsoDateOfMonthsFromToday(-months);
  },
  params: ['months']
});

// add custom rule, whether input is valid local number with comma instead decimal point
extend('currency', {
  validate: (value) => {
    return !isNaN(numberToCent(value));
  },
  message: (field) => i18n.t('currencyNotValid', { field })
});

// add custom rule, whether input with comma instead decimal point is greater than passed argument
extend('min_currency', {
  validate: (value, { minValue }) => {
    return numberToCent(value) > +minValue;
  },
  params: ['minValue'],
  message: (field, { minValue }) =>
    i18n.t('minCurrencyNotValid', { amount: formatCurrency(minValue), field })
});

// add custom rule, whether input with comma instead decimal point is equal or greater than passed argument
extend('min_equal_currency', {
  validate: (value, { minValue }) => {
    return numberToCent(value) >= +minValue;
  },
  params: ['minValue'],
  message: (field, { minValue }) =>
    i18n.t('minEqualCurrencyNotValid', { amount: formatCurrency(minValue), field })
});

// add custom rule, whether input with comma instead decimal point is less than passed argument
extend('max_currency', {
  validate: (value, { maxValue }) => {
    return numberToCent(value) <= +maxValue;
  },
  params: ['maxValue'],
  message: (field, { maxValue }) =>
    i18n.t('maxCurrencyNotValid', { amount: formatCurrency(maxValue), field })
});

extend('factorable_turnover', {
  validate: (value, { minValue = 10000000, maxValue = 750000000 }) => {
    return numberToCent(value) <= +maxValue && numberToCent(value) >= +minValue;
  },
  params: ['minValue', 'maxValue'],
  message: (field, { minValue = 10000000, maxValue = 750000000 }) => {
    return i18n.t('factorableTurnoverNotValid', {
      field,
      min: formatCurrency(minValue),
      max: formatCurrency(maxValue)
    });
  }
});

extend('min_size', {
  validate: (file, { minValue }) => {
    return !file || file.size >= minValue;
  },
  params: ['minValue'],
  message: () => i18n.t('fileIsEmpty')
});

extend('max_size', {
  validate: (file, maxValue) => {
    return !file || file.size <= maxValue;
  },
  message: () =>
    i18n.t('fileTooLarge', { max: formatBytesToMegabytes(fileConditions.MAX_FILE_SIZE) })
});

extend('password_constraints', {
  message: (field) => field + ' ' + i18n.t('passwordConstraints'),
  validate: (value) => /(?=.*\d)(?=.*\W)/.test(value)
});

extend('phone', {
  message: (field) => i18n.t('phoneNotValid', { field }),
  validate: (value) => /^\+?[0-9/ -]+$/.test(value) // allowed characters: digits, "+" (only first char), "/", "-", " "
});

extend('iban', {
  message: () => i18n.t('ibanNotValid'),
  validate: (value) => IBAN.isValid(value)
});

// edit email rule to fulfill rfc 5322 standard (no umlauts, no quotes in domain part, no dots before @ etc.))
extend('email', {
  validate: (value) =>
    // eslint-disable-next-line no-control-regex
    /^(?=.{1,64}@)(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?!(?:[0-9]*[.]){0,2}[0-9]*$)[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9-]{1,63})*$/.test(
      value
    )
});

// add custom rule for unique values in array
extend('unique', {
  validate: (value, existentValues = []) =>
    !existentValues.some((existentValue) => existentValue === value),
  message: (field) => i18n.t('valueAlreadyExistent', { name: field })
});
