import QueryParam from '@/statics/queryParam';

export default {
  computed: {
    queryParam() {
      return QueryParam;
    }
  },

  methods: {
    getQueryParam(name) {
      if (this.$route.query[name] === 'true') {
        return true;
      }

      return this.$route.query[name];
    },

    setQueryParam(name, value, replace) {
      if (this.$route.query[name] === value) {
        return;
      }

      this.$router[replace ? 'replace' : 'push']({
        ...this.$route,
        query: { ...this.$route.query, [name]: value || undefined }
      }).catch(() => {});
    },

    setQueryParams(queryObject, replace) {
      const changedQueries = Object.keys(queryObject).reduce((queries, queryParam) => {
        if (
          JSON.stringify(this.$route.query[queryParam]) === JSON.stringify(queryObject[queryParam])
        ) {
          return queries;
        }
        queries[queryParam] = queryObject[queryParam];
        return queries;
      }, {});

      if (Object.keys(changedQueries).length === 0) {
        return;
      }

      this.$router[replace ? 'replace' : 'push']({
        ...this.$route,
        query: { ...this.$route.query, ...changedQueries }
      });
    },

    getFilterQueryParam(name) {
      return this.$route.query[name] || [];
    },

    setFilterQueryParam(name, value) {
      if (typeof value === 'string') {
        value = [value];
      }
      if (JSON.stringify(value) === JSON.stringify(this.$route.query[name])) {
        // enable unselect in single selects
        this.$router.replace({
          ...this.$route,
          query: { ...this.$route.query, [name]: '' }
        });
        return;
      }

      this.$router.replace({
        ...this.$route,
        query: { ...this.$route.query, [name]: value }
      });
    },

    getSelectedAction(actions) {
      if (!this.getQueryParam(this.queryParam.ACTION)) {
        return {};
      }

      const foundAction = actions.find(
        (action) =>
          action.param === this.getQueryParam(this.queryParam.ACTION) &&
          action.permission !== false &&
          this.hasFulfilledCondition(action)
      );

      if (!foundAction) {
        this.setQueryParam(this.queryParam.ACTION, undefined);
        return {};
      }

      return foundAction;
    }
  }
};
