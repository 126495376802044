/**
 * @see {@link https://wiki.move-elevator.de/display/DVKMU/Ankaufstatus+-+Wertedefinition Definitions for most products}
 * @see {@link https://wiki.move-elevator.de/display/DVKMU/Allgemeine+Einstellungen+und+Definitionen Definitions with purchase assurance}
 */
export const VoucherStatusToShow = {
  // default
  OPEN: 'open',
  CLOSED: 'closed',
  FAILED: 'failed',
  PENDING: 'pending',
  UNKNOWN: 'unknown',
  // only tpf
  TPF_IN_REVIEW: 'tpf_in_review',
  TPF_ACCEPTED: 'tpf_accepted',
  TPF_NOT_PURCHASED: 'tpf_not_purchased',
  TPF_BALANCED: 'tpf_balanced'
};

export const BASIC_VOUCHER_STATUS_TO_SHOW_GROUPS = {
  open: [VoucherStatusToShow.OPEN],
  closed: [VoucherStatusToShow.CLOSED],
  failed: [VoucherStatusToShow.FAILED],
  pending: [VoucherStatusToShow.PENDING]
};

export default VoucherStatusToShow;
