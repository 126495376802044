export const NAMESPACE = 'failure';

export default (Vue) => ({
  namespaced: true,

  actions: {
    async checkStatus() {
      try {
        const response = await Vue.prototype.$http.get('/healthy');
        return { status: response.status };
      } catch (error) {
        return { status: error.response?.status ?? 0 };
      }
    }
  }
});
