<template>
  <v-row :dense="isMobile || dense" :class="{ 'mt-2 px-3': !noIndent }" align="start">
    <v-col v-if="hasLabel" cols="12" sm="6">
      <div :class="{ 'd-flex flex-column mt-4': true, 'mb-4': !isMobile }">
        <slot name="label">
          <label>{{ fieldLabel }}:</label>
          <span v-if="subLabel" class="caption grey--text">
            {{ $te(subLabel) ? $t(subLabel) : subLabel }}
          </span>
        </slot>
      </div>
    </v-col>
    <v-col
      cols="12"
      :sm="hasLabel ? '6' : '12'"
      :class="{ 'text-right': textRight, 'align-self-center': !isMobile }"
    >
      <slot></slot>
    </v-col>
    <v-col cols="12" :class="{ 'text-right pt-0': true, 'mt-n3': !isMobile }">
      <slot name="append"></slot>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'FormInputRow',

  data: () => ({
    labelSuffix: ':'
  }),

  props: {
    dense: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    noIndent: {
      type: Boolean,
      default: false
    },
    subLabel: {
      type: String,
      default: ''
    },
    textRight: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    fieldLabel() {
      return this.$te(this.label) ? this.$t(this.label) : this.label;
    },
    hasLabel() {
      return !!this.label || !!this.$scopedSlots.label;
    }
  }
};
</script>
