<template>
  <v-row
    :dense="!mobileLayout"
    :no-gutters="noGutters || mobileLayout"
    :class="{ 'mb-4': mobileLayout }"
    align="baseline"
  >
    <v-col
      :cols="mobileLayout ? 12 : 6"
      :class="{ 'caption align-baseline d-flex grey--text': mobileLayout }"
    >
      <span class="mr-4 d-block">
        <slot name="label">{{ $te(label) ? $t(label) : label }}</slot>
      </span>
      <slot v-if="mobileLayout" name="action"></slot>
    </v-col>
    <v-col
      :class="{
        'd-flex flex-column': true,
        'text-right align-end': textRight && !mobileLayout
      }"
      :cols="mobileLayout ? 12 : 6"
    >
      <slot>{{ value ? value : '-' }}</slot>
      <slot v-if="!mobileLayout" name="action"></slot>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'AppLabeledData',

  props: {
    label: {
      type: String,
      default: ''
    },
    noGutters: {
      type: Boolean,
      default: false
    },
    textRight: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number],
      default: ''
    },
    wrap: {
      type: Boolean,
      default: false
    },
    wrapMobile: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    mobileLayout() {
      return (this.isMobile && this.wrapMobile) || this.wrap;
    }
  }
};
</script>
