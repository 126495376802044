import { LAST_REFRESH_STORAGE } from '@/statics/storageKeys';

/**
 * Sometimes cashed index.html refer to old chunk versions, resulting in syntax errors.
 * This error can occur for component chunks (handled here) or for app-chunk or chunk-vendor (handled in index.html)
 * Attention: changes must possibly be reflected also in public/index.html
 * @param {Error} error
 * @param {RegExp} errorRegex
 */
export const reloadOnError = (error, errorRegex) => {
  if (!errorRegex.test(error.message)) {
    return;
  }

  let { date, version } = JSON.parse(localStorage.getItem(LAST_REFRESH_STORAGE)) || {};

  if (!date || date < Date.now() - 60 * 1000 * 60 || version !== process.env.VUE_APP_VERSION) {
    window.location.reload();
    localStorage.setItem(
      LAST_REFRESH_STORAGE,
      JSON.stringify({ date: Date.now(), version: process.env.VUE_APP_VERSION })
    );
    error.preventDefault();
  }
};

export default reloadOnError;
