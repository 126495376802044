import VoucherSubStatus from '@/statics/voucherSubStatus';
import { INVOICE_TYPES } from '@/statics/voucherType';
import { mapAttachments, mapLocation, mapServiceFeeCondition } from '@/shared/models';
import VoucherPermission from '@/modules/Vouchers/statics/voucherPermission';

const mapChildren = (children) =>
  children.map((child) => ({
    id: child.identifier,
    numberToShow: child.numberToShow,
    officialVoucherNumber: child.officialVoucherNumber,
    balancingVoucherNumber: child.balancingVoucherNumber,
    isBalancingVoucher: child.isBalancingVoucher,
    type: child.type,
    requiresProcessing: child.isProcessableVoucherType,
    amount: child.amount,
    uploaded: child.uploadedDate * 1000,
    voucherDate: child.voucherDate * 1000
  }));

export const mapList = (vouchers) =>
  vouchers.map((voucher) => ({
    id: voucher.identifier,
    status: voucher.status,
    statusToShow: voucher.statusToShow,
    subStatus: voucher.subStatus ?? VoucherSubStatus.UNVERIFIED,
    officialVoucherNumber: voucher.officialVoucherNumber,
    customNumber: voucher.customNumber,
    numberToShow: voucher.numberToShow,
    bnetId: voucher.bnetIdentifier,
    amount: voucher.amount ?? 0,
    purchaseStatus: voucher.purchaseStatus,
    leftoverAmount: voucher.leftoverAmount ?? 0,
    initialLeftoverAmount: voucher.initialLeftOverAmount,
    type: voucher.type,
    paymentDeadline: voucher.paymentDeadline * 1000,
    uploaded: voucher.uploadedDate * 1000,
    uploadedDate: voucher.uploadedDate * 1000,
    voucherDate: voucher.voucherDate * 1000,
    hasWorkQueue: voucher.hasWorkQueue,
    isInvoice: INVOICE_TYPES.includes(voucher.type),
    debtor: voucher.debtor
      ? {
          id: voucher.debtor.identifier,
          name: voucher.debtor.name
        }
      : null,
    location: voucher.location
      ? {
          id: voucher.location.identifier,
          name: voucher.location.name,
          customNumber: voucher.location.customDebtorNumber
        }
      : null,
    source: voucher.source,
    requiresProcessing: voucher.isProcessableVoucherType,
    parent: {
      numberToShow: voucher.parentVoucher?.numberToShow ?? '',
      type: 'invoice',
      officialVoucherNumber: voucher.parentVoucher?.officialVoucherNumber ?? '',
      requiresProcessing: true
    },
    children: mapChildren(voucher.children ?? voucher.childrenVouchers).sort(
      (a, b) => a.uploaded - b.uploaded
    )
  }));

export const mapVoucher = (voucher) => ({
  id: voucher.identifier,
  status: voucher.status,
  statusToShow: voucher.statusToShow,
  subStatus: voucher.subStatus,
  dunningStatus: voucher.dunningStatus,
  dunningType: voucher.dunningType ?? '',
  dunningBlockCount: parseInt(voucher.dunningBlockCount ?? '0'),
  purchaseStatus: voucher.purchaseStatus,
  numberToShow: voucher.numberToShow,
  customNumber: voucher.customNumber,
  officialVoucherNumber: voucher.officialVoucherNumber,
  customisedLabel: voucher.customisedLabel,
  balancingVoucherNumber: voucher.balancingVoucherNumber,
  isBalancingVoucher: voucher.isBalancingVoucher,
  sapNumber: voucher.sapNumber,
  userEmail: voucher.metadata?.userEmail,
  amount: voucher.amount ?? 0,
  leftoverAmount: voucher.leftoverAmount ?? 0,
  bnetId: voucher.bnetIdentifier,
  bnetStatus: voucher.bnetStatus,
  bnetPurchaseStatus: voucher.bnetPurchaseStatusDecision,
  documentId: voucher.documentIdentifier,
  type: voucher.type,
  requiresProcessing: voucher.isProcessableVoucherType,
  source: voucher.source,
  originalName: voucher.originalFileName,
  attachment: mapAttachments(voucher.attachments)[0],
  officialVoucherSource: voucher.officialVoucherSource,
  paymentDeadline: voucher.paymentDeadline * 1000,
  uploaded: voucher.uploadedDate * 1000,
  voucherDate: voucher.voucherDate * 1000,
  isInvoice: INVOICE_TYPES.includes(voucher.type),
  comment: voucher.comment || '',
  parent: voucher.parentVoucher
    ? {
        id: voucher.parentVoucher.identifier,
        numberToShow: voucher.parentVoucher.numberToShow,
        officialVoucherNumber: voucher.parentVoucher.officialVoucherNumber,
        amount: voucher.parentVoucher.amount,
        type: 'invoice',
        requiresProcessing: true
      }
    : null,
  debtor: voucher.debtor
    ? {
        id: voucher.debtor.identifier,
        name: voucher.debtor.name
      }
    : null,
  location: voucher.location ? mapLocation(voucher) : null,
  children: mapChildren(voucher.childrenVouchers).sort((a, b) => a.uploaded - b.uploaded),
  permanentDebt: voucher.permanentDebtVoucher
    ? {
        id: voucher.permanentDebtVoucher.identifier,
        customNumber: voucher.permanentDebtVoucher.customNumber,
        location: voucher.location?.name ?? ''
      }
    : null,
  encashmentFileNumber: voucher.encashmentData?.fileNumber ?? '',
  serviceFee: voucher.serviceFee
    ? {
        ...mapServiceFeeCondition(voucher.serviceFee.condition),
        amount: voucher.serviceFee.amount
      }
    : null
});

export const mapVoucherPermissions = (permissions) => ({
  abandonment: permissions[VoucherPermission.ABANDONMENT],
  annulation: permissions[VoucherPermission.ANNULATION],
  cancelDunningBlock: permissions[VoucherPermission.CANCEL_DUNNING_BLOCK],
  closeDispute: permissions[VoucherPermission.CLOSE_DISPUTE],
  closeDelay: permissions[VoucherPermission.CLOSE_DELAY],
  creditAdvice: permissions[VoucherPermission.CREDIT_ADVICE],
  directPayment: permissions[VoucherPermission.DIRECT_PAYMENT],
  openDispute: permissions[VoucherPermission.OPEN_DISPUTE],
  openDelay: permissions[VoucherPermission.OPEN_DELAY],
  setDunningBlock: permissions[VoucherPermission.SET_DUNNING_BLOCK],
  showBalancingSummary: permissions[VoucherPermission.SHOW_BALANCING_SUMMARY]
});

export const mapOpenWorkQueue = (list) =>
  list
    .filter((entry) => entry.status === 'open')
    .map((entry) => ({
      id: entry.identifier,
      updated: entry.updated * 1000,
      title: entry.title
    }));
