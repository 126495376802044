<template>
  <svg width="24px" height="22px" viewBox="0 0 24 22" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-334.000000, -13.000000)" fill="currentColor" fill-rule="nonzero">
        <g transform="translate(334.000000, 12.000000)">
          <path
            d="M12.0010541,1.19906662 C12.5767705,1.19906662 13.0867851,1.49478266 13.3654931,1.98988078 L13.3654931,1.98988078 L23.8072921,20.546427 C24.0760998,21.0240099 24.0661995,21.589169 23.7805913,22.0574104 C23.4970832,22.5230245 22.9969689,22.8009334 22.442253,22.8009334 L22.442253,22.8009334 L1.55955508,22.8009334 C1.0048392,22.8009334 0.504724881,22.5230245 0.221216764,22.0574104 C-0.0640914053,21.5888771 -0.0739916888,21.0240099 0.194816008,20.546427 L0.194816008,20.546427 L10.636615,1.98988078 C10.915323,1.49478266 11.4253376,1.19906662 12.0010541,1.19906662 Z M12.0013459,2.70199289 C11.7748254,2.70199289 11.5735988,2.81958349 11.4625869,3.01647937 L11.4625869,3.01647937 L1.68089377,20.3999247 C1.57353544,20.5908043 1.57775108,20.8164141 1.6915734,21.0034652 C1.80539571,21.1905163 1.99791022,21.3 2.219934,21.3 L2.219934,21.3 L21.782477,21.3 C22.0045007,21.3 22.197015,21.1905163 22.3108373,21.0034652 C22.4246597,20.8164141 22.4288753,20.5908043 22.321798,20.3999247 L22.321798,20.3999247 L12.5401049,3.01647937 C12.429093,2.81958349 12.2278664,2.70199289 12.0013459,2.70199289 Z M12.0483093,17.6855705 C12.5015842,17.7109754 12.8478718,18.0891157 12.8217651,18.5301715 C12.7956564,18.9712272 12.4070399,19.3081796 11.9537649,19.2827768 C11.5004899,19.2573718 11.1542024,18.8792316 11.1803091,18.4381757 C11.2064178,17.99712 11.5950343,17.6601676 12.0483093,17.6855705 Z M12.0010371,7.63692773 C12.4150371,7.63692773 12.7510371,8.00799744 12.7510371,8.25 L12.7510371,8.25 L12.7510371,15.0074714 C12.7510371,15.2494739 12.4150371,15.5187964 12.0010371,15.5187964 C11.5870371,15.5187964 11.2510371,15.2494739 11.2510371,15.0074714 L11.2510371,15.0074714 L11.2510371,8.25 C11.2510371,8.00799744 11.5870371,7.63692773 12.0010371,7.63692773 Z"
            transform="translate(12.001037, 12.000000) rotate(-360.000000) translate(-12.001037, -12.000000) "
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconAlert'
};
</script>
