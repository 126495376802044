import CreditorStatus from '@/modules/Admin/statics/creditorStatus';
import PRODUCT_CONFIGS from '@/productConfigs';
import Role from '@/statics/role';
import WorkQueueStatus from '@/modules/User/statics/workQueueStatus';
import { EMAIL_NOTIFICATION_SETTING_OPTIONS } from '@/modules/User/statics/emailNotificationSettingOption';
import { INVOICE_TYPES } from '@/statics/voucherType';
import { MILLISECONDS_PER_WEEK } from '@/statics/timePeriods';
import {
  checkRoutePermissionByTabs,
  createPermissionsFactory,
  mergePermissions
} from '@/helper/createPermissionsFactory';
import { mapLocation, mapServiceFeeCondition, mapServiceFeePeriod } from '@/shared/models';
import { mapTermsAndConditionsDocuments } from '@/modules/Authentication/models';

export const mapCreditor = (creditor) => ({
  company: {
    name: creditor.name,
    tradeName: creditor.tradeName,
    salutation: creditor.contactInformation.salutation,
    contactName: creditor.contactInformation.contactName,
    streetAndHouse: creditor.contactInformation.address.street,
    zip: creditor.contactInformation.address.zip,
    city: creditor.contactInformation.address.city,
    phone: creditor.contactInformation.phone,
    fax: creditor.contactInformation.fax,
    mobile: creditor.contactInformation.mobileNumber,
    email: creditor.contactInformation.email
  },
  legal: {
    taxNumber: creditor.contractData.taxNumber,
    registerNumber: creditor.contractData.registerNumber,
    placeOfAuthority: creditor.contractData.placeOfAuthority,
    proprietor: creditor.contractData.proprietor
  },
  sepa: {
    accountOwner: creditor.directDebitMandate.accountOwner,
    iban: creditor.directDebitMandate.iban
  },
  contract: {
    contractNumber: creditor.contractData.contractNumber,
    contractStart: creditor.contractData.contractStart * 1000,
    approvedLimit: creditor.limit.approvedLimit,
    serviceFees: mapServiceFeeCondition(creditor.serviceFeeCondition)
  },
  extensions: {
    letter2PostEnabled: creditor.doc2portal.documentsEnabled,
    doc2PortalEnabled: creditor.doc2portal.invoicesEnabled,
    webAppKey: creditor.webAppKey
  },
  serviceFeePeriod: mapServiceFeePeriod(creditor.serviceFeePeriod),
  documents: mapTermsAndConditionsDocuments(creditor.termsAndConditions?.documents ?? [])
});

export const mapCompanyUsers = (list) =>
  list.map((user) => ({
    id: user.identifier,
    role: user.role,
    name: user.name,
    email: user.email,
    status: user.status,
    isActive: user.active
  }));

export const mapWorkQueue = (list) =>
  list.map((entry) => ({
    id: entry.identifier,
    debtor: {
      name: entry.debtor.name,
      id: entry.debtor.identifier,
      type: entry.debtor.type
    },
    fileId: entry.fileIdentifier,
    location: entry.location ? mapLocation(entry) : null,
    documentSource: entry.documentUrl,
    attachmentSource: entry.easyDocumentUrl,
    vouchers: mapVouchers(entry.vouchers.filter((voucher) => INVOICE_TYPES.includes(voucher.type))),
    counterVouchers: mapVouchers(entry.counterVouchers),
    messages: mapMessages(entry.messages),
    isOpen: entry.status === WorkQueueStatus.OPEN || entry.status === WorkQueueStatus.READ,
    isSent: entry.status === WorkQueueStatus.SENT,
    showWarning: entry.status === WorkQueueStatus.SENT,
    showError:
      entry.status === WorkQueueStatus.ERROR ||
      (entry.status === WorkQueueStatus.OPEN &&
        Date.now() - entry.created * 1000 > MILLISECONDS_PER_WEEK),
    isDone: entry.status === WorkQueueStatus.DONE,
    hasError: entry.status === WorkQueueStatus.ERROR,
    title: entry.title,
    text: entry.text,
    transactionKey: entry.transactionKey,
    updated: entry.updated * 1000,
    created: entry.created * 1000,
    finished: entry.finished * 1000,
    sent: entry.sent * 1000,
    code: entry.stateOfAffairs.code,
    answerCode: entry.answerCode,
    accept: {
      code: entry.stateOfAffairs.accepted.code,
      text: entry.stateOfAffairs.accepted.text,
      fullAmount: entry.stateOfAffairs.accepted.fullAmount,
      followUp: entry.stateOfAffairs.followUpAction,
      noteRequired: entry.stateOfAffairs.accepted.noteRequired,
      fileRequired: entry.stateOfAffairs.acceptFileRequired
    },
    reject: {
      code: entry.stateOfAffairs.rejected.code,
      text: entry.stateOfAffairs.rejected.text,
      fullAmount: entry.stateOfAffairs.rejected.fullAmount,
      followUp: entry.stateOfAffairs.rejectAction,
      noteRequired: entry.stateOfAffairs.rejected.noteRequired,
      fileRequired: entry.stateOfAffairs.rejectFileRequired
    }
  }));

const mapVouchers = (vouchers) => vouchers.map((voucher) => mapVoucher(voucher));

const mapVoucher = (voucher) => ({
  id: voucher.identifier,
  numberToShow: voucher.numberToShow,
  type: voucher.type,
  officialVoucherNumber: voucher.officialVoucherNumber,
  requiresProcessing: voucher.isProcessableVoucherType,
  source: voucher.source,
  originalName: voucher.originalFileName,
  amount: voucher.amount,
  leftoverAmount: voucher.leftOverAmount,
  paymentDeadline: voucher.paymentDeadline * 1000
});

const mapMessages = (list) =>
  list.map((message) => ({
    id: message.identifier,
    text: message.text,
    created: message.created * 1000,
    sentFromCreditor: message.sender === 'creditor'
  }));

export const mapNotifications = (list) =>
  list.map((entry) => ({
    id: entry.identifier,
    status: entry.status,
    created: entry.created * 1000,
    title: entry.title,
    type: entry.type,
    message: entry.message,
    voucher: entry.voucher
      ? {
          id: entry.voucher.identifier,
          type: entry.voucher.type,
          officialVoucherNumber: entry.voucher.officialVoucherNumber,
          requiresProcessing: entry.voucher.isProcessableVoucherType,
          numberToShow: entry.voucher.numberToShow
        }
      : null,
    debtor: entry.debtor
      ? {
          id: entry.debtor.identifier,
          name: entry.debtor.name
        }
      : null
  }));

export const mapCreditorVouchers = (list) =>
  list.map((creditorVoucher) => ({
    amount: creditorVoucher.amount,
    id: creditorVoucher.identifier,
    readStatus: creditorVoucher.readStatus,
    referenceNumber: creditorVoucher.sapReferenceNumber,
    sapNumber: creditorVoucher.sapNumber,
    source: creditorVoucher.archiveSourceFileUrl,
    type: creditorVoucher.type,
    voucherDate: creditorVoucher.voucherDate * 1000
  }));

export const mapEmailNotificationSettings = (response) =>
  response.emailReminderSettings.map((item) => ({
    email: item.email,
    setting: Object.fromEntries(
      Object.entries(item.setting).sort(
        (settingA, settingB) =>
          EMAIL_NOTIFICATION_SETTING_OPTIONS.findIndex((setting) => setting === settingA[0]) -
          EMAIL_NOTIFICATION_SETTING_OPTIONS.findIndex((setting) => setting === settingB[0])
      )
    )
  }));

export const mapCurrentUser = (data) => {
  const userPermissions = mapUserPermissions({
    permissions: data.permission,
    hasAdminRole: data.roles.includes(Role.ADMIN)
  });
  const productPermissions = mapProductPermissions({
    productType: data.product,
    hasAdminRole: data.roles.includes(Role.ADMIN)
  });

  const mergedPermissions = mergePermissions({ productPermissions, userPermissions });

  mergedPermissions['navigation'] = {
    ...mergedPermissions.navigation,
    masterData: checkRoutePermissionByTabs(mergedPermissions.masterData),
    settings: checkRoutePermissionByTabs(mergedPermissions.settings),
    helpCenter: checkRoutePermissionByTabs(mergedPermissions.helpCenter)
  };

  if (data.roles.includes(Role.ADMIN)) {
    mergedPermissions['admin'] = data.permissions;
  }

  return {
    ...data,
    hasActiveServiceFeePeriod: data.isServiceFeePeriodActive,
    isTerminated: data.status === CreditorStatus.TERMINATED,
    permissions: mergedPermissions,
    requiresConfirmationOfFactoringRules: data.isApprovalOf10GoldenRulesNecessary
  };
};

export const mapUserPermissions = ({ permissions, hasAdminRole }) => {
  if (hasAdminRole) {
    return createPermissionsFactory();
  }

  if (!permissions) {
    return;
  }

  return createPermissionsFactory({
    dashboard: {
      availableCreditorLimit: true,
      balancingChart: true,
      cashback: true,
      daysOverdueChart: true,
      daysSalesOutstandingChart: true,
      debtorLimitRate: true,
      delay: true,
      dispute: true,
      dunningBlock: true,
      overdueRate: true,
      turnoverProgressionChart: true,
      turnoverVolumeChart: true,
      showOpenDunningTypeInvoices: true
    },
    debtorDetails: {
      activateDebtor: permissions.debtorDetails.activate,
      activateLocation: permissions.locationDetails.activate,
      addLocation: permissions.locationDetails.add,
      checkSolvency: permissions.debtorDetails.determineSolvency,
      createPermanentDebt: permissions.permanentDebts.create,
      deactivateDebtor: permissions.debtorDetails.deactivate,
      deactivateLocation: permissions.locationDetails.deactivate,
      deletePostAddress: permissions.locationDetails.deletePostAddress,
      editDebtor: permissions.debtorDetails.update,
      editLocation: permissions.locationDetails.update,
      editPaymentData: permissions.debtorDetails.updatePaymentData,
      editPostAddress: permissions.locationDetails.editPostAddress,
      increaseLimit: permissions.debtorDetails.increaseLimit,
      refreshLimit: permissions.debtorDetails.refreshLimit,
      showPaymentData: true,
      showSepa: permissions.debtorDetails.showSepa,
      showSolvency: true,
      tabPermanentDebts: true,
      tabLimit: true,
      uploadInvoice: permissions.locationDetails.uploadVoucher
    },
    debtors: {
      createDebtor: permissions.debtors.create,
      showLimit: true,
      showSumOfPurchasedInvoices: true
    },
    helpCenter: {
      tabFAQ: true,
      tabTutorials: true
    },
    masterData: {
      enableDoc2Portal: true,
      enableLetter2Post: true,
      enableWebApp: true,
      serviceFeesWithPurchaseFees: true,
      serviceFeesWithoutPurchaseFees: true,
      tabAboutMe: permissions.masterData.tabAboutMe,
      tabCreditorVouchers: permissions.masterData.tabCreditorVouchers,
      tabExtensions: permissions.masterData.tabDoc2portal,
      tabMyContract: permissions.masterData.tabContract
    },
    navigation: {
      dashboard: permissions.navigation.dashboard,
      masterData: true,
      permanentDebts: true,
      settings: true,
      unprocessed: permissions.navigation.unprocessed,
      helpCenter: true,
      workQueue: permissions.workQueue.list
    },
    notifications: {
      show: permissions.notifications.notifications
    },
    optIn: {
      accept: permissions.optIn.accept
    },
    permanentDebtDetails: {
      showSepa: permissions.permanentDebtDetails.showSepa,
      stop: permissions.permanentDebtDetails.stop,
      tabInvoices: permissions.permanentDebtDetails.tabInvoices
    },
    permanentDebts: {
      create: permissions.permanentDebts.create
    },
    settings: {
      tabCompanyUsers: permissions.settings.tabCompanyUser,
      tabEmailNotifications: permissions.settings.tabReminder,
      tabGeneral: permissions.settings.tabCommon
    },
    unprocessedDetails: {
      addAttachment: permissions.unprocessedVoucherDetails.addAttachment,
      cancel: permissions.unprocessedVoucherDetails.cancel,
      continue: permissions.unprocessedVoucherDetails.continue,
      deleteAttachment: permissions.unprocessedVoucherDetails.deleteAttachment,
      editDeliveryType: permissions.unprocessedVoucherDetails.editDeliveryType,
      reupload: permissions.unprocessedVoucherDetails.cancel && permissions.vouchers.upload
    },
    voucherDetails: {
      abandonment: permissions.voucherDetails.abandonment,
      annulation: permissions.voucherDetails.annulation,
      cancelDunningBlock: permissions.voucherDetails.reopenDunningBlock,
      closeDispute: permissions.voucherDetails.disputeClose,
      closeDelay: permissions.voucherDetails.delayClose,
      creditAdvice: permissions.voucherDetails.creditAdvice,
      directPayment: permissions.voucherDetails.directPayment,
      openDispute: permissions.voucherDetails.disputeOpen,
      openDelay: permissions.voucherDetails.delayOpen,
      openWorkQueue: permissions.voucherDetails.showOpenWorkQueue,
      setDunningBlock: permissions.voucherDetails.setDunningBlock,
      showPurchaseReversalWarning: true
    },
    vouchers: {
      openWorkQueue: permissions.vouchers.showOpenWorkQueue,
      sum: permissions.vouchers.showSum,
      upload: permissions.vouchers.upload
    },
    workQueue: {
      answer: permissions.workQueue.answer
    }
  });
};

export const mapProductPermissions = ({ productType, hasAdminRole }) => {
  if (hasAdminRole) {
    return createPermissionsFactory();
  }

  if (!productType) {
    return;
  }

  return createPermissionsFactory(
    PRODUCT_CONFIGS.find((config) => config.type === productType).permissions
  );
};
