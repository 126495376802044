export default {
  methods: {
    openVoucherUploadDialog() {
      this.setQueryParam(this.queryParam.UPLOAD_VOUCHER, true);
    },

    openCreateDebtorDialog() {
      this.setQueryParam(this.queryParam.CREATE_DEBTOR, true);
    },

    openCreatePermanentDebtDialog() {
      this.setQueryParam(this.queryParam.CREATE_PERMANENT_DEBT, true);
    }
  }
};
