export default function formatTime(milliseconds, fallback = '-') {
  if (!milliseconds || milliseconds < 0) {
    return fallback;
  }

  return new Date(milliseconds).toLocaleTimeString('de-DE', {
    minute: '2-digit',
    second: '2-digit'
  });
}
