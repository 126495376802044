import ProductType from '@/statics/productType';
import { LOCAL_PRODUCT_STORAGE } from '@/statics/storageKeys';
import { isLocal } from '@/statics/environment';

export const getProductTypeFromSubdomain = () => {
  const productTypeFromSubdomain = window.location.hostname.split('.').shift().toUpperCase();

  return Object.values(ProductType).find((type) => type === productTypeFromSubdomain);
};

export const resolveFromURL = () => {
  if (isLocal) {
    return (
      getProductTypeFromSubdomain() ??
      localStorage.getItem(LOCAL_PRODUCT_STORAGE) ??
      ProductType.DV_FACTORING
    );
  }

  const hostname = window.location.hostname;

  switch (hostname) {
    case process.env.VUE_APP_FACTORING_HOSTNAME:
      return ProductType.DV_FACTORING;
    case process.env.VUE_APP_BILLING_HOSTNAME:
      return ProductType.DV_BILLING;
    case process.env.VUE_APP_TEAMFACTOR_HOSTNAME:
      return ProductType.FW_FACTORING;
    case process.env.VUE_APP_DAA_HOSTNAME:
      return ProductType.DAA_BILLING;
    case process.env.VUE_APP_DAB_HOSTNAME:
      return ProductType.DAB_BILLING;
    case process.env.VUE_APP_FAS_HOSTNAME:
      return ProductType.FAS_BILLING;
    case process.env.VUE_APP_VHV_HOSTNAME:
      return ProductType.VHV_FACTORING;
    case process.env.VUE_APP_TPF_HOSTNAME:
      return ProductType.TPF_FACTORING;
    default:
      return ProductType.DV_FACTORING;
  }
};
