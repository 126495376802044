<template>
  <svg width="30px" height="30px" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect fill="currentColor" fill-rule="nonzero" x="0" y="5" width="30" height="1.5" rx="0.75" />
      <rect
        fill="currentColor"
        fill-rule="nonzero"
        x="0"
        y="10"
        width="30"
        height="1.5"
        rx="0.75"
      />
      <rect
        fill="currentColor"
        fill-rule="nonzero"
        x="0"
        y="15"
        width="30"
        height="1.5"
        rx="0.75"
      />
      <rect
        fill="currentColor"
        fill-rule="nonzero"
        x="0"
        y="20"
        width="30"
        height="1.5"
        rx="0.75"
      />
      <rect
        fill="currentColor"
        fill-rule="nonzero"
        x="0"
        y="25"
        width="17"
        height="1.5"
        rx="0.75"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconText'
};
</script>
