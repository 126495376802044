<template>
  <svg width="48px" height="48px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path
        d="M25.707113,3.42798322 C26.6536569,3.42798322 27.420954,4.19517988 27.420954,5.14172381 C27.420954,6.08826775 26.6536569,6.85556482 25.707113,6.85556482 L25.707113,6.85556482 L5.14142255,6.85556482 C4.19487862,6.85556482 3.42758155,7.62286189 3.42758155,8.56940583 L3.42758155,8.56940583 L3.42758155,42.8455231 C3.42758155,43.792067 4.19487862,44.5593641 5.14142255,44.5593641 L5.14142255,44.5593641 L35.9898578,44.5593641 C36.9364017,44.5593641 37.7036988,43.792067 37.7036988,42.8455231 L37.7036988,42.8455231 L37.7036988,25.7075146 C37.7036988,24.7609707 38.4709958,23.9936736 39.4175398,23.9936736 C40.3640837,23.9936736 41.1313808,24.7609707 41.1313808,25.7074142 L41.1313808,25.7074142 L41.1313808,42.8455231 C41.1313808,45.6850544 38.8294896,47.9869457 35.9899582,47.9869457 L35.9899582,47.9869457 L5.14142255,47.9869457 C2.30189117,47.9869457 -5.00000006e-08,45.6850544 -5.00000006e-08,42.8455231 L-5.00000006e-08,42.8455231 L-5.00000006e-08,8.56940583 C-5.00000006e-08,5.72987444 2.30189117,3.42798322 5.14142255,3.42798322 L5.14142255,3.42798322 Z M37.5,3 C38.2796961,3 38.9204487,3.59488808 38.9931334,4.35553999 L39,4.5 L39,10 L44,10 C44.8284271,10 45.5,10.6715729 45.5,11.5 C45.5,12.2796961 44.9051119,12.9204487 44.14446,12.9931334 L44,13 L39,13 L39,18.5 C39,19.3284271 38.3284271,20 37.5,20 C36.7203039,20 36.0795513,19.4051119 36.0068666,18.64446 L36,18.5 L36,13 L30.5,13 C29.6715729,13 29,12.3284271 29,11.5 C29,10.7203039 29.5948881,10.0795513 30.35554,10.0068666 L30.5,10 L36,10 L36,4.5 C36,3.67157288 36.6715729,3 37.5,3 Z"
        fill="currentColor"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconAddSquare'
};
</script>
