<template>
  <v-progress-circular
    :size="size"
    :width="size / 10"
    :color="color"
    indeterminate
    class="app__spinner"
  />
</template>

<script>
export default {
  name: 'AppSpinner',

  props: {
    color: {
      type: String,
      default: 'primary'
    },
    size: {
      type: [Number, String],
      default: 50
    }
  }
};
</script>
<style scoped>
.app__spinner {
  margin: 0 auto;
}
</style>
