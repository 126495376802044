import UnprocessedPermission from '@/modules/Unprocessed/statics/unprocessedPermission';
import { mapLocation, mapAttachments } from '@/shared/models';

export const mapList = (unprocessedList) =>
  unprocessedList
    .map((unprocessed) => ({
      id: unprocessed.identifier,
      type: unprocessed.type,
      status: unprocessed.status,
      statusToShow: unprocessed.statusToShow,
      subStatus: unprocessed.subStatus,
      bnetStatus: unprocessed.bnetStatus,
      originalName: unprocessed.originalFileName,
      uploaded: unprocessed.uploadedDate * 1000,
      endOfPauseDate:
        unprocessed.endOfPauseDate === null
          ? unprocessed.endOfPauseDate
          : unprocessed.endOfPauseDate * 1000
    }))
    .sort((a, b) => b.uploaded - a.uploaded);

export const mapUnprocessed = (unprocessed) => ({
  attachments: mapAttachments(unprocessed.attachments),
  bnetId: unprocessed.bnetIdentifier,
  bnetPurchaseStatus: unprocessed.bnetPurchaseStatusDecision,
  bnetStatus: unprocessed.bnetStatus,
  debtor: mapDebtor(unprocessed),
  deliveryType: unprocessed.deliveryType,
  email: unprocessed.email,
  emailCopy: unprocessed.emailCopy,
  emailBlindCopy: unprocessed.emailBlindCopy,
  endOfPauseDate:
    unprocessed.endOfPauseDate === null
      ? unprocessed.endOfPauseDate
      : unprocessed.endOfPauseDate * 1000,
  id: unprocessed.identifier,
  emailMarkedForDebtor: unprocessed.emailMarkedForDebtor,
  isProcessed: !!unprocessed.officialVoucherNumber,
  location: unprocessed.location ? mapLocation(unprocessed) : null,
  numberToShow: unprocessed.numberToShow,
  customisedLabel: unprocessed.customisedLabel,
  originalName: unprocessed.originalFileName,
  parent: mapParent(unprocessed),
  pendingStatus: unprocessed.pendingStatus,
  source: unprocessed.source,
  status: unprocessed.status,
  statusToShow: unprocessed.statusToShow,
  subStatus: unprocessed.subStatus,
  type: unprocessed.type,
  uploaded: unprocessed.uploadedDate * 1000,
  voucherDate: unprocessed.voucherDate * 1000
});

const mapParent = (unprocessed) => {
  if (unprocessed.parentVoucher) {
    return {
      id: unprocessed.parentVoucher.identifier,
      numberToShow: unprocessed.parentVoucher.numberToShow,
      type: 'invoice'
    };
  }
  return null;
};

const mapDebtor = (unprocessed) =>
  unprocessed.debtor
    ? {
        id: unprocessed.debtor.identifier,
        name: unprocessed.debtor.name,
        type: unprocessed.debtor.type
      }
    : {};

export const mapBnetErrors = (errorList) =>
  errorList
    // return array of error codes only (codes match format 9XX)
    .filter((entry) => /^9\d{2}$/.test(entry.voucherStatus))
    .map((entry) => entry.voucherStatus);

export const mapUnprocessedPermissions = (permissions) => ({
  cancel: permissions[UnprocessedPermission.CANCEL],
  continue: permissions[UnprocessedPermission.CONTINUE],
  editDeliveryType: permissions[UnprocessedPermission.EDIT_DELIVERY_TYPE]
});
