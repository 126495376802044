import BnetStatus from '@/modules/Unprocessed/statics/bnetStatus';

export default {
  fileName: 'Dateiname',
  saveEmailAtDebtor: 'E-Mail-Adresse in den Kunden-Stammdaten speichern.',
  mailCopy: 'E-Mail CC',
  mailBlindCopy: 'E-Mail BCC',
  attachment: {
    upload: 'Anhang hochladen',
    select: 'Anhänge auswählen',
    uploadCompleted: 'Der Anhang wurde erfolgreich hochgeladen',
    confirmDeleteInfo: 'Möchten Sie den Anhang {fileName} wirklich löschen?',
    info: 'Bis zum Ablauf des Timers können Sie Anhänge jederzeit löschen und neu hochladen, jedoch maximal ein Anhang je Beleg. | Bis zum Ablauf des Timers können Sie Anhänge jederzeit löschen und neu hochladen, jedoch maximal {count} Anhänge je Beleg.',
    confirmDelete: 'Löschen bestätigen'
  },
  cancel: {
    title: 'Beleg löschen',
    confirm: 'Löschen bestätigen',
    info: 'Ihnen ist ein Fehler unterlaufen? Solange der Beleg nicht verarbeitet wurde, können Sie Ihren Auftrag zurückziehen. Der Beleg wird dann aus unserem System entfernt.',
    confirmed: 'Verarbeitung abgebrochen',
    cancelReceived:
      'Die Verarbeitung wurde eingestellt. Sollten Sie zukünftig doch eine Erfassung wünschen, können Sie den Beleg jederzeit erneut hochladen.'
  },
  reupload: {
    title: 'Neu hochladen',
    confirm: 'Abbrechen und neu hochladen',
    info: 'Der Beleg konnte nicht verarbeitet werden. Sie können den Auftrag daher zurückziehen, um ein korrigiertes Dokument hochzuladen.',
    startReupload:
      'Die Verarbeitung wurde eingestellt. Folgen Sie der Weiterleitung, um den korrigierten Beleg hochzuladen',
    startUpload: 'Neuen Beleg hochladen'
  },
  continue: {
    title: 'Verarbeitung fortsetzen',
    continueProcessing: 'Weiter verarbeiten',
    info: 'Alles korrekt? Bitte prüfen Sie die Belegdaten und bestätigen Sie, dass die Verarbeitung des Beleges fortgesetzt werden soll.',
    confirmed: 'Verarbeitung wird fortgesetzt',
    confirmedInfo:
      'Vielen Dank! Wir haben Ihre Rückmeldung erhalten und die weitere Verarbeitung des Beleges eingeleitet.'
  },
  transferBasicProcessing: {
    title: 'Rechnung neu übergeben',
    transfer: 'Neu übergeben',
    info: 'Eine Beauftragung zum Direkt-Inkasso ist leider nicht möglich. Sie können Ihren Auftrag daher zurückziehen und den Beleg als normale Rechnung weiter verarbeiten.',
    confirmedInfo: 'Ihre Rechnung wird nun in den regulären Verarbeitungsprozess überführt.'
  },
  voucherNumberIsGenerated: 'Belegnr. wird generiert...',
  processing: 'In Verarbeitung',
  forInvoice: 'zur Rechnung',
  deliveryInPreparation: 'Dieses Dokument wird zum Versand vorbereitet',
  pauseDateInfo: 'Sie können das Dokument noch {time} Minuten lang bearbeiten.',
  noDataTitle: 'Coole Sache!',
  noDataText: 'Alle Dokumente sind erfolgreich verarbeitet',
  errorInfoCounted:
    'Wir haben {count} Fehler gefunden. Bitte korrigieren Sie das Dokument und laden Sie es erneut hoch.',
  pending: {
    info: 'Wir haben Auffälligkeiten festgestellt, die Ihre Rückmeldung erfordern. Bitte prüfen Sie den Vorgang und entscheiden Sie über das weitere Vorgehen.',
    requestFeedback:
      'Bitte teilen Sie uns mit, ob Sie dennoch eine Verarbeitung Ihrer Rechnung wünschen. Alternativ können Sie Ihre Rechnung auch löschen.',
    duplicate: {
      reason: `@:bnetStatusOptions.${BnetStatus.DUPLICATED_VOUCHER_NUMBER_221}.title`,
      textBilling: '@:unprocessed.pending.requestFeedback',
      textFactoring:
        'Bitte teilen Sie uns mit, ob Sie dennoch eine Verarbeitung Ihrer Rechnung wünschen. In diesem Fall erfolgt KEIN Ankauf der Rechnung. Alternativ können Sie Ihre Rechnung auch löschen.'
    },
    notPurchasable: {
      reason: 'Beleg nicht ankaufbar',
      text: '@:unprocessed.pending.requestFeedback'
    },
    inFutureBilling: {
      reason: 'Belegdatum liegt in der Zukunft',
      text: 'Bitte teilen Sie uns mit, ob Sie dennoch eine unmittelbare Verarbeitung Ihrer Rechnung wünschen. Wir werden Ihren Beleg automatisch weiterverarbeiten, sobald das Belegdatum dem Tagesdatum entspricht. Alternativ können Sie Ihre Rechnung auch löschen.'
    },
    inFutureFactoring: {
      reason: 'Belegdatum liegt in der Zukunft',
      text: 'Bitte teilen Sie uns mit, ob Sie dennoch eine unmittelbare Verarbeitung Ihrer Rechnung wünschen. Bitte beachten Sie, dass Ihre Rechnung in diesem Fall nicht angekauft wird. Wir werden Ihre Rechnung automatisch weiterverarbeiten, sobald das Belegdatum dem Tagesdatum entspricht. Sofern alle weiteren Ankaufkriterien erfüllt sind, wird Ihr Beleg dann auch angekauft. Alternativ können Sie Ihre Rechnung auch löschen.'
    },
    inFuturePerformanceDate: {
      reason: 'Leistungsdatum liegt in der Zukunft',
      text: 'Bitte teilen Sie uns mit, ob Sie dennoch eine unmittelbare Verarbeitung Ihrer Rechnung wünschen. Bitte beachten Sie, dass Ihre Rechnung in diesem Fall nicht angekauft wird. Wir werden Ihre Rechnung automatisch weiterverarbeiten, sobald das Leistungsdatum dem Tagesdatum entspricht. Sofern alle weiteren Ankaufkriterien erfüllt sind, wird Ihr Beleg dann auch angekauft. Alternativ können Sie Ihre Rechnung auch löschen.'
    },
    encashmentDirect: {
      reason: `@:bnetStatusOptions.${BnetStatus.ENCASHMENT_NOT_POSSIBLE_ERROR_935}.title`,
      text: 'Eine direkte Weitergabe Ihrer Rechnung ins Inkasso ist nur für bereits angemahnte, fällige Rechnungen möglich. Sie können diese daher "neu übergeben". Sie wird dann in unseren regulären Verarbeitungsprozess überführt. Alternativ können Sie Ihre Rechnung auch löschen.'
    },
    insuranceInvoiceIncomplete: {
      reason: `Rechnung nicht versichert`,
      text: 'Ein Versicherung der Rechnung ist nicht möglich, da sie nicht alle benötigten Daten enthält. Bitte teilen Sie uns mit ob die Rechnung ohne Versicherungsschutz verarbeitet werden soll. Alternativ können Sie Ihre Rechnung auch löschen.'
    },
    insurancePaymentDeadlineToShort: {
      reason: `Rechnung nicht versichert`,
      text: 'Ein Versicherung der Rechnung ist nicht möglich, da sie bereits fällig ist. Bitte teilen Sie uns mit ob die Rechnung ohne Versicherungsschutz verarbeitet werden soll. Alternativ können Sie Ihre Rechnung auch löschen.'
    },
    insurancePaymentDeadlineToLong: {
      reason: `Rechnung nicht versichert`,
      text: 'Ein Versicherung der Rechnung ist nicht möglich, da das Zahlungsziel mehr als 90 Tage beträgt. Bitte teilen Sie uns mit ob die Rechnung ohne Versicherungsschutz verarbeitet werden soll. Alternativ können Sie Ihre Rechnung auch löschen.'
    },
    insuranceDateToOld: {
      reason: `Rechnung nicht versichert`,
      text: 'Ein Versicherung der Rechnung ist nicht möglich, da das Leistungsdatum mehr als 30 Tage vor dem Rechnungsdatum liegt. Bitte teilen Sie uns mit ob die Rechnung ohne Versicherungsschutz verarbeitet werden soll. Alternativ können Sie Ihre Rechnung auch löschen.'
    }
  },
  isProcessed: 'Dieser Beleg wurde verarbeitet. Sie finden ihn nun in Ihrer Belegübersicht.',
  isEncashmentDirectVoucher:
    'Dieser Beleg wurde zum Direkt-Inkasso übergeben.<br/><br/>Diese Belege werden nicht versendet, daher können Sie keine Versandinformationen übergeben.'
};
