<template>
  <v-btn
    :id="id || title || undefined"
    :block="block"
    :class="{
      'btn--mobile': isMobile,
      'btn--white': color === 'white'
    }"
    :color="color"
    :disabled="disabled || loading"
    :loading="loading"
    depressed
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot>{{ $te(title) ? $t(title) : title }}</slot>
  </v-btn>
</template>

<script>
export default {
  name: 'AppBtn',

  props: {
    block: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.theme--light .v-btn {
  text-transform: none;
  font-size: 1rem;
  letter-spacing: var(--button-letter-spacing);
  min-height: 48px;
  height: auto;
  border-radius: 2px;
  flex-direction: column-reverse;
  max-width: 100%;
  min-width: unset;

  &.btn--mobile {
    white-space: normal;
    word-break: break-word;
    hyphens: auto;
    padding: 0 8px;
  }

  &.v-btn--has-bg {
    background-color: var(--c-button-background);
    color: var(--c-button-text);
  }

  &:hover:not(.v-btn--has-bg) {
    border-color: inherit;
    color: var(--c-button-background);
  }

  &.btn--white {
    --c-disabled: rgba(255, 255, 255, 0.8);

    color: var(--text);

    &.v-btn--disabled {
      background-color: var(--c-disabled) !important;
    }

    &.v-btn--outlined {
      background-color: unset !important;
      border-color: var(--c-white);
      color: var(--c-white);

      &.v-btn--disabled {
        color: var(--c-disabled) !important;
        border-color: var(--c-disabled);
      }
    }
  }
}
</style>
