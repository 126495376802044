<template>
  <svg
    width="30px"
    height="30px"
    viewBox="0 0 30 30"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g fill="currentColor" fill-rule="nonzero">
        <path
          d="M12.8571429,11.7857143 C13.4488765,11.7857143 13.9285714,12.2654092 13.9285714,12.8571429 L13.9285714,28.9285714 C13.9285714,29.5203051 13.4488765,30 12.8571429,30 L1.07142857,30 C0.479694911,30 0,29.5203051 0,28.9285714 L0,12.8571429 C0,12.2654092 0.479694911,11.7857143 1.07142857,11.7857143 L12.8571429,11.7857143 Z M28.9285714,20.3571429 C29.5203051,20.3571429 30,20.8368378 30,21.4285714 L30,28.9285714 C30,29.5203051 29.5203051,30 28.9285714,30 L17.1428571,30 C16.5511235,30 16.0714286,29.5203051 16.0714286,28.9285714 L16.0714286,21.4285714 C16.0714286,20.8368378 16.5511235,20.3571429 17.1428571,20.3571429 L28.9285714,20.3571429 Z M11.7857143,13.9285714 L2.14285714,13.9285714 L2.14285714,27.8571429 L11.7857143,27.8571429 L11.7857143,13.9285714 Z M27.8571429,22.5 L18.2142857,22.5 L18.2142857,27.8571429 L27.8571429,27.8571429 L27.8571429,22.5 Z M28.9285714,0 C29.5203051,0 30,0.479694911 30,1.07142857 L30,17.1428571 C30,17.7345908 29.5203051,18.2142857 28.9285714,18.2142857 L17.1428571,18.2142857 C16.5511235,18.2142857 16.0714286,17.7345908 16.0714286,17.1428571 L16.0714286,1.07142857 C16.0714286,0.479694911 16.5511235,0 17.1428571,0 L28.9285714,0 Z M27.8571429,2.14285714 L18.2142857,2.14285714 L18.2142857,16.0714286 L27.8571429,16.0714286 L27.8571429,2.14285714 Z M12.8571429,0 C13.4488765,0 13.9285714,0.479694911 13.9285714,1.07142857 L13.9285714,8.57142857 C13.9285714,9.16316223 13.4488765,9.64285714 12.8571429,9.64285714 L1.07142857,9.64285714 C0.479694911,9.64285714 0,9.16316223 0,8.57142857 L0,1.07142857 C0,0.479694911 0.479694911,0 1.07142857,0 L12.8571429,0 Z M11.7857143,2.14285714 L2.14285714,2.14285714 L2.14285714,7.5 L11.7857143,7.5 L11.7857143,2.14285714 Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconDashboard'
};
</script>
