<template>
  <v-btn
    :id="id || title || undefined"
    :class="{
      'tile-btn': true,
      'tile-btn--mobile': isMobile,
      'tile-btn--shadow': !depressed,
      'tile-btn--square': square
    }"
    :color="color"
    :depressed="depressed"
    :disabled="disabled || loading"
    :loading="loading"
    block
    text
    tile
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div class="tile-btn__wrapper">
      <component
        :is="icon"
        class="tile-btn__icon"
        :style="disabled ? 'fill: rgba(0,0,0,0.26)' : null"
      />
      {{ $te(title) ? $t(title) : title }}
    </div>
  </v-btn>
</template>

<script>
export default {
  name: 'TileBtn',

  props: {
    color: {
      type: String,
      default: undefined
    },
    depressed: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: [String, Object],
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    square: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.tile-btn.v-btn {
  @include caption-font-style;
  min-height: 96px;
  margin-top: 8px;
  padding: 12px 4px 4px 4px;
  flex-direction: column-reverse;
  text-transform: none;
  border-radius: 2px;
  white-space: normal;

  ::v-deep .v-btn__content {
    svg {
      margin-bottom: 8px;
    }
  }

  &.tile-btn--mobile {
    word-break: break-word;
    hyphens: auto;

    &:not(.tile-btn--square) {
      @include subtitle-1-font-style;
      min-height: 60px;
      height: auto;
      padding: 4px;
      align-items: flex-start;
      border: 1px solid #c3c3c3;

      .tile-btn__wrapper {
        flex-direction: row;
        justify-content: flex-start;
        text-align: start;
      }

      .tile-btn__icon {
        margin: 0 8px;
        height: auto;
        width: 30px;
      }
    }
  }
}

.tile-btn--shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

.tile-btn--square {
  width: 96px;
  height: 96px;
}

.tile-btn__wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.tile-btn__icon {
  height: 30px;
  width: auto;
}
</style>
