import Vue from 'vue';
import VueAxios from 'vue-axios';
import axios from 'axios';
import { ERROR_STATES } from '@/modules/Failure/statics/errorStatus';

export const BASE_URL = process.env.VUE_APP_DV_API_URL || `${window.document.location.origin}/api`;

const instance = axios.create({ baseURL: BASE_URL });
instance.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (ERROR_STATES.includes(err.response?.status)) {
      Vue.prototype.$eventHub.$emit('show-failure-page', err.response?.status);
    }

    return Promise.reject(err);
  }
);
Vue.use(VueAxios, instance);
