import VueAuth from '@websanova/vue-auth/dist/v2/vue-auth.common.js';
import driverHttpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.js';
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.js';
import { isLocal } from '@/statics/environment';
import { mapCurrentUser } from '@/modules/User/models';
import { resolveFromURL } from '@/helper/productResolver';

const productFromLocalStorage = isLocal ? resolveFromURL() : '';
const buildTokenKey = (key) =>
  !productFromLocalStorage ? key : key + '_' + productFromLocalStorage;

export const AUTH_TOKEN_KEY = buildTokenKey('auth_token_default');
export const REFRESH_TOKEN_KEY = buildTokenKey('refresh_token_default');
export const ADMIN_TOKEN_KEY = buildTokenKey('admin');
export const ADMIN_REFRESH_TOKEN_KEY = buildTokenKey('refresh_token_admin');

const REFRESH_API = 'token/refresh';

export default (Vue, router) => {
  const bearerAuth = {
    request: function (req, token) {
      if (req.url === REFRESH_API) {
        req.data = {
          refresh_token: this.token(this.options.refreshTokenKey),
          product: productFromLocalStorage || resolveFromURL()
        };
        return;
      }
      this.drivers.http.setHeaders.call(this, req, {
        Authorization: 'Bearer ' + token,
        ...(!!Vue.prototype.$auth.user()?.id && { 'X-User-ID': Vue.prototype.$auth.user()?.id }),
        ...(!!this.token(AUTH_TOKEN_KEY) && { 'X-User-Token': this.token(AUTH_TOKEN_KEY) }),
        ...(!!this.token(ADMIN_TOKEN_KEY) && { 'X-Admin-Token': this.token(ADMIN_TOKEN_KEY) })
      });
    },
    response: function (res) {
      const data = res.data || {};

      if (data.refresh_token) {
        this.token(this.options.refreshTokenKey, data.refresh_token);
      }

      return data.token;
    }
  };

  Vue.use(VueAuth, {
    plugins: {
      http: Vue.axios,
      router: router
    },
    drivers: {
      auth: bearerAuth,
      http: driverHttpAxios,
      router: driverRouterVueRouter
    },
    options: {
      tokenDefaultKey: AUTH_TOKEN_KEY,
      refreshTokenKey: REFRESH_TOKEN_KEY,
      stores: ['storage'],
      rolesKey: 'roles',
      loginData: {
        url: 'login',
        method: 'POST',
        redirect: '/',
        fetchUser: true,
        staySignedIn: true
      },
      fetchData: {
        url: 'user-management/user/current',
        method: 'GET',
        enabled: true
      },
      cookie: {
        HttpOnly: true
      },
      refreshData: {
        url: REFRESH_API,
        method: 'POST',
        enabled: true,
        interval: 10
      },
      parseUserData: (data) => {
        if (!data || Object.keys(data).length === 0) {
          return {};
        }

        return mapCurrentUser(data);
      }
    }
  });
};
