<template>
  <svg
    width="30px"
    height="30px"
    viewBox="0 0 30 30"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        id="path-delete"
        d="M3.45964034,30 L26.5403597,30 C26.6820522,30.0004367 26.8174678,29.9407703 26.9138253,29.8354422 C27.0101828,29.7301141 27.0586987,29.5887256 27.0476282,29.4455039 L27.0476282,6.76751564 C27.0465983,6.62755888 26.9893556,6.49407224 26.8891068,6.39785318 L20.4689891,0.113591371 L20.4689891,0.113591371 L20.3897284,0.065374528 L20.3025416,0.00912154509 C20.2397004,-0.00304051503 20.1751572,-0.00304051503 20.1123159,0.00912154509 L3.46756642,0.00912154509 C3.33302565,0.00697349684 3.20315538,0.0591103659 3.10653403,0.154059246 C3.00991268,0.249008125 2.95445757,0.378988742 2.95237179,0.515398392 L2.95237179,29.4455039 C2.94130135,29.5887256 2.98981721,29.7301141 3.08617471,29.8354422 C3.18253222,29.9407703 3.31794784,30.0004367 3.45964034,30 Z M20.6557377,2.45901639 L24.7463642,6.38385034 L20.6557377,6.38385034 L20.6557377,2.45901639 Z M4.26771274,1.30937758 L19.4716332,1.30937758 L19.4716332,6.95073357 C19.4716332,7.22845032 19.692551,7.45358424 19.9650668,7.45358424 L25.7243612,7.45358424 L25.7243612,28.667597 L4.27542264,28.667597 L4.26771274,1.30937758 Z M19.3563736,20.1290436 C19.188411,20.1276605 19.0278152,20.0586514 18.9097181,19.9370924 L10.1949712,11.0678621 C9.94554918,10.8140187 9.94554918,10.4024572 10.1949712,10.1486138 C10.4443931,9.89477044 10.848786,9.89477044 11.098208,10.1486138 L19.8129549,19.0178442 C19.9996739,19.2016484 20.0576163,19.4826341 19.9592582,19.727327 C19.8609001,19.9720199 19.6260629,20.1311089 19.3662993,20.1290436 L19.3563736,20.1290436 Z M10.6416267,20.0280273 C10.3818632,20.0300926 10.147026,19.8710036 10.0486679,19.6263107 C9.95030981,19.3816178 10.0082522,19.1006321 10.1949712,18.9168279 L18.9097181,10.0475975 C19.15914,9.79375415 19.5635329,9.79375415 19.8129549,10.0475975 C20.0623769,10.3014409 20.0623769,10.7130024 19.8129549,10.9668458 L11.098208,19.8360761 C10.977691,19.9601251 10.8130309,20.0293424 10.6416267,20.0280273 Z"
      />
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g>
        <mask fill="white">
          <use xlink:href="#path-delete" />
        </mask>
        <use fill="currentColor" fill-rule="nonzero" xlink:href="#path-delete" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconDeleteFile'
};
</script>
