/**
 * @see {@link https://wiki.move-elevator.de/display/DVKMU/Statusliste+bNet+--%3E+ME Statusliste bNet --> ME}
 */
/* eslint-disable sonarjs/no-duplicate-string */
import BnetStatus from '@/modules/Unprocessed/statics/bnetStatus';

export default {
  bnetStatusOptions: {
    [BnetStatus.IN_VALIDATION_102]: {
      title: 'Dokument wird geprüft',
      description: 'Ihr hochgeladenes Dokument wird auf Verarbeitbarkeit geprüft.'
    },
    [BnetStatus.IN_EDIT_103]: {
      title: 'Dokument erfolgreich geprüft',
      description:
        'Ihr hochgeladenes Dokument kann von uns verarbeitet werden. Die Inhalte werden ausgelesen.'
    },
    [BnetStatus.DEBTOR_PROCESSING_104]: {
      title: 'Kunde wird erkannt',
      description: 'Die Kundeninformationen auf Ihrer hochgeladenen Rechnung werden ausgelesen.'
    },
    [BnetStatus.DEBTOR_PROCESSING_COMPLETED_105]: {
      title: 'Kunde wird erkannt',
      description: 'Die Kundeninformationen auf Ihrer hochgeladenen Rechnung werden ausgelesen.'
    },
    [BnetStatus.DEBTOR_QUALITY_MANAGEMENT_106]: {
      title: 'Kunde wird manuell erfasst',
      description:
        'Die Kundeninformationen auf Ihrer hochgeladenen Rechnung werden manuell geprüft und erfasst. Hierbei kann es zu Verzögerungen kommen.'
    },
    [BnetStatus.VOUCHER_PROCESSING_107]: {
      title: 'Dokument wird erkannt',
      description: 'Die Rechnungsinhalte auf Ihrer hochgeladenen Rechnung werden ausgelesen.'
    },
    [BnetStatus.GENERAL_QUALITY_MANAGEMENT_108]: {
      title: 'Dokument wird manuell geprüft',
      description:
        'Die Rechnungsinhalte auf Ihrer hochgeladenen Rechnung  werden manuell geprüft und erfasst. Hierbei kann es zu Verzögerungen kommen.'
    },
    [BnetStatus.VOUCHER_PAUSED_109]: {
      title: 'Verarbeitung wurde unterbrochen',
      description: 'Die Verarbeitung Ihrer hochgeladenen Rechnung verzögert sich.'
    },
    [BnetStatus.VOUCHER_READ_110]: {
      title: 'Dokument erkannt'
    },
    [BnetStatus.DEBTOR_VERIFIED_111]: {
      title: 'Dokument erkannt'
    },
    [BnetStatus.OFFICIAL_VOUCHER_NUMBER_RECEIVED_112]: {
      title: 'Dokument erkannt'
    },
    [BnetStatus.SENT_TO_SAP_115]: {
      title: 'Beleg gebucht'
    },
    [BnetStatus.SEND_MAIL_COMMITTED_120]: {
      title: 'Postsendung wurde übergeben'
    },
    [BnetStatus.SEND_EMAIL_COMMITTED_121]: {
      title: 'E-Mail-Versand wurde eingeleitet'
    },
    [BnetStatus.SEND_MAIL_IN_PRODUCTION_123]: {
      title: 'Postsendung wird produziert'
    },
    [BnetStatus.SEND_MAIL_PRODUCED_124]: {
      title: 'Postsendung wird zugestellt'
    },
    [BnetStatus.SEND_EMAIL_SHIPPED_125]: {
      title: 'E-Mail-Versand'
    },
    [BnetStatus.SEND_EMAIL_ERROR_150]: {
      title: 'E-Mail-Versand fehlgeschlagen'
    },
    [BnetStatus.SEND_EMAILCC_ERROR_151]: {
      title: 'E-Mail-Versand "cc" fehlgeschlagen'
    },
    [BnetStatus.SEND_EMAILBBC_ERROR_152]: {
      title: 'E-Mail-Versand "bcc" fehlgeschlagen'
    },
    [BnetStatus.SEND_EMAIL_UNKNOWN_ERROR_153]: {
      title: 'E-Mail-Versand fehlgeschlagen'
    },
    [BnetStatus.VOUCHER_IN_FUTURE_FACTORING_220]: {
      title: 'Belegdatum in der Zukunft',
      description: 'Das Belegdatum Ihres Beleges liegt in der Zukunft'
    },
    [BnetStatus.DUPLICATED_VOUCHER_NUMBER_221]: {
      title: 'Belegnummer bereits vorhanden',
      description: 'Es gibt bereits einen Beleg mit der gleichen Belegnummer'
    },
    [BnetStatus.VOUCHER_IN_FUTURE_BILLING_222]: {
      title: 'Belegdatum in der Zukunft',
      description: 'Das Belegdatum Ihres Beleges liegt in der Zukunft'
    },
    [BnetStatus.VOUCHER_BLOCKED_801]: {
      title: 'Verarbeitung wurde abgebrochen'
    },
    [BnetStatus.INTERNAL_ERROR_901]: {
      title: 'interner Verarbeitungsfehler'
    },
    [BnetStatus.SAP_ERROR_902]: {
      title: 'Buchungsfehler'
    },
    [BnetStatus.PRINT_ERROR_904]: {
      title: 'Postversand fehlgeschlagen'
    },
    [BnetStatus.VOUCHER_GENERAL_ERROR_920]: {
      title: 'Dokument nicht lesbar'
    },
    [BnetStatus.VOUCHER_ADDRESS_ERROR_921]: {
      title: 'Adresse fehlerhaft'
    },
    [BnetStatus.VOUCHER_DATE_ERROR_922]: {
      title: 'Belegdatum fehlerhaft'
    },
    [BnetStatus.VOUCHER_NUMBER_ERROR_923]: {
      title: 'Belegnummer fehlt'
    },
    [BnetStatus.VOUCHER_FORMAT_ERROR_925]: {
      title: 'Datei bzw. Format fehlerhaft'
    },
    [BnetStatus.VOUCHER_TYPE_ERROR_926]: {
      title: 'Dokument ist keine Rechnung'
    },
    [BnetStatus.VOUCHER_DELIVERY_PERFORMANCE_ERROR_928]: {
      title: 'Liefer-/ Leistungsdatum fehlt'
    },
    [BnetStatus.VOUCHER_NOT_READABLE_ERROR_929]: {
      title: 'Dokument nicht lesbar'
    },
    [BnetStatus.VOUCHER_PAYMENT_CONDITION_ERROR_930]: {
      title: 'Zahlungsbedingungen fehlen'
    },
    [BnetStatus.VOUCHER_AMOUNT_ERROR_931]: {
      title: 'Betrag fehlerhaft'
    },
    [BnetStatus.VOUCHER_CURRENCY_ERROR_932]: {
      title: 'Währung ungültig'
    },
    [BnetStatus.VOUCHER_COUNTRY_ERROR_933]: {
      title: 'Empfängerland ungültig'
    },
    [BnetStatus.VOUCHER_DOCUMENT_PROTECTED_ERROR_934]: {
      title: 'Dokument hat Kennwortschutz',
      description: 'Das Dokument konnte nicht verarbeitet werden.'
    },
    [BnetStatus.ENCASHMENT_NOT_POSSIBLE_ERROR_935]: {
      title: 'Rechnung nicht fällig'
    },
    [BnetStatus.PDF_MULTIPLE_VOUCHERS_ERROR_936]: {
      title: 'Dokument besteht aus mehr als einer Rechnung'
    },
    [BnetStatus.VOUCHER_ATTACHMENT_DOCUMENT_PROTECTED_ERROR_937]: {
      title: 'Anlage hat Kennwortschutz'
    },
    [BnetStatus.VOUCHER_ATTACHMENT_UNDEFINED_ERROR_938]: {
      title: 'Anlage fehlerhaft'
    },
    [BnetStatus.VOUCHER_AMOUNT_ZERO_ERROR_939]: {
      title: 'Betrag ungültig'
    }
  }
};
