import DebtorType from '@/modules/Debtors/statics/debtorType';
import CreditorPermission from '@/modules/User/statics/creditorPermission';
import { add } from 'date-fns';

export const mapAddress = (address) => {
  const addressData = address?.value ?? address;

  if (!addressData) {
    return undefined;
  }

  return {
    name: (addressData.firstName ? `${addressData.firstName} ` : '') + (addressData.name ?? ''),
    city: addressData.city,
    house: addressData.house,
    street: addressData.street,
    streetAndHouse: addressData.street + (addressData.house ? ` ${addressData.house}` : ''),
    zip: addressData.zip
  };
};

export const mapTypeRelatedDebtorData = (debtor) => {
  if (debtor.debtorType === DebtorType.CUSTOMER) {
    return {
      name: debtor.customerDebtor.firstName + ' ' + debtor.customerDebtor.lastName,
      firstName: debtor.customerDebtor.firstName,
      lastName: debtor.customerDebtor.lastName,
      dateOfBirth: debtor.customerDebtor.dateOfBirth * 1000
    };
  }

  if (debtor.debtorType === DebtorType.BUSINESS) {
    return {
      name: debtor.businessDebtor.name
    };
  }
};

export const mapLocation = (item) => ({
  id: item.location.identifier,
  debtorId: item.debtor.identifier,
  name: item.location.name,
  customNumber: item.location.customDebtorNumber,
  deliveryType: item.location.deliveryType,
  created: item.location.creationDate * 1000,
  updated: item.location.updateDate * 1000,
  invoiceVolume: item.location.invoiceVolume,
  address: mapAddress(item.location.normalizedAddress),
  postAddress: item.location.postAddress ? mapAddress(item.location.postAddress) : null
});

export const mapAttachments = (attachments) =>
  attachments.map((attachment) => ({
    id: attachment.identifier,
    fileName: attachment.fileName,
    source: attachment.filePath
  }));

export const mapHistory = (history) =>
  history.map((entry) => ({
    id: entry.eventIdentifier,
    event: entry.event,
    text: entry.text,
    metadata: entry.metadata,
    published: entry.published * 1000,
    comment: entry.comment,
    documentSource: entry.documentUrl
  }));

export const mapCreditorPermissions = (permissions) => ({
  directPayment: permissions[CreditorPermission.DIRECT_PAYMENT],
  checkSolvency: permissions[CreditorPermission.CHECK_SOLVENCY],
  vrsManualCheckMandatory: permissions[CreditorPermission.VRS_MANUAL_CHECK_MANDATORY]
});

export const mapServiceFeeCondition = (condition) => ({
  id: condition.identifier,
  status: condition.status,
  serviceFeeMatrix: mapServiceFeeMatrix(condition.serviceFeeMatrixEntry),
  created: condition.creationDate * 1000,
  updated: condition.updateDate * 1000,
  startDate: condition.validFromDate * 1000,
  isCurrent: condition.current,
  isDefault: condition.isDefault
});

export const mapServiceFeeMatrix = (matrixEntry) => ({
  id: matrixEntry.identifier,
  name: matrixEntry.name,
  purchasedFee: matrixEntry.purchasedFee,
  notPurchasedFee: matrixEntry.notPurchasedFee,
  purchasedLaterFee: matrixEntry.purchasedLaterFee,
  minimumServiceFee: matrixEntry.minimumServiceFee,
  productType: matrixEntry.productType,
  sapId: matrixEntry.sapIdentifier,
  isDefault: matrixEntry.isDefault
});

export const mapServiceFeePeriod = (data) =>
  !data
    ? null
    : {
        created: data.creationDate * 1000,
        currentPeriod: {
          serviceFee: data.currentServiceFeeAnnual,
          chargedServiceFee: data.serviceFeeAmount,
          chargedPercentServiceFee: data.serviceFeeAmount / (data.currentServiceFeeAnnual / 100),
          start: data.start * 1000,
          end: data.end * 1000
        },
        nextPeriod: {
          serviceFee: data.nextPeriodServiceFeeAnnual,
          start: Date.parse(add(data.start * 1000, { years: 1 })),
          end: Date.parse(add(data.end * 1000, { years: 1 }))
        }
      };
