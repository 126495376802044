export const UserStatus = {
  ACTIVE: 'activated',
  DEACTIVATED: 'deactivated',
  NEW: 'new',
  PASSWORD_RESET: 'password_reset',
  PASSWORD_RESET_REQUESTED: 'password_reset_request',
  TERMINATED: 'terminated'
};

export const USER_STATUS_GROUPS = {
  [UserStatus.ACTIVE]: [
    UserStatus.ACTIVE,
    UserStatus.PASSWORD_RESET,
    UserStatus.PASSWORD_RESET_REQUESTED
  ],
  [UserStatus.NEW]: [UserStatus.NEW],
  [UserStatus.DEACTIVATED]: [UserStatus.DEACTIVATED],
  [UserStatus.TERMINATED]: [UserStatus.TERMINATED]
};

export default UserStatus;
