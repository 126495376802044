<template>
  <svg
    width="37px"
    height="37px"
    viewBox="0 0 37 37"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-294.000000, -786.000000)" fill="currentColor" fill-rule="nonzero">
        <g transform="translate(294.000000, 786.000000)">
          <g>
            <path
              d="M36.288,17.856 L34.6176,17.856 C34.3296,9.3888 27.4752,2.5344 19.008,2.2464 L19.008,0.576 C19.008,0.2304 18.7776,0 18.432,0 C18.0864,0 17.856,0.2304 17.856,0.576 L17.856,2.2464 C9.3888,2.5344 2.5344,9.3888 2.2464,17.856 L0.576,17.856 C0.2304,17.856 0,18.0864 0,18.432 C0,18.7776 0.2304,19.008 0.576,19.008 L2.2464,19.008 C2.5344,27.4752 9.3888,34.3296 17.856,34.6176 L17.856,36.288 C17.856,36.6336 18.0864,36.864 18.432,36.864 C18.7776,36.864 19.008,36.6336 19.008,36.288 L19.008,34.6176 C27.4752,34.3296 34.3296,27.4752 34.6176,19.008 L36.288,19.008 C36.6336,19.008 36.864,18.7776 36.864,18.432 C36.864,18.0864 36.6336,17.856 36.288,17.856 Z M19.008,33.4656 L19.008,31.7952 C19.008,31.4496 18.7776,31.2192 18.432,31.2192 C18.0864,31.2192 17.856,31.4496 17.856,31.7952 L17.856,33.4656 C10.0224,33.1776 3.6864,26.8416 3.3984,19.008 L5.0688,19.008 C5.4144,19.008 5.6448,18.7776 5.6448,18.432 C5.6448,18.0864 5.4144,17.856 5.0688,17.856 L3.3984,17.856 C3.6864,10.0224 10.0224,3.6864 17.856,3.3984 L17.856,5.0688 C17.856,5.4144 18.0864,5.6448 18.432,5.6448 C18.7776,5.6448 19.008,5.4144 19.008,5.0688 L19.008,3.3984 C26.8416,3.6864 33.1776,10.0224 33.4656,17.856 L31.7952,17.856 C31.4496,17.856 31.2192,18.0864 31.2192,18.432 C31.2192,18.7776 31.4496,19.008 31.7952,19.008 L33.4656,19.008 C33.1776,26.8416 26.8416,33.1776 19.008,33.4656 Z"
            />
            <path
              d="M21.3696,16.9344 C21.8304,16.3008 22.1184,15.552 22.1184,14.688 C22.1184,12.672 20.448,11.0016 18.432,11.0016 C16.416,11.0016 14.7456,12.672 14.7456,14.688 C14.7456,15.4944 15.0336,16.3008 15.4944,16.8768 C13.3056,17.9712 11.8656,20.2176 11.8656,22.752 C11.8656,23.4432 12.2688,24.48 14.112,25.1712 C15.264,25.5744 16.8192,25.8048 18.432,25.8048 C21.7152,25.8048 24.9984,24.8832 24.9984,22.752 C24.9984,20.2752 23.5584,18.0288 21.3696,16.9344 Z M18.432,12.1536 C19.8144,12.1536 20.9664,13.3056 20.9664,14.688 C20.9664,16.0704 19.8144,17.2224 18.432,17.2224 C17.0496,17.2224 15.8976,16.0704 15.8976,14.688 C15.8976,13.3056 17.0496,12.1536 18.432,12.1536 Z M18.432,24.7104 C15.3792,24.7104 13.0176,23.8464 13.0176,22.8096 C13.0176,20.6208 14.3424,18.6048 16.416,17.7984 C16.992,18.2016 17.6832,18.432 18.432,18.432 C19.1808,18.432 19.872,18.2016 20.448,17.7984 C22.464,18.6048 23.8464,20.5632 23.8464,22.8096 C23.8464,23.8464 21.4848,24.7104 18.432,24.7104 Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconTargetClient'
};
</script>
