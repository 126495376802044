import { calculateCreditorLimitFromTurnover } from '@/onboarding/helper/calculateCreditorLimitFromTurnover';
import { formatDateToTimestamp, formatTimestampToDate } from '@/helper/filter/formatDate';
import { mapServiceFeePeriod } from '@/shared/models';

export const COMPANY = 'company';
export const NAME = 'name';
export const SALUTATION = 'salutation';
export const FIRST_NAME = 'firstName';
export const LAST_NAME = 'lastName';
export const TRADE_NAME = 'tradeName';
export const FOUNDING_DATE = 'foundingDate';
export const TURNOVER = 'turnover';
export const ONLINE_TURNOVER_RATE = 'onlineTurnoverRate';
export const FACTORABLE_TURNOVER = 'factorableTurnover';
export const CREDITOR_LIMIT = 'creditorLimit';
export const STREET_AND_HOUSE = 'streetAndHouse';
export const ZIP = 'zip';
export const CITY = 'city';
export const PHONE = 'phone';
export const FAX = 'fax';
export const MOBILE = 'mobile';
export const EMAIL = 'email';

export const LEGAL = 'legal';
export const ENTITLED_TO_DEDUCT_VAT = 'entitledToDeductVat';
export const VOB = 'vob';
export const TAX_NUMBER = 'taxNumber';
export const REGISTER_NUMBER = 'registerNumber';
export const PLACE_OF_AUTHORITY = 'placeOfAuthority';
export const PROPRIETOR = 'proprietor';

export const SEPA = 'sepa';
export const ACCOUNT_OWNER = 'accountOwner';
export const IBAN = 'iban';
export const DATE = 'date';

export const INTERMEDIARY = 'intermediary';
export const ASSET_CONSULTANT = 'assetConsultant';
export const CONTACT_SALUTATION = 'contactSalutation';
export const CONTACT_FIRST_NAME = 'contactFirstName';
export const CONTACT_LAST_NAME = 'contactLastName';
export const INTERMEDIARY_STREET_AND_HOUSE = 'streetAndHouse';
export const INTERMEDIARY_ZIP = 'zip';
export const INTERMEDIARY_CITY = 'city';
export const INTERMEDIARY_PHONE = 'phone';
export const INTERMEDIARY_EMAIL = 'email';
export const DEPARTMENT = 'department';
export const ASSET_CONSULTANT_NUMBER = 'assetConsultantNumber';
export const INTERMEDIARY_NUMBER = 'intermediaryNumber';
export const NOTES = 'notes';
export const PRIVACY_POLICY_ACCEPTED = 'privacyPolicyAccepted';

export const CONTRACT = 'contract';
export const CONTRACT_DATE = 'contractDate';
export const LEGAL_FORM = 'legalForm';
export const LEGAL_FORM_KEY = 'key';
export const LEGAL_FORM_VALUE = 'value';
export const BUERGEL_SCORE = 'buergelScore';
export const CSP_IDENTIFIER = 'cspIdentifier';
export const DDM_IDENTIFIER = 'ddmIdentifier';
export const WZ_CODE = 'wzCode';
export const SET_UP_FEE = 'setUpFee';
export const ANNUAL_SERVICE_FEE = 'annualServiceFee';

export const CONDITIONS = 'conditions';
export const SERVICE_FEE_MATRIX_ID = 'serviceFeeMatrixIdentifier';

export const FORM_DATA_CONSTANTS = {
  COMPANY,
  NAME,
  SALUTATION,
  FIRST_NAME,
  LAST_NAME,
  TRADE_NAME,
  FOUNDING_DATE,
  TURNOVER,
  ONLINE_TURNOVER_RATE,
  FACTORABLE_TURNOVER,
  CREDITOR_LIMIT,
  STREET_AND_HOUSE,
  ZIP,
  CITY,
  PHONE,
  FAX,
  MOBILE,
  EMAIL,
  LEGAL,
  ENTITLED_TO_DEDUCT_VAT,
  VOB,
  TAX_NUMBER,
  REGISTER_NUMBER,
  PLACE_OF_AUTHORITY,
  PROPRIETOR,
  SEPA,
  ACCOUNT_OWNER,
  IBAN,
  DATE,
  INTERMEDIARY,
  ASSET_CONSULTANT,
  CONTACT_SALUTATION,
  CONTACT_FIRST_NAME,
  CONTACT_LAST_NAME,
  INTERMEDIARY_STREET_AND_HOUSE,
  INTERMEDIARY_ZIP,
  INTERMEDIARY_CITY,
  INTERMEDIARY_PHONE,
  INTERMEDIARY_EMAIL,
  PRIVACY_POLICY_ACCEPTED,
  DEPARTMENT,
  ASSET_CONSULTANT_NUMBER,
  INTERMEDIARY_NUMBER,
  CONTRACT,
  CONTRACT_DATE,
  LEGAL_FORM,
  LEGAL_FORM_KEY,
  LEGAL_FORM_VALUE,
  BUERGEL_SCORE,
  CSP_IDENTIFIER,
  DDM_IDENTIFIER,
  WZ_CODE,
  SET_UP_FEE,
  ANNUAL_SERVICE_FEE,
  CONDITIONS,
  SERVICE_FEE_MATRIX_ID,
  NOTES
};

export const createCreditorFactory = () => ({
  [COMPANY]: {
    [NAME]: '',
    [SALUTATION]: '',
    [FIRST_NAME]: '',
    [LAST_NAME]: '',
    [TRADE_NAME]: '',
    [FOUNDING_DATE]: '',
    [TURNOVER]: '',
    [ONLINE_TURNOVER_RATE]: '',
    [STREET_AND_HOUSE]: '',
    [ZIP]: '',
    [CITY]: '',
    [PHONE]: '',
    [FAX]: '',
    [MOBILE]: '',
    [EMAIL]: ''
  },
  [LEGAL]: {
    [ENTITLED_TO_DEDUCT_VAT]: '',
    [VOB]: '',
    [TAX_NUMBER]: '',
    [REGISTER_NUMBER]: '',
    [PLACE_OF_AUTHORITY]: '',
    [PROPRIETOR]: ''
  },
  [SEPA]: {
    [ACCOUNT_OWNER]: '',
    [IBAN]: '',
    [DATE]: formatTimestampToDate(Date.now() / 1000)
  },
  [INTERMEDIARY]: {
    [ASSET_CONSULTANT]: '',
    [CONTACT_SALUTATION]: '',
    [CONTACT_FIRST_NAME]: '',
    [CONTACT_LAST_NAME]: '',
    [INTERMEDIARY_STREET_AND_HOUSE]: '',
    [INTERMEDIARY_ZIP]: '',
    [INTERMEDIARY_CITY]: '',
    [INTERMEDIARY_PHONE]: '',
    [INTERMEDIARY_EMAIL]: '',
    [DEPARTMENT]: '',
    [ASSET_CONSULTANT_NUMBER]: '',
    [INTERMEDIARY_NUMBER]: '',
    [NOTES]: '',
    [PRIVACY_POLICY_ACCEPTED]: false
  },
  [CONTRACT]: {
    [CONTRACT_DATE]: '',
    [LEGAL_FORM]: {
      [LEGAL_FORM_KEY]: '',
      [LEGAL_FORM_VALUE]: ''
    },
    [BUERGEL_SCORE]: '',
    [CSP_IDENTIFIER]: '',
    [DDM_IDENTIFIER]: '',
    [WZ_CODE]: '',
    [SET_UP_FEE]: '',
    [ANNUAL_SERVICE_FEE]: '',
    serviceFeePeriod: null,
    serviceFee: null
  },
  [CONDITIONS]: {
    [SERVICE_FEE_MATRIX_ID]: ''
  }
});

export const mapDetails = (creditor) => ({
  [COMPANY]: {
    [NAME]: creditor.companyName,
    [SALUTATION]: creditor.contact.salutation,
    [FIRST_NAME]: creditor.contact.contactPerson.firstName,
    [LAST_NAME]: creditor.contact.contactPerson.lastName,
    [TRADE_NAME]: creditor.tradeName,
    [TURNOVER]: parseInt(creditor.annualTurnoverGross),
    [ONLINE_TURNOVER_RATE]: parseFloat(creditor.onlineTurnoverRate),
    [CREDITOR_LIMIT]: parseInt(creditor.creditorLimit),
    [STREET_AND_HOUSE]: creditor.address.street,
    [ZIP]: creditor.address.zip,
    [CITY]: creditor.address.city,
    [PHONE]: creditor.contact.telephoneNumber,
    [FAX]: creditor.contact.fax,
    [MOBILE]: creditor.contact.mobileNumber,
    [EMAIL]: creditor.contact.email
  },
  [LEGAL]: {
    [ENTITLED_TO_DEDUCT_VAT]: creditor.contract.entitledToDeductVat,
    [VOB]: creditor.contract.vob,
    [TAX_NUMBER]: creditor.contract.taxNumber,
    [REGISTER_NUMBER]: creditor.contract.registerNumber,
    [PLACE_OF_AUTHORITY]: creditor.contract.placeOfAuthority,
    [PROPRIETOR]: creditor.contract.proprietor
  },
  [SEPA]: {
    [ACCOUNT_OWNER]: creditor.sepa.accountOwner,
    [IBAN]: creditor.sepa.iban,
    [DATE]: formatTimestampToDate(creditor.sepa.date),
    readOnly: creditor.isSendToSap
  },
  [INTERMEDIARY]: {
    [ASSET_CONSULTANT]: creditor.intermediary.name,
    [CONTACT_SALUTATION]: creditor.intermediary.salutation,
    [CONTACT_FIRST_NAME]: creditor.intermediary.contactForename,
    [CONTACT_LAST_NAME]: creditor.intermediary.contactSurname,
    [INTERMEDIARY_STREET_AND_HOUSE]: creditor.intermediary.street,
    [INTERMEDIARY_ZIP]: creditor.intermediary.zip,
    [INTERMEDIARY_CITY]: creditor.intermediary.city,
    [INTERMEDIARY_PHONE]: creditor.intermediary.telephoneNumber,
    [INTERMEDIARY_EMAIL]: creditor.intermediary.email,
    [DEPARTMENT]: creditor.intermediary.department,
    [ASSET_CONSULTANT_NUMBER]: creditor.intermediary.salesAdvisorNumber,
    [INTERMEDIARY_NUMBER]: creditor.intermediary.intermediaryNumber
  },
  [CONTRACT]: {
    [CONTRACT_DATE]: formatTimestampToDate(creditor.contract.start),
    [LEGAL_FORM]: {
      [LEGAL_FORM_KEY]: creditor.legal.key || '',
      [LEGAL_FORM_VALUE]: creditor.legalForm || ''
    },
    [BUERGEL_SCORE]: creditor.legal.buergelScore,
    [CSP_IDENTIFIER]: creditor.legal.cspIdentifier,
    [DDM_IDENTIFIER]: creditor.legal.ddmIdentifier,
    [WZ_CODE]: creditor.legal.wzCode,
    [ANNUAL_SERVICE_FEE]: creditor.serviceFeePeriod?.nextPeriodServiceFeeAnnual,
    [SET_UP_FEE]: parseInt(creditor.contract.setupFee),
    serviceFeePeriod: mapServiceFeePeriod(creditor.serviceFeePeriod)
  },
  [CONDITIONS]: {
    [SERVICE_FEE_MATRIX_ID]: creditor.serviceFeeCondition.serviceFeeMatrixEntry.identifier
  }
});

export const mapPayload = ({
  product,
  formData,
  withCreditorLimit,
  maxCreditorLimitInCent,
  useCalculatedCreditorLimit
}) => ({
  productType: product,
  companyName: formData[COMPANY][NAME],
  legalForm: formData[CONTRACT][LEGAL_FORM][LEGAL_FORM_VALUE],
  annualTurnoverGross: formData[COMPANY][TURNOVER],
  tradeName: formData[COMPANY][TRADE_NAME],
  creditorLimit:
    withCreditorLimit && useCalculatedCreditorLimit
      ? calculateCreditorLimitFromTurnover(formData[COMPANY][TURNOVER], maxCreditorLimitInCent)
      : withCreditorLimit
      ? formData[COMPANY][CREDITOR_LIMIT]
      : '',
  intermediary: {
    name: formData[INTERMEDIARY][ASSET_CONSULTANT],
    salutation: formData[INTERMEDIARY][CONTACT_SALUTATION],
    contactForename: formData[INTERMEDIARY][CONTACT_FIRST_NAME],
    contactSurname: formData[INTERMEDIARY][CONTACT_LAST_NAME],
    street: formData[INTERMEDIARY][INTERMEDIARY_STREET_AND_HOUSE],
    zip: formData[INTERMEDIARY][INTERMEDIARY_ZIP],
    city: formData[INTERMEDIARY][INTERMEDIARY_CITY],
    telephoneNumber: formData[INTERMEDIARY][INTERMEDIARY_PHONE],
    email: formData[INTERMEDIARY][INTERMEDIARY_EMAIL],
    department: formData[INTERMEDIARY][DEPARTMENT],
    intermediaryNumber: formData[INTERMEDIARY][INTERMEDIARY_NUMBER],
    salesAdvisorNumber: formData[INTERMEDIARY][ASSET_CONSULTANT_NUMBER]
  },
  address: {
    street: formData[COMPANY][STREET_AND_HOUSE],
    zip: formData[COMPANY][ZIP],
    city: formData[COMPANY][CITY]
  },
  contact: {
    salutation: formData[COMPANY][SALUTATION],
    contactPersonFirstName: formData[COMPANY][FIRST_NAME],
    contactPersonLastName: formData[COMPANY][LAST_NAME],
    telephoneNumber: formData[COMPANY][PHONE],
    mobileNumber: formData[COMPANY][MOBILE],
    email: formData[COMPANY][EMAIL],
    fax: formData[COMPANY][FAX]
  },
  contract: {
    taxNumber: formData[LEGAL][TAX_NUMBER],
    setupFee: formData[CONTRACT][SET_UP_FEE],
    placeOfAuthority: formData[LEGAL][PLACE_OF_AUTHORITY],
    entitledToDeductVat: !!formData[LEGAL][ENTITLED_TO_DEDUCT_VAT],
    registerNumber: formData[LEGAL][REGISTER_NUMBER],
    proprietor: formData[LEGAL][PROPRIETOR],
    division: 1,
    start: formatDateToTimestamp(formData[CONTRACT][CONTRACT_DATE])
  },
  creditorServiceFee: {
    ...(isNaN(parseInt(formData[CONTRACT][ANNUAL_SERVICE_FEE]))
      ? {}
      : {
          serviceFeeAnnual: formData[CONTRACT][ANNUAL_SERVICE_FEE]
        }),
    sapMatrixIdentifier: formData[CONDITIONS][SERVICE_FEE_MATRIX_ID]
  },
  legal: {
    key: formData[CONTRACT][LEGAL_FORM][LEGAL_FORM_KEY],
    buergelScore: formData[CONTRACT][BUERGEL_SCORE],
    cspIdentifier: formData[CONTRACT][CSP_IDENTIFIER],
    ddmIdentifier: formData[CONTRACT][DDM_IDENTIFIER],
    wzCode: formData[CONTRACT][WZ_CODE]
  },
  sepa: {
    accountOwner: formData[SEPA][ACCOUNT_OWNER],
    city: formData[COMPANY][CITY],
    iban: formData[SEPA][IBAN],
    start: formatDateToTimestamp(formData[SEPA][DATE])
  }
});

export default createCreditorFactory;
