import ProductType from '@/statics/productType';
import i18n from '@/helper/i18n';
import { BASIC_CREDITOR_VOUCHER_TYPE_GROUPS } from '@/modules/User/statics/creditorVoucherType';
import { BASIC_VOUCHER_STATUS_TO_SHOW_GROUPS } from '@/statics/voucherStatusToShow';
import {
  FACTORABLE_TURNOVER,
  FOUNDING_DATE,
  ONLINE_TURNOVER_RATE,
  VOB
} from '@/helper/createCreditorFactory';
import VoucherSubStatus, {
  BASIC_VOUCHER_SUB_STATUS_FILTER_OPTIONS
} from '@/statics/voucherSubStatus';
import { BASIC_VOUCHER_TYPE_GROUPS, VoucherType } from '@/statics/voucherType';
import { FACTORING_PERMISSIONS } from '@/statics/productPermissions';
import {
  IMPRINT_FINTECRITY,
  PRIVACY_POLICY_FINTECRITY,
  PRODUCT_INFO_DV,
  SERVICE_HOTLINE_FINTECRITY,
  SERVICE_MAIL_FINTECRITY
} from '@/statics/customerServiceInformation';
import PurchaseStatus from '@/modules/Vouchers/statics/purchaseStatus';
import BnetStatus from '@/modules/Unprocessed/statics/bnetStatus';

const PRODUCT_NAME = 'DV AbrechnungPlus mit Ausfallschutz';

const VHV_FACTORING_CONFIG = {
  type: ProductType.VHV_FACTORING,
  productName: PRODUCT_NAME,
  logo: require('@/assets/DV-logo.svg'),
  partnerLogo: require('@/assets/FinTecrity-logo.svg'),
  favIcon: '/favicons/faviconDV.ico',
  theme: 'dv' /* matches key of $theme-base in styles/themes/index.scss */,
  background: null,
  placeLogoInHeader: false,
  serviceHotline: SERVICE_HOTLINE_FINTECRITY,
  serviceMail: SERVICE_MAIL_FINTECRITY,
  infoUrl: PRODUCT_INFO_DV,
  privacyPolicyUrl: PRIVACY_POLICY_FINTECRITY,
  imprintUrl: IMPRINT_FINTECRITY,
  withPurchaseAssurance: false,

  voucherStatusToShowGroups: BASIC_VOUCHER_STATUS_TO_SHOW_GROUPS,
  voucherSubStatusFilter: BASIC_VOUCHER_SUB_STATUS_FILTER_OPTIONS,
  voucherTypeGroups: {
    ...BASIC_VOUCHER_TYPE_GROUPS,
    // additionally supported voucher types
    [VoucherType.DEPOSIT_INSURANCE]: VoucherType.DEPOSIT_INSURANCE
  },
  creditorVoucherTypeGroups: BASIC_CREDITOR_VOUCHER_TYPE_GROUPS,

  permissions: {
    ...FACTORING_PERMISSIONS,
    debtors: {
      ...FACTORING_PERMISSIONS.debtors,
      showSumOfPurchasedInvoices: false
    },
    helpCenter: {
      ...FACTORING_PERMISSIONS.helpCenter,
      tabFAQ: false
    },
    masterData: {
      ...FACTORING_PERMISSIONS.masterData,
      serviceFeesWithPurchaseFees: false,
      serviceFeesWithoutPurchaseFees: true
    },
    voucherDetails: {
      ...FACTORING_PERMISSIONS.voucherDetails,
      showPurchaseReversalWarning: false
    }
  },

  onboarding: {
    useCalculatedCreditorLimit: false,
    blacklist: [VOB, ONLINE_TURNOVER_RATE, FACTORABLE_TURNOVER, FOUNDING_DATE]
  },

  faq: [],

  documents: {
    masterData: [
      {
        title: i18n.t('masterData.dataProtectionRegulation'),
        path: '/assets/masterData/APL/FTY_Datenschutzhinweise_Art.13 14_072024.pdf'
      },
      {
        title: i18n.t('masterData.insuranceCoverageRules'),
        path: '/assets/masterData/VHV/Regeln zu Abrechnung mit Ausfallschutz_13062023.pdf'
      },
      {
        title: i18n.t('masterData.infoChangeBankDetails'),
        path: '/assets/masterData/APL/Bankdatenänderung_FTY_2024.docx'
      },
      {
        title: i18n.t('masterData.notificationLetter'),
        path: '/assets/masterData/APL/Notifikationsschreiben_Zusammenarbeit_FinTecrity.docx'
      }
    ],

    sepa: {
      template: {
        title: i18n.t('permanentDebts.pdf.sepaTemplate'),
        path: '/assets/sepa/Vorlage_Einzugsermächtigung.pdf'
      }
    },

    upload: {
      tips: '/assets/upload/DV/Hinweise_zum_Rechnungsaufbau.pdf'
    }
  },

  translations: {
    debtors: {
      approvedCreditorLimit: 'Meine Jahreshöchstentschädigung',
      availableCreditorLimit: 'Verfügbare Jahreshöchstentschädigung',
      purchased: 'Versicherbar',
      notPurchased: 'Nicht versichert',
      noOpenPurchasedInvoices: 'Keine offenen, versicherbaren Rechnungen vorhanden.',
      limitAffectedBy:
        'Folgende Faktoren wurden bei der Prüfung zur Versicherungsfähigkeit berücksichtigt',
      noPurchasedInvoices:
        'Derzeit liegen keine offenen, versicherbaren Rechnungen zu diesem Kunden vor.',
      showPurchasedInvoicesInVoucherList: 'Versicherbare Rechnungen in Belegübersicht anzeigen',
      lastOpenPurchasedInvoices: 'Ihre bis zu 15 letzten offenen, versicherbaren Rechnungen',
      limitReasonCodes: {
        customer: {
          ID_1: 'Ihr Kunde kann nicht versichert werden, da eine negative Bonität zur angefragten Person vorliegt.',
          ID_4: 'Ihr Kunde kann nicht versichert werden, da die angefragte Person nicht eindeutig identifiziert werden konnte.',
          ID_9: 'Ihr Kunde kann nicht versichert werden, da Negativmerkmale vorliegen.',
          ID_10: 'Ihr Kunde kann nicht versichert werden, da die angefragte Person verstorben ist.'
        },
        business: {
          ID_1: 'Ihr Kunde kann nicht versichert werden, da eine negative Bonität zur angefragten Firma vorliegt.',
          ID_4: 'Ihr Kunde kann nicht versichert werden, da die angefragte Firma nicht eindeutig identifiziert werden konnte.',
          ID_9: 'Ihr Kunde kann nicht versichert werden, da Negativmerkmale vorliegen.',
          ID_10: 'Ihr Kunde kann nicht versichert werden, da die angefragte Firma erloschen ist.'
        }
      }
    },
    historyEvents: {
      limitReasonDebtorPersonHasANegativeSolvency:
        'Ihr Kunde kann nicht versichert werden, da eine negative Bonität zur angefragten Person vorliegt.',
      limitReasonDebtorPersonWasNotIdentified:
        'Ihr Kunde kann nicht versichert werden, da die angefragte Person nicht eindeutig identifiziert werden konnte.',
      limitReasonDebtorPersonWasFoundOnBlacklist:
        'Ihr Kunde kann nicht versichert werden, da Negativmerkmale vorliegen.',
      limitReasonDebtorPersonWasDied:
        'Ihr Kunde kann nicht versichert werden, da die angefragte Person verstorben ist.',
      limitReasonDebtorCompanyHasANegativeSolvency:
        'Ihr Kunde kann nicht versichert werden, da eine negative Bonität zur angefragten Firma vorliegt.',
      limitReasonDebtorCompanyWasNotIdentified:
        'Ihr Kunde kann nicht versichert werden, da die angefragte Firma nicht eindeutig identifiziert werden konnte.',
      limitReasonDebtorCompanyWasFoundOnBlacklist:
        'Ihr Kunde kann nicht versichert werden, da Negativmerkmale vorliegen.',
      limitReasonDebtorCompanyWasTerminated:
        'Ihr Kunde kann nicht versichert werden, da die angefragte Firma erloschen ist.',
      factoring: {
        purchaseStatusWasMarkedAsPurchasable: 'Limit nicht ausreichend um Beleg zu versichern',
        purchaseStatusWasMarkedAsInReview: 'Versicherungsschutz wird geprüft',
        voucherWasPurchased: 'Der Beleg ist versicherbar',
        voucherWasDisclosed: 'Die Abtretung der Rechnung wurde offengelegt',
        voucherWasPurchasedLater: 'Der Beleg wurde nachträglich versicherbar',
        purchaseStatusWasMarkedAsManualPurchaseList: 'Der Beleg ist versicherbar',
        purchaseStatusWasMarkedAsCountedBack: 'Der Versicherungsschutz für den Beleg ist entfallen',
        voucherWasIncomplete: 'Kein Versicherungsschutz - der Beleg ist unvollständig',
        voucherWasNotPurchasableDateOfSupplyInFuture:
          'Kein Versicherungsschutz - das Leistungsdatum liegt in der Zukunft',
        voucherWasLowerThanThreeDays:
          'Kein Versicherungsschutz - die Restfälligkeit beträgt weniger als 3 Tage',
        voucherWasTermOfCreditMoreThanNinetyDays:
          'Kein Versicherungsschutz - das Zahlungsziel beträgt mehr als 90 Tage',
        voucherDateInFuture: 'Kein Versicherungsschutz - das Belegdatum liegt in der Zukunft',
        voucherDateOfSupplyBeforeInvoiceDate:
          'Kein Versicherungsschutz - das Leistungsdatum liegt mehr als 30 Tage vor dem Rechnungsdatum',
        duplicatedVoucherNumberWasFound:
          'Kein Versicherungsschutz – die Belegnummer ist bereits vorhanden'
      }
    },
    masterData: {
      approvedLimit: 'Jahreshöchstentschädigung',
      increaseCreditorLimitInfo:
        'Die Jahreshöchstentschädigung ist zu gering? Bitte gehen Sie auf die VHV Versicherung bzw. Ihren Vermögensberater zu. Um die Jahreshöchstentschädigung zu ändern, muss Ihre aktuelle Versicherungspolice angepasst werden.'
    },
    onboarding: {
      determinedCreditorLimit: '@:onboarding.annualMaximumCompensation'
    },
    serviceFees: {
      notPurchasedFee: ''
    },
    settings: {
      emailNotifications: {
        settings: {
          bnetPurchaseStatus: {
            title: 'Versicherbarkeit',
            tooltip: 'Wir benachrichtigen Sie, wenn ein Beleg nicht versichert werden kann.'
          }
        }
      }
    },
    unprocessed: {
      pending: {
        inFutureBilling: {
          reason: 'Belegdatum liegt in der Zukunft',
          text: 'Bitte teilen Sie uns mit, ob Sie dennoch eine unmittelbare Verarbeitung Ihrer Rechnung wünschen. Bitte beachten Sie, dass Ihre Rechnung in diesem Fall nicht versichert wird. Wir werden Ihre Rechnung automatisch weiterverarbeiten, sobald das Belegdatum dem Tagesdatum entspricht. Sofern alle weiteren Versicherungskriterien erfüllt sind, wird Ihr Beleg dann auch versichert. Alternativ können Sie Ihre Rechnung auch löschen.'
        },
        duplicate: {
          reason: `@:bnetStatusOptions.${BnetStatus.DUPLICATED_VOUCHER_NUMBER_221}.title`,
          textBilling: '@:unprocessed.pending.requestFeedback',
          textFactoring:
            'Bitte teilen Sie uns mit, ob Sie dennoch eine Verarbeitung Ihrer Rechnung wünschen. In diesem Fall besteht KEIN Versicherungsschutz der Rechnung. Alternativ können Sie Ihre Rechnung auch löschen.'
        },
        notPurchasable: {
          reason: 'Beleg nicht versicherbar',
          text: '@:unprocessed.pending.requestFeedback'
        },
        inFutureFactoring: {
          reason: 'Belegdatum liegt in der Zukunft',
          text: 'Bitte teilen Sie uns mit, ob Sie dennoch eine unmittelbare Verarbeitung Ihrer Rechnung wünschen. Bitte beachten Sie, dass Ihre Rechnung in diesem Fall nicht versichert wird. Wir werden Ihre Rechnung automatisch weiterverarbeiten, sobald das Belegdatum dem Tagesdatum entspricht. Sofern alle weiteren Versicherungskriterien erfüllt sind, wird Ihr Beleg dann auch versichert. Alternativ können Sie Ihre Rechnung auch löschen.'
        },
        inFuturePerformanceDate: {
          reason: 'Leistungsdatum liegt in der Zukunft',
          text: 'Bitte teilen Sie uns mit, ob Sie dennoch eine unmittelbare Verarbeitung Ihrer Rechnung wünschen. Bitte beachten Sie, dass Ihre Rechnung in diesem Fall nicht versichert wird. Wir werden Ihre Rechnung automatisch weiterverarbeiten, sobald das Leistungsdatum dem Tagesdatum entspricht. Sofern alle weiteren Versicherungskriterien erfüllt sind, wird Ihr Beleg dann auch versichert. Alternativ können Sie Ihre Rechnung auch löschen.'
        }
      }
    },
    vouchers: {
      purchaseStatus: 'Versicherungsstatus',
      purchaseStatusInfo: 'Dieser Beleg kann aus folgendem Grund nicht versichert werden:',
      subStatusOptions: {
        [VoucherSubStatus.PURCHASABLE]: 'Nicht versichert',
        [VoucherSubStatus.NOT_PURCHASABLE]: 'Nicht versichert',
        [VoucherSubStatus.PURCHASED]: 'Versicherbar',
        [VoucherSubStatus.PURCHASED_LATER]: 'Versicherbar',
        [VoucherSubStatus.PURCHASED_MANUALLY]: 'Versicherbar',
        [VoucherSubStatus.NOT_PURCHASABLE_ANYMORE]: 'Nicht versichert',
        [VoucherSubStatus.COUNT_BACK]: 'Nicht versichert',
        [VoucherSubStatus.COUNTED_BACK]: 'Nicht versichert',
        [VoucherSubStatus.NOT_PURCHASED]: 'Nicht versichert',
        [VoucherSubStatus.PAYMENT_REMINDER]: 'An Zahlung erinnert'
      },
      purchaseStatusOptions: {
        [PurchaseStatus.PURCHASED]: 'Versicherbar',
        [PurchaseStatus.NOT_PURCHASED]: 'Nicht versichert'
      }
    }
  }
};

export default VHV_FACTORING_CONFIG;
