/**
 * @see {@link https://wiki.move-elevator.de/display/DVKMU/Allgemeine+Einstellungen+und+Definitionen}
 */
import PurchaseStatus from '@/modules/Vouchers/statics/purchaseStatus';
import VoucherSubStatus from '@/statics/voucherSubStatus';

export default {
  vouchers: {
    purchaseStatusOptions: {
      [PurchaseStatus.NOT_PURCHASED]: 'Kein Ankauf'
    },
    subStatusOptions: {
      [VoucherSubStatus.PURCHASABLE]: '@:(vouchers.purchaseStatusOptions.accepted)',
      [VoucherSubStatus.COUNT_BACK]: '@:(vouchers.purchaseStatusOptions.accepted)'
    },
    dunningBlock: {
      warningPurchaseReversal: '@:(vouchers.dunningBlock.warningPurchaseAssuranceReversal)',
      warningPurchaseReversalSetAgain:
        '@:(vouchers.dunningBlock.warningPurchaseAssuranceReversalSetAgain)'
    }
  }
};
