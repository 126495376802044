<template>
  <v-container fill-height fluid class="pa-0 flex-nowrap" :style="style">
    <v-row no-gutters justify="center">
      <v-col cols="12" sm="8" md="6" lg="4">
        <v-card elevation="12">
          <slot></slot>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'AppViewContainer',

  props: {
    backgroundSrc: {
      type: String,
      default: ''
    }
  },

  computed: {
    style() {
      return this.backgroundSrc
        ? {
            backgroundImage: `url(${this.backgroundSrc})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }
        : {};
    }
  }
};
</script>

<style scoped lang="scss">
#app.v-application .v-card {
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  padding: 0;
}

#app.v-application.mobile .v-card {
  border-radius: 0;
  margin: 0;
  min-width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
}
#app.v-application.mobile .v-card ::v-deep > * {
  display: flex;
  flex-direction: column;

  &:only-child {
    flex: 1 1 auto;
  }
}

::v-deep hr.v-divider {
  border-color: rgb(240, 240, 240);
}
</style>
