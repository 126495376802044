export default {
  computed: {
    isMobile() {
      // <= 960px
      return this.$vuetify.breakpoint.smAndDown;
    },
    isDesktop() {
      // >= 960px
      return this.$vuetify.breakpoint.mdAndUp;
    },
    mobileBreakpointPx() {
      return this.$vuetify.breakpoint.thresholds.sm;
    },
    isSafari() {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }
  }
};
