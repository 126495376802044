<template>
  <svg
    width="17px"
    height="19px"
    viewBox="0 0 17 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-871.000000, -136.000000)" fill="currentColor" fill-rule="nonzero">
        <g
          transform="translate(879.500000, 145.500000) rotate(32.000000) translate(-879.500000, -145.500000) translate(874.000000, 138.000000)"
        >
          <path
            d="M9.82885906,13.6363636 L9.49328859,13.6363636 L9.49328859,11.3181818 C9.49328859,10.7727273 9.25838926,10.2613636 8.88926174,9.88636364 L6.47315436,7.56818182 L8.92281879,5.11363636 C9.29194631,4.73863636 9.49328859,4.22727273 9.49328859,3.68181818 L9.49328859,1.36363636 L9.82885906,1.36363636 C10.1979866,1.36363636 10.5,1.05681818 10.5,0.681818182 C10.5,0.306818182 10.1979866,0 9.82885906,0 L9.49328859,0 L1.50671141,0 L1.17114094,0 C0.802013423,0 0.5,0.306818182 0.5,0.681818182 C0.5,1.05681818 0.802013423,1.36363636 1.17114094,1.36363636 L1.50671141,1.36363636 L1.50671141,3.68181818 C1.50671141,4.22727273 1.74161074,4.73863636 2.11073826,5.11363636 L4.52684564,7.43181818 L2.07718121,9.88636364 C1.70805369,10.2613636 1.50671141,10.7727273 1.50671141,11.3181818 L1.50671141,13.6363636 L1.17114094,13.6363636 C0.802013423,13.6363636 0.5,13.9431818 0.5,14.3181818 C0.5,14.6931818 0.802013423,15 1.17114094,15 L1.50671141,15 L9.49328859,15 L9.82885906,15 C10.1979866,15 10.5,14.6931818 10.5,14.3181818 C10.5,13.9431818 10.1979866,13.6363636 9.82885906,13.6363636 Z M2.84899329,3.64772727 L2.84899329,1.32954545 L8.15100671,1.32954545 L8.15100671,3.64772727 C8.15100671,3.81818182 8.08389262,3.98863636 7.98322148,4.09090909 L5.56711409,6.51136364 L3.05033557,4.09090909 C2.91610738,3.98863636 2.84899329,3.81818182 2.84899329,3.64772727 Z M2.84899329,11.2840909 C2.84899329,11.1136364 2.91610738,10.9431818 3.01677852,10.8409091 L5.43288591,8.42045455 L7.94966443,10.8409091 C8.08389262,10.9431818 8.15100671,11.1136364 8.15100671,11.2840909 L8.15100671,13.6022727 L2.84899329,13.6022727 L2.84899329,11.2840909 Z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconHourglass'
};
</script>
