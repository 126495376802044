import PRODUCT_CONFIGS from '@/productConfigs';
import { NAMESPACE as NAMESPACE_ONBOARDING } from '@/onboarding/store';
import { PRODUCT_TYPES, FACTORING_PRODUCTS, BILLING_PRODUCTS } from '@/statics/productType';
import {
  PURCHASE_STATUS_GROUPS,
  PURCHASE_STATUS_GROUPS_WITH_PURCHASE_ASSURANCE
} from '@/modules/Vouchers/statics/purchaseStatus';
import { mapState } from 'vuex';
import { resolveFromURL } from '@/helper/productResolver';

export default {
  computed: {
    ...mapState(NAMESPACE_ONBOARDING, ['product']),
    isOnboarding() {
      return this.$route.path.includes(this.routeName.ONBOARDING);
    },
    productType() {
      if (this.isOnboarding) {
        return PRODUCT_TYPES.includes(this.$route.params.type)
          ? this.$route.params.type
          : undefined;
      }

      return this.$auth.user()?.product || this.productTypeFromUrl;
    },
    productTypeFromUrl() {
      return resolveFromURL();
    },
    product() {
      return PRODUCT_CONFIGS.find((product) => this.productType === product.type);
    },
    productName() {
      return this.product?.productName ?? '';
    },
    portalName() {
      if (this.product?.portalName) {
        return this.product.portalName;
      }

      if (this.productName) {
        return `${this.productName.split(' ').join('-')}-${this.$t('portal')}`;
      }

      return this.$t('portal');
    },
    isFactoring() {
      return FACTORING_PRODUCTS.includes(this.productType);
    },
    isBilling() {
      return BILLING_PRODUCTS.includes(this.productType);
    },
    purchaseStatusGroups() {
      /**
       * @see {@link https://wiki.move-elevator.de/display/DVKMU/Ankaufstatus+-+Wertedefinition Definitions for most products}
       * @see {@link https://wiki.move-elevator.de/display/DVKMU/Allgemeine+Einstellungen+und+Definitionen Definitions with purchase assurance}
       */
      return this.product.withPurchaseAssurance
        ? PURCHASE_STATUS_GROUPS_WITH_PURCHASE_ASSURANCE
        : PURCHASE_STATUS_GROUPS;
    }
  },

  methods: {
    hasFulfilledCondition(item, conditionPayload) {
      const condition =
        typeof item.condition === 'function' ? item.condition(conditionPayload) : item.condition;

      return condition === true || condition === undefined;
    },

    filterItemsWithFulfilledCondition(list) {
      return list.filter((item) => this.hasFulfilledCondition(item));
    }
  }
};
