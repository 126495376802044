import FAQLinks from '@/modules/User/statics/faqLinks';
import ProductType from '@/statics/productType';
import i18n from '@/helper/i18n';
import { BASIC_CREDITOR_VOUCHER_TYPE_GROUPS } from '@/modules/User/statics/creditorVoucherType';
import { BASIC_VOUCHER_STATUS_TO_SHOW_GROUPS } from '@/statics/voucherStatusToShow';
import { BASIC_VOUCHER_SUB_STATUS_FILTER_OPTIONS } from '@/statics/voucherSubStatus';
import { BASIC_VOUCHER_TYPE_GROUPS, VoucherType } from '@/statics/voucherType';
import { DEGENER_PERMISSIONS } from '@/statics/productPermissions';
import {
  IMPRINT_FINTECRITY,
  PRIVACY_POLICY_FINTECRITY,
  PRODUCT_INFO_DEGENER,
  SERVICE_HOTLINE_FINTECRITY,
  SERVICE_MAIL_FINTECRITY
} from '@/statics/customerServiceInformation';
import {
  ASSET_CONSULTANT,
  ASSET_CONSULTANT_NUMBER,
  CREDITOR_LIMIT,
  DEPARTMENT,
  FACTORABLE_TURNOVER,
  FOUNDING_DATE,
  INTERMEDIARY_NUMBER,
  ONLINE_TURNOVER_RATE,
  VOB
} from '@/helper/createCreditorFactory';

const PRODUCT_NAME = 'FSO360 Cash (BASIC)';

const DAB_BILLING_CONFIG = {
  type: ProductType.DAB_BILLING,
  productName: PRODUCT_NAME,
  portalName: 'FSO360-Cash-BASIC-Portal',
  logo: require('@/assets/DEGENER-logo-small.svg'),
  partnerLogo: require('@/assets/FinTecrity-logo.svg'),
  scaleLogosByWidth: true,
  favIcon: '/favicons/faviconDegener.ico',
  theme: 'fintecrity' /* matches key of $theme-fintecrity in styles/themes/index.scss */,
  background: null,
  placeLogoInHeader: false,
  serviceHotline: SERVICE_HOTLINE_FINTECRITY,
  serviceMail: SERVICE_MAIL_FINTECRITY,
  infoUrl: PRODUCT_INFO_DEGENER,
  privacyPolicyUrl: PRIVACY_POLICY_FINTECRITY,
  imprintUrl: IMPRINT_FINTECRITY,
  withPurchaseAssurance: false,

  voucherStatusToShowGroups: BASIC_VOUCHER_STATUS_TO_SHOW_GROUPS,
  voucherSubStatusFilter: BASIC_VOUCHER_SUB_STATUS_FILTER_OPTIONS,
  voucherTypeGroups: {
    ...BASIC_VOUCHER_TYPE_GROUPS,
    // no support for permanent debt invoices
    [VoucherType.PERMANENT_DEBT_INVOICE]: []
  },
  creditorVoucherTypeGroups: BASIC_CREDITOR_VOUCHER_TYPE_GROUPS,

  permissions: DEGENER_PERMISSIONS,

  onboarding: {
    blacklist: [
      ONLINE_TURNOVER_RATE,
      FACTORABLE_TURNOVER,
      CREDITOR_LIMIT,
      FOUNDING_DATE,
      VOB,
      INTERMEDIARY_NUMBER,
      ASSET_CONSULTANT,
      DEPARTMENT
    ],
    rules: {
      [ASSET_CONSULTANT_NUMBER]: 'required|digits:6'
    },
    setUpFeeValues: [0]
  },

  faq: [FAQLinks.DEBTORS, FAQLinks.ENCASHMENT],

  documents: {
    masterData: [],

    sepa: {
      template: {
        title: i18n.t('permanentDebts.pdf.sepaTemplate'),
        path: '/assets/sepa/Vorlage_Einzugsermächtigung.pdf'
      }
    },

    upload: {}
  },

  translations: {
    onboarding: {
      assetConsultantNumber: '@:onboarding.intermediaryNumberFull',
      assetConsultantData: '@:onboarding.intermediaryData'
    }
  }
};

export default DAB_BILLING_CONFIG;
