<template>
  <v-navigation-drawer
    v-if="!$route.meta.noSidebar"
    v-model="show"
    :permanent="!isMobile"
    :width="isMobile ? '80vw' : width"
    app
  >
    <div v-if="isMobile">
      <close-btn-mobile @click="show = false" />
      <partner-logos v-if="product.placeLogoInHeader" class="sidebar__logos--mobile" />
      <contact-bar-content class="pl-6 pr-8 pt-4 pb-8" />

      <user-menu class="sidebar__prepend" />
      <v-list-item
        v-if="permissions.navigation[routeName.HELP_CENTER]"
        link
        class="sidebar__prepend"
        :to="{ name: routeName.HELP_CENTER }"
      >
        <v-list-item-content>
          <v-list-item-title class="d-flex align-center caption">
            <icon-help-center />
            {{ $t('helpCenter.helpCenter') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="mx-4" />
    </div>

    <v-list v-else class="list__logos">
      <v-list-item
        :inactive="$route.name === defaultRoute.route"
        class="d-flex flex-column justify-center align-center"
        exact
        @click="pushToDefaultRoute"
      >
        <partner-logos
          ref="logos"
          small
          vertical
          height="55px"
          width="55px"
          @loaded="setLogoOffset"
        />
      </v-list-item>

      <div v-if="!isProduction" class="d-flex flex-column caption align-center mt-4">
        <span class="font-weight-bold">
          {{ envInfo }}
        </span>

        <local-product-select v-if="isLocal" />

        <template v-else>
          {{ productType }}
        </template>
      </div>
    </v-list>

    <v-list dense :class="{ 'mt-12': !isMobile }">
      <template v-for="(item, index) in Object.values(routes)">
        <v-list-item
          v-if="hasFulfilledCondition(item)"
          :key="index"
          link
          :to="
            item.route
              ? {
                  name: item.route,
                  ...(item.route === $route.name
                    ? { query: $route.query, params: $route.params }
                    : undefined)
                }
              : undefined
          "
          :exact="item.exact"
          @click="item.action ? item.action() : undefined"
        >
          <v-list-item-content>
            <v-list-item-title :class="{ 'd-flex body-1 align-center': isMobile }">
              <component
                :is="item.icon"
                :class="{ 'ml-2 mr-4': isMobile }"
                style="height: 24px; width: 30px"
              />
              <div
                :class="{ 'pt-1': !isMobile }"
                v-html="item.name || $t(`routes.${item.route}`)"
              ></div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import CloseBtnMobile from '@/shared/components/CloseBtnMobile';
import ContactBarContent from '@/shared/components/layout/ContactBarContent';
import PartnerLogos from '@/shared/components/PartnerLogos';
import UserMenu from '@/modules/User/components/UserMenu';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'Sidebar',

  components: {
    PartnerLogos,
    ContactBarContent,
    CloseBtnMobile,
    UserMenu,
    LocalProductSelect: () =>
      import(
        '@/shared/components/layout/LocalProductSelect' /* webpackChunkName: "LocalProductSelect" */
      )
  },

  props: {
    width: {
      type: Number,
      default: 100
    }
  },

  data: () => ({
    currentLogoOffsetPx: 0
  }),

  computed: {
    ...mapState(['showSidebar']),
    show: {
      get() {
        return this.showSidebar;
      },
      set(value) {
        if (value || value === this.show) {
          return;
        }
        this.toggleSidebar();
      }
    }
  },

  watch: {
    isMobile() {
      if (this.isMobile) {
        this.currentLogoOffsetPx = 0;
      }
    }
  },

  methods: {
    ...mapMutations({
      toggleSidebar: 'TOGGLE_SIDEBAR'
    }),

    pushToDefaultRoute() {
      if (this.$route.name === this.defaultRoute.route) {
        return;
      }

      this.$router.push({ name: this.defaultRoute.route });
    },

    setLogoOffset() {
      const logoElement = document.querySelector('.list__logos');
      const logoDividerElement = document.getElementById('logo-divider');

      if (!logoElement || !logoDividerElement) {
        return;
      }

      const headerTotalHeightPx = Array.from(document.querySelectorAll('header.v-toolbar')).reduce(
        (sum, element) => sum + element.clientHeight,
        0
      );
      const offset = Math.round(
        headerTotalHeightPx - logoDividerElement.getBoundingClientRect().y - 2
      );

      if (offset === 0) {
        return;
      }

      logoElement.style.transform = `translateY(${this.currentLogoOffsetPx + offset}px)`;
      this.currentLogoOffsetPx += offset;
    }
  }
};
</script>

<style lang="scss" scoped>
$border-width: 5px;

.sidebar__logos--mobile {
  height: 55px;
  max-width: fit-content;
  margin-left: 12px;
  margin-right: 24px;
  margin-top: 16px;
}

.v-list:not(.list__logos) {
  .v-list-item {
    border-left: $border-width solid transparent;
    min-height: 100px;
    padding-right: $border-width;
    padding-left: 0;
    margin: 0 auto;

    &:after {
      min-height: unset;
    }
  }

  .v-list-item__title {
    word-break: break-word;
    white-space: pre-line;
    text-align: center;
    font-size: 12px;
  }

  .v-list-item--active {
    border-left: $border-width solid var(--c-nav-highlight);

    .v-list-item__title {
      color: var(--c-nav-text-active, var(--c-primary));
      font-weight: 600 !important;
    }
  }

  .v-list-item--link:before {
    left: calc(0px - #{$border-width});
  }
}

.v-application.mobile {
  .v-list-item {
    min-height: 76px;
  }

  .sidebar__prepend {
    min-height: 50px !important;
    min-width: 100%;
    display: flex;
    align-items: stretch;

    ::v-deep .v-list-group__header__append-icon {
      margin-left: 0;
    }

    & ::v-deep > div {
      flex: 1;
    }

    svg,
    i {
      height: 30px;
      width: 30px;
      margin-left: 8px;
      margin-right: 12px;
    }
  }
}

.list__logos {
  transition: transform 250ms ease-in-out;

  .v-list-item::after {
    min-height: unset;
  }
}
</style>
