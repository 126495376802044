<template>
  <v-menu
    ref="modal"
    v-model="modal"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template #activator="{ on }">
      <ValidationProvider
        v-slot="{ errors }"
        v-bind="validationProps"
        ref="provider"
        class="app-date-picker__wrapper"
      >
        <v-text-field
          :id="fieldId"
          v-model.trim="dateFormatted"
          v-bind="$attrs"
          :append-icon="appendIcon ? '$iconCalendar' : null"
          :class="{
            'input--align-right': alignRight,
            'input--has-error': errors.length > 0,
            'input--prepend-inner': prependInnerIcon
          }"
          :error-messages="errorMessage && errors.length > 0 ? [errorMessage] : errors"
          :hide-details="errors.length === 0"
          :label="fieldLabelWithSupplement"
          :placeholder="fieldPlaceholder"
          :prepend-inner-icon="prependInnerIcon ? '$iconCalendar' : null"
          autocomplete="off"
          data-testid="app-date-picker__input"
          outlined
          v-on="on"
          @blur="changed"
          @click:append="modal = !modal"
          @click:prepend-inner="modal = !modal"
        />
      </ValidationProvider>
    </template>
    <v-date-picker
      ref="date-picker"
      v-model="date"
      v-bind="$attrs"
      :min="min"
      :max="max"
      color="primary"
      first-day-of-week="1"
      locale="de-DE"
      no-title
      @input="modal = false"
    />
  </v-menu>
</template>
<script>
import FormValidationMixin from '@/mixins/FormValidationMixin';
import { formatIsoToLocal, formatLocalToIso } from '@/helper/filter/formatDate';

export default {
  name: 'AppDatePicker',

  mixins: [FormValidationMixin],

  props: {
    alignRight: {
      type: Boolean,
      default: false
    },
    appendIcon: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    },
    min: {
      type: String,
      default: ''
    },
    max: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'lazy'
    },
    prependInnerIcon: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    dateMenu: false,
    modal: false,
    date: null,
    dateFormatted: null
  }),

  watch: {
    date(date) {
      // accept empty input
      if (!date && !this.dateFormatted) {
        return this.$emit('input', '');
      }

      // decline invalid date inputs
      if (!date) {
        return;
      }

      this.dateFormatted = this.formatDate(date);
      this.$emit('input', date);
    },
    value(value) {
      this.date = value;
    }
  },

  computed: {
    validationRules() {
      let rules = 'is_date|';

      if (this.rules) {
        rules += `${this.rules}|`;
      }

      if (this.required) {
        rules = 'required|' + rules;
      }
      if (this.max) {
        rules += `max_date:${this.max}|`;
      }

      if (this.min) {
        rules += `min_date:${this.min}|`;
      }
      return rules;
    },
    fieldIsDirty() {
      return this.$refs.provider.flags.dirty;
    },
    validationProps() {
      return {
        name: this.validationName,
        rules: this.validationRules,
        mode: this.modal && !this.fieldIsDirty ? 'passive' : this.mode,
        vid: this.vid ?? this.fieldId,
        customMessages: this.customErrorMessages
      };
    }
  },

  methods: {
    changed() {
      this.date = this.parseDate(this.dateFormatted);
    },
    formatDate(date = '') {
      return formatIsoToLocal(date);
    },
    parseDate(date) {
      return formatLocalToIso(date);
    }
  },

  created() {
    if (this.value) {
      this.dateFormatted = this.formatDate(this.value);
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@/styles/mixins/textFieldStyle';

::v-deep .v-text-field {
  @include AppTextFieldMixin;
}
</style>
