import authorizationMixin from '@/mixins/AuthorizationMixin';
import envMixin from './EnvMixin';
import globalDialogsMixin from '@/mixins/GlobalDialogsMixin';
import permissionMixin from '@/mixins/PermissionMixin';
import productMixin from './ProductMixin';
import queryMixin from './QueryMixin';
import responsiveDesignMixin from '@/mixins/ResponsiveDesignMixin';
import routerLinkMixin from './RouterLinkMixin';
import routesMixin from './RoutesMixin';

export default (Vue) => {
  Vue.mixin(authorizationMixin);
  Vue.mixin(envMixin);
  Vue.mixin(globalDialogsMixin);
  Vue.mixin(permissionMixin);
  Vue.mixin(productMixin);
  Vue.mixin(queryMixin);
  Vue.mixin(responsiveDesignMixin);
  Vue.mixin(routerLinkMixin);
  Vue.mixin(routesMixin);
};
