/* eslint-disable no-console */
import { register } from 'register-service-worker';
import Vue from 'vue';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    registered() {
      caches.keys().then((keyList) => {
        keyList.forEach((key) => caches.delete(key));
      });
    },
    updated(registration) {
      Vue.prototype.$eventHub.$emit('content-update', registration);
    }
  });
}

let refreshing = false;
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.addEventListener('controllerchange', () => {
    if (!refreshing) {
      window.location.reload();
      refreshing = true;
    }
  });
}
