const Environment = {
  DEV: 'dev',
  PROD: 'prod',
  STAGE: 'stage',
  VM: 'vm'
};
const currentEnvironment = process.env.VUE_APP_ENV;

export const isLocal = currentEnvironment === Environment.VM;
export const isProduction = currentEnvironment === Environment.PROD;
export const envInfo = isProduction ? '' : currentEnvironment.toUpperCase();
