import 'babel-polyfill';

import App from '@/App.vue';
import Vue from 'vue';
import configureAuth from '@/plugins/vue-auth';
import configureDirectives from '@/helper/directives';
import configureFilters from '@/helper/filter';
import configureMixins from '@/mixins';
import configureSentry from '@/plugins/sentry';
import i18n from '@/helper/i18n';
import router from '@/helper/router';
import store from '@/helper/store';
import vuetify from '@/plugins/vuetify';
import '@/plugins/axios';
import '@/plugins/vee-validate';
import '@/registerServiceWorker';
import '@/shared/components/base';
import '@/shared/components/icons';

Vue.config.productionTip = false;

// Global event bus
Vue.prototype.$eventHub = new Vue();

configureAuth(Vue, router);
configureSentry(Vue, router);
configureDirectives(Vue);
configureFilters(Vue);
configureMixins(Vue);

new Vue({
  router,
  i18n,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
