<template>
  <v-container v-if="!$route.meta.noHeader" fluid :class="{ 'pa-0': !isMobile }">
    <v-slide-x-reverse-transition mode="out-in">
      <v-row :key="title" transition="slide-x-reverse" mode="out-in" class="flex-auto">
        <v-col cols="12">
          <h1 class="background-text--text">{{ title }}</h1>
          <h4 v-if="subtitle" class="background-text-light--text">{{ subtitle }}</h4>
        </v-col>
      </v-row>
    </v-slide-x-reverse-transition>
  </v-container>
</template>

<script>
export default {
  name: 'AppHeader',

  computed: {
    title() {
      if (this.$route.name === this.routeName.DASHBOARD) {
        return this.$i18n.t('dashboard.title', { name: this.$auth.user().name });
      }

      return this.$route.name && this.$i18n.messages.de[this.$route.name].title
        ? this.$i18n.t(`${this.$route.name}.title`)
        : '';
    },
    subtitle() {
      return this.$route.name && this.$i18n.messages.de[this.$route.name].subtitle
        ? this.$i18n.t(`${this.$route.name}.subtitle`)
        : '';
    }
  }
};
</script>
