import DebtorStatus from '@/modules/Debtors/statics/debtorStatus';
import DebtorType from '@/modules/Debtors/statics/debtorType';
import PaymentMethod from '@/modules/Debtors/statics/paymentMethod';

export default {
  title: 'Ihre Kunden',
  subtitle: 'Hier finden Sie einen Überblick über Ihre Kunden',
  debtors: 'Kunden',
  approvedCreditorLimit: 'Mein Ankaufrahmen',
  availableCreditorLimit: 'Verfügbarer Ankaufrahmen',
  sumPurchasedInvoices: 'Summe angekaufter Rechnungen',
  customerId: 'Kundennr.',
  customDebtorNumber: 'Kundennummer',
  postBox: 'Postfach',
  homeAddress: 'Hausanschrift',
  debt: 'Offene Forderungen',
  totalInvoiceVolume: 'Gesamtes Volumen',
  purchased: 'Angekauft',
  notPurchased: 'Nicht angekauft',
  thereofLeftover: 'davon offen',
  availableLimit: 'Verfügbares Limit',
  totalCreditLimit: 'Gesamtes Kreditlimit',
  thereofAvailable: 'davon verfügbar',
  noOpenPurchasedInvoices: 'Keine offenen, angekauften Rechnungen vorhanden.',
  noData: 'Sie haben noch keine Kunden angelegt',
  noDataInfo:
    'Legen Sie jetzt einen Kunden an, um seine Details zu verwalten und Belege zu ihm hochzuladen.',
  createFirst: 'Jetzt meinen ersten Kunden anlegen',
  debtorType: 'Kundentyp',
  debtorTypes: {
    [DebtorType.BUSINESS]: 'Firmenkunde',
    [DebtorType.CUSTOMER]: 'Privatkunde'
  },
  paymentMethod: 'Zahlweg',
  paymentMethods: {
    [PaymentMethod.BANK_TRANSFER]: 'Überweisung',
    [PaymentMethod.SEPA_DIRECT_DEBIT]: 'SEPA-Lastschrift'
  },
  statusOptions: {
    [DebtorStatus.ACTIVE]: 'Aktiv',
    [DebtorStatus.INACTIVE]: 'Deaktiviert',
    [DebtorStatus.REACTIVATED]: `@:(debtors.statusOptions.${[DebtorStatus.ACTIVE]})`
  },
  limitAbove: 'Limit siehe oben',
  hasOpenDebtorAssignmentsCounted:
    '1 Kundenanlage wird von uns manuell überprüft. Nach Abschluss unserer Prüfung wird Ihr Kunde wie gewohnt in der Kundenübersicht angezeigt. | {count} Kundenanlagen werden von uns manuell überprüft. Nach Abschluss unserer Prüfung werden Ihre Kunden wie gewohnt in der Kundenübersicht angezeigt.',
  showDebtorsInReview: 'Kundendaten anzeigen',
  hideDebtorsInReview: 'Kundendaten ausblenden',
  separatePostAddress: 'Separate Versandadresse',
  newDebtor: 'Neuer Kunde',
  addDebtorAndCheckLimit: 'Kunde anlegen & Limit prüfen',
  checkLimit: 'Jetzt Limit prüfen',
  checkAddress: 'Kundendaten prüfen',
  addDebtorError: 'Fehler bei der Anlage des Kunden',
  debtorFound: 'Bestehenden Kunden gefunden',
  addLocationRequest: 'Möchten Sie Ihre Angaben als weitere Adresse hinzufügen?',
  addNewLocation: 'Weitere Adresse hinzufügen',
  deliveryType: 'Versandart',
  locationExistent: 'Der Kunde wurde bereits mit dieser Adresse angelegt.',
  locationFound: 'Gefundene Adresse',
  customerExistent: 'Der Kunde ist bereits angelegt.',
  anotherLimitCheckRequest:
    'Falls Sie eine Limitanfrage erneut starten oder erhöhen möchten, können Sie die Anfrage direkt über die Kunden-Stammdaten einleiten.',
  checkLimitResult: 'Ergebnis Limit für',
  createDebtorConfirm: 'Möchten Sie einen neuen Kunden mit den folgenden Adressdaten anlegen?',
  limitAmountsTo: 'Das Limit beträgt',
  temporaryLimitAmountsTo: 'Das vorläufige Limit beträgt',
  limitAffectedBy: 'Folgende Faktoren wurden bei der Bestimmung des Limits berücksichtigt',
  limitResultInfo:
    'Limit zu niedrig? Sie können nach Anlegen des Kunden eine Anfrage zur Limiterhöhung starten.',
  temporaryResultInfo:
    'Durch manuelle Nacharbeiten kann sich das Limit in den kommenden Tagen noch verändern. Sie können außerdem nach Anlegen des Kunden eine Anfrage zur Limiterhöhung starten.',
  requiresDebtorAssignment:
    'Die Kundenanlage wird von uns manuell überprüft. Nach Abschluss unserer Prüfung wird Ihr Kunde wie gewohnt in der Kundenübersicht angezeigt.',
  createDebtor: 'Kunde anlegen',
  dontCreateDebtor: 'Kunde nicht anlegen',
  debtorCreated: 'Kunde erfolgreich angelegt',
  createDebtorError:
    'Es ist ein technischer Fehler aufgetreten. Leider können wir Ihre Kundenanlage derzeit nicht durchführen. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.',
  editDebtor: 'Kundendaten bearbeiten',
  editedDebtor: 'Kundendaten bearbeitet',
  addLocation: 'Versandadresse hinzufügen',
  editLocation: 'Versandadresse bearbeiten',
  editedLocation: 'Versandadresse bearbeitet',
  editPostAddress: 'Separate Versandadresse bearbeiten',
  editPostAddressText:
    'Hier können Sie eine von der Rechnungsanschrift abweichende Zustellanschrift Ihres Kunden hinterlegen.',
  recipient: 'Empfänger',
  deletePostAddress: 'Versandadresse löschen',
  deletePostAddressText:
    'Sind Sie sicher, dass Sie die hinterlegte Versandadresse entfernen möchten?',
  addressDeleted: 'Adresse gelöscht',
  editDebtorError:
    'Es ist ein technischer Fehler aufgetreten. Leider können wir Ihre Änderungsanfrage derzeit nicht durchführen. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.',
  increaseLimitRequest: 'Limiterhöhung beantragen',
  refreshLimit: 'Limit erneuern',
  editPaymentData: 'Zahlungsdaten bearbeiten',
  invoiceDeliveryType: 'Rechnungsversand',
  debtorInfo: 'Kundeninfo',
  paymentData: 'Zahlungsdaten',
  sepaMandate: 'SEPA-Mandat',
  sepaRecorded: 'Liegt vor',
  noInvoices: 'Es liegen noch keine Rechnungen zu diesem Kunden vor.',
  noPurchasedInvoices: 'Derzeit liegen keine offenen, angekauften Rechnungen zu diesem Kunden vor.',
  showInvoicesInVoucherList: 'Rechnungen in Belegübersicht anzeigen',
  showPurchasedInvoicesInVoucherList: 'Angekaufte Rechnungen in Belegübersicht anzeigen',
  linkOptions: {
    all: 'Alle',
    open: 'Offene',
    closed: 'Geschlossene'
  },
  lastInvoices: 'Ihre  bis zu 15 letzten Rechnungen',
  lastOpenPurchasedInvoices: 'Ihre bis zu 15 letzten offenen, angekauften Rechnungen',
  isOnBlacklist:
    'Ihr Kunde wurde von der Ankaufzusage ausgeschlossen, da Auffälligkeiten vorliegen.',
  limit: 'Limit',
  approvedLimit: 'bestätigter Ankaufrahmen',
  limitStatus: 'Limitstatus',
  expired: 'Abgelaufen',
  since: 'Stand',
  updatedOn: 'Geändert am',
  validUntil: 'Gültig bis',
  limitExpiresOn: 'Das Limit ist bis zum <b>{date}</b> gültig.',
  limitExpiredOn: 'Das Limit ist am <b>{date}</b> abgelaufen.',
  limitInfo:
    'Folgende Rechnungen schöpfen den Debitorenkredit aktuell aus und beschränken das Limit. Mit dem Zahlungseingang beim Factoring-Dienstleister wird wieder Limit frei.',
  limitNotFound: 'Keine Limit- und Statusinformationen vorhanden',
  limitRequestSent: 'Ihre Limitanfrage wurde versandt',
  limitIncrease: {
    increaseFor: 'Limiterhöhung für',
    currentLimit: 'Aktuelles Limit',
    wantedLimit: 'Gewünschtes Limit',
    durationInfo:
      'Bitte beachten Sie, dass Ihre Anfrage aufgrund der manuellen Prüfung mehrere Tage dauern kann.',
    sent: 'Wir haben Ihre Anfrage erhalten. Die Bearbeitung Ihrer Anfrage kann mehrere Tage in Anspruch nehmen. Wir informieren Sie schnellstmöglich über den Beschluss.'
  },
  limitRefresh: {
    refreshFor: 'Limit erneuern für',
    request: 'Wollen Sie das Limit für den Kunden jetzt erneuern?',
    sent: 'Wir haben Ihre Anfrage erhalten. Die Bearbeitung Ihrer Anfrage kann mehrere Tage in Anspruch nehmen. Wir informieren Sie schnellstmöglich über das Ergebnis.'
  },
  limitSelectOptions: {
    eq0: '0 €',
    gr0: 'über 0 €',
    eq500: '500 €',
    grEq500: 'mindestens 500 €',
    grEq2500: 'mindestens 25.000 €',
    expired: 'ist abgelaufen',
    expires_soon: 'läuft bald ab (3 Monate)'
  },
  limitReasonCodes: {
    customer: {
      ID_1: 'Ihr Kunde hat kein Kreditlimit erhalten, da eine negative Bonität zur angefragten Person vorliegt.',
      ID_3: 'Ihr Kunde hat ein reduziertes Kreditlimit erhalten, da eine mittlere Bonität zur angefragten Person vorliegt.',
      ID_4: 'Ihr Kunde hat ein reduziertes Kreditlimit erhalten, da die angefragte Person nicht eindeutig identifiziert werden konnte.',
      ID_9: 'Ihr Kunde hat kein Kreditlimit erhalten, da Negativmerkmale oder ein Abtretungsverbot vorliegen.',
      ID_10: 'Ihr Kunde hat kein Kreditlimit erhalten, da die angefragte Person verstorben ist.'
    },
    business: {
      ID_1: 'Ihr Kunde hat kein Kreditlimit erhalten, da eine negative Bonität zur angefragten Firma vorliegt.',
      ID_4: 'Ihr Kunde hat ein reduziertes Kreditlimit erhalten, da die angefragte Firma nicht eindeutig identifiziert werden konnte.',
      ID_9: 'Ihr Kunde hat kein Kreditlimit erhalten, da Negativmerkmale oder ein Abtretungsverbot vorliegen.',
      ID_10: 'Ihr Kunde hat kein Kreditlimit erhalten, da die angefragte Firma erloschen ist.'
    }
  },
  solvencyPrivacyPolicyTitle: 'Datenschutzrichtlinien zur Bonitätsprüfung',
  solvencyPrivacyPolicyText:
    'Die Informationspflichten nach Art. 14 DSGVO zur Einholung von Bonitätsinformationen gegenüber meinem Kunden wurden erfüllt. Weitere Details finden Sie unter <a href="https://www.crif.de/datenschutz" target="_blank">www.crif.de/datenschutz</a>',
  solvencyPrivacyPolicyTitleNoPermission: 'Bonitätsprüfung derzeit nicht möglich',
  solvencyPrivacyPolicyTextNoPermission:
    'Sie haben in der Vergangenheit intensiv die Möglichkeit genutzt, Ihre Kunden auf deren Bonität zu prüfen. Leider haben Sie nicht im gleichen Maße Rechnungen bei uns eingereicht. Dies ist jedoch Voraussetzung dafür Bonitätsprüfungen über unser Portal durchführen zu können. Sofern Sie weitere Rechnungen bei uns einreichen und sich das Verhältnis von Bonitätsprüfungen zu eingereichten Rechnungen angleicht, werden wir die Funktion für Bonitätsprüfungen wieder für Sie freischalten. Bei Fragen wenden Sie sich gerne an unseren Support.',
  solvency: 'Bonität',
  checkSolvency: 'Bonität prüfen',
  checkingSolvency: 'Bonität wird geprüft...',
  solvencyAffectedBy: 'Folgende Faktoren wurden bei der Bestimmung der Bonität berücksichtigt',
  solvencyDecision: 'Ergebnis Bonität für',
  checkDebtorError:
    'Es ist ein technischer Fehler aufgetreten. Leider können wir Ihren Kunden derzeit nicht prüfen. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.',
  highSolvency: 'hoch',
  averageSolvency: 'mittel',
  lowSolvency: 'gering',
  solvencyReasonCodes: {
    customer: {
      ID_0: 'Es liegen keine Auffälligkeiten vor - geringes Ausfallrisiko',
      ID_1: 'Ihr Kunde hat eine negative Bonität erhalten, da Negativmerkmale zur angefragten Person vorliegen - hohes Ausfallrisiko',
      ID_3: 'Ihr Kunde hat eine reduzierte Bonität erhalten, da Auffälligkeiten zur angefragten Person vorliegen - erhöhtes Ausfallrisiko',
      ID_4: 'Ihr Kunde hat eine reduzierte Bonität erhalten, da die angefragte Person nicht eindeutig identifiziert werden konnte.',
      ID_10:
        'Ihr Kunde hat eine negative Bonität erhalten, da die angefragte Person verstorben ist - hohes Ausfallrisiko'
    },
    business: {
      ID_0: 'Es liegen keine Auffälligkeiten vor - geringes Ausfallrisiko',
      ID_1: 'Ihr Kunde hat eine reduzierte Bonität erhalten, da Auffälligkeiten zur angefragten Firma vorliegen - erhöhtes Ausfallrisiko',
      ID_3: 'Ihr Kunde hat eine negative Bonität erhalten, da Negativmerkmale zur angefragten Firma vorliegen - hohes Ausfallrisiko',
      ID_4: 'Ihr Kunde hat eine reduzierte Bonität erhalten, da die angefragte Firma nicht eindeutig identifiziert werden konnte.',
      ID_8: 'Bei Ihrem Kunden handelt es sich um eine öffentlich-rechtliche Einrichtung - geringes Ausfallrisiko',
      ID_10:
        'Ihr Kunde hat eine negative Bonität erhalten, da die angefragte Firma erloschen ist - hohes Ausfallrisiko'
    }
  },
  editDebtorStatus: {
    activate: 'Aktivieren',
    deactivate: 'Deaktivieren',
    activateDebtor: 'Kunde aktivieren',
    activateDebtorRequest: 'Möchten Sie diesen Kunden wirklich wieder aktivieren?',
    activateDebtorConfirmed: 'Kunde aktiviert',
    deactivateDebtor: 'Kunde deaktivieren',
    deactivateDebtorRequest:
      'Sie können einen Kunden deaktivieren, um ihn aus Ihrer Liste auszublenden und Ihre Kundenübersicht so auf dem aktuellen Stand zu halten.',
    deactivateDebtorInfo:
      'Mit dem Kunden werden auch alle zugehörigen Versandadressen deaktiviert. Deaktivierte Kunden und Versandadressen können nicht mehr bearbeitet werden. Die Belege zum Kunden bleiben jedoch weiterhin in Ihrer Belegübersicht erhalten.',
    deactivateDebtorWarning:
      'Wenn Sie einen deaktivierten Kunden nachträglich wieder aktivieren möchten, wenden Sie sich bitte an unseren Support.',
    deactivateDebtorConfirmed: 'Kunde deaktiviert',
    activateLocation: 'Versandadresse aktivieren',
    activateLocationRequest: 'Möchten Sie diese Versandadresse wirklich wieder aktivieren?',
    activateLocationConfirmed: 'Versandadresse aktiviert',
    deactivateLocation: 'Versandadresse deaktivieren',
    deactivateLocationRequest:
      'Sie können eine Versandadresse deaktivieren, um sie aus Ihrer Liste zu entfernen und Ihre Kundenübersicht so auf dem aktuellen Stand zu halten.',
    deactivateLocationInfo:
      'Deaktivierte Versandadressen können nicht mehr bearbeitet werden. Die Belege zur Versandadresse bleiben jedoch weiterhin in Ihrer Belegübersicht erhalten.',
    deactivateLocationConfirmed: 'Versandadresse deaktiviert',
    permanentDebtWarningDebtor:
      'Bitte stoppen Sie zunächst alle aktiven regelmäßigen Einzüge, bevor Sie den Kunden deaktivieren.',
    permanentDebtWarningLocation:
      'Bitte stoppen Sie zunächst alle aktiven regelmäßigen Einzüge, bevor Sie die Versandadresse deaktivieren.',
    deactivateLastLocationWarning:
      'Mit der letzten aktiven Versandadresse wird auch der Kunde deaktiviert. Wenn Sie einen deaktivierten Kunden nachträglich wieder aktivieren möchten, wenden Sie sich bitte an unseren Support.'
  },
  editPayment: {
    editDataFor: 'Zahlungsdaten bearbeiten für',
    sepaData: 'Angaben zum SEPA-Lastschriftmandat',
    sepaInfo:
      'Bitte prüfen Sie noch einmal, dass die hinterlegten Angaben korrekt sind. Eine nachträgliche Änderung des SEPA-Lastschriftmandates ist aus rechtlichen Gründen nur über unseren Kundenservice möglich.',
    confirmed: 'Zahlungsdaten bearbeitet'
  }
};
