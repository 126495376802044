import showSnackbar from '@/helper/showSnackbar';
import { infoDialogStorage } from '@/mixins/UserInfoMixin';
import { mapUnapprovedTermsAndConditions } from '@/modules/Authentication/models';

export const NAMESPACE = 'authentication';

const initialState = () => ({
  adminView: false,
  current: null,
  loadingCurrent: false,
  storedInfoDialogs: []
});

export default (Vue) => ({
  namespaced: true,

  state: initialState(),

  getters: {},

  mutations: {
    SET_ADMIN_VIEW(state, enabled) {
      state.adminView = enabled;
    },
    SET_CURRENT(state, user) {
      state.current = user;
    },
    SET_LOADING_CURRENT(state) {
      state.loadingCurrent = true;
    },
    RESET_LOADING_CURRENT(state) {
      state.loadingCurrent = false;
    },
    GET_STORED_INFO_DIALOGS(state) {
      state.storedInfoDialogs = JSON.parse(localStorage.getItem(infoDialogStorage)) ?? [];
    },
    SET_STORED_INFO_DIALOGS(state, keyList) {
      state.storedInfoDialogs = keyList;
      localStorage.setItem(infoDialogStorage, JSON.stringify(keyList));
    },
    RESET_STATE(state) {
      const initial = initialState();
      Object.keys(initial).forEach((key) => {
        state[key] = initial[key];
      });
    }
  },

  actions: {
    async updatePassword(_, password) {
      try {
        await Vue.prototype.$http.post('/user-management/command/update-password', {
          userIdentifier: Vue.prototype.$auth.user().id,
          password: password
        });
        return {};
      } catch (error) {
        return { error };
      }
    },

    async requestPasswordReset(_, email) {
      try {
        await Vue.prototype.$http.post('/user-management/command/password-reset/request', {
          email
        });
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async resetPassword(_, payload) {
      try {
        await Vue.prototype.$http.post('/user-management/command/password-reset', payload);
        return {};
      } catch (error) {
        return { error };
      }
    },

    async fetchUnapprovedTermsAndConditions() {
      try {
        const { data } = await Vue.prototype.$http.get(
          '/user-management/list-unaccepted-terms-and-conditions'
        );

        return { data: mapUnapprovedTermsAndConditions(data) };
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    },

    async approveTermsAndConditions(_, approvalId) {
      try {
        await Vue.prototype.$http.get(
          `/user-management/approve-terms-and-conditions/${approvalId}`
        );
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return { error };
      }
    }
  }
});
