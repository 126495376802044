<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.21 31.49">
    <g id="Layer_2">
      <g id="Layer_1-2" fill="currentColor">
        <path
          d="M20.82,21.4,25,9.56l-.6-.25L18.91,20.6a5.38,5.38,0,0,0-1.14-.13,5.51,5.51,0,1,0,3.05.93ZM18.28,29a2.29,2.29,0,0,1-1.78-.78,2.69,2.69,0,0,1-.64-1.52h-.54v-.43h.5v-.13c0-.14,0-.27,0-.4h-.52v-.44h.58a2.76,2.76,0,0,1,.72-1.48,2.31,2.31,0,0,1,1.74-.73,2.5,2.5,0,0,1,1.17.28l-.17.58a2.05,2.05,0,0,0-1-.25,1.53,1.53,0,0,0-1.16.49,2.1,2.1,0,0,0-.53,1.11h2.46v.44H16.6c0,.12,0,.25,0,.37v.16h2.56v.43H16.65a2.14,2.14,0,0,0,.48,1.16,1.7,1.7,0,0,0,1.24.51,2.38,2.38,0,0,0,1.08-.29l.14.55A2.32,2.32,0,0,1,18.28,29Z"
        />
        <path
          d="M17.64,10.39a17.74,17.74,0,0,1,4.23.35l-1.28,2.68a14.65,14.65,0,0,0-2.95-.4A16.21,16.21,0,0,0,6,17.35,16.73,16.73,0,0,0,.69,29H0A17.91,17.91,0,0,1,4.83,16.18,18.39,18.39,0,0,1,17.64,10.39Z"
        />
        <path
          d="M37.21,29H32.64A15.28,15.28,0,0,0,28.58,18a15.09,15.09,0,0,0-3.85-3l1.34-2.93a19.14,19.14,0,0,1,5.07,3.41A19.38,19.38,0,0,1,37.21,29Z"
        />
        <path
          d="M29.93,3.59a2.37,2.37,0,0,1-.51.82l-.25.24L25.42,7.86,24.86,3a2,2,0,0,1,0-.35,2.63,2.63,0,1,1,5.08,1Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconCheckSolvency'
};
</script>
