export const DebtorStatus = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  REACTIVATED: 'reactivated'
};

export const DEBTOR_STATE_GROUPS = {
  [DebtorStatus.ACTIVE]: [DebtorStatus.ACTIVE, DebtorStatus.REACTIVATED],
  [DebtorStatus.INACTIVE]: [DebtorStatus.INACTIVE]
};

export default DebtorStatus;
