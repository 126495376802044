/* roles used for authentication */

export const Role = {
  // creditor roles
  CREDITOR: 'ROLE_CREDITOR',
  CREDITOR_ASSISTANT: 'ROLE_CREDITOR-ASSISTANT',
  CREDITOR_MANAGEMENT: 'ROLE_CREDITOR-MANAGEMENT',
  CREDITOR_OBSERVER: 'ROLE_CREDITOR-OBSERVER',

  // admin roles
  ADMIN: 'ROLE_ADMIN',
  PRODUCT_ADMIN: 'ROLE_PRODUCT_ADMIN',
  SUPER_ADMIN: 'ROLE_SUPER_ADMIN'
};

export const CREDITOR_ROLES = [
  Role.CREDITOR,
  Role.CREDITOR_ASSISTANT,
  Role.CREDITOR_MANAGEMENT,
  Role.CREDITOR_OBSERVER
];

export const ADMIN_ROLES = [Role.ADMIN, Role.PRODUCT_ADMIN];

/* roles used for company user management and administration */
export const UserManagementRole = {
  CREDITOR_USER: 'creditor',

  // company user roles
  COMPANY_USER_MANAGEMENT: 'creditor-management',
  COMPANY_USER_ASSISTANT: 'creditor-assistant',
  COMPANY_USER_OBSERVER: 'creditor-observer'
};

export const COMPANY_USER_ROLES = [
  UserManagementRole.COMPANY_USER_MANAGEMENT,
  UserManagementRole.COMPANY_USER_ASSISTANT,
  UserManagementRole.COMPANY_USER_OBSERVER
];

export default Role;
