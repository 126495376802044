import Vue from 'vue';
import VueI18n from 'vue-i18n';
import translations from '../shared/translations/translations';
import bnetStatusTranslations from '../shared/translations/bnetStatusTranslations';

Vue.use(VueI18n);

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'de',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'de',
  messages: {
    de: {
      ...translations,
      ...bnetStatusTranslations
    }
  }
});
