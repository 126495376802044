<template>
  <v-btn
    :id="id || title || undefined"
    :class="{
      'text-btn': true,
      'text-btn--mobile': isMobile,
      'text-btn--spacious': spacious,
      'text-btn--underlined': underlined
    }"
    :color="color"
    :disabled="disabled || loading"
    :loading="loading"
    text
    v-bind="$attrs"
    v-on="$listeners"
  >
    <span v-if="prependIcon" class="text-btn__icon-wrapper text-btn__icon-wrapper--prepend">
      <component :is="prependIcon" v-if="prependIcon" :style="{ '--size': iconSizeVar }" />
    </span>

    <slot>{{ $te(title) ? $t(title) : title }}</slot>
    <span v-if="appendIcon" class="text-btn__icon-wrapper text-btn__icon-wrapper--append">
      <component :is="appendIcon" v-if="appendIcon" :style="{ '--size': iconSizeVar }" />
    </span>
  </v-btn>
</template>

<script>
export default {
  name: 'AppTextBtn',

  props: {
    appendIcon: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    },
    iconSize: {
      type: [String, Number],
      default: undefined
    },
    loading: {
      type: Boolean,
      default: false
    },
    prependIcon: {
      type: String,
      default: ''
    },
    spacious: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    underlined: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    iconSizeVar() {
      return isNaN(this.iconSize) ? this.iconSize : this.iconSize + 'px';
    }
  }
};
</script>

<style lang="scss" scoped>
@mixin icon-styles($size) {
  ::v-deep .v-icon,
  ::v-deep svg {
    width: var(--size, $size);
    height: var(--size, $size);
    font-size: var(--size, $size);
  }
}

.text-btn {
  @include icon-styles(1.125rem);

  &.v-size--small {
    @include icon-styles(1rem);
  }

  &.v-size--large {
    @include icon-styles(1.25rem);
  }

  letter-spacing: inherit;
  font-size: inherit;
  text-transform: none;
  flex-direction: row;
  justify-content: flex-start;
  vertical-align: baseline;
  white-space: normal;
  word-break: break-word;
  hyphens: auto;
  border-radius: 2px;

  &.v-btn {
    padding: 0;
    height: auto;
    min-height: unset;
    width: fit-content;
    min-width: unset;
    max-width: 100%;
  }

  ::v-deep.v-btn__content {
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-indent: 0;
    text-align: left;
    line-height: inherit;
    vertical-align: inherit;
    white-space: normal;
  }
}

.text-btn--spacious {
  letter-spacing: var(--button-letter-spacing);
}

.text-btn--underlined {
  text-decoration: underline;
}

.text-btn__icon-wrapper {
  vertical-align: text-top;

  &--prepend {
    margin-right: 8px;
  }

  &--append {
    margin-left: 8px;
  }
}

::v-deep .v-progress-circular {
  width: 100% !important;
  height: 100% !important;
}
</style>
