<template>
  <app-icon-btn
    :color="isMobile ? 'text' : 'background-text'"
    @click="$router.push({ query: { ...$route.query, showNotifications: true } })"
  >
    <app-tooltip text="notifications.title" :disabled="isMobile">
      <v-badge id="newsfeed-badge" :value="unreadNotificationsCount > 0" color="error" overlap>
        <template #badge>
          {{ unreadNotificationsCount }}
        </template>
        <icon-notifications />
      </v-badge>
    </app-tooltip>
  </app-icon-btn>
</template>

<script>
import { NAMESPACE } from '@/modules/User/store';
import { mapState } from 'vuex';

export default {
  name: 'NotificationsActivator',

  computed: {
    ...mapState(NAMESPACE, ['unreadNotificationsCount'])
  }
};
</script>
