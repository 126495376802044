<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 100 125"
    enable-background="new 0 0 100 100"
    xml:space="preserve"
    fill="currentColor"
  >
    <polygon points="5,8 8,5 50,47 92,5 95,8 53,50 95,92 92,95 50,53 8,95 5,92 47,50 " />
  </svg>
</template>

<script>
export default {
  name: 'IconPlaceholder'
};
</script>
