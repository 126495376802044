export const SERVICE_HOTLINE_FINTECRITY = '+49 (0)621 399957-60';
export const SERVICE_HOTLINE_TEAMFAKTOR = '+49 (0)231 2222-3460';

export const SERVICE_MAIL_FINTECRITY = 'service@fintecrity.de';
export const SERVICE_MAIL_TEAMFAKTOR = 'service@teamfaktor-nw.com';
export const SERVICE_MAIL_TRANSPOREON = 'transporeon-factoring@service.teamfaktor-nw.com';

export const SERVICE_TIMES = [{ days: 'Mo-Fr', time: '7:00-18:00' }];

export const IMPRINT_FINTECRITY = 'https://www.fintecrity.de/impressum/';
export const IMPRINT_TEAMFAKTOR = 'https://www.factoringwerk.com/impressum/';

export const PRIVACY_POLICY_FINTECRITY = 'https://www.fintecrity.de/datenschutzerklaerung/';
export const PRIVACY_POLICY_TEAMFAKTOR = 'https://www.factoringwerk.com/datenschutz/';

export const PRODUCT_INFO_DEGENER = 'https://www.degener.de/';
export const PRODUCT_INFO_DV = 'https://dv-gmbh.de/';
export const PRODUCT_INFO_FINTECRITY = 'https://www.fintecrity.de/';
export const PRODUCT_INFO_TEAMFAKTOR = 'https://www.factoringwerk.com/';
