/**
 * @see {@link https://wiki.move-elevator.de/display/DVKMU/Statusliste+bNet+--%3E+ME Statusliste bNet --> ME}
 */
export const BnetStatus = {
  UNKNOWN_0: 0,
  DOCUMENT_RECEIVED_101: 101,
  IN_VALIDATION_102: 102,
  IN_EDIT_103: 103,
  DEBTOR_PROCESSING_104: 104,
  DEBTOR_PROCESSING_COMPLETED_105: 105,
  DEBTOR_QUALITY_MANAGEMENT_106: 106,
  VOUCHER_PROCESSING_107: 107,
  GENERAL_QUALITY_MANAGEMENT_108: 108,
  VOUCHER_PAUSED_109: 109,
  VOUCHER_READ_110: 110,
  DEBTOR_VERIFIED_111: 111,
  OFFICIAL_VOUCHER_NUMBER_RECEIVED_112: 112,
  DOCUMENT_CREATED_113: 113,
  DOCUMENT_ARCHIVED_114: 114,
  SENT_TO_SAP_115: 115,

  SEND_MAIL_COMMITTED_120: 120,
  SEND_EMAIL_COMMITTED_121: 121,
  SEND_EMAILCC_COMMITTED_122: 122,
  SEND_MAIL_IN_PRODUCTION_123: 123,
  SEND_MAIL_PRODUCED_124: 124,
  SEND_EMAIL_SHIPPED_125: 125,
  SEND_EMAILCC_SHIPPED_126: 126,
  SEND_EMAILBBC_SHIPPED_127: 127,
  SEND_EMAIL_ERROR_150: 150,
  SEND_EMAILCC_ERROR_151: 151,
  SEND_EMAILBBC_ERROR_152: 152,
  SEND_EMAIL_UNKNOWN_ERROR_153: 153,
  SEND_EMAILBBC_COMMITTED_154: 154,

  PROCESSED_200: 200,
  COMPLETED_201: 201,
  VOUCHER_IN_FUTURE_FACTORING_220: 220,
  DUPLICATED_VOUCHER_NUMBER_221: 221,
  VOUCHER_IN_FUTURE_BILLING_222: 222,

  VOUCHER_BLOCKED_801: 801,
  BREAKUP_SUCCESSFULLY_802: 802,
  BREAKUP_FAILED_803: 803,

  INTERNAL_ERROR_901: 901,
  SAP_ERROR_902: 902,
  DOCUMENT_ARCHIVE_ERROR_903: 903,
  PRINT_ERROR_904: 904,
  PORTAL_ERROR_905: 905,
  VOUCHER_GENERAL_ERROR_920: 920,
  VOUCHER_ADDRESS_ERROR_921: 921,
  VOUCHER_DATE_ERROR_922: 922,
  VOUCHER_NUMBER_ERROR_923: 923,
  VOUCHER_BEFORE_CONTRACT_ERROR_924: 924,
  VOUCHER_FORMAT_ERROR_925: 925,
  VOUCHER_TYPE_ERROR_926: 926,
  VOUCHER_BANK_ERROR_927: 927,
  VOUCHER_DELIVERY_PERFORMANCE_ERROR_928: 928,
  VOUCHER_NOT_READABLE_ERROR_929: 929,
  VOUCHER_PAYMENT_CONDITION_ERROR_930: 930,
  VOUCHER_AMOUNT_ERROR_931: 931,
  VOUCHER_CURRENCY_ERROR_932: 932,
  VOUCHER_COUNTRY_ERROR_933: 933,
  VOUCHER_DOCUMENT_PROTECTED_ERROR_934: 934,
  ENCASHMENT_NOT_POSSIBLE_ERROR_935: 935,
  PDF_MULTIPLE_VOUCHERS_ERROR_936: 936,
  VOUCHER_ATTACHMENT_DOCUMENT_PROTECTED_ERROR_937: 937,
  VOUCHER_ATTACHMENT_UNDEFINED_ERROR_938: 938,
  VOUCHER_AMOUNT_ZERO_ERROR_939: 939
};

export default BnetStatus;
