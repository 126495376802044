import showSnackbar from '@/helper/showSnackbar';
import {
  mapDebtor,
  mapDebtorPermissions,
  mapList,
  mapLocation,
  mapLocationPermissions,
  mapNewDebtorData,
  mapOpenDebtorAssignments
} from '@/modules/Debtors/models';
import { mapHistory } from '@/shared/models';
import formatLogData from '@/helper/formatLogData';
import { mapLocationPermanentDebts } from '@/modules/PermanentDebts/models';
import qs from 'qs';

export const NAMESPACE = 'debtors';

const initialState = () => ({
  debtors: [],
  debtorsCount: 0,
  debtorsEmpty: true,
  openDebtorAssignmentsList: [],
  openDebtorAssignmentsCount: 0,
  debtorNames: [],
  customDebtorNumbers: [],
  creditorLimits: null,
  detailedDebtor: null,
  detailedDebtorRaw: null,
  processLog: [],
  processLogRaw: [],
  loadingDebtors: false,
  loadingDetails: false,
  identCheckResponse: null,
  listError: false,
  detailsError: false
});

export default (Vue) => ({
  namespaced: true,

  state: initialState(),

  getters: {
    identifiersByNames: (state) => (names) =>
      state.debtorNames
        .filter(({ name }) => names.includes(name))
        .map(({ identifier }) => identifier)
  },

  mutations: {
    SET_DEBTORS(
      state,
      { debtors, count, creditorLimits, empty, openAssignmentsList, openAssignmentsCount }
    ) {
      state.debtors = debtors;
      state.debtorsCount = count;
      state.debtorsEmpty = empty;
      state.creditorLimits = creditorLimits;
      state.openDebtorAssignmentsList = openAssignmentsList;
      state.openDebtorAssignmentsCount = openAssignmentsCount;
    },
    RESET_DEBTORS(state) {
      state.debtors = [];
      state.debtorsCount = 0;
      state.debtorsEmpty = true;
      state.creditorLimits = null;
    },
    SET_DEBTOR_NAMES(state, names) {
      state.debtorNames = names;
    },
    SET_CUSTOM_DEBTOR_NUMBERS(state, numbers) {
      state.customDebtorNumbers = numbers;
    },
    SET_DETAILED_DEBTOR(state, { debtor, debtorRaw = state.detailedDebtorRaw }) {
      state.detailedDebtor = debtor;
      state.detailedDebtorRaw = debtorRaw;
    },
    RESET_DETAILED_DEBTOR(state) {
      state.detailedDebtor = null;
      state.detailedDebtorRaw = null;
    },
    SET_PROCESSLOG(state, { processLog, processLogRaw }) {
      state.processLog = processLog;
      state.processLogRaw = processLogRaw;
    },
    RESET_PROCESSLOG(state) {
      state.processLog = [];
      state.processLogRaw = [];
    },
    SET_IDENT_CHECK_RESPONSE(state, data) {
      state.identCheckResponse = data;
    },
    RESET_IDENT_CHECK_RESPONSE(state) {
      state.identCheckResponse = null;
    },
    SET_LOADING_DEBTORS(state) {
      state.loadingDebtors = true;
    },
    RESET_LOADING_DEBTORS(state) {
      state.loadingDebtors = false;
    },
    SET_LOADING_DETAILS(state) {
      state.loadingDetails = true;
    },
    RESET_LOADING_DETAILS(state) {
      state.loadingDetails = false;
    },
    SET_LIST_ERROR(state) {
      state.listError = true;
    },
    RESET_LIST_ERROR(state) {
      state.listError = false;
    },
    SET_DETAILS_ERROR(state) {
      state.detailsError = true;
    },
    RESET_DETAILS_ERROR(state) {
      state.detailsError = false;
    },
    RESET_STATE(state) {
      const initial = initialState();
      Object.keys(initial).forEach((key) => {
        state[key] = initial[key];
      });
    }
  },

  actions: {
    async fetchDebtors({ commit }, { page, rowsPerPage, filters, sorting }) {
      commit('RESET_LIST_ERROR');
      commit('SET_LOADING_DEBTORS');

      try {
        const [debtorListResponse, openDebtorAssignmentsResponse] = await Promise.all([
          Vue.prototype.$http.get(`/debtor/list`, {
            params: { page, rowsPerPage, filters, sorting },
            paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' })
          }),
          Vue.prototype.$http.get('open-debtor-decisions-debtor')
        ]);
        const debtors = debtorListResponse.data.debtors;
        const creditorLimits = debtorListResponse.data.creditorLimits;
        const count = debtorListResponse.data.count;
        const empty = debtorListResponse.data.empty;
        const openAssignmentsList = openDebtorAssignmentsResponse.data.items;
        const openAssignmentsCount = openDebtorAssignmentsResponse.data.count;

        commit('SET_DEBTORS', {
          debtors: mapList(debtors),
          creditorLimits,
          count,
          empty,
          openAssignmentsList: mapOpenDebtorAssignments(openAssignmentsList),
          openAssignmentsCount
        });
        commit('RESET_LOADING_DEBTORS');
        return {};
      } catch (error) {
        commit('SET_LIST_ERROR');
        commit('RESET_DEBTORS');
        commit('RESET_LOADING_DEBTORS');
        return { error };
      }
    },

    async fetchDetailedDebtor({ commit, dispatch, rootState }, identifier) {
      commit('RESET_DETAILS_ERROR');
      commit('SET_LOADING_DETAILS');
      commit('RESET_DETAILED_DEBTOR');

      if (rootState.authentication.adminView) {
        commit('RESET_PROCESSLOG');
        dispatch('fetchProcessLog', identifier);
      }

      try {
        const [debtorResponse, historyResponse, permissionsResponse] = await Promise.all([
          Vue.prototype.$http.get(`/debtor/show/${identifier}`),
          Vue.prototype.$http.get(`/debtor/${identifier}/history/list`),
          Vue.prototype.$http.get(`/debtor/${identifier}/permissions`)
        ]);

        const debtor = {
          ...mapDebtor(debtorResponse.data),
          history: mapHistory(historyResponse.data),
          permissions: mapDebtorPermissions(permissionsResponse.data)
        };

        commit('SET_DETAILED_DEBTOR', { debtor: debtor, debtorRaw: debtorResponse.data });
        commit('RESET_LOADING_DETAILS');
        return {};
      } catch (error) {
        commit('SET_DETAILS_ERROR');
        commit('RESET_LOADING_DETAILS');
        return { error };
      }
    },

    async fetchProcessLog({ commit }, identifier) {
      try {
        const response = await Vue.prototype.$http.get(`/debtor/${identifier}/process-log/list`);

        commit('SET_PROCESSLOG', {
          processLog: formatLogData(response.data),
          processLogRaw: response.data
        });

        return {};
      } catch {
        showSnackbar({ text: 'serverError', color: 'error' });
      }
    },

    async fetchDetailedLocation({ commit }, { debtorIdentifier, locationIdentifier }) {
      commit('RESET_DETAILS_ERROR');
      commit('SET_LOADING_DETAILS');
      commit('RESET_DETAILED_DEBTOR');

      try {
        const [locationResponse, permissionsResponse] = await Promise.all([
          Vue.prototype.$http.get(`/debtor/${debtorIdentifier}/location/${locationIdentifier}`),
          Vue.prototype.$http.get(`/location/${locationIdentifier}/permissions`)
        ]);

        const location = {
          ...mapLocation(locationResponse.data),
          permissions: mapLocationPermissions(permissionsResponse.data)
        };

        commit('SET_DETAILED_DEBTOR', { debtor: location, debtorRaw: locationResponse.data });
        commit('RESET_LOADING_DETAILS');
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        commit('SET_DETAILS_ERROR');
        commit('RESET_LOADING_DETAILS');
        return { error };
      }
    },

    async fetchLocationPermanentDebts(_, identifier) {
      try {
        const response = await Vue.prototype.$http.get(
          `/location/${identifier}/permanent-debt-vouchers`
        );
        return { response: mapLocationPermanentDebts(response.data), responseRaw: response.data };
      } catch (error) {
        showSnackbar({ text: 'serverError' });
        return null;
      }
    },

    async fetchFilterDebtorNames({ commit }) {
      try {
        const response = await Vue.prototype.$http.get(`/debtor/names/list`);

        commit('SET_DEBTOR_NAMES', response.data);

        return { response: response.data };
      } catch (error) {
        showSnackbar({ text: 'serverError' });
        return null;
      }
    },

    async fetchFilterCustomDebtorNumber({ commit }) {
      try {
        const response = await Vue.prototype.$http.get(`/debtor/customer-number/list`);

        commit('SET_CUSTOM_DEBTOR_NUMBERS', response.data);

        return { response: response.data };
      } catch (error) {
        showSnackbar({ text: 'serverError' });
        return null;
      }
    },

    async requestIdentCheck({ commit }, debtorData) {
      try {
        const response = await Vue.prototype.$http.post('/csp/debtor', debtorData);

        commit('SET_IDENT_CHECK_RESPONSE', response.data);
        return { response: mapNewDebtorData(response.data) };
      } catch (error) {
        return { error };
      }
    },

    async createDebtor({ commit, state }) {
      try {
        const response = await Vue.prototype.$http.post('/debtor/create', state.identCheckResponse);

        commit('RESET_IDENT_CHECK_RESPONSE');
        return { debtorId: response.data.debtor, locationId: response.data.location };
      } catch (error) {
        showSnackbar({ text: 'debtors.addDebtorError' });
        return { error };
      }
    },

    async editDebtor(_, { debtorIdentifier, payload }) {
      try {
        await Vue.prototype.$http.post(`/debtor/update/${debtorIdentifier}`, payload);
        return {};
      } catch (error) {
        showSnackbar({ text: 'changesNotSaved' });
        return { error };
      }
    },

    async activateDebtor(_, debtorIdentifier) {
      try {
        await Vue.prototype.$http.get(`/debtor/${debtorIdentifier}/activate`);
        return {};
      } catch (error) {
        showSnackbar({ text: 'changesNotSaved' });
        return { error };
      }
    },

    async deactivateDebtor(_, debtorIdentifier) {
      try {
        await Vue.prototype.$http.get(`/debtor/${debtorIdentifier}/deactivate`);
        return {};
      } catch (error) {
        showSnackbar({ text: 'changesNotSaved' });
        return { error };
      }
    },

    async editLocation(_, { locationIdentifier, payload }) {
      try {
        await Vue.prototype.$http.post(`/location/update/${locationIdentifier}`, payload);
        return {};
      } catch (error) {
        showSnackbar({ text: 'changesNotSaved' });
        return { error };
      }
    },

    async addLocation(_, { debtorIdentifier, locationData }) {
      try {
        const response = await Vue.prototype.$http.post(
          `/debtor/${debtorIdentifier}/add-location`,
          locationData
        );
        return { locationId: response.data };
      } catch (error) {
        showSnackbar({ text: 'addresses.addressNotAdded' });
        return { error };
      }
    },

    async requestLimitIncrease(_, { debtorIdentifier, data }) {
      try {
        await Vue.prototype.$http.post(`/sap/debtor/${debtorIdentifier}/increase-limit`, data);
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError' });
        return { error };
      }
    },

    async refreshLimit(_, { debtorIdentifier }) {
      try {
        await Vue.prototype.$http.post(`/debtor/${debtorIdentifier}/limit-refresh`);
        return {};
      } catch (error) {
        showSnackbar({ text: 'serverError' });
        return { error };
      }
    },

    async checkSolvency(_, identifier) {
      try {
        const response = await Vue.prototype.$http.get(`/debtor/${identifier}/determine-solvency`);
        return { response: response.data };
      } catch (error) {
        return { error };
      }
    },

    async editLocationPostAddress(_, { identifier, address }) {
      try {
        await Vue.prototype.$http.post(`/location/${identifier}/post-address`, address);
        return {};
      } catch (error) {
        showSnackbar({ text: 'changesNotSaved' });
        return { error };
      }
    },

    async deleteLocationPostAddress(_, identifier) {
      try {
        await Vue.prototype.$http.delete(`/location/${identifier}/post-address`);
        return {};
      } catch (error) {
        showSnackbar({ text: 'changesNotSaved' });
        return { error };
      }
    },

    async activateLocation(_, locationIdentifier) {
      try {
        await Vue.prototype.$http.get(`/location/${locationIdentifier}/activate`);
        return {};
      } catch (error) {
        showSnackbar({ text: 'changesNotSaved' });
        return { error };
      }
    },

    async deactivateLocation(_, locationIdentifier) {
      try {
        await Vue.prototype.$http.get(`/location/${locationIdentifier}/deactivate`);
        return {};
      } catch (error) {
        showSnackbar({ text: 'changesNotSaved' });
        return { error };
      }
    }
  }
});
