export const ProductType = {
  DV_FACTORING: 'SMAFA',
  DV_BILLING: 'APL',
  FW_FACTORING: 'FW',
  DAA_BILLING: 'DAA',
  DAB_BILLING: 'DAB',
  FAS_BILLING: 'FAS',
  VHV_FACTORING: 'VHV',
  TPF_FACTORING: 'TPF'
};

export const PRODUCT_TYPES = Object.values(ProductType);

export const FACTORING_PRODUCTS = [
  ProductType.DV_FACTORING,
  ProductType.FW_FACTORING,
  ProductType.VHV_FACTORING,
  ProductType.TPF_FACTORING
];
export const BILLING_PRODUCTS = [
  ProductType.DV_BILLING,
  ProductType.DAA_BILLING,
  ProductType.DAB_BILLING,
  ProductType.FAS_BILLING
];

export default ProductType;
