import Vue from 'vue';

import IconActivateDebtor from '@/shared/components/icons/IconActivateDebtor';
import IconAddSquare from '@/shared/components/icons/IconAddSquare';
import IconAdminActions from '@/shared/components/icons/IconAdminActions';
import IconAlert from '@/shared/components/icons/IconAlert';
import IconBlock from '@/shared/components/icons/IconBlock';
import IconBookmarkFull from '@/shared/components/icons/IconBookmarkFull';
import IconCalendar from './IconCalendar';
import IconCheck from '@/shared/components/icons/IconCheck';
import IconCheckSolvency from '@/shared/components/icons/IconCheckSolvency';
import IconCheckWithCircle from '@/shared/components/icons/IconCheckWithCircle';
import IconChecklist from '@/shared/components/icons/IconChecklist';
import IconChevronLeft from '@/shared/components/icons/IconChevronLeft';
import IconChevronRight from '@/shared/components/icons/IconChevronRight';
import IconClients from '@/shared/components/icons/IconClients';
import IconContinuingObligation from '@/shared/components/icons/IconContinuingObligation';
import IconCredit from '@/shared/components/icons/IconCredit';
import IconCsvImport from '@/shared/components/icons/IconCsvImport';
import IconDashboard from '@/shared/components/icons/IconDashboard';
import IconDeactivateDebtor from '@/shared/components/icons/IconDeactivateDebtor';
import IconDelay from '@/shared/components/icons/IconDelay';
import IconDeleteFile from '@/shared/components/icons/IconDeleteFile';
import IconDispute from '@/shared/components/icons/IconDispute';
import IconDot from '@/shared/components/icons/IconDot';
import IconDownload from '@/shared/components/icons/IconDownload';
import IconEditDebtor from '@/shared/components/icons/IconEditDebtor';
import IconEuro from '@/shared/components/icons/IconEuro';
import IconFileDoc from '@/shared/components/icons/IconFileDoc';
import IconFilePdf from '@/shared/components/icons/IconFilePdf';
import IconGauge from '@/shared/components/icons/IconGauge';
import IconHash from '@/shared/components/icons/IconHash';
import IconHelpCenter from '@/shared/components/icons/IconHelpCenter';
import IconHourglass from '@/shared/components/icons/IconHourglass';
import IconIncreaseLimit from '@/shared/components/icons/IconIncreaseLimit';
import IconInfo from '@/shared/components/icons/IconInfo';
import IconLightbulb from '@/shared/components/icons/IconLightbulb';
import IconMapMarker from '@/shared/components/icons/IconMapMarker';
import IconMessage from '@/shared/components/icons/IconMessage';
import IconMoney from '@/shared/components/icons/IconMoney';
import IconNotifications from './IconNotifications';
import IconOpenInNew from '@/shared/components/icons/IconOpenInNew';
import IconPen from '@/shared/components/icons/IconPen';
import IconPermanentDebt from '@/shared/components/icons/IconPermanentDebt';
import IconPlaceholder from './IconPlaceholder';
import IconPlus from '@/shared/components/icons/IconPlus';
import IconRefreshLimit from '@/shared/components/icons/IconRefreshLimit';
import IconSearch from '@/shared/components/icons/IconSearch';
import IconSepa from '@/shared/components/icons/IconSepa';
import IconTargetClient from '@/shared/components/icons/IconTargetClient';
import IconText from '@/shared/components/icons/IconText';
import IconTrashCan from '@/shared/components/icons/IconTrashCan';
import IconTutorials from '@/shared/components/icons/IconTutorials';
import IconUploadInvoice from '@/shared/components/icons/IconUploadInvoice';
import IconVoucherNumber from '@/shared/components/icons/IconVoucherNumber';
import IconVouchers from './IconVouchers';
import IconWallet from '@/shared/components/icons/IconWallet';
import IconWorkQueue from '@/shared/components/icons/IconWorkQueue';

Vue.component('IconActivateDebtor', IconActivateDebtor);
Vue.component('IconAddSquare', IconAddSquare);
Vue.component('IconAdminActions', IconAdminActions);
Vue.component('IconAlert', IconAlert);
Vue.component('IconBlock', IconBlock);
Vue.component('IconBookmarkFull', IconBookmarkFull);
Vue.component('IconCalendar', IconCalendar);
Vue.component('IconCheck', IconCheck);
Vue.component('IconCheckSolvency', IconCheckSolvency);
Vue.component('IconCheckWithCircle', IconCheckWithCircle);
Vue.component('IconChecklist', IconChecklist);
Vue.component('IconChevronLeft', IconChevronLeft);
Vue.component('IconChevronRight', IconChevronRight);
Vue.component('IconClients', IconClients);
Vue.component('IconContinuingObligation', IconContinuingObligation);
Vue.component('IconCredit', IconCredit);
Vue.component('IconCsvImport', IconCsvImport);
Vue.component('IconDashboard', IconDashboard);
Vue.component('IconDeactivateDebtor', IconDeactivateDebtor);
Vue.component('IconDelay', IconDelay);
Vue.component('IconDeleteFile', IconDeleteFile);
Vue.component('IconDispute', IconDispute);
Vue.component('IconDot', IconDot);
Vue.component('IconDownload', IconDownload);
Vue.component('IconEditDebtor', IconEditDebtor);
Vue.component('IconEuro', IconEuro);
Vue.component('IconFileDoc', IconFileDoc);
Vue.component('IconFilePdf', IconFilePdf);
Vue.component('IconGauge', IconGauge);
Vue.component('IconHash', IconHash);
Vue.component('IconHelpCenter', IconHelpCenter);
Vue.component('IconHourglass', IconHourglass);
Vue.component('IconIncreaseLimit', IconIncreaseLimit);
Vue.component('IconInfo', IconInfo);
Vue.component('IconLightbulb', IconLightbulb);
Vue.component('IconMapMarker', IconMapMarker);
Vue.component('IconMessage', IconMessage);
Vue.component('IconMoney', IconMoney);
Vue.component('IconNotifications', IconNotifications);
Vue.component('IconOpenInNew', IconOpenInNew);
Vue.component('IconPen', IconPen);
Vue.component('IconPermanentDebt', IconPermanentDebt);
Vue.component('IconPlaceholder', IconPlaceholder);
Vue.component('IconPlus', IconPlus);
Vue.component('IconRefreshLimit', IconRefreshLimit);
Vue.component('IconSearch', IconSearch);
Vue.component('IconSepa', IconSepa);
Vue.component('IconTargetClient', IconTargetClient);
Vue.component('IconText', IconText);
Vue.component('IconTrashCan', IconTrashCan);
Vue.component('IconTutorials', IconTutorials);
Vue.component('IconUploadInvoice', IconUploadInvoice);
Vue.component('IconVoucherNumber', IconVoucherNumber);
Vue.component('IconVouchers', IconVouchers);
Vue.component('IconWallet', IconWallet);
Vue.component('IconWorkQueue', IconWorkQueue);
