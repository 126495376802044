<template>
  <v-container fluid class="app-container pa-0" :style="cssVars">
    <div class="app-background gradient-diagonal--primary"></div>

    <failure-page v-if="errorStatus" :error-status="errorStatus" />

    <reload-page v-else-if="forceReload" />

    <template v-else>
      <sidebar v-if="$auth.check()" :width="sidebarWidthPx" />
      <contact-bar
        v-if="$auth.check() && !isMobile"
        :key="`contact-bar_${current}`"
        :style="contactBarStyle"
      />
      <toolbar v-if="$auth.check()" :key="`toolbar_${current}`" :style="toolbarStyle" />
      <global-dialogs v-if="$auth.check()" :key="`global-dialogs_${current}`" />
      <app-snackbar />
      <refresh-snackbar />
      <tutorial-snackbar v-if="showTutorials" />
      <v-main :style="contentStyle">
        <app-header v-if="$auth.check()" />
        <v-fade-transition mode="out-in">
          <app-loading-sheet v-if="loadingCurrent || errorStatus === undefined" />
          <router-view v-else />
        </v-fade-transition>
      </v-main>
    </template>
  </v-container>
</template>

<script>
import AppHeader from '@/shared/components/layout/AppHeader';
import AppSnackbar from '@/shared/components/layout/AppSnackbar';
import ContactBar from '@/shared/components/layout/ContactBar';
import FailurePage from '@/modules/Failure/views/FailurePage';
import Sidebar from '@/shared/components/layout/Sidebar';
import Toolbar from '@/shared/components/layout/Toolbar';
import { AUTH_TOKEN_KEY } from '@/plugins/vue-auth';
import { ERROR_STATES, ErrorStatus } from '@/modules/Failure/statics/errorStatus';
import { NAMESPACE as FAILURE_NAMESPACE } from '@/modules/Failure/store';
import { NAMESPACE as AUTHENTICATION_NAMESPACE } from '@/modules/Authentication/store';
import { TUTORIAL_STORAGE } from '@/statics/storageKeys';
import { MILLISECONDS_PER_DAY } from '@/statics/timePeriods';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'Layout',

  components: {
    AppHeader,
    AppSnackbar,
    ContactBar,
    FailurePage,
    GlobalDialogs: () =>
      import('@/shared/components/layout/GlobalDialogs' /* webpackChunkName: "GlobalDialogs" */),
    RefreshSnackbar: () =>
      import('@/shared/components/RefreshSnackbar' /* webpackChunkName: "RefreshSnackbar" */),
    ReloadPage: () =>
      import('@/modules/Authentication/views/ReloadPage' /* webpackChunkName: "ReloadPage" */),
    Sidebar,
    Toolbar,
    TutorialSnackbar: () =>
      import(
        '@/modules/User/components/TutorialSnackbar' /* webpackChunkName: "TutorialSnackbar" */
      )
  },

  data: () => ({
    errorStatus: undefined,
    forceReload: false,
    showTutorials: undefined,
    unwatchRouteFn: undefined
  }),

  computed: {
    ...mapState(AUTHENTICATION_NAMESPACE, ['current', 'loadingCurrent']),
    cssVars() {
      return {
        '--sidebar-width': this.sidebarWidthPx + 'px'
      };
    },
    contentPaddingPx() {
      return this.isMobile ? 0 : 24; // Padding between Sidebar and Content
    },
    sidebarWidthPx() {
      return this.isMobile ? 0 : 100;
    },
    contentTotalSpaceLeftPx() {
      return this.sidebarWidthPx + this.contentPaddingPx; // space between left window border and content
    },
    contentStyle() {
      if (!this.$auth.check() || this.$route.meta.noSidebar) {
        return;
      }

      return this.$route.meta.noToolbar || this.$route.meta.noHeader
        ? `
        padding-left: ${this.$auth.check() ? this.contentTotalSpaceLeftPx : 0}px;
        height: 100%;
      `
        : `
        padding-bottom: ${this.contentPaddingPx}px;
        padding-right: ${this.contentPaddingPx}px;
        padding-left: ${this.contentTotalSpaceLeftPx}px;
      `;
    },
    toolbarStyle() {
      return this.isMobile
        ? `padding-left: ${this.contentPaddingPx}px;
           padding-right: ${this.contentPaddingPx}px;
          `
        : `margin-left: ${this.contentTotalSpaceLeftPx}px`;
    },
    contactBarStyle() {
      return `padding-left: ${this.sidebarWidthPx + this.contentPaddingPx}px`;
    }
  },

  methods: {
    ...mapActions(FAILURE_NAMESPACE, ['checkStatus']),

    async loginWithToken(token) {
      if (!token) {
        return;
      }

      this.setLoadingCurrent();
      this.$auth.token(AUTH_TOKEN_KEY, token);
      await this.$auth
        .fetch({})
        .then(async ({ data }) => {
          this.setCurrent(data.id);

          if (this.$auth.redirect()?.from) {
            // user was not authenticated and got redirected to login, use route from before redirect
            return this.$router.replace({
              ...this.$auth.redirect().from,
              query: {
                ...this.$auth.redirect().from.query,
                [this.queryParam.USE_TOKEN]: undefined
              }
            });
          }

          // user was already logged in, no redirect was executed
          this.$router.replace({
            ...this.$route,
            query: { ...this.$route.query, [this.queryParam.USE_TOKEN]: undefined }
          });
        })
        .catch(() => {})
        .finally(() => {
          this.resetLoadingCurrent();
        });
    },

    checkLoginViaDeeplink() {
      const tokenFromUrlQuery = window.location.search.split(`${this.queryParam.USE_TOKEN}=`)[1];
      if (tokenFromUrlQuery) {
        this.loginWithToken(tokenFromUrlQuery);
      }
    },

    async listenForApiStatus() {
      this.$eventHub.$on('show-failure-page', (errorStatus) => {
        this.handleApiResponseStatus(errorStatus);
      });

      this.$on('hook:beforeDestroy', () => {
        this.$eventHub.$off('show-failure-page');
      });

      const { status } = await this.checkStatus();

      this.handleApiResponseStatus(status);
    },

    handleApiResponseStatus(status) {
      const reloadTimeStampFromUrl = Number(
        window.location.search.split([this.queryParam.RELOAD_ON_ERROR] + '=')[1]
      );

      if (!ERROR_STATES.includes(status)) {
        this.errorStatus = null;

        if (reloadTimeStampFromUrl) {
          // wait until vue-router is initiated and remove timestamp from query params
          this.unwatchRouteFn = this.$watch('$route', () => {
            this.setQueryParam(this.queryParam.RELOAD_ON_ERROR, undefined, true);
            this.unwatchRouteFn();
          });
        }

        return;
      }

      if (
        status === ErrorStatus.DISTURBANCE &&
        (isNaN(reloadTimeStampFromUrl) ||
          reloadTimeStampFromUrl + MILLISECONDS_PER_DAY < Date.now())
      ) {
        this.reloadPage();
        return;
      }

      this.errorStatus = status;
    },

    reloadPage() {
      const url = new URL(window.location.href);
      url.searchParams.set(this.queryParam.RELOAD_ON_ERROR, String(Date.now()));
      window.location.replace(url);
    },

    listenForUserChange() {
      this.$eventHub.$on('current-changed', () => (this.forceReload = true));
      this.$on('hook:beforeDestroy', () => {
        this.$eventHub.$off('current-changed');
      });
    },

    initiateTutorialSettings() {
      this.showTutorials = JSON.parse(localStorage.getItem(TUTORIAL_STORAGE)) !== false;

      this.$eventHub.$on('update-tutorial-settings', (value) => this.updateTutorialSettings(value));
      this.$on('hook:beforeDestroy', () => {
        this.$eventHub.$off('update-tutorial-settings');
      });
    },

    updateTutorialSettings(value) {
      localStorage.setItem(TUTORIAL_STORAGE, value);
      this.showTutorials = value;
    }
  },

  async created() {
    this.checkLoginViaDeeplink();

    this.listenForApiStatus();

    this.listenForUserChange();

    this.initiateTutorialSettings();
  }
};
</script>

<style scoped>
:root {
  --sidebar-width: 100px;
}

.app-background {
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: -1;
}

.app-container {
  height: 100%;
  width: 100vw;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.app-container .v-main {
  flex: 1 1 100%;
}

>>> .v-main__wrap {
  display: flex;
  flex-direction: column;
}
</style>
